.questionCardContainer {
  padding: 1rem;
  border-top: 8px solid #f1f4f6;
  border-left: 8px solid #f1f4f6;


  .question_Button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.2rem 0rem 0rem;
    position: relative;
    width: auto;
    width: 100%;

    .index_ques {
      display: flex;
      width: 100%;
      align-items: baseline;
    }

    
    li {
      font: normal normal 500 18px/27px Poppins;
    }

    .buttons {
      margin: 10px 0;

      button {
        border: none;
        background-color: #fc8643;
        color: #fff;
        padding: 0.3rem 1rem;
        border-radius: 19px;
      }
    }

    .quet-selected {

      ul {
        display: flex;
        align-items: flex-start;

        li {
          button {
            background: transparent;

          }
        }

        .qust-radio span:nth-child(1) {
          background: #53DA3D;
          width: 20px;
          height: 20px;
          display: inline-block;
          line-height: 20px;
          border-radius: 50%;
          color: #fff;
          font-size: 10px;
          margin-right: 5px;
        }

        .qust-radio span:nth-child(2) {
          background: #EE5353;
          width: 20px;
          height: 20px;
          display: inline-block;
          line-height: 20px;
          border-radius: 50%;
          color: #fff;
          font-size: 10px;
        }

        .bkg-gry {
          padding: 5px;
          background: #F1F4F6;
          width: 60px;
          height: 32px;
          margin: 0 6px 15px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }

        .time_select {
          padding: 0;
          border: none;

          select {
            background: #F1F4F6;
            border-radius: 6px;
            border: none;
            font-size: 14px;
          }
        }
      }
    }
  }

  .hrLine {
    height: 3px;
    background-color: #f1f4f6;
  }

  .questionBreak {
    background-color: #f1f4f6;
    width: 100%;
    height: 8px;
  }
}

@media screen and (max-width: 960px) {

  .questionCardContainer {

    padding: 1rem;
    border-top: 8px solid #f1f4f6;
    border-left: 8px solid #f1f4f6;
    border-right: 8px solid #f1f4f6;

    .question_Button {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0.2rem 0rem 0.5rem;

      .index_ques {
        width: 100%;
      }

      .qest-text {
        width: 100%;
        text-overflow: wrap;
        text-align: left;
      }

      li {
        font: normal normal 500 18px/27px Poppins;
      }

      .buttons {
        margin: 10px 0 10px -15px;

        button {
          border: none;
          background-color: #fc8643;
          color: #fff;
          padding: 0.3rem 0.1rem;
          border-radius: 14px;
          width: 132px;
        }
      }

      .quet-selected {

        ul {
          width: 100%;
          display: flex;
          align-items: flex-start;

          li {
            button {
              background: transparent;
              padding: 0 5px;
            }
          }

          .qust-radio span:nth-child(1) {
            background: #53DA3D;
            width: 20px;
            height: 20px;
            display: inline-block;
            line-height: 20px;
            border-radius: 50%;
            color: #fff;
            font-size: 10px;
            margin-right: 5px;
          }

          .qust-radio span:nth-child(2) {
            background: #EE5353;
            width: 20px;
            height: 20px;
            display: inline-block;
            line-height: 20px;
            border-radius: 50%;
            color: #fff;
            font-size: 10px;
          }

          .bkg-gry {
            padding: 5px;
            background: #F1F4F6;
            width: 60px;
            height: 32px;
            margin: 0 6px 15px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

          }

          .time_select {
            padding: 0;
            border: none;

            select {
              background: #F1F4F6;
              border-radius: 6px;
              border: none;
              font-size: 14px;
            }
          }
        }
      }
    }

    .hrLine {
      height: 3px;
      background-color: #f1f4f6;
    }
   
    .questionBreak {
      background-color: #f1f4f6;
      width: 100%;
      height: 8px;
    }
  }
}

.qest-text{
  font-family: "MJXZERO", "MJXTEX", "Times New Roman", "SFPro", sans-serif !important;
}

.singleOption{
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: baseline;
  border-radius: 4px;
  padding: 0.5rem;
  font-family: "MJXZERO", "MJXTEX", "Times New Roman", "SFPro", sans-serif !important;
}