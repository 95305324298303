.headerFilter {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 1rem;
  .filterText {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.7rem;
    span {
      font: normal normal 700 32px/48px Poppins;
      color: #2b2b2b;
    }
    img {
      object-fit: cover;
    }
    p {
      font: normal normal 400 20px/30px Poppins;
      color: #2b2b2b;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  .filterButtons {
    display: flex;
    flex-wrap: wrap;
    margin-top: auto;
    margin-bottom: auto;
    gap: 1rem;
    .singleButtonContainer {
      border-radius: 20px;
      border: 1px solid #f16b24;
      background-color: #ffffff;
      color: #2b2b2b;
      padding: 0.3rem 1rem;
      option {
        background-color: #fff;
        cursor: pointer;
        &:hover {
          background-color: #fc8643;
        }
      }
    }
  }
}

.singleButtonContainer {
  color: #2B2B2B;
  &:hover{
    cursor: pointer;
    background-color: #fc8643;
  }
}

.clickedButton{
  color: #fff;
  background-color: #fc8643;
}

.test_quiz_whole_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 2rem;
  margin: 2rem 1rem;
}

.test_quiz_container {
  padding: 1.5rem 1rem;
  padding:  1rem;
  width: auto;
  position: relative;
  border-radius: 12px;
  border: none;
  border-style: none;
  box-shadow: -1px 3px rgba(0, 0, 0, 0.25), 1px 5px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  .test_quiz_details {
    margin-bottom: 0.7rem;
    span {
      font: normal normal 700 24px/36px Poppins;
      color: rgba(43, 43, 43, 1);
    }
    .testORQuiz_questions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.6rem;
      margin-top: 0.7rem;
      span {
        background-color: #15b0bf;
        color: white;
        border-radius: 7px;
        padding: 0.2rem 0.4rem;
        font: normal normal 500 18px/27px Poppins;
      }
      p {
        padding: 0.2rem 0.5rem;
        border-radius: 3px;
        margin-top: auto;
        margin-bottom: auto;
        background-color: #f1f4f6;
        font: normal normal 500 16px/24px Poppins;
      }
    }
    p {
      font-size: 18px;
      line-height: 2.25rem;
      font-weight: 700;
    }
  }
  .test_quiz_footer {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    p {
      color: #2b2b2b;
      margin-top: auto;
      margin-bottom: auto;
      font: normal normal 600 16px/20px Poppins;
      span {
        font: normal normal 600 16px/20px Poppins;
      }
    }
    span {
      font: normal normal 400 16px/20px Poppins;
    }
  }
  .top_right_corner {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(252, 134, 67, 1);
    border-radius: 0px 12px 0px 12px;
    padding: 0.4rem 0.6rem;
    text-align: center;
    p {
      color: #fff;
      font-size: 18px;
      line-height: 27px;
      font-weight: 400;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}

@media screen and (max-width: 1200px) {
  .headerFilter {
    .filterText {
      span {
        font: normal normal 700 26px/32px Poppins;
      }
      p {
        font: normal normal 400 18px/26px Poppins;
      }
    }
    .filterButtons {
      padding: 0.3rem 0.3rem;
      gap: 0.5rem;
      .singleButtonContainer {
        select {
          padding: 0.2rem 0.3rem;
        }
      }
    }
  }

  .test_quiz_container {
    padding: 1rem;
    .test_quiz_details {
      span {
        font: normal normal 700 16px/24px Poppins;
      }
      .testORQuiz_questions {
        span {
          font: normal normal 400 14px/24px Poppins;
        }
        p {
          font: normal normal 500 12px/22px Poppins;
        }
      }
    }
    .test_quiz_footer {
      gap: 1rem;
      p {
        font: normal normal 600 14px/18px Poppins;
        span {
          font: normal normal 600 14px/18px Poppins;
        }
      }
      span {
        font: normal normal 600 14px/18px Poppins;
      }
    }
    .top_right_corner {
      padding: 0.1rem 0.4rem;
      p {
        font: normal normal 400 14px/20px Poppins;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .headerFilter {
    gap: 1rem;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .filterText {
      span {
        font: normal normal 400 18px/36px Poppins;
      }
      img {
        object-fit: cover;
      }
      p {
        font: normal normal 400 18px/36px Poppins;
      }
    }
    .filterButtons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      padding: 0.3rem 0.3rem;
      .singleButtonContainer {
        select {
          padding: 0.3rem 1rem;
        }
      }
    }
  }
  .test_quiz_whole_container {
    grid-template-columns: repeat(1, 1fr);
  }
  .test_quiz_container {
    .test_quiz_details {
      span {
        font: normal normal 700 16px/24px Poppins;
      }
      .testORQuiz_questions {
        margin-top: 0.5rem;
        span {
          font: normal normal 500 14px/24px Poppins;
        }
      }
    }
    .test_quiz_footer {
      p {
        font: normal normal 600 14px/18px Poppins;
        span {
          font: normal normal 600 14px/18px Poppins;
        }
      }
      span {
        font: normal normal 600 14px/18px Poppins;
      }
    }
  }
}

.pt-header {
 // font-size: calc(24px + 0.5vw) !important;
  //font-size: clamp(1.2rem, 2.5vw, 1.5rem) !important;
  font-size: clamp(1.2rem, 3vw, 1.8rem) !important;
}
.pt-normal {
  font-size: calc(18px + 0.5vw) !important;
}
.pt-small {
  font-size: calc(14px + 0.3vw) !important;
}
.pt-extra-small {
  font-size: calc(11px + 0.3vw) !important;
}
