
.icon-download:before { content: '\e800'; } /* '' */
.icon-concept:before { content: '\e801'; } /* '' */
.icon-info:before { content: '\e802'; } /* '' */
.icon-profile:before { content: '\e803'; } /* '' */
.icon-scholorship:before { content: '\e804'; } /* '' */
.icon-search-01:before { content: '\e805'; } /* '' */
.icon-back-arrow:before { content: '\e806'; } /* '' */
.icon-logout:before { content: '\e807'; } /* '' */
.icon-next:before { content: '\e808'; } /* '' */
.icon-next2:before { content: '\e809'; } /* '' */
.icon-share:before { content: '\e80a'; } /* '' */
.icon-bookmark:before { content: '\e80b'; } /* '' */
.icon-time:before { content: '\e80c'; } /* '' */
.icon-minus:before { content: '\e80d'; } /* '' */
.icon-edit:before { content: '\e80e'; } /* '' */
.icon-call-us:before { content: '\e80f'; } /* '' */
.icon-email-us:before { content: '\e810'; } /* '' */
.icon-leader:before { content: '\e811'; } /* '' */
.icon-quicktips:before { content: '\e812'; } /* '' */
.icon-chapters:before { content: '\e813'; } /* '' */
.icon-blogs:before { content: '\e814'; } /* '' */
.icon-feeds-game:before { content: '\e815'; } /* '' */
.icon-feeds-testimonials:before { content: '\e816'; } /* '' */
.icon-feed-subject:before { content: '\e817'; } /* '' */
.icon-active-students:before { content: '\e818'; } /* '' */
.icon-challenge:before { content: '\e819'; } /* '' */
.icon-chemistry:before { content: '\e81a'; } /* '' */
.icon-coins:before { content: '\e81b'; } /* '' */
.icon-full-screen:before { content: '\e81c'; } /* '' */
.icon-list:before { content: '\e81d'; } /* '' */
.icon-maths:before { content: '\e81e'; } /* '' */
.icon-pause:before { content: '\e81f'; } /* '' */
.icon-physics:before { content: '\e820'; } /* '' */
.icon-powerups:before { content: '\e821'; } /* '' */
.icon-civics:before { content: '\e822'; } /* '' */
.icon-cricket:before { content: '\e823'; } /* '' */
.icon-cyber:before { content: '\e824'; } /* '' */
.icon-economics:before { content: '\e825'; } /* '' */
.icon-geography:before { content: '\e826'; } /* '' */
.icon-graph:before { content: '\e827'; } /* '' */
.icon-history:before { content: '\e828'; } /* '' */
.icon-science:before { content: '\e829'; } /* '' */
.icon-quiz:before { content: '\e82a'; } /* '' */
.icon-political:before { content: '\e82b'; } /* '' */
.icon-biology:before { content: '\e82c'; } /* '' */
.icon-english:before { content: '\e82d'; } /* '' */
.icon-exit:before { content: '\e82e'; } /* '' */
.icon-colors:before { content: '\e82f'; } /* '' */
.icon-fullscreen:before { content: '\e830'; } /* '' */
.icon-lock:before { content: '\e831'; } /* '' */
.icon-right:before { content: '\e832'; } /* '' */
.icon-wrong:before { content: '\e833'; } /* '' */
.icon-settings:before { content: '\e834'; } /* '' */
.icon-share-1:before { content: '\e835'; } /* '' */
.icon-chat:before { content: '\e836'; } /* '' */
.icon-send:before { content: '\e837'; } /* '' */
.icon-delete:before { content: '\e838'; } /* '' */
.icon-learn:before { content: '\e839'; } /* '' */
.icon-practice:before { content: '\e83a'; } /* '' */
.icon-channel:before { content: '\e83b'; } /* '' */
.icon-bookmark-1:before { content: '\e83c'; } /* '' */
.icon-live-classes:before { content: '\e83d'; } /* '' */
.icon-leaderboard:before { content: '\e83e'; } /* '' */
.icon-profile-1:before { content: '\e83f'; } /* '' */
.icon-search-1:before { content: '\e840'; } /* '' */
.icon-ask-doubt:before { content: '\e841'; } /* '' */
.icon-assessment:before { content: '\e842'; } /* '' */
.icon-ask-doubt-1:before { content: '\e843'; } /* '' */
.icon-doubt:before { content: '\e844'; } /* '' */
.icon-reports:before { content: '\e845'; } /* '' */
.icon-drive:before { content: '\e846'; } /* '' */
.icon-code:before { content: '\e847'; } /* '' */
.icon-default:before { content: '\e848'; } /* '' */
.icon-exit-fullscreen:before { content: '\e849'; } /* '' */
.icon-copy-icon:before { content: '\e84a'; } /* '' */
.icon-install:before { content: '\e84b'; } /* '' */
.icon-calendar:before { content: '\e84c'; } /* '' */
.icon-bright:before { content: '\e84d'; } /* '' */
.icon-dark:before { content: '\e84e'; } /* '' */
.icon-inform:before { content: '\e84f'; } /* '' */
.icon-registered-students:before { content: '\e850'; } /* '' */
.icon-time-1:before { content: '\e851'; } /* '' */
.icon-active-student:before { content: '\e852'; } /* '' */
.icon-dashboard:before { content: '\e853'; } /* '' */
.icon-inactive-students:before { content: '\e854'; } /* '' */
.icon-logout-1:before { content: '\e855'; } /* '' */
.icon-profile-2:before { content: '\e856'; } /* '' */
.icon-user:before { content: '\e857'; } /* '' */
.icon-plus:before { content: '\e858'; } /* '' */
.icon-active-students-1:before { content: '\e859'; } /* '' */
.icon-insights:before { content: '\e85a'; } /* '' */
.icon-livetv:before { content: '\e85b'; } /* '' */
.icon-summary:before { content: '\e85c'; } /* '' */
.icon-evs:before { content: '\e85d'; } /* '' */
.icon-report:before { content: '\e85e'; } /* '' */
.icon-refresh:before { content: '\e85f'; } /* '' */
.icon-funzone:before { content: '\e860'; } /* '' */
.icon-i:before { content: '\e861'; } /* '' */
.icon-filter:before { content: '\e862'; } /* '' */
.icon-filter2:before { content: '\e863'; } /* '' */
.icon-liveclasses:before { content: '\e864'; } /* '' */
.icon-accuracy-icon:before { content: '\e865'; } /* '' */
.icon-assigned:before { content: '\e866'; } /* '' */
.icon-checkbox:before { content: '\e868'; } /* '' */
.icon-whiteassessments:before { content: '\e86b'; } /* '' */
.icon-poll:before { content: '\e86c'; } /* '' */
.icon-print:before { content: '\e86d'; } /* '' */
.icon-questions-icon:before { content: '\e86e'; } /* '' */
.icon-quiz-1:before { content: '\e86f'; } /* '' */
.icon-multiplechoice:before { content: '\e870'; } /* '' */
.icon-participant-icon:before { content: '\e872'; } /* '' */
.icon-whiteliveclasses:before { content: '\e873'; } /* '' */
.icon-whitereports:before { content: '\e874'; } /* '' */
.icon-whitedoubts:before { content: '\e875'; } /* '' */
