.login {
  width: 100vw;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  margin: 0px;
  padding: 0px;
  background-image: linear-gradient(to right bottom, #15388c, #00509e, #0067ab, #007db4, #1892bb); }

.login-scrollable {
  overflow-y: auto;
  height: 100%;
  width: 100%; }

.login-inner {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.toggle-credits {
  width: 100%; }

@media screen and (max-height: 600px) {
  .login-scrollable {
    align-items: flex-start; } }

@media screen and (max-height: 620px) {
  #copyright {
    display: none; } }

@media screen and (min-width: 640px) {
  .login {} }

@media screen and (min-width: 960px) {
  .login {} }

@media screen and (min-width: 1200px) {
  .login {} }

@media screen and (min-width: 1600px) {
  .login {} }

.logo {
  padding: 0px 10px; }
