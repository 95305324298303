.input_label_container{
    position: relative;
    display: grid;
    place-items: center;
    input[type="radio"]:checked + label::after{
        background: url(../../img/tick_selected.png);
        background-repeat: no-repeat;
        background-size: 0.6rem;
        color: #15B15C;
        position: absolute;
        top: 2px;
        right: -10px;
    }
    input[type="radio"]:not(:disabled) ~ label{
        border: 1px solid #969696;
        border-radius: 0.5rem;
        color: #767676;
        height: 2.8rem;
    }
    
    input[type="radio"]:checked + label{
        border: 1px solid #15B15C;
        color: #15B15C;
        border-radius: 0.5rem;
    }
}

.input_label_container{
    .label_styles{
        display: grid; 
        place-items: center;
        width: 100%;
        height: 100%;
    }
}

.resend_otp{
  a:link {color:#000000;}      /* unvisited link */
  a:visited {color:#000000;}  /* visited link */
  a:hover {color:#fc8643;}  /* mouse over link */
  a:active {color:#000000;}  /* selected link */
}