@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&display=swap");

* {
  margin: 0;
  padding: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Poppins";
}

.page_header_teacher {
  font-weight: 600;
  font-size: 34px;
  line-height: 51px;
  color: #444444;
}

.page_subheader_teacher {
  font-weight: 500;
  font-size: 24.4613px;
  line-height: 37px;
  text-decoration-line: underline;
  color: #686868;
}

.content_wrapper {
  margin-top: 30px;
}


.page_header {
  font-style: normal;
  font-weight: 500;
  font-size: 23.8922px;
  line-height: 36px;
  /* identical to box height */

  text-decoration-line: underline;

  color: #686868;
  margin-bottom: 10px;
}

ul.custom_student_progress_graph {
  border: none;
  justify-content: space-evenly;
  background-color: #f5f5f5;
  margin-bottom: 4px;
  box-shadow: 0px 1.21067px 6.05337px rgba(0, 0, 0, 0.13);
  border-radius: 3.63202px 3.63202px 0px 0px;
  display: flex;
  align-content: center;
}

ul.custom_student_progress_graph li.nav-item {
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.custom_student_progress_graph li {
  border: none;
  background-color: #f5f5f5;
}

ul.custom_student_progress_graph li button,
ul.custom_student_progress_graph li button.nav-link.active,
ul.custom_student_progress_graph li button.nav-link:focus,
ul.custom_student_progress_graph li button.nav-link:hover {
  border: none !important;
  background-color: transparent;
}

ul.custom_student_progress_graph li button.nav-link:hover {
  color: #fc8643;
}

ul.custom_student_progress_graph li button.nav-link {
  font-weight: 600;
  font-size: 16.0137px;
  line-height: 24px;
  padding: 20px 10px;
  color: #ffc6a6;
}

ul.custom_student_progress_graph li button.nav-link.active {
  color: #fc8643;
  font-weight: 600;
  border-bottom: 2.42135px solid #fc8643 !important;
}

.custom_tab_content {
  overflow-y: auto;
  max-height: 100%;
  margin: 0px 10px 0px 10px;
}

.custom_tab_content::-webkit-scrollbar {
  height: 4px;
}

.custom_tab_content::-webkit-scrollbar-track {
  background-color: #fff !important;
  border: 0.4px solid rgb(137, 137, 137);
}

.custom_tab_content::-webkit-scrollbar-thumb {
  background-color: rgb(137, 137, 137);
}

div.input input,
div.input input:focus {
  border: none;
  text-align: left;
  font-weight: 400;
  font-size: 9.95px;
  line-height: 28px;
  /* or 278% */
  width: 100%;
  color: #b1b1b1;
  background-color: transparent;
  outline: none;
}

div.input {
  border: 2.3px solid #959595;
  border-radius: 3px;
  min-width: 175px;
  padding: 0px 5px;
  background: #ffffff;
}

.reportcard {
  padding: 15px 30px;
  background: #f5f5f5;
  border-radius: 12.4599px;
  height: 100%;
}

.reportcard h3,
.leaderboard h3 {
  font-weight: 500;
  font-size: 17.3746px;
  line-height: 26px;
  color: #585858;
  margin-bottom: 20px;
}

.report_card_table {
  background: #ffffff;
  border-radius: 12.8665px;
}

.reportcard .report_card_table .custom_report_table .progress {
  display: inline-flex;
  min-width: 60px;
  margin-left: 10px;
}

.custom_report_table tr th {
  font-style: normal;
  font-weight: 600;
  font-size: 14.0267px;
  line-height: 21px;
  /* identical to box height */
  border: 2.14442px solid #f5f5f5;
  border-top: none;
  border-bottom: none;
  color: #7b7b7b;
}

.custom_report_table tr td {
  font-style: normal;
  font-weight: 500;
  font-size: 17.9416px;
  line-height: 27px;
  /* identical to box height */
  border: 2.14442px solid #f5f5f5;
  border-top: none;
  border-bottom: none;
  color: #9c9c9c;
}

.custom_report_table tr td .progress,
.performance_section_table tr td .progress {
  background: #e2e2e2;
  box-shadow: inset 0px 1.16889px 1.16889px rgba(0, 0, 0, 0.1);
  border-radius: 7.01336px;
  height: 17.53px;
  padding: 0;
}

.performance_section_table tr td .progress {
  width: 77%;
}

.custom_report_table tr td .progress .progress-bar,
.performance_section_table tr td .progress .progress-bar {
  background: #43b9fc;
  box-shadow: 1.16889px 0px 2.33779px rgba(0, 0, 0, 0.15);
  border-radius: 7.01336px;
}

.table.custom_report_table> :not(:first-child) {
  border: none;
  border-top: 2.14442px solid #f5f5f5;
}

.table.custom_report_table tr th:not(:first-child),
.table.custom_report_table tr td:not(:first-child) {
  text-align: center;
}

.progresstd {
  display: flex;
  align-items: center;
}

.leaderboard {
  padding: 15px 30px;
  background: #f5f5f5;
  border-radius: 12.4599px;
  height: 100%;
}

.mini_leaderboard_listing .mini_leaderboard_listing_item {
  display: flex;
  align-items: center;
}

.mini_leaderboard_listing .mini_leaderboard_listing_item div img {
  width: 35.9px;
  height: 35.9px;
}

.mini_leaderboard_listing .mini_leaderboard_listing_item div {
  margin: 10px;
  font-weight: 500;
  font-size: 16.3645px;
  line-height: 25px;
  /* identical to box height */

  color: #9c9c9c;
  text-transform: capitalize;
}

.mini_leaderboard_listing .mini_leaderboard_listing_item div:nth-child(1) {
  min-width: 40px;
}

.custom_mini_board li {
  flex: 1 1 0px;
  margin-right: 5px;
}

.custom_mini_board li:nth-last-child(1) {
  margin-right: 0px;
}

.custom_mini_board.nav-tabs .nav-link {
  background: #ffffff;
  border-radius: 5.84447px 5.84447px 0px 0px;
  font-weight: 600;
  font-size: 14.0267px;
  line-height: 21px;
  /* identical to box height */

  color: #7b7b7b;
  width: 100%;
}

.nav-tabs {
  border-bottom: 1px solid #fc8643;
}

.custom_mini_board.nav-tabs .nav-item.show .nav-link,
.custom_mini_board.nav-tabs .nav-link.active {
  background: #fc8643 !important;
  /* box-shadow: 0px -1.16889px 4.67557px rgba(0, 0, 0, 0.15); */
  border-radius: 5.84447px 5.84447px 0px 0px;
  color: #ffffff !important;
}

.custom_mini_board_tab,
.custom_releam_tab {
  background-color: #ffffff;
  padding-top: 10px;
  padding-left: 15px;
  /* border-top: 1px solid #fc8643; */
}

.custom_releam_tab>div {
  margin-top: 20px;
  padding-bottom: 20px;
}

.custom_releam_tab div div {
  font-weight: 500;
  font-size: 14.0267px;
  line-height: 21px;
  /* identical to box height */

  color: #939393;
  margin-bottom: 20px;
}

.big_tab_section {
  margin-bottom: 30px;
  margin-top: 30px;
}

.big_tab_section h3 {
  font-weight: 500;
  font-size: 17.3746px;
  line-height: 26px;
  color: #585858;
  margin-bottom: 20px;
}

.big_tabs {
  display: flex;
  margin-bottom: 30px;
}

.big_tabs .tab {
  background: #ffffff;
  box-shadow: 1.16889px 2.33779px 9.35114px 2.33779px rgba(0, 0, 0, 0.1);
  border-radius: 5.8144px;
  /* width: 90px;
  height: 90px; */
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  transition: box-shadow 0.4s;
  position: relative;
}

.big_tabs .tab:hover {
  box-shadow: 1.16889px 2.33779px 9.35114px 2.33779px rgba(0, 0, 0, 0.19);
}

.big_tabs .tab img {
  width: 50%;
}

.releam_block {
  margin-bottom: 40px;
}

.score_section {
  background: #ffffff;
  box-shadow: 0px 2px 26px -1px rgba(0, 0, 0, 0.15);
  border-radius: 11.6889px;
  padding: 10px 50px;
  display: flex;
  flex-direction: column;
  height: 82%;
  justify-content: center;
  margin-top: 30px;
}

.showing_results {
  font-size: 17px;
  font-weight: 500;
}

.icon_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0px;
}

.icon_section div:nth-child(1) {
  font-style: normal;
  font-weight: 400;
  font-size: 18.5942px;
  line-height: 24px;
  text-align: right;
  color: #7b7b7b;
  max-width: 90px;
}

.icon_section .icon img {
  width: 37px;
  position: absolute;
  left: 0;
}

.icon_section .icon {
  font-weight: 400;
  font-size: 18.1616px;
  line-height: 24px;
  /* or 130% */
  position: relative;
  color: #7b7b7b;
  padding-left: 50px;
  width: 170px;
}

.icon_section .dots {
  font-weight: 600;
  font-size: 38.0358px;
  line-height: 24px;
  /* identical to box height, or 62% */

  color: #7b7b7b;
}

.icon_section .percentage {
  font-weight: 500;
  font-size: 18.7023px;
  line-height: 28px;
  /* identical to box height */
  width: 50px;
  color: #828282;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 105%;
  height: 110%;
  top: -7px;
  left: -15px;
  background: #00000024;
}

.hide {
  display: none;
}

.filter_section,
.principle_component {
  position: relative;
}

.tab.active svg path {
  fill: #fc8643;
}

.tab.active {
  color: #fc8643;
  font-weight: 600;
}

.custom_studen-class-selection.dropdown {
  max-width: 404.44px;
  margin-bottom: 20px;
}

.custom_studen-class-selection.dropdown.teacher {
  max-width: 590px;
  margin: auto;
  margin-bottom: 30px;
}

.custom_studen-class-selection .tooltiptext {
  opacity: 0;
  /* width: 120px; */
  background-color: rgba(0, 0, 0, 0.848);
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 10px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top: -20px;
  transition: opacity 0.5s;
  pointer-events: none;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom_studen-class-selection .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.848) transparent transparent transparent;
}

.custom_studen-class-selection.dropdown.disabled-click:hover .tooltiptext {
  opacity: 1;
}

.custom_studen-class-selection.dropdown.disabled-click:active {
  pointer-events: none;
}

.custom_studen-class-selection.dropdown .dropdown-menu.show {
  width: 100%;
  max-height: 350px;
  overflow: scroll;
  overflow-x: hidden;
}

.custom_studen-class-selection.dropdown>button {
  background-color: #ffffff !important;
  border: 2.07665px solid #e7e7e7;
  border-radius: 5.53773px;
  color: #585858;
  height: 61.95px;
  width: 404.44px;
  text-align: left;
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 21.0401px;
  line-height: 32px;
  /* identical to box height */
  color: #585858;
}

.custom_studen-class-selection.dropdown.teacher>button {
  width: 590px;
}

.custom_studen-class-selection.dropdown>button span {
  font-weight: 500;
}

.custom_studen-class-selection.dropdown>button.dropdown-toggle:after {
  position: absolute;
  right: 0;
  height: 100%;
  width: 56.38px;
  border: 1px solid lightgray;
  top: 0;
  display: none;
}

.dash_drop_btn_list {
  position: absolute;
  right: 0;
  height: 100%;
  width: 56.38px;
  border-left: 1px solid lightgray;
  top: 0;
  display: flex;
  align-content: center;
  justify-content: center;
}

.custom_studen-class-selection .dash_drop_btn_list svg {
  max-width: 20px;
  -webkit-transition: -webkit-transform 0.4s ease-in-out;
}

.custom_studen-class-selection.show .dash_drop_btn_list svg {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.custom_studen-class-selection.dropdown>button.dropdown-toggle:hover,
.custom_studen-class-selection.dropdown>button.dropdown-toggle:focus,
.custom_studen-class-selection.dropdown>button.dropdown-toggle:active,
.custom_studen-class-selection.dropdown.show>.btn-primary.dropdown-toggle {
  border-color: #e7e7e7 !important;
  background-color: #ffffff !important;
  color: #585858;
}

.custom_studen-class-selection.dropdown .accordion-header,
.custom_studen-class-selection.dropdown .accordion-body {
  padding: 0px 10px;
}

.custom_studen-class-selection.dropdown .accordion-header button {
  border: none;
  background-color: transparent;
  font-size: 14px;
}

.custom_studen-class-selection.dropdown .card {
  margin-bottom: 0px;
}

.custom_studen-class-selection.dropdown .card .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  background-color: #fff;
  border-bottom: 0 solid #fff;
}

.custom_studen-class-selection.dropdown .card .card-header>div {
  width: 100%;
  padding-left: 10px;
}

.custom_studen-class-selection.dropdown .card .card-header>button {
  background-color: transparent !important;
  border: none;
  -webkit-transition: -webkit-transform 0.4s ease-in-out;
  margin-right: 11px;
}

.custom_studen-class-selection.dropdown .card .card-header>button.active {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.custom_studen-class-selection.dropdown .accordion {
  padding-left: 10px;
}

.custom_studen-class-selection.dropdown .card .card-header label input {
  margin-right: 10px;
}

.custom_studen-class-selection.dropdown .card .card-header label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.lil_ligher {
  opacity: 0.6;
}

.disabled {
  pointer-events: none;
}

.wrapper_for_page_padding {
  padding: 0px 20px;
}

.classListWrapper {
  display: flex;
  overflow-x: auto;
}

.classListWrapper {
  overflow-x: auto;
  padding-bottom: 5px;
}

.classListWrapper::-webkit-scrollbar {
  height: 4px;
}

.classListWrapper::-webkit-scrollbar-track {
  background-color: #fff !important;
  border: 0.4px solid rgb(137, 137, 137);
}

.classListWrapper::-webkit-scrollbar-thumb {
  background-color: rgb(137, 137, 137);
}

.classListItem {
  background: #f5f5f5;
  border-radius: 30.3174px;
  min-width: fit-content;
  min-height: 45.48px;
  font-weight: 500;
  font-size: 17.7886px;
  line-height: 27px;
  color: #585858;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  padding: 0px 10px;
}

.classListItem.active {
  pointer-events: none;
}

.classListItem.active,
.classListItem:hover {
  background: #fc8643;
  color: #ffffff !important;
}

.nav-tabs .nav-link.active {
  background-color: #f5f5f5 !important;
}

.mini_leaderboard_listing .mini_leaderboard_listing_item div.active_user_color {
  color: #fc8643;
}

/* .max-height_container {
  max-height: 400px;
  overflow: auto;
} */
.teacher_report_heading {
  font-weight: 500;
  font-size: 26.2931px;
  line-height: 39px;

  color: #585858;
}

.teacher_report_heading span {
  font-weight: 700;
  font-size: 26.2931px;
  line-height: 39px;
}

.insight_heading {
  font-weight: 600;
  font-size: 19.2448px;
  line-height: 29px;
  /* identical to box height */
  margin-bottom: 10px;
  color: #7b7b7b;
}

.insight_section {
  margin-bottom: 30px;
}

.insight_block_bordered_card {
  border: 1.2028px solid #aaaaaa;
  border-radius: 9.62238px;
  padding: 20px;
}

.upper,
.lower {
  margin-bottom: 10px;
}

.upper_heading {
  font-weight: 400;
  font-size: 18.6884px;
  line-height: 24px;
  /* identical to box height, or 130% */
  margin-bottom: 10px;
  color: #37ce34;
}

.upper_content,
.lower_content {
  max-height: 300px;
  overflow: auto;
  margin-left: 10px;
  padding: 10px;
  border-left: 3px solid lightgray;
}

.upper_content>div,
.lower_content>div {
  font-weight: 500;
  font-size: 15.4984px;
  line-height: 23px;
  color: #828282;
  margin-bottom: 5px;
}

.lower_heading {
  font-weight: 400;
  font-size: 18.5234px;
  line-height: 24px;
  /* identical to box height, or 130% */
  margin-bottom: 10px;
  color: #fc8643;
}

.upper_content>div span,
.lower_content>div span {
  float: right;
}

.leaderboard .tab-content {
  min-height: 70%;
  /* background: #fff; */
}

.listing_tab .tab-content {
  height: 70vh;
  background-color: #f5f5f5;
  /* padding: 10px; */
  overflow: hidden;
  /* overflow-y: auto; */
  padding-bottom: 10px;
}

.super_admin .overall_insight .page_heading {
  font-style: normal;
  font-weight: 500;
  font-size: 20.44px;
  line-height: 31px;
  /* identical to box height */
  margin-bottom: 20px;
  text-decoration-line: underline;

  color: #686868;
}

.student_count_block {
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 11.8999px;
  line-height: 18px;
  text-align: center;
  color: #585858;
  padding: 30px 20px;
  align-items: center;
  /* justify-content: space-around; */
}

.student_count_block>div {
  margin-right: 20px;
}

.student_count_block>div.sign {
  font-style: normal;
  font-weight: 500;
  font-size: 20.348px;
  line-height: 31px;
  /* identical to box height */

  text-align: center;

  color: #585858;
}

.super_school_rank_class {
  background-color: #f5f5f5;
}

.super_school_rank_class .header .heading {
  font-style: normal;
  font-weight: 600;
  font-size: 15.4704px;
  line-height: 23px;

  color: #fc8643;
}

.super_school_rank_class .header {
  padding: 20px;
  box-shadow: 0px 1.1696px 5.84799px rgba(0, 0, 0, 0.13);
  border-radius: 3.50879px 3.50879px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.super_school_rank_class .body {
  padding: 20px;
}

.super_performance_section_table thead {
  font-weight: 600;
  font-size: 14.4911px;
  line-height: 22px;
  /* identical to box height */
  color: #7b7b7b;
}

.super_listing {
  background-color: #fff;
  text-align: center;
}

.custom_super_classlist_toggle button.btn-primary,
.custom_super_classlist_toggle button.btn-primary:hover,
.custom_super_classlist_toggle button:not(:disabled):not(.disabled):active,
.custom_super_classlist_toggle.show>.btn-primary.dropdown-toggle {
  border: 0.766251px solid #e7e7e7 !important;
  border-radius: 2.04334px;
  background-color: #fff !important;
  color: #fc8643;
  min-width: 200px;
  text-align: left;
}

.custom_super_classlist_toggle button.dropdown-toggle:after {
  display: none;
}

.custom_super_classlist_toggle button.dropdown-toggle i,
.select_class i {
  position: absolute;
  right: 0;
  width: 35px;
  height: 100%;
  top: 0px;
  background-color: #d1d1d1;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.custom_super_classlist_toggle .dropdown-menu {
  min-width: 200px;
}

.custom_super_classlist_toggle .dropdown-menu>div {
  padding: 5px 10px;
}

.custom_super_classlist_toggle .dropdown-menu>.dropdown-item,
.custom_super_classlist_toggle .dropdown-menu>.dropdown-item:hover {
  font-weight: 400;
  color: #212529;
}

.custom_super_classlist_toggle .dropdown-menu>.dropdown-item.active,
.custom_super_classlist_toggle .dropdown-menu>.dropdown-item.active:hover,
.custom_super_classlist_toggle .dropdown-menu>.dropdown-item:hover {
  background-color: #e5e5e5 !important;
}

.filter_ana_heading {
  font-weight: 500;
  font-size: 20.44px;
  line-height: 31px;
  /* identical to box height */
  text-decoration-line: underline;
  color: #686868;
  margin-bottom: 20px;
}

.super_select_class_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select_class {
  max-width: 194px;
  min-height: 38px;
  border: 1.28298px solid #e7e7e7;
  border-radius: 3.42128px;
  overflow: hidden;
  position: relative;
  border-right: none;
}

.select_class .super_class_selection {
  background: #ffffff;
  border: none;
  min-width: 194px;
  min-height: 38px;
  padding: 0px 5px;
}

.select_class .super_class_selection::-ms-expand {
  display: none;
}

.search_box_dropdown {
  position: absolute;
  top: 42px;
  width: 100%;
  background: #fff;
  z-index: 99;
  padding: 5px;
  border: 0.5px solid lightgray;
  display: none;
}

.search_box_dropdown.show {
  display: block;
}

.search_box_dropdown>div {
  margin: 2px 0px;
  text-transform: capitalize;
  cursor: pointer;
}

#CommonPageHolder.autoheight {
  height: auto !important;
}

.c-multi-select-dropdown {
  /* margin: 100px auto; */
  /* font-family: "Roboto", sans-serif; */
  position: relative;
  /* width: 300px; */
}

.c-multi-select-dropdown:hover .c-multi-select-dropdown__options {
  display: block;
}

.c-multi-select-dropdown .c-multi-select-dropdown__selected {
  border: solid 1px #eee;
  font-size: 14px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.c-multi-select-dropdown .c-multi-select-dropdown__selected>img {
  height: 18px;
}

.c-multi-select-dropdown .c-multi-select-dropdown__options {
  display: none;
  position: absolute;
  box-sizing: border-box;
  left: 0;
  width: 100%;
  list-style: none;
  padding-left: 0px;
  border: solid 1px #eee;
  padding: 5px 0px;
  max-height: 400px;
  overflow: auto;
  background-color: #fff;
  z-index: 999;
}

.c-multi-select-dropdown .c-multi-select-dropdown__option {
  display: flex;
  align-items: center;
  padding: 6px 10px;
  cursor: pointer;
}

.c-multi-select-dropdown .c-multi-select-dropdown__option:hover {
  background-color: #eee;
}

.c-multi-select-dropdown .c-multi-select-dropdown__option-checkbox {
  margin-right: 6px;
}

.custom_student_listing_table tbody tr td {
  text-transform: capitalize;
}

.table th,
.table td {
  text-transform: capitalize;
  font-size: 15px;
}

.section_scrollable {
  margin-top: 30px;
  max-height: 350px;
  overflow-y: auto;
  margin-bottom: 50px;
}

.section_scrollable::-webkit-scrollbar,
.custom_studen-class-selection.dropdown .dropdown-menu.show::-webkit-scrollbar {
  height: 4px;
}

.section_scrollable::-webkit-scrollbar-track,
.custom_studen-class-selection.dropdown .dropdown-menu.show::-webkit-scrollbar-track {
  background-color: #fff !important;
  border: 0.4px solid rgb(137, 137, 137);
}

.section_scrollable::-webkit-scrollbar-thumb,
.custom_studen-class-selection.dropdown .dropdown-menu.show::-webkit-scrollbar-thumb {
  background-color: rgb(137, 137, 137);
}

.info_icon {
  background: #d9d9d9;
  width: 14px;
  height: 15.65px;
  border-radius: 50%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin-top: 5px;
}

.info_icon:hover {
  background-color: #c6c6c6;
}

.info_icon .tooltiptext {
  opacity: 0;
  /* width: 120px; */
  background-color: rgba(0, 0, 0, 0.848);
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 10px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top: -40px;
  transition: opacity 0.5s;
  pointer-events: none;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 300px;
  line-height: 1.3;
}

.info_icon:hover .tooltiptext {
  opacity: 1;
}

.info_icon img {
  /* width: 100%; */
  max-height: 12px;
}

@media only screen and (max-width: 1300px) {
  .reportcard {
    padding: 15px;
  }

  .reportcard h3,
  .leaderboard h3 {
    font-size: 17.3746px;
    margin-bottom: 10px;
  }

  .custom_report_table tr th {
    font-size: 7.38546px;
  }

  .custom_report_table tr td {
    font-size: 9.44677px;
    padding: 6px 5px;
  }

  .custom_report_table tr td .progress {
    height: 9.23px;
    margin-top: 10px;
  }

  .custom_mini_board.nav-tabs .nav-link {
    font-size: 11.5102px;
    padding: 5px 0px;
  }

  .leaderboard {
    padding: 15px;
  }

  .score_section {
    height: auto;
    margin-bottom: 30px;
    padding: 30px;
  }

  .wrapper_for_page_padding {
    padding: 0px 0px;
  }

  .performance_section_table thead {
    font-size: 13px;
    line-height: 12px;
  }

  /* .performance_section_table tbody {
    font-size: 6.5808px;
    line-height: 10px;
  } */

  .performance_section_table tr td .progress {
    height: 8.23px;
  }

  .performance_section_table tr td .progressandper span {
    margin-right: 5px;
  }

  .super_school_rank_class .header .heading {
    font-size: 12.4704px;
  }

  .custom_super_classlist_toggle button.btn-primary,
  .custom_super_classlist_toggle button.btn-primary:hover,
  .custom_super_classlist_toggle button:not(:disabled):not(.disabled):active {
    min-width: 127px;
    height: auto;
  }

  .custom_super_classlist_toggle button.dropdown-toggle i,
  .select_class i {
    width: 28px;
  }
}

@media only screen and (max-width: 900px) {
  .wrapper_for_page_padding.filtering_mobile {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.15s ease-out;
  }

  .wrapper_for_page_padding.filtering_mobile.show {
    max-height: 600px;
    transition: max-height 0.25s ease-in;
  }

  /* .performance_section_table tbody {
    font-size: 10px;
  } */
  .custom_student_listing_table thead {
    font-size: 13px;
  }

  .custom_student_listing_table {
    margin-bottom: 0px;
  }

  .icon_section div:nth-child(1),
  .icon_section .percentage {
    font-size: 16px;
  }

  .profile_section_heading {
    font-size: 20px;
    margin-top: 20px;
  }

  .score_section {
    padding: 10px 30px;
    margin-top: 10px;
  }

  .profile_page_wrapper .scrore_section_heading {
    font-size: 15px;
    margin-bottom: 10px;
  }

  .teacher_report_heading,
  .teacher_report_heading span {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .insight_heading {
    font-size: 15px;
  }

  .upper_heading,
  .lower_heading {
    font-size: 14px;
  }

  .upper_content>div,
  .lower_content>div {
    font-size: 13px;
  }

  .line_chart_section {
    min-height: 300px;
  }

  .custom_student_listing_table tbody tr td {
    font-size: 12px;
  }

  td:has(> .progressandper) {
    padding: 10px 2px !important;
    font-size: 9px;
  }

  .tab-content {
    margin-bottom: 20px;
  }

  .listing_tab .tab-content {
    height: 40vh;
  }

  .insight_section {
    margin-bottom: 100px;
  }

  .reportcard .report_card_table .custom_report_table .progress {
    margin: auto;
  }
}

@media only screen and (max-width: 500px) {
  .middle_border_block {
    height: 50px;
  }

  .middle_border_block div {
    width: 100%;
    height: 0;
  }

  .performance_section table.performance_section_table tbody tr td:nth-child(2) .progressandper .progress {
    display: none;
  }

  .performance_section table.performance_section_table tr th:nth-child(1),
  .performance_section table.performance_section_table tr td:nth-child(1) {
    max-width: 190px;
    font-size: 12px;
  }

  .performance_section table.performance_section_table tr th:nth-child(2),
  .performance_section table.performance_section_table tr td:nth-child(2) {
    /* max-width: 40px; */
    text-align: center;
  }

  .progressandper span {
    width: 30%;
  }

  tbody .profile_image {
    width: 20px;
    float: left;
  }

  .page_subheader_teacher {
    font-size: 16px;
  }

  /* .classListWrapper {
    overflow: scroll;
    scrollbar: 2px;
  } */

  .classListItem {
    font-size: 13px;
    /* min-width: 116.24px;
    min-height: 35.48px; */
  }

  .custom_studen-class-selection.dropdown.teacher>button {
    width: 100%;
    font-size: 17px;
    height: 50px;
  }

  .container-fluid .container-fluid {
    padding: 0px;
  }

  .reportcard {
    padding: 10px;
  }

  .reportcard .report_card_table .custom_report_table .progress {
    display: none;
  }

  .custom_report_table tr th {
    line-height: 1.5;
  }

  .custom_report_table tr th {
    width: 30px;
    padding: 4px;
  }

  .custom_report_table td {
    padding: 0.5rem !important;
  }

  .info_icon .tooltiptext {
    transform: scale(0.7);
  }

  .custom_report_table tr th:not(:last-child) .info_icon .tooltiptext {
    transform: translate(-33%, -50%);
  }

  .custom_report_table tr th:nth-child(4) .info_icon .tooltiptext,
  .custom_report_table tr th:nth-child(5) .info_icon .tooltiptext {
    transform: translate(-65%, -50%);
  }

  .custom_report_table tr th:nth-child(6) .info_icon .tooltiptext {
    transform: translate(-84%, -50%);
  }

  .custom_studen-class-selection.dropdown>button {
    width: 100%;
    height: 51px;
    font-size: 16px;
  }

  /* .custom_mini_board.nav-tabs .nav-link {
    font-size: 9px;
  } */
  .reportcard h3,
  .leaderboard h3,
  .big_tab_section h3,
  .showing_results {
    font-size: 14px;
  }

  .progresstd {
    display: block;
  }

  .mini_leaderboard_listing .mini_leaderboard_listing_item div {
    font-size: 12.3645px;
    margin: 4px 5px;
  }

  .mini_leaderboard_listing .mini_leaderboard_listing_item div img {
    width: 25.9px;
    height: 25.9px;
  }

  .icon_section div:nth-child(1),
  .icon_section .percentage {
    font-size: 14px;
  }

  .icon_section .icon img {
    top: 5px;
  }

  .custom_releam_tab div div {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .custom_releam_tab>div {
    margin-top: 10px;
    padding-bottom: 10px;
  }

  .performance_section_table tr td .progress {
    display: none;
  }

  .table thead th {
    font-size: 10px;
  }

  .performance_section table.performance_section_table tr td,
  .performance_section table.performance_section_table tr th {
    vertical-align: middle;
  }

  .custom_studen-class-selection.dropdown .card .card-header label {
    max-width: 250px;
  }
}

.school_name_header {
  font-weight: 600;
  font-size: 22.17px;
  line-height: 33px;
  color: #fc8643;
}

.school_name_subheader {
  font-weight: 400;
  font-size: 22.17px;
  line-height: 33px;
  color: #6d6d6d;
  margin-bottom: 20px;
}

.filter_title {
  font-weight: 500;
  font-size: 20.44px;
  line-height: 31px;
  /* identical to box height */

  text-decoration-line: underline;

  color: #686868;
  margin-bottom: 20px;
}

select.input-lg {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /* no standardized syntax available, no ie-friendly solution available */
}

select+i.fa {
  float: right;
  margin-top: -30px;
  margin-right: 5px;
  /* this is so when you click on the chevron, your click actually goes on the dropdown menu */
  pointer-events: none;
  /* everything after this is just to cover up the original arrow */
  /* (for browsers that don't support the syntax used above) */
  background-color: #fff;
  padding-right: 5px;
}

.filter_section {
  margin-bottom: 20px;
}

.filter_section .form_elm {
  margin-bottom: 15px;
}

.filter_section .select_btn_wrap {
  position: relative;
  width: 100%;
}

.filter_section .select_btn_wrap .select_btn {
  width: 35px;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  position: absolute;
  align-items: center;
  right: 0;
  background: #d1d1d1;
  /* border-radius: 8px; */
  pointer-events: none;
}

.filter_section .select_btn_wrap .select_btn img {
  height: 12px;
}

.filter_section .select_btn_wrap select {
  background: #ffffff;
  border: 1.28298px solid #e7e7e7;
  border-radius: 3.42128px;
  font-weight: 500;
  font-size: 14.717px;
  line-height: 20px;
  /* identical to box height, or 139% */
  color: #8f8f8f;
  outline: none;
  box-shadow: none;
}

.apply_filter_button {
  background: #314075;
  box-shadow: inset 1px 2px 1.7803px rgba(255, 255, 255, 0.24);
  border-radius: 6.13315px;
  font-weight: 500;
  font-size: 15.8729px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 13.2px;
  cursor: pointer;
  transition: all 0.5s;
}

.apply_filter_button:hover {
  background: #2c3a6a;
}

.middle_border_block {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.middle_border_block div {
  border: 1px solid #e7e7e7;
  position: absolute;
  width: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fc8643;
  font-weight: 500;
  font-size: 10.72px;
  line-height: 20px;
  /* identical to box height, or 191% */

  color: #fc8643;
}

.middle_border_block div span {
  background-color: #fff;
}

.form_input_elm .input-group-text {
  background-color: transparent;
  border: none;
  padding: 0px 9px;
  height: 100%;
}

.form_input_elm .input-group-text svg {
  color: #d1d1d1;
}

.form_input_elm input.form-control {
  box-shadow: none;
  border: none;
  padding: 0;
  min-height: 37px;
  font-weight: 500;
  font-size: 14.717px;
  line-height: 20px;
  /* identical to box height, or 139% */
  padding-top: 0px !important;
  color: #000000;
}

.form_input_elm .input-group {
  border: 1.28298px solid #d1d1d1;
  border-radius: 3.42128px;
}

.or_block {
  text-align: center;
  position: relative;
  font-weight: 500;
  font-size: 10.72px;
  /* line-height: 20px; */
  color: #fc8643;
  min-height: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.or_block div {
  position: absolute;
  width: 100%;
  border: 1px solid #e7e7e7;
  height: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.or_block div span {
  background-color: #fff;
  padding: 0px 4px;
}

.subfilter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  /* align-items: center; */
}

.principle_component_heading {
  font-weight: 500;
  font-size: 20.44px;
  line-height: 31px;
  /* identical to box height */

  text-decoration-line: underline;

  color: #686868;
  margin-bottom: 10px;
}

.student_listing {
  background-color: #ffffff;
}

.custom_student_listing_table thead {
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  border-bottom: 1.09699px solid #d5d5d5;
  color: #7b7b7b;
}

.custom_student_listing_table tbody tr td:not(:nth-child(2)) {
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;

  color: #9c9c9c;
}

.make_it_clickable svg {
  cursor: pointer;
  transition: all 0.5s;
}

.make_it_clickable svg:hover path,
.make_it_clickable svg:hover circle,
.make_it_clickable.active svg path,
.make_it_clickable.active svg circle {
  stroke: #fc8643;
}

.profile_image {
  margin-right: 15px;
  float: left;
}

.performance_section_heading {
  font-weight: 500;
  font-size: 14.8642px;
  line-height: 22px;

  color: #585858;
  margin-bottom: 20px;
}

.performance_section_heading span {
  color: #fc8643;
}

.performance_section_table thead {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #7b7b7b;
  text-align: center;
}

.performance_section_table tbody {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #828282;
}

.table.performance_section_table> :not(:first-child) {
  border-top: none;
}

.progressandper {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.progressandper span {
  width: 35%;
  /* padding-left: 3px; */
}

.line_chart_section {
  margin-bottom: 30px;
}

.profile_section_heading {
  font-weight: 500;
  font-size: 26.1607px;
  line-height: 39px;

  color: #585858;
  margin-bottom: 20px;
}

.profile_section_heading span {
  font-weight: 700;
}

.profile_page_wrapper .scrore_section_heading {
  font-weight: 600;
  font-size: 19.1478px;
  line-height: 29px;
  /* identical to box height */

  color: #7b7b7b;
  margin-bottom: 20px;
}

.dd-arrow span {
  pointer-events: none;
  float: right;
}

.collapse_element_click,
.dd-arrow span {
  text-align: center;
  transition: all 1s;
}

.collapse_element_click svg,
.dd-arrow svg {
  cursor: pointer;
}

.collapse_element_click.show,
.dd-arrow.showing span {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.collapse.show td:first-child {
  padding-right: 0px;
  padding-left: 30px;
  width: 490px;
}

.dd-arrow {
  width: 430px;
  text-transform: capitalize;
}

tr.collapse td:first-child {
  text-transform: capitalize;
}