$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 1024px;

.form-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  width: 100%;

  .form-group {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 0.5rem;
    }

    input {
      height: 4vh;
      width: 100%;
      border: 1px solid #000;
      border-radius: 5px;
    }
  }

  @media (min-width: $breakpoint-large) {
    grid-template-columns: 40% repeat(3, 20%);

    .student-name {
      grid-column: span 1;
    }

    .roll-no,
    .class,
    .date {
      grid-column: span 0.5;
    }
  }

  @media (max-width: $breakpoint-large) {
    grid-template-columns: repeat(2, 1fr);

    .student-name {
      grid-column: span 1;
    }

    .roll-no,
    .class,
    .date {
      grid-column: span 1;
    }
  }

  @media (max-width: $breakpoint-small) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;

    .form-group {
      flex: 1 1 50%;
      max-width: 100%;
    }
  }
}


.totalMarks{
  color: #000;
  font-weight: 500;
  margin-top: -20px;
}



 .newDiv{
   margin-bottom: 60px !important;
   margin-top: 50px !important;
 }

 @media print {
  @page {
    margin: 0.5cm;
    size: auto;
  }

  body {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }

  .printFont {
    font-size: clamp(16px, 2vw, 20px) !important;
  }

  .subjectiveImage {
    width: 440px !important;
}

  .borderBottom {
    margin-top: 35px !important;
    margin-bottom: 60px !important;
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
    border-bottom: 2px solid #000000;
  }
  .stundentInfo{
    margin-top: 3% !important;
  }

  .totalMarks {
    margin-top: 10px !important;
    margin-left: 32px !important;
    font-size: clamp(20px, 1.5vw, 24px) !important;
  }
  .subjectInfo {
    margin-left: 30px !important;
    font-size: clamp(25px, 2.5vw, 25px) !important;
    font-weight: 600;
  }

  .printGrade {
    margin-left: 8px !important;
    font-size: clamp(20px, 1.5vw, 20px) !important;
    margin-top: 2px;
    align-items: center;
  }

  .editModalQuiz{
    display: none !important;
  }

  .durationWhilePrint {
    margin-right: 30px !important;
    font-size: clamp(20px, 2vw, 25px) !important;
  }

  .studentInputWhilePrint {
    margin-left: 30px !important;
    margin-right: 30px !important;
    font-size: clamp(16px, 2vw, 20px) !important;
    
    .date {
      margin-right: 50px !important;
    }

    .roll-no {
      margin-right: 50px !important;
    }
  }

  .textAreaWidth {
    width: 85vw;
  }

  .questionAdjust {
    display: none;
  }

  .quesType {
    display: none;
    color: #FC8643 !important;
  }

  .qust-radio span:nth-child(1) {
    background: #53DA3D !important;
    width: 20px;
    height: 20px;
    display: inline-block;
    line-height: 20px;
    border-radius: 50%;
    color: #fff !important;
    font-size: clamp(8px, 1vw, 10px) !important;
    margin-right: 5px;
  }

  .quesMarksPositive span:nth-child(2) {
    background: #EE5353 !important;
    width: 20px;
    height: 20px;
    display: inline-block;
    line-height: 20px;
    border-radius: 50%;
    color: #fff !important;
    font-size: clamp(8px, 1vw, 10px) !important;
  }

  .bkg-gry {
    padding: 5px;
    background: #F1F4F6 !important;
    width: 60px;
    height: 32px;
    margin: 0 6px 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .singleOption {
    background-color: transparent !important; 
    color: #999898 !important; 
  }

  .singleOption.correct {
    background-color: #C8FCBF !important;
    color: #124609 !important;
  }

  .mcqText {
    background-color: #C8FCBF !important;
    color: #1ac374 !important;
  }

  .printMarks {
    display: flex !important;
    gap: 5px;
    color: #000;
    font-weight: 500;
    margin-left: 3px;
  }

  .subjectivePrint {
    background-color: #C8FCBF !important;
    color: #124609 !important;
    align-items: flex-start !important;
  }

  .questionCardContainer {
    border-top: none;
  }

  .printMCMS{
    background-color: #C8FCBF !important;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 22px;

  @media screen and (max-width: 768px) {
    width: 35px;
    height: 18px;
  }

  @media screen and (max-width: 480px) {
    width: 30px;
    height: 16px;
  }
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;

  @media screen and (max-width: 768px) {
    border-radius: 18px;
  }

  @media screen and (max-width: 480px) {
    border-radius: 16px;
  }
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 2px;
  bottom: 3.2px;
  background-color: #fff;
  transition: .4s;
  border-radius: 50%;

  @media screen and (max-width: 768px) {
    height: 13px;
    width: 13px;
  }

  @media screen and (max-width: 480px) {
    height: 11px;
    width: 11px;
  }
}

input:checked + .slider {
  background-color: #84e075;
}

input:focus + .slider {
  box-shadow: 0 0 1px #84e075;
}

input:checked + .slider:before {
  transform: translateX(26px);

  @media screen and (max-width: 768px) {
    transform: translateX(18px);
  }

  @media screen and (max-width: 480px) {
    transform: translateX(15px);
  }
}


@media screen and (min-width: 340px) {
  .backarrIcon {
    padding: 0.2rem;
    margin: 0.5rem;
    border-radius: 19px;
  }
  .arrIconRes {
    padding: 0.5rem !important;
    margin: 0.2rem !important;
  }

  .showAnwerToggel {
    padding: 0 !important;

    span {
      padding-left: 5px;
      margin: 0;
      font-size: 11px;
      white-space: nowrap;
    }
  }

  .spaceForSubj {
    span {
      padding-left: 2px;
      font-size: 10px;
      white-space: nowrap;
      display: block;
    }
  }

  .toggel {
    margin-top: 5px;
    margin-right: 5px;
  }

  .prntBtn {
    font-size: 12px;
      

    @media screen and (max-width: 768px) {
      font-size: 10px;
      padding: 0.4rem;
    }

    @media screen and (max-width: 480px) {
      font-size: 8px;
      padding: 0.3rem;
    }
  }
 .stundentInfo{
    padding: 30px 35px 0px 32px;

    h4{
      font-size: 15px;
    }
    p{
      font-size: 12px !important;
      font-weight: 700;
      font-family: "Poppins";
      color: #000;
    }
  }

  .questionAdjustHeading{
    display: block !important;
    align-items: center;
    h5{
      font-size: 15px;
      white-space: nowrap;
    }
    span{
      font-size: 11px;
      white-space: nowrap;
    }
  }

  .textAreaWidth{
    width: 65vw;
  }

  .quesMarks{
    margin-left: 1px !important;
  }

  .quesAdjBtnAndBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    flex-direction: column;
   .sortButton{
    margin-top: 20px;
   }
}


.printMr{
  gap: 0.2rem !important;
}}


@media screen and (min-width: 359px) and (max-width: 360px) {
  
  .backarrIcon {
    border-radius: 20px; 
  }

  .arrIconRes {
    padding: 0.6rem !important; 
    margin: 0.3rem !important; 
  }

  .showAnwerToggel {
    padding: 0 !important;

    span {
      padding-left: 2px;
      margin: 0;
      font-size: 10px; 
    }
  }

  .spaceForSubj {
    span {
      padding-left: 2px; 
      margin: 0;
      font-size: 9px; 
    }
  }

  .toggel {
    margin-top: 5px; 
    margin-right: 4px; 
  }
  .printMr{
    gap: 0.2rem !important;
  }

  .prntBtn {
    font-size: 14px; 

    @media screen and (max-width: 768px) {
      font-size: 12px;
      padding: 0.5rem; 
    }

    @media screen and (max-width: 480px) {
      font-size: 10px;
      padding: 0.4rem;  
    }
  }
 .stundentInfo{
    padding: 30px 38px 0px 38px;

    h4{
      font-size: 14px;
      white-space: nowrap;
    }
   
    p{
      font-size: 11px !important;
    }
  }

  .questionAdjustHeading{
    display: flex;
    align-items: center;
    h5{
      font-size: 15px;
      white-space: nowrap;
    }
    span{
      font-size: 12px;
    }
  }
  .textAreaWidth{
    width: 65vw;
  }

  .quesAdjBtnAndBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    flex-direction: column;
   .sortButton{
    margin-top: 20px;
   }
   }
}
@media screen and (min-width: 240px) and (max-width: 760px) {
  .singleOption{
    flex-wrap: wrap !important;
    padding-right: 20px !important;
  }
  .subjectivePrint{
    align-items: flex-start !important;
  }

  mjx-math{
    display: inline !important;
    white-space: normal !important;
  }
}

@media screen and (min-width: 344px) and (max-width: 344px) and (min-height: 882px) and (max-height: 882px) {

  .backarrIcon {
    border-radius: 20px; 
  }

  .arrIconRes {
    padding: 0.3rem !important; 
    margin: 0.2rem !important; 
  }

  .dynamicBtnBottom{
    gap: 5px !important;
  }

  .showAnwerToggel {
    padding: 0 !important;

    span {
      padding-left: 2px;
      margin: 0;
      font-size: 10px; 
    }
  }

  .spaceForSubj {
    span {
      padding-left: 2px; 
      margin: 0;
      font-size: 9px; 
    }
  }

  .toggel {
    margin-top: 5px; 
    margin-right: 4px; 
  }

  .printMr {
    gap: 0.2rem !important;
  }

  .prntBtn {
    font-size: 12px; 

    @media screen and (max-width: 768px) {
      font-size: 12px;
      padding: 0.5rem; 
    }

    @media screen and (max-width: 480px) {
      font-size: 10px;
      padding: 0.4rem;  
    }
  }

  .stundentInfo {
    padding: 30px 38px 0px 38px;

    h4 {
      font-size: 14px;
      white-space: nowrap;
    }
    
   
  }

  .questionAdjustHeading {
    display: flex;
    align-items: center;

    h5 {
      font-size: 15px;
      white-space: nowrap;
    }

    span {
      font-size: 10px;
    }
  }

  .textAreaWidth {
    width: 65vw;
  }

  .quesAdjBtnAndBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    flex-direction: column;

    .sortButton {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: 400px) {
  .backarrIcon {
    padding: 0.2rem;
    margin: 0.5rem;
    border-radius: 19px;
  }

  .printMr{
    gap: 1rem !important;
  }
  .arrIconRes {
    padding: 0.5rem !important;
    margin: 0.2rem !important;
  }

  .showAnwerToggel {
    padding: 0 !important;

    span {
      padding-left: 5px;
      margin: 0;
      font-size: 12px;
      white-space: nowrap;
    }
  }

  .spaceForSubj {
    span {
      padding-left: 5px;
      margin: 0;
      font-size: 12px;
      white-space: nowrap;
      display: block;
      content: "Space For Subj";
    }

    
  }

  .toggel {
    margin-top: 5px;
    margin-right: 5px;
  }


  .prntBtn {
    font-size: 12px;
      

    @media screen and (max-width: 768px) {
      font-size: 10px;
      padding: 0.4rem;
    }

    @media screen and (max-width: 480px) {
      font-size: 8px;
      padding: 0.3rem;
    }
  }
 .stundentInfo{
    padding: 30px 38px 0px 38px;
   
  }

  .questionAdjustHeading{
    display: flex;
    align-items: center;
    h5{
      font-size: 15px;
      white-space: nowrap;
    }
    span{
      font-size: 12px;
    }
  }
  .textAreaWidth{
    width: 70vw;
  }
  .quesAdjBtnAndBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    flex-direction: column;
   .sortButton{
    margin-top: 20px;
   }
   }
}

@media screen and (min-width: 540px) {
  .backarrIcon {
    padding: 0.1rem;
    margin: 0.3rem;
    border-radius: 19px;
  }

  .arrIconRes {
    padding: 0.3rem !important;
    margin: 0.3rem !important;
    font-size: 12px !important;
  }

  .showAnwerToggel {
    padding: 0 !important;

    span {
      padding-left: 15px;
      margin: 0;
      font-size: 12px;
      white-space: nowrap;
    }
  }

  .spaceForSubj {
     span {
      padding-left: 15px;
      margin: 0;
      font-size: 12px;
      white-space: nowrap;
    }
  }

  .toggel {
    margin-top: 5px;
    margin-right: 5px;
  }

  .prntBtn {
    font-size: 12px !important;
      

    @media screen and (max-width: 768px) {
      font-size: 10px;
      padding: 0.4rem;
    }

    @media screen and (max-width: 480px) {
      font-size: 8px;
      padding: 0.3rem;
    }
  }
 .stundentInfo{
    padding: 30px 38px 0px 38px;

    h4{
      font-size: 18px;
    }
    p{
      font-size: 18px !important;
      font-weight: 500;
      font-family: "Poppins";
    }
    span{
      font-size: smaller;
    }
  }

  .questionAdjustHeading{
    display: flex;
    align-items: center;
    h5{
      font-size: 15px;
      white-space: nowrap;
    }
    span{
      font-size: 12px;
    }
  }

  .textAreaWidth{
    width: 65vw;
  }

  .quesAdjBtnAndBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    flex-direction: column;
   .sortButton{
    margin-top: 20px;
   }
   }
}

@media screen and (min-width: 640px) {
  .backarrIcon {
    padding: 0.2rem;
    margin: 0.5rem;
    border-radius: 19px;
  }

  .arrIconRes {
    padding: 0.5rem !important;
    margin: 0.2rem !important;
    font-size: 15px !important;
  }

  .showAnwerToggel {
    padding: 0 !important;
    span {
      padding-left: 15px;
      margin: 0;
      font-size: 13px;
      white-space: nowrap;
    }
  }

  .spaceForSubj {
    span {
      padding-left: 15px;
      margin: 0;
      font-size: 13px;
      white-space: nowrap;
    }
  }

  .toggel {
    margin-top: 5px;
    margin-right: 5px;
  }
  .prntBtn {
    font-size: 14px !important;
      

    @media screen and (max-width: 768px) {
      font-size: 10px;
      padding: 0.4rem;
    }

    @media screen and (max-width: 480px) {
      font-size: 8px;
      padding: 0.3rem;
    }
  }
  .stundentInfo{
    padding: 30px 38px 0px 38px;

    h4{
      font-size: 18px;
    }
    span{
      font-size: smaller;
    }
  }

  .questionAdjustHeading{
    display: flex;
    align-items: center;
    gap: 2;
    h5{
      font-size: 18px;
    }
    span{
      font-size: 13px;
    }
  }

  .stundentInfo{
    padding: 30px 38px 0px 38px;

    h4{
      font-size: 18px;
    }
    span{
      font-size: smaller;
    }
  }

  .questionAdjustHeading{
    display: flex;
    align-items: center;
    h5{
      font-size: 15px;
      white-space: nowrap;
    }
    span{
      font-size: 12px;
    }
  }

  .stundentInfo{
    padding: 30px 38px 0px 38px;

    h4{
      font-size: 18px;
    }
    span{
      font-size: smaller;
    }
  }

  .questionAdjustHeading{
    display: flex;
    align-items: center;
    h5{
      font-size: 15px;
      white-space: nowrap;
    }
    span{
      font-size: 12px;
    }
  }
  .textAreaWidth{
    width: 65vw;
  }
  .quesAdjBtnAndBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    flex-direction: column;
   .sortButton{
    margin-top: 0;
   }
   }

}

@media screen and (min-width: 768px) {
  .backarrIcon {
    padding: 0.2rem;
    margin: 0.5rem;
    border-radius: 19px;
  }

  .totalMarks{
    color: #000;
    font-weight: 500;
    margin-top: -28px;
  }
  
  .arrIconRes {
    padding: 0.5rem !important;
    margin: 0.2rem !important;
    font-size: 15px !important;
  }

  .showAnwerToggel {
    padding: 0 !important;
    span {
      padding-left: 15px;
      margin: 0;
      font-size: 13px;
      white-space: nowrap;
    }
  }

  .spaceForSubj {

    span {
      padding-left: 15px;
      margin: 0;
      font-size: 13px;
      white-space: nowrap;
    }
  }

  .toggel {
    margin-top: 5px;
    margin-right: 5px;
  }


  .prntBtn {
    font-size: 14px !important;
      

    @media screen and (max-width: 768px) {
      font-size: 10px;
      padding: 0.4rem;
    }

    @media screen and (max-width: 480px) {
      font-size: 8px;
      padding: 0.3rem;
    }
  }
  .stundentInfo{
    padding: 30px 38px 0px 38px;

    h4{
      font-size: 18px;
    }
  }

  

  .questionAdjustHeading{
    display: flex;
    align-items: center;
    h5{
      font-size: 15px;
      white-space: nowrap;
    }
    span{
      font-size: 12px;
    }
  }
  .textAreaWidth{
    width: 65vw;
  }

  .quesAdjBtnAndBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    flex-direction: column;

    .sortButton{
      margin-top: 20px;
     }
  }
  
}

@media screen and (min-width: 1024px) {
  .backarrIcon {
    padding: 0.4rem;
    margin: 0.5rem;
    border-radius: 19px;
  }

  .arrIconRes {
    padding: 0.5rem !important;
    margin: 0.2rem !important;
    font-size: 18px !important;
  }

  

  .showAnwerToggel {
    padding: 0 !important;

    span {
      padding-left: 15px;
      margin: 0;
      font-size: 14px;
      white-space: nowrap;
    }
  }

  .spaceForSubj {

    span {
      padding-left: 15px;
      margin: 0;
      font-size: 14px;
      white-space: nowrap;
    }

   
  }

  .toggel {
    margin-top: 5px;
    margin-right: 5px;
  }


  .prntBtn {
    font-size: 16px !important;
      

    @media screen and (max-width: 768px) {
      font-size: 10px;
      padding: 0.4rem;
    }

    @media screen and (max-width: 480px) {
      font-size: 8px;
      padding: 0.3rem;
    }
  }
  
  .stundentInfo{
    padding: 30px 38px 0px 38px;

    h4{
      font-size: 18px;
    }
    span{
      font-size: smaller;
    }
  }

  .questionAdjustHeading{
    display: flex;
    align-items: center;
    gap: 2;
    h5{
      font-size: 18px;
    }
    span{
      font-size: 13px;
    }
  }
  .dateinput{
    width: 12vw !important;
  }
  
  .quesAdjBtnAndBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    flex-direction: row;

    .sortButton{
      margin-top: 0px;
     }
  }

  

  .textAreaWidth{
    width: 70vw;
  }


}

@media screen and (min-width: 1280px) {
  .backarrIcon {
    padding: 0.5rem;
    margin: 0.6rem;
    border-radius: 19px;
  }

  .arrIconRes {
    padding: 0.5rem !important;
    margin: 0.2rem !important;
    font-size: 20px !important;
  }

  .showAnwerToggel {
    padding: 0 !important;

    span {
      padding-left: 15px;
      margin: 0;
      font-size: 16px;
      white-space: nowrap;
    }
  }

  .spaceForSubj {

    span {
      padding-left: 15px;
      margin: 0;
      font-size: 16px;
      white-space: nowrap;
    }

   
  }

  .toggel {
    margin-top: 5px;
    margin-right: 5px;
  }

  .prntBtn {
    font-size: 18px !important;
      

    @media screen and (max-width: 768px) {
      font-size: 10px;
      padding: 0.4rem;
    }

    @media screen and (max-width: 480px) {
      font-size: 8px;
      padding: 0.3rem;
    }
  }
  .stundentInfo{
    padding: 30px 50px 0px 50px;

    h4{
      font-size: 20px;
    }
    span{
      font-size: smaller;
    }
  }

  .questionAdjustHeading{
    display: flex;
    align-items: center;
    gap: 2;
    h5{
      font-size: medium;
    }
    span{
      font-size: 15px;
    }
  }

  
  .dateinput{
    width: 12vw !important;
  }

  .quesAdjBtnAndBox{
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sortButton{
      margin-top: 0;
     }
   
  }

.textAreaWidth{
  width: 70vw;
}

}

@media screen and (min-width: 1536px) {
  .backarrIcon {
    padding: 0.5rem;
    margin: 0.6rem;
    border-radius: 19px;
  }

  .arrIconRes {
    padding: 0.5rem !important;
    margin: 0.2rem !important;
    font-size: 20px !important;
  }

  .showAnwerToggel {
    padding: 0 !important;

    span {
      padding-left: 15px;
      margin: 0;
      font-size: 18px;
      white-space: nowrap;
    }
  }

  .spaceForSubj {

    span {
      padding-left: 15px;
      margin: 0;
      font-size: 18px;
      white-space: nowrap;
    }

  }

  .toggel {
    margin-top: 5px;
    margin-right: 5px;
  }


  .prntBtn {
    font-size: 20px !important;
  }

  .stundentInfo{
    padding: 30px 50px 0px 50px;
  }

  .dateinput{
    width: 12vw !important;
  }

.textAreaWidth{
  width: 70vw;
}

.quesAdjBtnAndBox{
  display: flex;
  justify-content: space-between;
  align-items: center;

  .sortButton{
    margin-top: 9px; 
   }
}
}
 
.printHead{
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.dragSubj{
  background-color:  #678ef1 !important;
}

.sortBtnHover {
  &:hover {
    background-color: #3159bc !important;
  }
}

.resetBtnHover{
  &:hover{
    background-color: #de3838 !important;
  }
  border: 0.2px solid grey;
  padding: 10px;
  border-radius:8px;
  background-color: #FC4343;
  color: #fff;
}


@media screen and (min-width: 768px) and (max-width: 1300px) {
  .subjectiveImage{
    width: 440px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 3600px) {
  .subjectivePrint{
    align-items: flex-start !important;
  }

}
@media screen and (min-width: 68px) and (max-width: 900px) {
  
  .subjectInfo{
    font-weight: 700;
    font-size: 16px !important;
    color: #000000;
    display: flex !important;
    flex-wrap: wrap;
    margin-top: -2px;
  }

  .printGrade{
    font-weight: 700;
    font-size: 15px !important;
    color: #000000;
    margin-top: 5px;
  }

  .editIconText{
    display: none !important;
  }
  .editModalQuiz{
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-radius: 50% !important;
    background-color: #FC8643;
    color: #fff !important;
    border-color: #fff !important;
    // padding: 5px !important;
  }
  .quizInfo{
    margin-bottom: 40px !important;
    display: flex !important;
    flex-direction: column !important;
  }
}

@media screen and (min-width: 912px) {
  .subjectInfo{
    font-weight: 700 ;
    font-size: 26px !important;
    color: #000000;
    display: flex !important;
    flex-wrap: wrap;
    margin-top: -2px;
  }
  .quizInfo{
    margin-bottom: 40px !important;
    display: flex !important;
    align-items: center !important;
  }
  .printGrade{
    margin-top: 10px;
    font-size: 16px !important;
    margin-left: 8px;
  }
}

