$color_1: #fff;
$font-family_1: "Poppins", sans-serif;
$blue-clr: #314075;
$orng-clr: #fc8643;

.header-frame {
  width: 100%;
  padding: 12px 15px;
  z-index: 1009;
  background-color: #15388c;
  position: relative;
  height: 60px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 0 0 0px 0px;
  .header-content-section {
    position: relative;
    .left-arrow-mob {
      cursor: pointer;
      display: none;
      align-items: center;
      justify-content: center;
      width: 25px;
    }
    .middle-actions-col {
      position: absolute;
      right: 90px;
      top: 50%;
      transform: translateY(-50%);
    }
    .main-column-border {
      padding-left: 20px;
      display: flex;
      flex-direction: row;
      position: relative;
      &::before {
        content: "";
        width: 2px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: #1d1225;
        box-shadow: inset 0 1px 1px $blue-clr;
        opacity: 0.6;
      }
      .main-box {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: $color_1;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 25px;
        border: 0 none;
        position: relative;
        margin-right: 10px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        img {
          width: 25px;
        }
        @media screen and (min-width: 992px) {
          .main-box {
            background: #ccc;
          }
        }
      }
    }
    .mob-search {
      display: none;
    }
    .btn-column {
      &::before {
        content: "";
        width: 2px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: #1d1225;
        box-shadow: inset 0 1px 1px $blue-clr;
        opacity: 0.6;
      }
    }
    .main-column {
      padding-left: 10px;
      display: flex;
      flex-direction: row;
      position: relative;
      align-items: center;
      .main-box {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: $color_1;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 25px;
        border: 0 none;
        position: relative;
        margin-right: 10px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        img {
          width: 25px;
          margin-right: 2px;
        }
      }
    }
  }
}
.cst-text-elipse {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}

.header-frame {
  .header-content-section {
    display: flex;
    align-content: center;
    .search-input {
      max-width: inherit;
      width: 18.4vw;
      background: #758dd8;
      border-radius: 20px;
      border: 0 none;
      margin: 0 10px 0 0;
      .input-group-text {
        background: none;
        border: 0 none;
      }
      .form-control {
        background: none;
        border: 0 none;
        font-weight: 300;
        color: #fff;
        height: 30px !important;
      }
    }
    .btn-column {
      padding-left: 10px;
      display: flex;
      align-items: center;
      flex-direction: row;
      position: relative;
      .btn-blue-rnd {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.5 !important;
        color: #ffffff;
        background: #274da6;
        border: 0 none;
        border-radius: 20px;
        position: relative;
        margin-right: 24px;
        height: auto;
        &::after {
          content: "";
          width: 4px;
          height: 4px;
          border: solid #fff;
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 3px;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          position: absolute;
          top: 15px;
          right: -15px;
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
      }
      .btn-blue-rnd:hover {
        background-color: #002ead;
        transition: 0.7s;
      }
    }
    .right-actions-col {
      position: absolute;
      right: 0;
      top: 5px;
      display: flex;
      .fa {
        font-size: 32px;
        color: #fff;
        margin-left: 10px;
      }
      .notification-col {
        position: relative;
        margin-left: 20px;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .notification-box-container {
          width: 350px;
          position: absolute;
          top: 40px;
          right: -20px;
          z-index: 99;
          display: none;
          transition: 0.6s ease-In-Out;
          background-color: #fff;
          border-radius: 14px;
          &:before {
            content: "";
            position: absolute;
            top: -7px;
            right: 23px;
            width: 0;
            height: 0;
            // border-top: 16px solid transparent;
            // border-bottom: 10px solid transparent;
            // border-right: 13px solid #f5f5f5;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid white;
            // transform: rotate(0deg);
          }
        }
      }
      .setting-col {
        position: relative;
        margin-left: 20px;
      }
      .setting-option-block {
        width: 300px;
        position: absolute;
        top: 10px;
        right: 0;
        z-index: 99;
        display: none;
        transition: 0.6s ease-In-Out;
        &::before {
          content: "";
          position: absolute;
          top: 26px;
          right: 12px;
          width: 0;
          height: 0;
          border-top: 16px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 13px solid #f5f5f5;
          transform: rotate(225deg);
        }
      }
    }
  }
}

.header-frame
  .header-content-section
  .right-actions-col
  .notification-icon:hover
  ~ .notification-box-container,
.header-frame
  .header-content-section
  .right-actions-col
  .notification-box-container:hover {
  display: block;
}

.header-frame
  .header-content-section
  .right-actions-col
  .setting-icon:hover
  ~ .setting-option-block,
.header-frame
  .header-content-section
  .right-actions-col
  .setting-option-block:hover {
  display: block;
}
.header-content-section {
  .right-actions-col {
    .notification-col {
      span {
        img {
          width: 24px;
        }
      }
    }
    .notification-box-container {
      padding: 2rem;
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
      span {
        text-align: center;
        font-weight: 600;
      }
    }
    .setting-col {
      span {
        img {
          width: 24px;
        }
      }
    }
    .setting-option-block {
      ul {
        margin-top: 40px;
        padding: 10px 15px !important;
        border-radius: 10px;
        background: #f5f5f5;
        box-shadow: 1px 2px 19px rgba(0, 0, 0, 0.5);
        li {
          padding-bottom: 10px;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.5;
          color: #5c5c5c;
          a {
            color: #5c5c5c;
            &:hover {
              color: $orng-clr;
            }
            .custom-icon {
              color: $orng-clr;
              font-size: 18px;
              margin: 0 15px 0 0;
            }
          }
        }
        .btn-logout {
          width: 100%;
          height: 40px;
          font-size: 14px;
          line-height: 1 !important;
          color: $orng-clr;
          background: #fff;
          border: 2px solid $orng-clr;
          border-radius: 40px;
          &:hover {
            background: $orng-clr;
            color: #fff;
          }
        }
      }
    }
  }
}

// responsive scss

@media screen and (min-width: 1440px) {
  .header-frame .header-content-section .search-input {
    width: 18.5vw;
  }
  .setting-option-block {
    ul {
      .btn-logout {
        font-size: 18px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .unified-theme-light {
    .side-menu {
      background-color: #fff !important;
      display: block !important;
      .nav-item {
        .nav-link {
          height: auto;
          .active {
            .innerMenu {
              filter: grayscale(1) contrast(5);
              -webkit-filter: grayscale(0) contrast(5);
              background: $blue-clr !important;
              border-radius: 6px 6px 0 0;
              color: #fff;
            }
          }
          .innerMenu {
            filter: grayscale(1) contrast(0);
            -webkit-filter: grayscale(1) contrast(0);
          }
        }
      }
    }
  }
  .header-frame {
    min-height: 35px;
    padding: 5px 15px;
    height: auto;
    .header-content-section {
      height: 40px;
      .left-arrow-mob {
        display: flex;
      }
      .middle-actions-col {
        display: none !important;
      }
      .mob-search {
        display: block;
      }
      .notification-col {
        .fa {
          font-size: 18px;
        }
        img {
          width: 22px;
          height: 22px;
          margin: 0 0 0 15px;
        }
      }
      //   .notification-box-container {
      //     display: none;
      //   }
      .setting-col {
        display: none;
      }
      .input-group {
        display: none;
      }
      #page-header-breadcrums {
        display: none;
      }
    }
  }
  .header-frame .header-content-section .right-actions-col {
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
}
