/* DraggableItem.scss */
@keyframes drag-animation {
    from {
      transform: scale(1);
      opacity: 1;
    }
    to {
      transform: scale(1.1);
      opacity: 0.7;
    }
  }
  
  .dragging {
    animation: drag-animation 0.2s ease-in-out;
  }
  
  .placeholder {
    border: 2px dashed #2740e4;
    background-color: #f7f7f7;
    transition: background-color 0.3s ease;
  }
  
  .placeholder-active {
    background-color: #e0e0e0;
  }
  
  .item-content {
    transition: transform 0.2s ease;
  }
  
  .item-content:hover {
    transform: scale(1.05);
  }
  