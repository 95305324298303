.tableContainer {
  position: relative;
  margin: 2rem 0rem;
  padding: 1rem;
  height: auto;
  border: 4px solid #f3f6f9;
  border-radius: 20px;
  .headerContent {
    padding: 1rem;
    .leftContent {
      float: left;
      span {
        font: normal normal 400 18px/21px Poppins;
        color: #212121;
      }
      p {
        font: normal normal 500 12px/21px Poppins;
        color: #b5b5c3;
      }
    }
    .rightContent {
      float: right;
      button {
        border: none;
        padding: 0.6rem;
        border-radius: 6px;
        background-color: #fc8643;
        font: normal normal 600 13px/19px Poppins;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  table {
    .thread {
      border-radius: 6px;
      background-color: #f3f6f9;
      tr {
        th {
          font: normal normal 400 14px/18px Poppins;
          color: #b5b5c3;
        }
      }
    }
    tbody {
      tr {
        th {
          color: #464e5f;
        }
        td {
          color: #464e5f;
          font-weight: 700;
        }
      }
    }
  }
  .downloadButton {
    border: none;
    padding: 0.6rem;
    border-radius: 6px;
    background-color: #fc8643;
    font: normal normal 600 13px/19px Poppins;
    color: #fff;
    cursor: pointer;
  }
}
