.container3 {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  margin-top: 1rem;
  width: 100%;
  .outerContainer {
    position: relative;
    width: auto;
    height: 150px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    color: white;
    .thead {
      tr {
        th {
          font: normal normal 500 42px/56px Poppins;
        }
      }
    }
    tbody {
      tr {
        th {
          font: normal normal 500 24px/28px Poppins;
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .container3 {
    gap: 1rem;
    .outerContainer {
      width: 200px;
      height: 150px;
      .thead {
        tr {
          th {
            font: normal normal 500 36px/32px Poppins;
          }
        }
      }
      tbody {
        tr {
          th {
            font: normal normal 500 20px/24px Poppins;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .container3 {
    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .outerContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      .thead {
        tr {
          th {
            font: normal normal 500 28px/20px Poppins;
          }
        }
      }
      tbody {
        tr {
          th {
            font: normal normal 500 20px/20px Poppins;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 460px) {
  .container3 {
    gap: 1rem;
    flex-wrap: wrap;
    .outerContainer {
      padding: 0.5rem;
      .thead {
        tr {
          th {
            font: normal normal 500 28px/16px Poppins;
          }
        }
      }
      tbody {
        tr {
          th {
            font: normal normal 500 16px/16px Poppins;
          }
        }
      }
    }
  }
}
