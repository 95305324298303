.divider{
    border: 2px solid #F1F4F6;
    margin: 1.5rem 0rem;
}
.tabContainer{
            .custom_student_list_table{
                li{
                    margin-right: 2rem;
                    button{
                        background-color: #fff !important;
                        font: normal normal 500 20px/30px Poppins;
                        @media only screen and (max-width: 460px){
                            font: normal normal 500 16px/16px Poppins;
                        }
                        color: #2B2B2B;
                        border-color: none !important;
                        &:active{
                            background-color: #fff !important;
                            border-color: none !important;
                        }
                    }
                }
            }
            .nav-tabs{
                border-bottom: none !important;
                padding: 0.5rem 1.2rem 0.5rem 0rem;
            }
        }


.thread{
    background-color: #F3F6F9;
    border-radius: 6px;
    position:sticky;
    top:0;

    tr{
        th{
            font: normal normal 600 12px/18px Poppins;
            color: #B5B5C3;
        }
    }
}

tbody{
    tr{
        td{
            font: normal normal 600 14px/21px Poppins;
            color: #464E5F;
        }
    }
}

.nav-link{
    padding: 0.5rem 0rem;
}
.nav-tabs .nav-link.active{
    background-color: #fff !important;
    border-color: #fff;
}
