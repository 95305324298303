@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  outline: none;
  box-sizing: border-box;
}

*html,
body,
button {
  font-family: "Poppins", sans-serif;
}

button:focus {
  outline: none;
  outline: 0 !important;
  box-shadow: none !important;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.border-radius-round {
  border-radius: 5px !important;
}

.border-radius-pill {
  border-radius: 50px !important;
}

.fast-comments .footer {
  display: none;
}

/*------------ For my reference---------*/
/*------------for unified dark and light basic theme ---------*/
/*----for unified dark and light basic theme----*/
/*--------theme for chat--------*/
/*---------general------------*/
/*--------------for quiz-----*/
/*  for island themes*/
/************For Challenge Themes*****************/
/******************Chapter Background Theme*********************/
.chemistry-chapter-bg {
  background-image: linear-gradient(to bottom,
      #00a8ff,
      rgba(0, 145, 255, 0)) !important;
}

.maths-chapter-bg {
  background-image: linear-gradient(to bottom,
      #eb567d,
      rgba(235, 86, 125, 0)) !important;
}

.physics-chapter-bg {
  background-image: linear-gradient(to bottom,
      #ffaa00,
      rgba(255, 204, 0, 0)) !important;
}

.biology-chapter-bg {
  background-image: linear-gradient(to bottom,
      #19ba5d,
      rgba(20, 189, 90, 0)) !important;
}

.maths-chapter-top-bg {
  background-color: #ec5383 !important;
  z-index: 99;
}

.physics-chapter-top-bg {
  background-color: #fdac0b !important;
  z-index: 99;
}

.biology-chapter-top-bg {
  background-color: #1eb766 !important;
  z-index: 99;
}

.text-orange {
  color: #fc8643;
}

.text-red {
  color: #e96969;
}

.text-green {
  color: #17bd59;
}

.text-bold {
  font-family: "stepapp-secondary-bold", sans-serif;
  font-weight: 600;
}

.text-normal {
  font-family: "stepapp-secondary-regular", sans-serif;
}

.text-semibold {
  font-family: "stepapp-secondary-medium", sans-serif;
}

.text-pri {
  font-family: "Poppins", sans-serif;
}

.text-med {
  font-size: 18px;
}

.text-icon {
  font-size: 15px;
}

.text-icon-big {
  font-size: 35px;
}

.w100 {
  font-weight: 100;
}

.w200 {
  font-weight: 200;
}

.w300 {
  font-weight: 300;
}

.w400 {
  font-weight: 400;
}

.w600 {
  font-weight: 600;
}

.w700 {
  font-weight: 700;
}

.title {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
}

.help-page-icon {
  font-size: 50px;
  line-height: 40px;
}

.whitelink,
.text-white {
  color: #fff;
  font-weight: normal;
  cursor: pointer;
}

.whitelink:hover,
.text-white:hover {
  color: #e4ac13;
  text-decoration: none;
}

.whitelink:active,
.text-white:active {
  color: #fc8643;
}

.whitelink:focus,
.text-white:focus {
  color: #e4ac13;
}

.whitelink:visited,
.text-white:visited {
  color: #fff;
}

.whitelink-hidedeskop {
  color: #15388c !important;
}

.normal-link {
  color: #000;
  font-weight: 600px;
  cursor: pointer;
}

.normal-link:hover {
  color: #e4ac13;
  text-decoration: none;
}

.normal-link:active {
  color: #fc8643;
}

.normal-link:focus {
  color: #e4ac13;
}

.normal-link:visited {
  color: #000;
}

.alert-error {
  color: #721c24;
}

.text-normal {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 12px;
  color: #000;
}

.text-med {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 14px;
  color: #000;
}

.text-large {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 30px;
  color: #000;
}

/*----for-quiz-----*/
.bg-green {
  background-color: #17bd59;
}

.bg-red {
  background-color: #e96969;
}

#header {
  height: 100px;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0px !important;
}

.navbar-expand-lg .navbar-nav .nav-link {
  color: #caccce;
  font-size: 12px;
}

.navbar-expand-lg .navbar-nav .nav-link:active {
  color: #fff;
}

.navbar-responsive {
  background-color: #173d99;
  color: #fff;
}

.subnavbar .active a {
  position: relative;
  color: #fff !important;
  font-weight: 500;
  border-bottom: 3px solid #fff;
}

.subnavbar .navbar-nav .nav-link {
  color: #caccce;
}

.navbar-dark .navbar-toggler {
  border: none;
  background: transparent;
}

.subnavbar li {
  padding: 0px 10px;
}

.subnavbar i {
  font-size: 22px;
}

.search-mobile {
  display: none;
}

.d-mobile {
  display: none;
}

* {
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
  -webkit-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

body {
  font-family: "stepapp-secondary-regular", sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#footer {
  width: 100%;
  margin: auto;
  vertical-align: bottom;
  line-height: 30px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1500;
  height: 30px;
  padding: 0px;
}

.footer-text {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 11px;
  line-height: 30px;
  display: block;
  height: 30px;
  width: 100%;
  color: rgba(255, 255, 255, 0.5);
}

.fix-bottom-nav {
  padding: 10px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1500;
  background-color: transparent;
}

.input-group {
  text-align: left;
}

.form-label {
  font-size: 12px !important;
  line-height: 12px;
}

/*--------custom input-------*/
.custom-input-style-onboarding {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  color: #000 !important;
  height: 40px;
  vertical-align: middle;
  display: inline-block;
}

.custom-input-style-onboarding:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #000;
  text-decoration: none;
  border: 1px solid #6c757d;
}

.custom-input-style-onboarding:active,
.custom-input-style-onboarding:focus {
  background-color: rgba(255, 255, 255, 0.1);
  color: #000 !important;
}

.custom-input-style-onboarding:visited {
  background-color: #fff;
  color: #000 !important;
}

.custom-input-style-onboarding-2 {
  height: 55px;
  text-align: center;
  background-color: #caccce;
  color: #000 !important;
}

.custom-input-style-onboarding-2:hover {
  background-color: #caccce;
  color: #000;
  text-decoration: none;
  border: 1px solid #6c757d;
}

.custom-input-style-onboarding-2:active,
.custom-input-style-onboarding-2:focus {
  background-color: #caccce;
  color: #000 !important;
}

.custom-input-style-onboarding-2:visited {
  background-color: #fff;
  color: #000 !important;
}

.input:-internal-autofill-selected {
  appearance: menulist-button;
  -webkit-appearance: menulist-button;
  background-color: #caccce;
  color: #000;
}

.custom-input-style-normal {
  height: 40px !important;
  text-align: left;
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid transparent;
}

.custom-input-style-normal:hover {
  background-color: #fff;
  color: #000;
  text-decoration: none;
  border: 1px solid #6c757d;
}

.custom-input-style-normal:active,
.custom-input-style-normal:focus {
  background-color: #fff;
  color: #000 !important;
  border: 1px solid #6c757d;
}

.custom-input-style-normal:visited {
  background-color: #fff;
  color: #000 !important;
  border: 1px solid #6c757d;
}

/*---------  styled radio buttons --------*/
/*********Radio Buttons***********/
input[type="radio"] {
  display: none;
}

input[type="radio"]:not(:disabled)~label {
  cursor: pointer;
}

input[type="radio"]:disabled~label {
  color: #bcc2bf;
  border-color: #bcc2bf;
  box-shadow: none;
  cursor: not-allowed;
}

.select-label {
  height: 75px;
  line-height: 75px;
  display: block;
  background: #57add0;
  color: #fff;
  border: none;
  border-radius: 15px;
  text-align: center;
  position: relative;
  opacity: 1;
  font-size: 20px;
  font-family: "stepapp-secondary-regular", sans-serif;
  border: 3px solid transparent;
}

.select-label2 {
  height: 50px;
  line-height: 45px;
  display: block;
  background: #e4ac13;
  color: #fff;
  border: none;
  border-radius: 50px;
  text-align: center;
  position: relative;
  opacity: 1;
  font-size: 22px;
  font-family: "stepapp-secondary-regular", sans-serif;
  border: 3px solid transparent;
}

.select-label-true {
  height: 50px;
  line-height: 45px;
  display: block;
  background: #17bd59;
  color: #fff;
  border: none;
  border-radius: 50px;
  text-align: center;
  position: relative;
  opacity: 1;
  font-size: 22px;
  font-family: "stepapp-secondary-regular", sans-serif;
  border: 3px solid transparent;
}

.select-label-false {
  height: 50px;
  line-height: 45px;
  display: block;
  background: #e96969;
  color: #fff;
  border: none;
  border-radius: 50px;
  text-align: center;
  position: relative;
  opacity: 1;
  font-size: 22px;
  font-family: "stepapp-secondary-regular", sans-serif;
  border: 3px solid transparent;
}

input[type="radio"]:checked+label {
  border: 3px solid #15388c;
  /* color: black; */
  opacity: 1;
}

input[type="radio"]:checked+label::after {
  font-family: fontello;
  content: "";
  height: 30px;
  width: 30px;
  background: url("../images/check.svg");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 30px;
  z-index: 999;
  position: absolute;
  top: -10px;
  right: -5px;
}

.custom-control-input:checked~.custom-control-label::before {
  background-image: none;
  background-color: transparent;
  border: 2px solid #170be1;
  margin-left: 13px;
  width: 90px;
}

/* ---------------------------------------------------
label formating
--------------------------------------------------- */
.form-control-soft {
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  color: #000;
  padding-top: 7px !important;
}

.form-control-soft:hover {
  color: #000 !important;
  background-color: #fff;
  border: 2px solid #fc8643;
  box-shadow: none;
}

.form-control-soft:focus,
.form-control-soft:active {
  color: #000 !important;
  background-color: #fff;
  border: 2px solid #fc8643;
  box-shadow: none;
}

.form-control-soft:visited {
  color: #000;
  border-color: 1px solid #fc8643;
  box-shadow: none;
}

.form-control-glass {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid #ddd;
  color: #000;
  padding-top: 7px !important;
  width: 100%;
  height: 40px;
}

.form-control-glass:hover {
  color: #000 !important;
  background-color: rgba(255, 255, 255, 0.1);
  border: 2px solid #fc8643;
  box-shadow: none;
}

.form-control-glass:focus,
.form-control-glass:active {
  color: #000 !important;
  background-color: rgba(255, 255, 255, 0.1);
  border: 2px solid #fc8643;
  box-shadow: none;
}

.form-control-glass:visited {
  color: #000;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: 1px solid #fc8643;
  box-shadow: none;
}

.form-control {
  border-radius: 10px;
  color: #000;
  padding-top: 7px !important;
  height: 40px !important;
}

.form-control:visited {
  color: #000;
  background-color: #fff !important;
  border-color: 1px solid #fc8643;
  box-shadow: none;
}

.form-control:disabled,
.form-control[readonly] {
  opacity: 0.1;
  color: #7a7f9a !important;
  background-color: #fff !important;
}

.custom-checkbx {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbx input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.custom-checkbx:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbx input:checked~.checkmark {
  background-color: #fff;
  border: 1px solid #fc8643;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbx input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbx .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #fc8643;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* The radio */
.radio {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkround {
  position: absolute;
  top: 6px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-color: #fc8643;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.radio input:checked~.checkround {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkround:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio input:checked~.checkround:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio .checkround:after {
  left: 2px;
  top: 2px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #fc8643;
}

/* Create a custom radio button */
.checkround {
  position: absolute;
  top: 6px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-color: #fc8643;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.radio input:checked~.checkround {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkround:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio input:checked~.checkround:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio .checkround:after {
  left: 2px;
  top: 2px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #fc8643;
}

.selectpicker {
  height: 50px;
}

input,
input:hover,
input:focus,
input:active,
input:focus-within,
.form-control:focus {
  box-shadow: none;
  border-width: 1px;
  border-color: #15388c;
  outline: none !important;
  font-weight: normal;
}

.input-group>.custom-select:not(:last-child),
.input-group>.form-control:not(:last-child) {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

textarea {
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  font-size: 18px;
  font-family: "stepapp-secondary-regular", sans-serif;
  padding: 10px;
  border-width: 1px;
  border-color: #caccce;
  outline: none !important;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  -webkit-appearance: menulist-button;
  background-image: none !important;
  color: -internal-light-dark-color(black, white) !important;
}

/*-------------accordian---------*/
.card {
  border-radius: 15px 10px !important;
  border: none;
}

.panel {
  border: none;
  box-shadow: none;
  padding: 5px;
  background-color: #fff;
  background-color: #fff;
}

.accordian-content {
  padding-top: 20px;
  border-radius: 0px;
}

.panel-text {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #6c757d !important;
  transition: all 0.5s ease 0s;
  text-decoration: none;
  text-align: left;
}

.panel-text-collapsed {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #fc8643 !important;
  transition: all 0.5s ease 0s;
  text-decoration: none;
}

.panel-text-collapsed .panel-text {
  color: #fc8643 !important;
}

.panel-title {
  padding-right: 10px;
}

.panel-title a:before {
  content: "";
  font-family: "Fontello";
  width: 25px;
  height: 25px;
  line-height: 28px;
  font-size: 15px;
  font-weight: 900;
  color: #6c757d;
  text-align: center;
  position: absolute;
  right: 15px;
  transform: rotate(0);
  transition: all 0.3s ease 0s;
}

.panel-title-collapsed a:before {
  content: "";
  font-family: "Fontello";
  width: 25px;
  height: 25px;
  line-height: 28px;
  font-size: 15px;
  font-weight: 900;
  color: #fc8643;
  text-align: center;
  position: absolute;
  right: 15px;
  transform: rotate(135deg);
  transition: all 0.3s ease 0s;
}

.panel-body {
  padding: 10px;
  border: none;
  font-size: 15px;
  color: #615f5f;
  line-height: 27px;
  background-color: #fff;
  border-radius: 0px 0px 15px 15px;
  border-top: 1px solid #caccce;
  padding-top: 10px;
}

.accordion>.card>.card-header {
  border-radius: 0;
  margin-bottom: -1px;
  cursor: pointer;
}

/*-----for quiz module------*/
.custom-input-style-quiz {
  height: 55px;
  text-align: left;
  background-color: #fff;
  color: #000 !important;
  padding-left: 20px;
}

.custom-input-style-quiz:hover {
  background-color: #fff;
  color: #000;
  text-decoration: none;
  border: 1px solid #6c757d;
}

.custom-input-style-quiz:active,
.custom-input-style-quiz:focus {
  background-color: #fff;
  color: #000 !important;
}

.custom-input-style-quiz:visited {
  background-color: #fff;
  color: #000 !important;
}

.form-control-quiz {
  border-radius: 5px !important;
  background-color: #fff;
  border: 1px solid #ddd;
  color: #000;
}

.invalid-stepapp-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e96969;
}

.bg-nav {
  background-color: #15388c;
  color: #fff;
  border: none !important;
}

.quiz-menu .navbar-responsive {
  background-color: #000 !important;
  color: #fff;
}

.quiz-nav-sub {
  background-color: #080808;
}

.nav-text {
  display: none;
}

.chapter-nav-text {
  display: block;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
}

.chapter-nav-img {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.profile-quiz {
  max-height: 100vh !important;
  overflow-y: auto;
}

body,
html {
  width: 100%;
  height: 100%;
}

svg {
  width: auto !important;
  height: auto !important;
  margin: 3px;
  /* display: inline-block; */
}

* {
  scrollbar-width: thin;
  scrollbar-color: #fc8643 #15388c;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none !important;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fc8643;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e4ac13;
}

.cursor-pointer {
  cursor: pointer !important;
}

.grey-link {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 14px;
  color: #6c757d;
  font-weight: 600px;
}

.grey-link:hover {
  text-decoration: none;
  color: #fc8643;
}

.orange-link-u {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 14px;
  color: #fc8643;
  font-weight: 600px;
  text-decoration: underline;
}

.orange-link-u:visited {
  text-decoration: none;
  color: #fc8643;
  text-decoration: underline;
}

.orange-link-u:hover {
  color: #15388c;
  text-decoration: underline;
}

.white-link-u {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 14px;
  color: #fff !important;
  font-weight: 600px;
  text-decoration: underline;
}

.white-link-u:visited {
  text-decoration: none;
  color: #fc8643;
  text-decoration: underline;
}

.white-link-u:hover {
  color: #15388c;
  text-decoration: underline;
}

.text-faded {
  color: rgba(255, 255, 255, 0.7);
}

.w100 {
  font-weight: 100;
}

.w200 {
  font-weight: 200;
}

.w300 {
  font-weight: 300;
}

.w400 {
  font-weight: 400;
}

.w600 {
  font-weight: 600;
}

.w700 {
  font-weight: 700;
}

.width-5 {
  width: 5% !important;
}

.width-10 {
  width: 10% !important;
}

.width-12 {
  width: 12% !important;
}

.width-13 {
  width: 13% !important;
}

.width-15 {
  width: 15% !important;
}

.width-20 {
  width: 20% !important;
}

.width-23 {
  width: 23% !important;
}

.width-25 {
  width: 25% !important;
}

.width-26 {
  width: 26% !important;
}

.width-33 {
  width: 33%;
}

.width-30 {
  width: 30% !important;
}

.width-40 {
  width: 40% !important;
}

.width-45 {
  width: 45% !important;
}

.width-47 {
  width: 47% !important;
}

.width-50 {
  width: 50% !important;
}

.width-52 {
  width: 52% !important;
}

.width-60 {
  width: 60% !important;
}

.width-70 {
  width: 70% !important;
}

.width-75 {
  width: 75% !important;
}

.width-80 {
  width: 80% !important;
}

.width-85 {
  width: 85% !important;
}

.width-90 {
  width: 90% !important;
}

.width-100 {
  width: 100% !important;
}

.width-auto {
  width: auto !important;
}

.width-200pixels {
  width: 200px !important;
}

.body-font {
  font-size: small;
}

.border-bottom-none {
  border-bottom: none !important;
}

.progress {
  border-radius: 50px;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #1b1f5f;
  height: 12px;
  padding: 3px;
}

.p-height {
  height: 4px;
}

.progress-bar-physics {
  background-color: #e4ac13;
  border-radius: 100px;
}

.progress-bar-chemistry {
  background-color: #3396be;
  border-radius: 100px;
}

.progress-bar-biology {
  background-color: #17bd59;
  border-radius: 100px;
}

.progress-bar-maths {
  background-color: #e96969;
  margin-left: 5px;
  border-radius: 100px;
}

.progress-bar-default {
  background-color: #996fd6;
  border-radius: 100px;
}

.physics {
  background-color: #e4ac13;
}

.chem {
  background-color: #3396be;
}

.maths {
  background-color: #e96969;
}

.bio {
  background-color: #17bd59;
}

.default {
  background-color: #996fd6;
}

.text_physics {
  color: #e4ac13;
}

.text_chem {
  color: #3396be;
}

.text_maths {
  color: #e96969;
}

.text_bio {
  color: #17bd59;
}

.text_default {
  color: #996fd6;
}

/*--------------for Mobile number Updates-------------*/
.react-tel-input {
  position: relative;
  top: -5px;
  width: 100%;
}

.react-tel-input .form-control {
  position: relative;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: transparent !important;
  border: none !important;
  border-radius: 5px;
  line-height: 40px;
  height: 40px !important;
  width: auto !important;
  outline: none;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background: transparent !important;
  border: none !important;
  border-radius: 50px 0 0 50px !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus,
.selected-flag:active,
.selected-flag:focus-within {
  background-color: transparent !important;
}

.react-tel-input .country-list {
  outline: none;
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
  background-color: white;
  width: 200px;
  max-height: 200px;
  overflow-y: scroll;
  text-align: left;
  border-radius: 0 0 3px 3px;
}

.react-tel-input .flag-dropdown.open {
  z-index: 2;
  background: transparent !important;
  border-radius: 50px 0 0 50px !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
  border-radius: 50px 0 0 50px !important;
}

.rotate-180 {
  /* IE 9 */
  /* Safari 3-8 */
  transform: rotate(180deg);
}

.rotate-45 {
  /* IE 9 */
  /* Safari 3-8 */
  transform: rotate(45deg);
}

.top {
  z-index: 980;
}

.custom-cur-lock {
  cursor: url("../images/lock.svg");
}

/* With a .cur fallback */
.custom-cur-lock {
  cursor: url("../images/lock.svg");
}

/* With a custom hotspot */
.custom-cur-lock {
  cursor: url("../images/lock.svg") 10 12;
}

/* With a non-custom fallback: */
.custom-cur-lock {
  cursor: url("../images/lock.svg"), not-allowed;
}

.img-circle {
  border-radius: 50%;
}

.pagination>li>a,
.pagination>li>span {
  border-radius: 15px !important;
  margin: 0 5px;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #fc8643;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.nav-link {
  cursor: pointer;
}

.nav-link.active {
  font-weight: 600;
}

/*----video----*/
.shaka-video {
  width: 100%;
  height: 100%;
}

.shaka-video-container {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url("../images/logo/logo-dark.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #000;
  background-size: 50%;
}

.shaka-video-container .material-icons-round {
  font-family: Material Icons Round !important
}

.shaka-controls-button-panel {
  font-size: 23px !important;
  color: #fc8643;
  margin: 1px 3px;
  padding: 0;
  border: 0;
}

.shaka-range-element {
  appearance: none;
  -webkit-appearance: none;
  background: #15388c !important;
  border-radius: 10px !important;
  color: #fff;
}

.shaka-volume-bar-container {
  display: block;
  width: 100px !important;
  background-color: #15388c !important;
  border-radius: 10px !important;
}

.shaka-seek-bar {
  border: 0.5px solid #000 !important;
  background-color: #15388c !important;
  border-radius: 100px;
}

.shaka-seek-bar:hover,
.shaka-seek-bar:focus,
.shaka-seek-bar:active {
  background-color: #15388c !important;
}

.shaka-controls-button-panel>* {
  color: #15388c;
  background-color: #fc8643;
  border-radius: 5px;
  height: 32px;
  margin: 1px 6px;
  padding: 0;
  background: 0 0;
  border: 0;
  cursor: pointer;
}

.shaka-bottom-controls {
  /* background-color: #000; */
  border-radius: 10px;
}

/*-----------onboarding -----*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: auto !important;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform, transition;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.backarrow {
  font-size: 12px;
  color: #6c757d;
}

.loader-position {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.color-danger {
  color: #e96969;
}

.paddingb-80 {
  padding-bottom: 80px;
}

/*-----------flex center-----*/
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*---uk checkbos---*/
.uk-checkbox,
.uk-radio:checked,
.uk-checkbox:checked,
.uk-checkbox:indeterminate {
  border-radius: 5px !important;
  margin: 0px 3px !important;
}

.modal-content {
  border-radius: 10px !important;
}

.island-theme-1 body {
  background-image: linear-gradient(to bottom, #5ee7df, #fbc2eb);
}

.island-theme-1 .theme-color {
  height: 100vh;
  display: block;
  background-image: linear-gradient(to bottom, #5ee7df, #fbc2eb);
  padding: 0px;
  margin: 0px;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-1 .external-color {
  background-image: linear-gradient(to bottom, #5ee7df, #fbc2eb);
}

.island-theme-1 .island-course-header {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.island-theme-1 .island-course-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}

.island-theme-1 #content-with-header {
  position: fixed;
  top: 56px;
  left: 0px;
  height: calc(100vh - 56px);
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 50px;
}

.island-theme-1 #island-content {
  height: 100vh;
  display: block;
  padding: 0px;
  margin: 0px;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-1 .fix-bottom-nav {
  background: transparent;
}

.island-theme-1 .island-pri-bg {
  border-radius: 5px;
  color: #fff;
  background-color: #7d486e;
  height: 40px;
  min-width: 40px;
  border: none;
  max-width: auto;
  padding: 0px 5px;
}

.island-theme-1 .island-pri-bg:hover {
  background-color: #914d7e;
  border: none;
}

.island-theme-1 .island-pri-bg:active,
.island-theme-1 .island-pri-bg:focus {
  background-color: #ad6499;
}

.island-theme-1 .island-pri-bg:visited {
  background-color: #ad6499;
}

.island-theme-1 .intro-btn button {
  min-height: 52px;
  background-color: #7d486e;
  border-radius: 5px;
  border: none;
  font-size: 15px;
  color: #ffffff;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.island-theme-1 .intro-btn button:hover {
  background-color: #7d486e;
}

.island-theme-1 .intro-btn button:active {
  background-color: #7d486e;
}

.island-theme-1 .intro-btn button:visited {
  background-color: #7d486e;
}

.island-theme-1 .intro-btn button:focus {
  background-color: #7d486e;
}

.island-theme-1 .intro-btn button:active:focus {
  background-color: #7d486e;
}

.island-theme-1 .intro-btn button:focus-within {
  background-color: #7d486e;
}

.island-theme-1 .incomplete-block .intro-btn button {
  opacity: 0.3;
  cursor: not-allowed;
}

.island-theme-1 .incomplete-block .intro-btn button:hover {
  background-color: #7d486e;
}

.island-theme-1 .incomplete-block .intro-btn button:active {
  background-color: #7d486e;
}

.island-theme-1 .incomplete-block .intro-btn button:visited {
  background-color: #7d486e;
}

.island-theme-1 .button {
  background-color: #7d486e;
  border: 0;
  border-radius: 5px;
  bottom: 10px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  padding: 1em 2em;
  position: absolute;
  right: 10px;
}

.island-theme-1 .course-progress ul {
  background-color: #ad6499;
}

.island-theme-1 .course-progress ul li {
  color: #fff;
}

.island-theme-1 .videosection .nav_bg {
  background: transparent !important;
}

.island-theme-1 .revisevideo .nav_bg {
  background: transparent !important;
}

.island-theme-1 .treasurevideo .nav_bg {
  background: transparent !important;
}

.island-theme-1 .lessons-nav-button {
  background-color: #5d3552 !important;
}

.island-theme-1 .video-lesson-card {
  background-color: black;
  border-radius: 0px;
  margin: 0px auto;
  padding: 0px;
}

.island-theme-1 .video-Card {
  background-color: black;
}

.island-theme-1 .info-screen-header-border,
.island-theme-1 .info-screen-footer-border {
  border-color: rgba(255, 255, 255, 0.8) !important;
}

.island-theme-1 .info-screen {
  background-color: #ad6499 !important;
  border: 3px solid #fff !important;
}

.island-theme-1 .select-theme {
  height: 16vh;
  display: block;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  position: relative;
  opacity: 1;
  width: 110px;
  margin: 10px;
  border: 3px solid transparent;
}

.island-theme-1 .island-video {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 81vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.island-theme-1 .lessons-nav-button-circle {
  background-color: #ad6499 !important;
}

.island-theme-1 .vid-lessons-title {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  text-align: left;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
}

.island-theme-1 .lessons-nav-button-circle {
  cursor: pointer;
  background-color: #ad6499 !important;
  border-radius: 50px;
  padding: 0px;
  box-shadow: none;
  border: 1px solid transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #fff;
  height: 35px;
  width: 35px;
  margin: 0px 10px;
  text-align: center;
  line-height: 35px;
}

.island-theme-1 .lessons-nav-button-circle:hover {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #ad6499 !important;
}

.island-theme-1 .lessons-nav-button-circle:active {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-1 .lessons-nav-button-circle:visited {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #ad6499 !important;
}

.island-theme-1 .lessons-nav-button-circle-active {
  cursor: pointer;
  border-radius: 50px;
  padding: 0px;
  box-shadow: none;
  border: 1px solid transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  height: 35px;
  width: 35px;
  margin: 0px 10px;
  text-align: center;
  line-height: 35px;
  color: #ad6499 !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-1 .lessons-nav-button-circle-active:active,
.island-theme-1 .lessons-nav-button-circle-active:hover,
.island-theme-1 .lessons-nav-button-circle-active:visited {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-1 .step-rect-sel {
  background-color: #fff !important;
}

.island-theme-1 .step-rect {
  background-color: #5d3552 !important;
}

.island-theme-1 .island-modal {
  height: auto;
  display: block;
  background-color: #7d486e;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-1 .island-report {
  height: 90vh;
  width: 100vw;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.island-theme-1 .modal-header {
  color: #ad6499 !important;
  border-radius: 10px 10px 0px 0px;
}

.island-theme-1 .modal-body {
  border-radius: 0px;
}

.island-theme-1 .modal-custom-bg {
  background-color: #ad6499 !important;
  color: #fff !important;
}

.island-theme-1 .modal-custom-bg h5 {
  color: #fff !important;
}

.island-theme-1 .modal-custom-footer-bg {
  background-color: #ad6499 !important;
}

.island-theme-1 .btn-pill {
  height: 40px;
  font-size: 15px;
  line-height: 28px;
  border-radius: 100px;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-weight: 500;
  background-color: #914d7e;
  border: none;
  padding: 0px 8px;
  color: #fff;
  z-index: 3000;
}

.island-theme-1 .btn-pill .btn-basic:hover {
  border: none;
  outline: none !important;
}

.island-theme-1 .btn-pill .btn-basic:focus {
  border: none;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.island-theme-1 .button-small {
  background-color: #5d3552;
}

.island-theme-1 .button-small:hover {
  background-color: #7d486e;
}

.island-theme-1 .button-small:active {
  background-color: #7d486e;
}

.island-theme-1 .button-small:visited {
  background-color: #7d486e;
}

.island-theme-1 .button-small:focus {
  background-color: #7d486e;
}

.island-theme-1 .button-small:active:focus {
  background-color: #7d486e;
}

.island-theme-1 .button-small:focus-within {
  background-color: #7d486e;
}

.island-theme-1 .modal-button {
  min-width: auto;
  min-height: 32px;
  background-color: #914d7e;
  border-radius: 10px !important;
  border: none;
  font-size: 15px;
  color: #ffffff;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  direction: ltr;
}

.island-theme-1 .modal-button:hover {
  background-color: #7d486e;
}

.island-theme-1 .modal-button:active {
  background-color: #7d486e;
}

.island-theme-1 .modal-button:visited {
  background-color: #7d486e;
}

.island-theme-1 .modal-button:focus {
  background-color: #7d486e;
}

.island-theme-1 .modal-button:active:focus {
  background-color: #7d486e;
}

.island-theme-1 .modal-button:focus-within {
  background-color: #7d486e;
}

.island-theme-1 .theme-text,
.island-theme-1 .lessontitle,
.island-theme-1 .theme-text {
  color: #7d486e !important;
}

.island-theme-1 .progress-bar-color {
  background-color: #7d486e !important;
  border-radius: 10px;
}

.island-theme-1 .island-tabs-sel {
  border-bottom: 4px solid #ad6499;
  color: #ad6499;
}

.island-theme-1 .test {
  background: linear-gradient(to bottom, #5ee7df, #fbc2eb);
}

.island-theme-1 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-4 {
  background: linear-gradient(to bottom, #5ee7df, #fbc2eb) !important;
}

.island-theme-1 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-1 {
  background: linear-gradient(to bottom, #5ee7df, #fbc2eb);
}

.island-theme-1 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-2 {
  background: linear-gradient(to bottom, #e579b0, #9075e7);
}

.island-theme-1 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-3 {
  background: linear-gradient(to bottom, #0feadd, #3198d1);
}

.island-theme-1 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-5 {
  background: linear-gradient(to bottom, #1b1f5f, #000);
}

.island-theme-1 .input[type="radio"]:checked+label {
  background: linear-gradient(to bottom, #5ee7df, #fbc2eb) !important;
}

.island-theme-1 .nav_bg {
  background-color: Transparent;
  width: 100%;
}

.island-theme-1 .modal-title {
  color: #ad6499;
}

.island-theme-1 .bg-circle-big-silver {
  background-color: #7d486e;
  color: #fff;
}

.island-theme-1 .quiz-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  max-height: 40vh;
  /* background-color: #022d4c; */
  background-color: #7d486e;
}

.island-theme-1 .ant-drawer-header {
  background-color: #7d486e;
}

.island-theme-1 .ant-drawer-header .ant-drawer-title {
  color: #fff;
}

.island-theme-1 .shaka-seek-bar {
  border: 0.5px solid #7d486e !important;
  background-color: #7d486e !important;
}

.island-theme-1 .shaka-range-element {
  border: 0.5px solid #7d486e !important;
  background-color: #7d486e !important;
}

.island-theme-1 .dv-star-rating {
  display: flex;
}

.island-theme-1 .dv-star-rating input[type="radio"]:checked+label {
  border: 3px solid transparent;
  background-color: #7d486e;
  opacity: 1;
}

.island-theme-1 .dv-star-rating input[type="radio"]:checked+label::after {
  display: none;
}

.island-theme-1 .dv-star-rating-empty-star {
  background-color: #caccce;
}

.island-theme-1 .dv-star-rating-full-star {
  background-color: #7d486e;
}

.island-theme-1 .button-red,
.island-theme-1 .button-red:active,
.island-theme-1 .button-red:focus,
.island-theme-1 .button-red:visited {
  background-color: red !important;
}

.island-theme-1 .button-green,
.island-theme-1 .button-green:active,
.island-theme-1 .button-green:focus,
.island-theme-1 .button-green:visited,
.island-theme-1 .button-green:focus-within,
.island-theme-1 .button-green:focus-visible {
  background-color: green !important;
}

.island-theme-1 .info-bg-island {
  background-color: #7d486e;
}

.island-theme-2 body {
  background-image: linear-gradient(to bottom, #e579b0, #9075e7);
}

.island-theme-2 .theme-color {
  height: 100vh;
  display: block;
  background-image: linear-gradient(to bottom, #e579b0, #9075e7);
  padding: 0px;
  margin: 0px;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-2 .external-color {
  background-image: linear-gradient(to bottom, #e579b0, #9075e7);
}

.island-theme-2 .island-course-header {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.island-theme-2 .island-course-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}

.island-theme-2 #content-with-header {
  position: fixed;
  top: 56px;
  left: 0px;
  height: calc(100vh - 56px);
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 50px;
}

.island-theme-2 #island-content {
  height: 100vh;
  display: block;
  padding: 0px;
  margin: 0px;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-2 .fix-bottom-nav {
  background: transparent;
}

.island-theme-2 .island-pri-bg {
  border-radius: 5px;
  color: #fff;
  background-color: #702677;
  height: 40px;
  min-width: 40px;
  border: none;
  max-width: auto;
  padding: 0px 5px;
}

.island-theme-2 .island-pri-bg:hover {
  background-color: #534390;
  border: none;
}

.island-theme-2 .island-pri-bg:active,
.island-theme-2 .island-pri-bg:focus {
  background-color: #6856b0;
}

.island-theme-2 .island-pri-bg:visited {
  background-color: #6856b0;
}

.island-theme-2 .intro-btn button {
  min-height: 52px;
  background-color: #702677;
  border-radius: 5px;
  border: none;
  font-size: 15px;
  color: #ffffff;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.island-theme-2 .intro-btn button:hover {
  background-color: #702677;
}

.island-theme-2 .intro-btn button:active {
  background-color: #702677;
}

.island-theme-2 .intro-btn button:visited {
  background-color: #702677;
}

.island-theme-2 .intro-btn button:focus {
  background-color: #702677;
}

.island-theme-2 .intro-btn button:active:focus {
  background-color: #702677;
}

.island-theme-2 .intro-btn button:focus-within {
  background-color: #702677;
}

.island-theme-2 .incomplete-block .intro-btn button {
  opacity: 0.3;
  cursor: not-allowed;
}

.island-theme-2 .incomplete-block .intro-btn button:hover {
  background-color: #702677;
}

.island-theme-2 .incomplete-block .intro-btn button:active {
  background-color: #702677;
}

.island-theme-2 .incomplete-block .intro-btn button:visited {
  background-color: #702677;
}

.island-theme-2 .button {
  background-color: #702677;
  border: 0;
  border-radius: 5px;
  bottom: 10px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  padding: 1em 2em;
  position: absolute;
  right: 10px;
}

.island-theme-2 .course-progress ul {
  background-color: #6856b0;
}

.island-theme-2 .course-progress ul li {
  color: #fff;
}

.island-theme-2 .videosection .nav_bg {
  background: transparent !important;
}

.island-theme-2 .revisevideo .nav_bg {
  background: transparent !important;
}

.island-theme-2 .treasurevideo .nav_bg {
  background: transparent !important;
}

.island-theme-2 .lessons-nav-button {
  background-color: #4c1a50 !important;
}

.island-theme-2 .video-lesson-card {
  background-color: black;
  border-radius: 0px;
  margin: 0px auto;
  padding: 0px;
}

.island-theme-2 .video-Card {
  background-color: black;
}

.island-theme-2 .info-screen-header-border,
.island-theme-2 .info-screen-footer-border {
  border-color: rgba(255, 255, 255, 0.8) !important;
}

.island-theme-2 .info-screen {
  background-color: #6856b0 !important;
  border: 3px solid #fff !important;
}

.island-theme-2 .select-theme {
  height: 16vh;
  display: block;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  position: relative;
  opacity: 1;
  width: 110px;
  margin: 10px;
  border: 3px solid transparent;
}

.island-theme-2 .island-video {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 81vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.island-theme-2 .lessons-nav-button-circle {
  background-color: #6856b0 !important;
}

.island-theme-2 .vid-lessons-title {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  text-align: left;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
}

.island-theme-2 .lessons-nav-button-circle {
  cursor: pointer;
  background-color: #6856b0 !important;
  border-radius: 50px;
  padding: 0px;
  box-shadow: none;
  border: 1px solid transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #fff;
  height: 35px;
  width: 35px;
  margin: 0px 10px;
  text-align: center;
  line-height: 35px;
}

.island-theme-2 .lessons-nav-button-circle:hover {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #6856b0 !important;
}

.island-theme-2 .lessons-nav-button-circle:active {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-2 .lessons-nav-button-circle:visited {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #6856b0 !important;
}

.island-theme-2 .lessons-nav-button-circle-active {
  cursor: pointer;
  border-radius: 50px;
  padding: 0px;
  box-shadow: none;
  border: 1px solid transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  height: 35px;
  width: 35px;
  margin: 0px 10px;
  text-align: center;
  line-height: 35px;
  color: #6856b0 !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-2 .lessons-nav-button-circle-active:active,
.island-theme-2 .lessons-nav-button-circle-active:hover,
.island-theme-2 .lessons-nav-button-circle-active:visited {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-2 .step-rect-sel {
  background-color: #fff !important;
}

.island-theme-2 .step-rect {
  background-color: #4c1a50 !important;
}

.island-theme-2 .island-modal {
  height: auto;
  display: block;
  background-color: #702677;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-2 .island-report {
  height: 90vh;
  width: 100vw;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.island-theme-2 .modal-header {
  color: #6856b0 !important;
  border-radius: 10px 10px 0px 0px;
}

.island-theme-2 .modal-body {
  border-radius: 0px;
}

.island-theme-2 .modal-custom-bg {
  background-color: #6856b0 !important;
  color: #fff !important;
}

.island-theme-2 .modal-custom-bg h5 {
  color: #fff !important;
}

.island-theme-2 .modal-custom-footer-bg {
  background-color: #6856b0 !important;
}

.island-theme-2 .btn-pill {
  height: 40px;
  font-size: 15px;
  line-height: 28px;
  border-radius: 100px;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-weight: 500;
  background-color: #534390;
  border: none;
  padding: 0px 8px;
  color: #fff;
  z-index: 3000;
}

.island-theme-2 .btn-pill .btn-basic:hover {
  border: none;
  outline: none !important;
}

.island-theme-2 .btn-pill .btn-basic:focus {
  border: none;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.island-theme-2 .button-small {
  background-color: #4c1a50;
}

.island-theme-2 .button-small:hover {
  background-color: #702677;
}

.island-theme-2 .button-small:active {
  background-color: #702677;
}

.island-theme-2 .button-small:visited {
  background-color: #702677;
}

.island-theme-2 .button-small:focus {
  background-color: #702677;
}

.island-theme-2 .button-small:active:focus {
  background-color: #702677;
}

.island-theme-2 .button-small:focus-within {
  background-color: #702677;
}

.island-theme-2 .modal-button {
  min-width: auto;
  min-height: 32px;
  background-color: #534390;
  border-radius: 10px !important;
  border: none;
  font-size: 15px;
  color: #ffffff;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  direction: ltr;
}

.island-theme-2 .modal-button:hover {
  background-color: #702677;
}

.island-theme-2 .modal-button:active {
  background-color: #702677;
}

.island-theme-2 .modal-button:visited {
  background-color: #702677;
}

.island-theme-2 .modal-button:focus {
  background-color: #702677;
}

.island-theme-2 .modal-button:active:focus {
  background-color: #702677;
}

.island-theme-2 .modal-button:focus-within {
  background-color: #702677;
}

.island-theme-2 .theme-text,
.island-theme-2 .lessontitle,
.island-theme-2 .theme-text {
  color: #702677 !important;
}

.island-theme-2 .progress-bar-color {
  background-color: #702677 !important;
  border-radius: 10px;
}

.island-theme-2 .island-tabs-sel {
  border-bottom: 4px solid #6856b0;
  color: #6856b0;
}

.island-theme-2 .test {
  background: linear-gradient(to bottom, #e579b0, #9075e7);
}

.island-theme-2 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-4 {
  background: linear-gradient(to bottom, #e579b0, #9075e7) !important;
}

.island-theme-2 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-1 {
  background: linear-gradient(to bottom, #5ee7df, #fbc2eb);
}

.island-theme-2 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-2 {
  background: linear-gradient(to bottom, #e579b0, #9075e7);
}

.island-theme-2 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-3 {
  background: linear-gradient(to bottom, #0feadd, #3198d1);
}

.island-theme-2 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-5 {
  background: linear-gradient(to bottom, #1b1f5f, #000);
}

.island-theme-2 .input[type="radio"]:checked+label {
  background: linear-gradient(to bottom, #e579b0, #9075e7) !important;
}

.island-theme-2 .nav_bg {
  background-color: Transparent;
  width: 100%;
}

.island-theme-2 .modal-title {
  color: #6856b0;
}

.island-theme-2 .bg-circle-big-silver {
  background-color: #702677;
  color: #fff;
}

.island-theme-2 .quiz-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  max-height: 40vh;
  /* background-color: #4c1a50; */
  background-color: #42024c;
}

.island-theme-2 .ant-drawer-header {
  background-color: #702677;
}

.island-theme-2 .ant-drawer-header .ant-drawer-title {
  color: #fff;
}

.island-theme-2 .shaka-seek-bar {
  border: 0.5px solid #702677 !important;
  background-color: #702677 !important;
}

.island-theme-2 .shaka-range-element {
  border: 0.5px solid #702677 !important;
  background-color: #702677 !important;
}

.island-theme-2 .dv-star-rating {
  display: flex;
}

.island-theme-2 .dv-star-rating input[type="radio"]:checked+label {
  border: 3px solid transparent;
  background-color: #702677;
  opacity: 1;
}

.island-theme-2 .dv-star-rating input[type="radio"]:checked+label::after {
  display: none;
}

.island-theme-2 .dv-star-rating-empty-star {
  background-color: #caccce;
}

.island-theme-2 .dv-star-rating-full-star {
  background-color: #702677;
}

.island-theme-2 .button-red,
.island-theme-2 .button-red:active,
.island-theme-2 .button-red:focus,
.island-theme-2 .button-red:visited {
  background-color: red !important;
}

.island-theme-2 .button-green,
.island-theme-2 .button-green:active,
.island-theme-2 .button-green:focus,
.island-theme-2 .button-green:visited,
.island-theme-2 .button-green:focus-within,
.island-theme-2 .button-green:focus-visible {
  background-color: green !important;
}

.island-theme-2 .info-bg-island {
  background-color: #702677;
}

.island-theme-3 .theme-color {
  height: 100vh;
  display: block;
  background-image: linear-gradient(to bottom, #0feadd, #3198d1);
  padding: 0px;
  margin: 0px;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-3 .island-course-header {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.island-theme-3 .island-course-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}

.island-theme-3 #content-with-header {
  position: fixed;
  top: 56px;
  left: 0px;
  height: calc(100vh - 56px);
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 50px;
}

.island-theme-3 #island-content {
  height: 100vh;
  display: block;
  padding: 0px;
  margin: 0px;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-3 .fix-bottom-nav {
  background: transparent;
}

.island-theme-3 .island-pri-bg {
  border-radius: 5px;
  color: #fff;
  background-color: #286ba0;
  height: 40px;
  min-width: 40px;
  border: none;
  max-width: auto;
  padding: 0px 5px;
}

.island-theme-3 .island-pri-bg:hover {
  background-color: #1e5077;
  border: none;
}

.island-theme-3 .island-pri-bg:active,
.island-theme-3 .island-pri-bg:focus {
  background-color: #286ba0;
}

.island-theme-3 .island-pri-bg:visited {
  background-color: #286ba0;
}

.island-theme-3 .intro-btn button {
  min-height: 52px;
  background-color: #286ba0;
  border-radius: 5px;
  border: none;
  font-size: 15px;
  color: #ffffff;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.island-theme-3 .intro-btn button:hover {
  background-color: #286ba0;
}

.island-theme-3 .intro-btn button:active {
  background-color: #286ba0;
}

.island-theme-3 .intro-btn button:visited {
  background-color: #286ba0;
}

.island-theme-3 .intro-btn button:focus {
  background-color: #286ba0;
}

.island-theme-3 .intro-btn button:active:focus {
  background-color: #286ba0;
}

.island-theme-3 .intro-btn button:focus-within {
  background-color: #286ba0;
}

.island-theme-3 .incomplete-block .intro-btn button {
  opacity: 0.3;
  cursor: not-allowed;
}

.island-theme-3 .incomplete-block .intro-btn button:hover {
  background-color: #286ba0;
}

.island-theme-3 .incomplete-block .intro-btn button:active {
  background-color: #286ba0;
}

.island-theme-3 .incomplete-block .intro-btn button:visited {
  background-color: #286ba0;
}

.island-theme-3 .button {
  background-color: #286ba0;
  border: 0;
  border-radius: 5px;
  bottom: 10px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  padding: 1em 2em;
  position: absolute;
  right: 10px;
}

.island-theme-3 .course-progress ul {
  background-color: #286ba0;
}

.island-theme-3 .course-progress ul li {
  color: #fff;
}

.island-theme-3 .videosection .nav_bg {
  background: transparent !important;
}

.island-theme-3 .revisevideo .nav_bg {
  background: transparent !important;
}

.island-theme-3 .treasurevideo .nav_bg {
  background: transparent !important;
}

.island-theme-3 .lessons-nav-button {
  background-color: #1e5077 !important;
}

.island-theme-3 .video-lesson-card {
  background-color: black;
  border-radius: 0px;
  margin: 0px auto;
  padding: 0px;
}

.island-theme-3 .video-Card {
  background-color: black;
}

.island-theme-3 .info-screen-header-border,
.island-theme-3 .info-screen-footer-border {
  border-color: rgba(255, 255, 255, 0.8) !important;
}

.island-theme-3 .info-screen {
  background-color: #286ba0 !important;
  border: 3px solid #fff !important;
}

.island-theme-3 .select-theme {
  height: 16vh;
  display: block;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  position: relative;
  opacity: 1;
  width: 110px;
  margin: 10px;
  border: 3px solid transparent;
}

.island-theme-3 .island-video {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 81vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.island-theme-3 .lessons-nav-button-circle {
  background-color: #286ba0 !important;
}

.island-theme-3 .vid-lessons-title {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  text-align: left;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
}

.island-theme-3 .lessons-nav-button-circle {
  cursor: pointer;
  background-color: #286ba0 !important;
  border-radius: 50px;
  padding: 0px;
  box-shadow: none;
  border: 1px solid transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #fff;
  height: 35px;
  width: 35px;
  margin: 0px 10px;
  text-align: center;
  line-height: 35px;
}

.island-theme-3 .lessons-nav-button-circle:hover {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #286ba0 !important;
}

.island-theme-3 .lessons-nav-button-circle:active {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-3 .lessons-nav-button-circle:visited {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #286ba0 !important;
}

.island-theme-3 .lessons-nav-button-circle-active {
  cursor: pointer;
  border-radius: 50px;
  padding: 0px;
  box-shadow: none;
  border: 1px solid transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  height: 35px;
  width: 35px;
  margin: 0px 10px;
  text-align: center;
  line-height: 35px;
  color: #286ba0 !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-3 .lessons-nav-button-circle-active:active,
.island-theme-3 .lessons-nav-button-circle-active:hover,
.island-theme-3 .lessons-nav-button-circle-active:visited {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-3 .step-rect-sel {
  background-color: #fff !important;
}

.island-theme-3 .step-rect {
  background-color: #1e5077 !important;
}

.island-theme-3 .island-modal {
  height: auto;
  display: block;
  background-color: #286ba0;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-3 .island-report {
  height: 90vh;
  width: 100vw;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.island-theme-3 .modal-header {
  color: #286ba0 !important;
  border-radius: 10px 10px 0px 0px;
}

.island-theme-3 .modal-body {
  border-radius: 0px;
}

.island-theme-3 .modal-custom-bg {
  background-color: #286ba0 !important;
  color: #fff !important;
}

.island-theme-3 .modal-custom-bg h5 {
  color: #fff !important;
}

.island-theme-3 .modal-custom-footer-bg {
  background-color: #286ba0 !important;
}

.island-theme-3 .btn-pill {
  height: 40px;
  font-size: 15px;
  line-height: 28px;
  border-radius: 100px;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-weight: 500;
  background-color: #1e5077;
  border: none;
  padding: 0px 8px;
  color: #fff;
  z-index: 3000;
}

.island-theme-3 .btn-pill .btn-basic:hover {
  border: none;
  outline: none !important;
}

.island-theme-3 .btn-pill .btn-basic:focus {
  border: none;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.island-theme-3 .button-small {
  background-color: #1e5077;
}

.island-theme-3 .button-small:hover {
  background-color: #286ba0;
}

.island-theme-3 .button-small:active {
  background-color: #286ba0;
}

.island-theme-3 .button-small:visited {
  background-color: #286ba0;
}

.island-theme-3 .button-small:focus {
  background-color: #286ba0;
}

.island-theme-3 .button-small:active:focus {
  background-color: #286ba0;
}

.island-theme-3 .button-small:focus-within {
  background-color: #286ba0;
}

.island-theme-3 .modal-button {
  min-width: auto;
  min-height: 32px;
  background-color: #1e5077;
  border-radius: 10px !important;
  border: none;
  font-size: 15px;
  color: #ffffff;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  direction: ltr;
}

.island-theme-3 .modal-button:hover {
  background-color: #286ba0;
}

.island-theme-3 .modal-button:active {
  background-color: #286ba0;
}

.island-theme-3 .modal-button:visited {
  background-color: #286ba0;
}

.island-theme-3 .modal-button:focus {
  background-color: #286ba0;
}

.island-theme-3 .modal-button:active:focus {
  background-color: #286ba0;
}

.island-theme-3 .modal-button:focus-within {
  background-color: #286ba0;
}

.island-theme-3 .theme-text,
.island-theme-3 .lessontitle,
.island-theme-3 .theme-text {
  color: #045daf !important;
}

.island-theme-3 .progress-bar-color {
  background-color: #045daf !important;
  border-radius: 10px;
}

.island-theme-3 .island-tabs-sel {
  border-bottom: 4px solid #286ba0;
  color: #286ba0;
}

.island-theme-3 .test {
  background: linear-gradient(to bottom, #0feadd, #3198d1);
}

.island-theme-3 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-4 {
  background: linear-gradient(to bottom, #0feadd, #3198d1) !important;
}

.island-theme-3 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-1 {
  background: linear-gradient(to bottom, #5ee7df, #fbc2eb);
}

.island-theme-3 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-2 {
  background: linear-gradient(to bottom, #e579b0, #9075e7);
}

.island-theme-3 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-3 {
  background: linear-gradient(to bottom, #0feadd, #3198d1);
}

.island-theme-3 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-5 {
  background: linear-gradient(to bottom, #1b1f5f, #000);
}

.island-theme-3 .input[type="radio"]:checked+label {
  background: linear-gradient(to bottom, #0feadd, #3198d1) !important;
}

.island-theme-3 .nav_bg {
  background-color: Transparent;
  width: 100%;
}

.island-theme-3 .modal-title {
  color: #286ba0;
}

.island-theme-3 .bg-circle-big-silver {
  background-color: #286ba0;
  color: #fff;
}

.island-theme-3 .quiz-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  max-height: 40vh;
  /* background-color: #1e5077; */
  background-color: #022d4c;
}

.island-theme-3 .ant-drawer-header {
  background-color: #286ba0;
}

.island-theme-3 .ant-drawer-header .ant-drawer-title {
  color: #fff;
}

.island-theme-3 .shaka-seek-bar {
  border: 0.5px solid #286ba0 !important;
  background-color: #286ba0 !important;
}

.island-theme-3 .shaka-range-element {
  border: 0.5px solid #286ba0 !important;
  background-color: #286ba0 !important;
}

.island-theme-3 .dv-star-rating {
  display: flex;
}

.island-theme-3 .dv-star-rating input[type="radio"]:checked+label {
  border: 3px solid transparent;
  background-color: #286ba0;
  opacity: 1;
}

.island-theme-3 .dv-star-rating input[type="radio"]:checked+label::after {
  display: none;
}

.island-theme-3 .dv-star-rating-empty-star {
  background-color: #caccce;
}

.island-theme-3 .dv-star-rating-full-star {
  background-color: #286ba0;
}

.island-theme-3 .button-red,
.island-theme-3 .button-red:active,
.island-theme-3 .button-red:focus,
.island-theme-3 .button-red:visited {
  background-color: red !important;
}

.island-theme-3 .button-green,
.island-theme-3 .button-green:active,
.island-theme-3 .button-green:focus,
.island-theme-3 .button-green:visited,
.island-theme-3 .button-green:focus-within,
.island-theme-3 .button-green:focus-visible {
  background-color: green !important;
}

.island-theme-3 .info-bg-island {
  background-color: #286ba0;
}

.island-theme-4 body {
  background-image: linear-gradient(to bottom, #0feadd, #3198d1);
}

.island-theme-4 .theme-color {
  height: 100vh;
  display: block;
  background-image: linear-gradient(to bottom, #0feadd, #3198d1);
  padding: 0px;
  margin: 0px;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-4 .external-color {
  background-image: linear-gradient(to bottom, #0feadd, #3198d1);
}

.island-theme-4 .island-course-header {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.island-theme-4 .island-course-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}

.island-theme-4 #content-with-header {
  position: fixed;
  top: 56px;
  left: 0px;
  height: calc(100vh - 56px);
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 50px;
}

.island-theme-4 #island-content {
  height: 100vh;
  display: block;
  padding: 0px;
  margin: 0px;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-4 .fix-bottom-nav {
  background: transparent;
}

.island-theme-4 .island-pri-bg {
  border-radius: 5px;
  color: #fff;
  background-color: #286ba0;
  height: 40px;
  min-width: 40px;
  border: none;
  max-width: auto;
  padding: 0px 5px;
}

.island-theme-4 .island-pri-bg:hover {
  background-color: #1e5077;
  border: none;
}

.island-theme-4 .island-pri-bg:active,
.island-theme-4 .island-pri-bg:focus {
  background-color: #286ba0;
}

.island-theme-4 .island-pri-bg:visited {
  background-color: #286ba0;
}

.island-theme-4 .intro-btn button {
  min-height: 52px;
  background-color: #286ba0;
  border-radius: 5px;
  border: none;
  font-size: 15px;
  color: #ffffff;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.island-theme-4 .intro-btn button:hover {
  background-color: #286ba0;
}

.island-theme-4 .intro-btn button:active {
  background-color: #286ba0;
}

.island-theme-4 .intro-btn button:visited {
  background-color: #286ba0;
}

.island-theme-4 .intro-btn button:focus {
  background-color: #286ba0;
}

.island-theme-4 .intro-btn button:active:focus {
  background-color: #286ba0;
}

.island-theme-4 .intro-btn button:focus-within {
  background-color: #286ba0;
}

.island-theme-4 .incomplete-block .intro-btn button {
  opacity: 0.3;
  cursor: not-allowed;
}

.island-theme-4 .incomplete-block .intro-btn button:hover {
  background-color: #286ba0;
}

.island-theme-4 .incomplete-block .intro-btn button:active {
  background-color: #286ba0;
}

.island-theme-4 .incomplete-block .intro-btn button:visited {
  background-color: #286ba0;
}

.island-theme-4 .button {
  background-color: #286ba0;
  border: 0;
  border-radius: 5px;
  bottom: 10px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  padding: 1em 2em;
  position: absolute;
  right: 10px;
}

.island-theme-4 .course-progress ul {
  background-color: #286ba0;
}

.island-theme-4 .course-progress ul li {
  color: #fff;
}

.island-theme-4 .videosection .nav_bg {
  background: transparent !important;
}

.island-theme-4 .revisevideo .nav_bg {
  background: transparent !important;
}

.island-theme-4 .treasurevideo .nav_bg {
  background: transparent !important;
}

.island-theme-4 .lessons-nav-button {
  background-color: #1e5077 !important;
}

.island-theme-4 .video-lesson-card {
  background-color: black;
  border-radius: 0px;
  margin: 0px auto;
  padding: 0px;
}

.island-theme-4 .video-Card {
  background-color: black;
}

.island-theme-4 .info-screen-header-border,
.island-theme-4 .info-screen-footer-border {
  border-color: rgba(255, 255, 255, 0.8) !important;
}

.island-theme-4 .info-screen {
  background-color: #286ba0 !important;
  border: 3px solid #fff !important;
}

.island-theme-4 .select-theme {
  height: 16vh;
  display: block;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  position: relative;
  opacity: 1;
  width: 110px;
  margin: 10px;
  border: 3px solid transparent;
}

.island-theme-4 .island-video {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 81vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.island-theme-4 .lessons-nav-button-circle {
  background-color: #286ba0 !important;
}

.island-theme-4 .vid-lessons-title {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  text-align: left;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
}

.island-theme-4 .lessons-nav-button-circle {
  cursor: pointer;
  background-color: #286ba0 !important;
  border-radius: 50px;
  padding: 0px;
  box-shadow: none;
  border: 1px solid transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #fff;
  height: 35px;
  width: 35px;
  margin: 0px 10px;
  text-align: center;
  line-height: 35px;
}

.island-theme-4 .lessons-nav-button-circle:hover {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #286ba0 !important;
}

.island-theme-4 .lessons-nav-button-circle:active {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-4 .lessons-nav-button-circle:visited {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #286ba0 !important;
}

.island-theme-4 .lessons-nav-button-circle-active {
  cursor: pointer;
  border-radius: 50px;
  padding: 0px;
  box-shadow: none;
  border: 1px solid transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  height: 35px;
  width: 35px;
  margin: 0px 10px;
  text-align: center;
  line-height: 35px;
  color: #286ba0 !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-4 .lessons-nav-button-circle-active:active,
.island-theme-4 .lessons-nav-button-circle-active:hover .island-theme-4 .lessons-nav-button-circle-active:visited {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-4 .step-rect-sel {
  background-color: #fff !important;
}

.island-theme-4 .step-rect {
  background-color: #1e5077 !important;
}

.island-theme-4 .island-modal {
  height: auto;
  display: block;
  background-color: #286ba0;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-4 .island-report {
  height: 90vh;
  width: 100vw;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.island-theme-4 .modal-header {
  color: #286ba0 !important;
  border-radius: 10px 10px 0px 0px;
}

.island-theme-4 .modal-body {
  border-radius: 0px;
}

.island-theme-4 .modal-custom-bg {
  background-color: #286ba0 !important;
  color: #fff !important;
}

.island-theme-4 .modal-custom-bg h5 {
  color: #fff !important;
}

.island-theme-4 .modal-custom-footer-bg {
  background-color: #286ba0 !important;
}

.island-theme-4 .btn-pill {
  height: 40px;
  font-size: 15px;
  line-height: 28px;
  border-radius: 100px;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-weight: 500;
  background-color: #1e5077;
  border: none;
  padding: 0px 8px;
  color: #fff;
  z-index: 3000;
}

.island-theme-4 .btn-pill .btn-basic:hover {
  border: none;
  outline: none !important;
}

.island-theme-4 .btn-pill .btn-basic:focus {
  border: none;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.island-theme-4 .button-small {
  background-color: #1e5077;
}

.island-theme-4 .button-small:hover {
  background-color: #286ba0;
}

.island-theme-4 .button-small:active {
  background-color: #286ba0;
}

.island-theme-4 .button-small:visited {
  background-color: #286ba0;
}

.island-theme-4 .button-small:focus {
  background-color: #286ba0;
}

.island-theme-4 .button-small:active:focus {
  background-color: #286ba0;
}

.island-theme-4 .button-small:focus-within {
  background-color: #286ba0;
}

.island-theme-4 .modal-button {
  min-width: auto;
  min-height: 32px;
  background-color: #1e5077;
  border-radius: 10px !important;
  border: none;
  font-size: 15px;
  color: #ffffff;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  direction: ltr;
}

.island-theme-4 .modal-button:hover {
  background-color: #286ba0;
}

.island-theme-4 .modal-button:active {
  background-color: #286ba0;
}

.island-theme-4 .modal-button:visited {
  background-color: #286ba0;
}

.island-theme-4 .modal-button:focus {
  background-color: #286ba0;
}

.island-theme-4 .modal-button:active:focus {
  background-color: #286ba0;
}

.island-theme-4 .modal-button:focus-within {
  background-color: #286ba0;
}

.island-theme-4 .theme-text,
.island-theme-4 .lessontitle,
.island-theme-4 .theme-text {
  color: #045daf !important;
}

.island-theme-4 .progress-bar-color {
  background-color: #045daf !important;
  border-radius: 10px;
}

.island-theme-4 .island-tabs-sel {
  border-bottom: 4px solid #286ba0;
  color: #286ba0;
}

.island-theme-4 .test {
  background: linear-gradient(to bottom, #0feadd, #3198d1);
}

.island-theme-4 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-4 {
  background: linear-gradient(to bottom, #0feadd, #3198d1) !important;
}

.island-theme-4 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-1 {
  background: linear-gradient(to bottom, #5ee7df, #fbc2eb);
}

.island-theme-4 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-2 {
  background: linear-gradient(to bottom, #e579b0, #9075e7);
}

.island-theme-4 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-3 {
  background: linear-gradient(to bottom, #0feadd, #3198d1);
}

.island-theme-4 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-5 {
  background: linear-gradient(to bottom, #1b1f5f, #000);
}

.island-theme-4 .input[type="radio"]:checked+label {
  background: linear-gradient(to bottom, #0feadd, #3198d1) !important;
}

.island-theme-4 .nav_bg {
  background-color: Transparent;
  width: 100%;
}

.island-theme-4 .modal-title {
  color: #286ba0;
}

.island-theme-4 .bg-circle-big-silver {
  background-color: #286ba0;
  color: #fff;
}

.island-theme-4 .quiz-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  max-height: 40vh;
  /* background-color: #1e5077; */
  background-color: #024c4c;
}

.island-theme-4 .ant-drawer-header {
  background-color: #286ba0;
}

.island-theme-4 .ant-drawer-header .ant-drawer-title {
  color: #fff;
}

.island-theme-4 .shaka-seek-bar {
  border: 0.5px solid #286ba0 !important;
  background-color: #286ba0 !important;
}

.island-theme-4 .shaka-range-element {
  border: 0.5px solid #286ba0 !important;
  background-color: #286ba0 !important;
}

.island-theme-4 .dv-star-rating {
  display: flex;
}

.island-theme-4 .dv-star-rating input[type="radio"]:checked+label {
  border: 3px solid transparent;
  background-color: #286ba0;
  opacity: 1;
}

.island-theme-4 .dv-star-rating input[type="radio"]:checked+label::after {
  display: none;
}

.island-theme-4 .dv-star-rating-empty-star {
  background-color: #caccce;
}

.island-theme-4 .dv-star-rating-full-star {
  background-color: #286ba0;
}

.island-theme-4 .button-red,
.island-theme-4 .button-red:active,
.island-theme-4 .button-red:focus,
.island-theme-4 .button-red:visited {
  background-color: red !important;
}

.island-theme-4 .button-green,
.island-theme-4 .button-green:active,
.island-theme-4 .button-green:focus,
.island-theme-4 .button-green:visited,
.island-theme-4 .button-green:focus-within,
.island-theme-4 .button-green:focus-visible {
  background-color: green !important;
}

.island-theme-4 .info-bg-island {
  background-color: #286ba0;
}

/* .island-theme-maths body {
  background-image: linear-gradient(to bottom, #E96969, #FE9F65);
} */

.island-theme-maths .external-color {
  background-image: linear-gradient(to bottom, #e96969, #fe9f65);
}

.island-theme-maths .island-course-header {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.island-theme-maths .island-course-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}

.island-theme-maths #content-with-header {
  /* position: fixed; */
  position: absolute;
  top: 35px;
  left: 0px;
  height: calc(100vh - 56px);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 50px;
}

.island-theme-maths #island-content {
  height: 100vh;
  display: block;
  padding: 0px;
  margin: 0px;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-maths .fix-bottom-nav {
  background: transparent;
}

.island-theme-maths .island-pri-bg {
  border-radius: 5px;
  color: #fff;
  background-color: #a04040;
  height: 40px;
  min-width: 40px;
  border: none;
  max-width: auto;
  padding: 0px 5px;
}

.island-theme-maths .island-pri-bg:hover {
  background-color: #b23d3d;
  border: none;
}

.island-theme-maths .island-pri-bg:active,
.island-theme-maths .island-pri-bg:focus {
  background-color: #c75b5b;
}

.island-theme-maths .island-pri-bg:visited {
  background-color: #c75b5b;
}

.island-theme-maths .intro-btn button {
  min-height: 52px;
  background-color: #a04040;
  border-radius: 5px;
  border: none;
  font-size: 15px;
  color: #ffffff;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.island-theme-maths .intro-btn button:hover {
  background-color: #a04040;
}

.island-theme-maths .intro-btn button:active {
  background-color: #a04040;
}

.island-theme-maths .intro-btn button:visited {
  background-color: #a04040;
}

.island-theme-maths .intro-btn button:focus {
  background-color: #a04040;
}

.island-theme-maths .intro-btn button:active:focus {
  background-color: #a04040;
}

.island-theme-maths .intro-btn button:focus-within {
  background-color: #a04040;
}

.island-theme-maths .incomplete-block .intro-btn button {
  opacity: 0.3;
  cursor: not-allowed;
}

.island-theme-maths .incomplete-block .intro-btn button:hover {
  background-color: #a04040;
}

.island-theme-maths .incomplete-block .intro-btn button:active {
  background-color: #a04040;
}

.island-theme-maths .incomplete-block .intro-btn button:visited {
  background-color: #a04040;
}

.island-theme-maths .button {
  background-color: #a04040;
  border: 0;
  border-radius: 5px;
  bottom: 10px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  padding: 1em 2em;
  position: absolute;
  right: 10px;
}

.island-theme-maths .course-progress ul {
  background-color: #c75b5b;
}

.island-theme-maths .course-progress ul li {
  color: #fff;
}

.island-theme-maths .videosection .nav_bg {
  background: transparent !important;
}

.island-theme-maths .revisevideo .nav_bg {
  background: transparent !important;
}

.island-theme-maths .treasurevideo .nav_bg {
  background: transparent !important;
}

.island-theme-maths .lessons-nav-button {
  background-color: #7c3131 !important;
}

.island-theme-maths .video-lesson-card {
  background-color: #0e0606;
  border-radius: 0px;
  margin: 0px auto;
  padding: 0px;
}

.island-theme-maths .video-Card {
  background-color: #0e0606;
}

.island-theme-maths .info-screen-header-border,
.island-theme-maths .info-screen-footer-border {
  border-color: rgba(255, 255, 255, 0.8) !important;
}

.island-theme-maths .info-screen {
  background-color: #c75b5b !important;
  border: 3px solid #fff !important;
}

.island-theme-maths .select-theme {
  height: 16vh;
  display: block;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  position: relative;
  opacity: 1;
  width: 110px;
  margin: 10px;
  border: 3px solid transparent;
}

.island-theme-maths .island-video {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 81vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.island-theme-maths .lessons-nav-button-circle {
  background-color: #c75b5b !important;
}

.island-theme-maths .vid-lessons-title {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  text-align: left;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
}

.island-theme-maths .lessons-nav-button-circle {
  cursor: pointer;
  background-color: #c75b5b !important;
  border-radius: 50px;
  padding: 0px;
  box-shadow: none;
  border: 1px solid transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #fff;
  height: 35px;
  width: 35px;
  margin: 0px 10px;
  text-align: center;
  line-height: 35px;
}

.island-theme-maths .lessons-nav-button-circle:hover {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #c75b5b !important;
}

.island-theme-maths .lessons-nav-button-circle:active {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-maths .lessons-nav-button-circle:visited {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #c75b5b !important;
}

.island-theme-maths .lessons-nav-button-circle-active {
  cursor: pointer;
  border-radius: 50px;
  padding: 0px;
  box-shadow: none;
  border: 1px solid transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  height: 35px;
  width: 35px;
  margin: 0px 10px;
  text-align: center;
  line-height: 35px;
  color: #c75b5b !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-maths .lessons-nav-button-circle-active:active,
.island-theme-maths .lessons-nav-button-circle-active:hover,
.island-theme-maths .lessons-nav-button-circle-active:visited {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-maths .step-rect-sel {
  background-color: #ffa500 !important;
}

.island-theme-maths .step-rect {
  background-color: #7c3131 !important;
}

.island-theme-maths .island-modal {
  height: auto;
  display: block;
  background-color: #a04040;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-maths .island-report {
  width: 100vw;
  height: calc(100vh - 60px);
  overflow-y: auto;
  margin-top: 20px !important;
}

.island-theme-maths .modal-header {
  color: #c75b5b !important;
  border-radius: 10px 10px 0px 0px;
}

.island-theme-maths .modal-body {
  border-radius: 0px;
}

.island-theme-maths .modal-custom-bg {
  background-color: #c75b5b !important;
  color: #fff !important;
}

.island-theme-maths .modal-custom-bg h5 {
  color: #fff !important;
}

.island-theme-maths .modal-custom-footer-bg {
  background-color: #c75b5b !important;
}

.island-theme-maths .btn-pill {
  height: 40px;
  font-size: 15px;
  line-height: 28px;
  border-radius: 100px;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-weight: 500;
  background-color: #b23d3d;
  border: none;
  padding: 0px 8px;
  color: #fff;
  z-index: 3000;
}

.island-theme-maths .btn-pill .btn-basic:hover {
  border: none;
  outline: none !important;
}

.island-theme-maths .btn-pill .btn-basic:focus {
  border: none;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.island-theme-maths .button-small {
  background-color: #7c3131;
}

.island-theme-maths .button-small:hover {
  background-color: #a04040;
}

.island-theme-maths .button-small:active {
  background-color: #a04040;
}

.island-theme-maths .button-small:visited {
  background-color: #a04040;
}

.island-theme-maths .button-small:focus {
  background-color: #a04040;
}

.island-theme-maths .button-small:active:focus {
  background-color: #a04040;
}

.island-theme-maths .button-small:focus-within {
  background-color: #a04040;
}

.island-theme-maths .modal-button {
  min-width: auto;
  min-height: 32px;
  background-color: #b23d3d;
  border-radius: 10px !important;
  border: none;
  font-size: 15px;
  color: #ffffff;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  direction: ltr;
}

.island-theme-maths .modal-button:hover {
  background-color: #a04040;
}

.island-theme-maths .modal-button:active {
  background-color: #a04040;
}

.island-theme-maths .modal-button:visited {
  background-color: #a04040;
}

.island-theme-maths .modal-button:focus {
  background-color: #a04040;
}

.island-theme-maths .modal-button:active:focus {
  background-color: #a04040;
}

.island-theme-maths .modal-button:focus-within {
  background-color: #a04040;
}

.island-theme-maths .theme-text,
.island-theme-maths .lessontitle,
.island-theme-maths .theme-text {
  color: #8b0e0e !important;
}

.island-theme-maths .progress-bar-color {
  background-color: #8b0e0e !important;
  border-radius: 10px;
}

.island-theme-maths .island-tabs-sel {
  border-bottom: 4px solid #c75b5b;
  color: #c75b5b;
}

.island-theme-maths .test {
  background: linear-gradient(to bottom, #e96969, #fe9f65);
}

.island-theme-maths .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-4 {
  background: linear-gradient(to bottom, #e96969, #fe9f65) !important;
}

.island-theme-maths .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-1 {
  background: linear-gradient(to bottom, #5ee7df, #fbc2eb);
}

.island-theme-maths .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-2 {
  background: linear-gradient(to bottom, #e579b0, #9075e7);
}

.island-theme-maths .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-3 {
  background: linear-gradient(to bottom, #0feadd, #3198d1);
}

.island-theme-maths .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-5 {
  background: linear-gradient(to bottom, #1b1f5f, #000);
}

.island-theme-maths .input[type="radio"]:checked+label {
  background: linear-gradient(to bottom, #e96969, #fe9f65) !important;
}

.island-theme-maths .nav_bg {
  background-color: Transparent;
  width: 100%;
}

.island-theme-maths .modal-title {
  color: #c75b5b;
}

.island-theme-maths .bg-circle-big-silver {
  background-color: #a04040;
  color: #fff;
}

.island-theme-maths .quiz-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  max-height: 40vh;
  /* background-color: #7c3131; */
  background-color: #4c0212;
}

.island-theme-maths .ant-drawer-header {
  background-color: #a04040;
}

.island-theme-maths .ant-drawer-header .ant-drawer-title {
  color: #fff;
}

.island-theme-maths .shaka-seek-bar {
  border: 0.5px solid #a04040 !important;
  background-color: #a04040 !important;
}

.island-theme-maths .shaka-range-element {
  border: 0.5px solid #a04040 !important;
  background-color: #a04040 !important;
}

.island-theme-maths .dv-star-rating {
  display: flex;
}

.island-theme-maths .dv-star-rating input[type="radio"]:checked+label {
  border: 3px solid transparent;
  background-color: #a04040;
  opacity: 1;
}

.island-theme-maths .dv-star-rating input[type="radio"]:checked+label::after {
  display: none;
}

.island-theme-maths .dv-star-rating-empty-star {
  background-color: #caccce;
}

.island-theme-maths .dv-star-rating-full-star {
  background-color: #a04040;
}

.island-theme-maths .button-red,
.island-theme-maths .button-red:active,
.island-theme-maths .button-red:focus,
.island-theme-maths .button-red:visited {
  background-color: red !important;
}

.island-theme-maths .button-green,
.island-theme-maths .button-green:active,
.island-theme-maths .button-green:focus,
.island-theme-maths .button-green:visited,
.island-theme-maths .button-green:focus-within,
.island-theme-maths .button-green:focus-visible {
  background-color: green !important;
}

.island-theme-maths .info-bg-island {
  background-color: #a04040;
}

.island-theme-chemistry body {
  background-image: linear-gradient(to bottom, #65c1f3, #0373b4);
}

.island-theme-chemistry .external-color {
  background-image: linear-gradient(to bottom, #65c1f3, #0373b4);
}

.island-theme-chemistry .island-course-header {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.island-theme-chemistry .island-course-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}

.island-theme-chemistry #content-with-header {
  position: fixed;
  top: 56px;
  left: 0px;
  height: calc(100vh - 56px);
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 50px;
}

.island-theme-chemistry #island-content {
  height: 100vh;
  display: block;
  padding: 0px;
  margin: 0px;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-chemistry .fix-bottom-nav {
  background: transparent;
}

.island-theme-chemistry .island-pri-bg {
  border-radius: 5px;
  color: #fff;
  background-color: #044f95;
  height: 40px;
  min-width: 40px;
  border: none;
  max-width: auto;
  padding: 0px 5px;
}

.island-theme-chemistry .island-pri-bg:hover {
  background-color: #00488b;
  border: none;
}

.island-theme-chemistry .island-pri-bg:active,
.island-theme-chemistry .island-pri-bg:focus {
  background-color: #0063be;
}

.island-theme-chemistry .island-pri-bg:visited {
  background-color: #0063be;
}

.island-theme-chemistry .intro-btn button {
  min-height: 52px;
  background-color: #044f95;
  border-radius: 5px;
  border: none;
  font-size: 15px;
  color: #ffffff;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.island-theme-chemistry .intro-btn button:hover {
  background-color: #044f95;
}

.island-theme-chemistry .intro-btn button:active {
  background-color: #044f95;
}

.island-theme-chemistry .intro-btn button:visited {
  background-color: #044f95;
}

.island-theme-chemistry .intro-btn button:focus {
  background-color: #044f95;
}

.island-theme-chemistry .intro-btn button:active:focus {
  background-color: #044f95;
}

.island-theme-chemistry .intro-btn button:focus-within {
  background-color: #044f95;
}

.island-theme-chemistry .incomplete-block .intro-btn button {
  opacity: 0.3;
  cursor: not-allowed;
}

.island-theme-chemistry .incomplete-block .intro-btn button:hover {
  background-color: #044f95;
}

.island-theme-chemistry .incomplete-block .intro-btn button:active {
  background-color: #044f95;
}

.island-theme-chemistry .incomplete-block .intro-btn button:visited {
  background-color: #044f95;
}

.island-theme-chemistry .button {
  background-color: #044f95;
  border: 0;
  border-radius: 5px;
  bottom: 10px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  padding: 1em 2em;
  position: absolute;
  right: 10px;
}

.island-theme-chemistry .course-progress ul {
  background-color: #0063be;
}

.island-theme-chemistry .course-progress ul li {
  color: #fff;
}

.island-theme-chemistry .videosection .nav_bg {
  background: transparent !important;
}

.island-theme-chemistry .revisevideo .nav_bg {
  background: transparent !important;
}

.island-theme-chemistry .treasurevideo .nav_bg {
  background: transparent !important;
}

.island-theme-chemistry .lessons-nav-button {
  background-color: #033563 !important;
}

.island-theme-chemistry .video-lesson-card {
  background-color: black;
  border-radius: 0px;
  margin: 0px auto;
  padding: 0px;
}

.island-theme-chemistry .video-Card {
  background-color: black;
}

.island-theme-chemistry .info-screen-header-border,
.island-theme-chemistry .info-screen-footer-border {
  border-color: rgba(255, 255, 255, 0.8) !important;
}

.island-theme-chemistry .info-screen {
  background-color: #0063be !important;
  border: 3px solid #fff !important;
}

.island-theme-chemistry .select-theme {
  height: 16vh;
  display: block;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  position: relative;
  opacity: 1;
  width: 110px;
  margin: 10px;
  border: 3px solid transparent;
}

.island-theme-chemistry .island-video {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 81vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.island-theme-chemistry .lessons-nav-button-circle {
  background-color: #0063be !important;
}

.island-theme-chemistry .vid-lessons-title {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  text-align: left;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
}

.island-theme-chemistry .lessons-nav-button-circle {
  cursor: pointer;
  background-color: #0063be !important;
  border-radius: 50px;
  padding: 0px;
  box-shadow: none;
  border: 1px solid transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #fff;
  height: 35px;
  width: 35px;
  margin: 0px 10px;
  text-align: center;
  line-height: 35px;
}

.island-theme-chemistry .lessons-nav-button-circle:hover {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #0063be !important;
}

.island-theme-chemistry .lessons-nav-button-circle:active {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-chemistry .lessons-nav-button-circle:visited {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #0063be !important;
}

.island-theme-chemistry .lessons-nav-button-circle-active {
  cursor: pointer;
  border-radius: 50px;
  padding: 0px;
  box-shadow: none;
  border: 1px solid transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  height: 35px;
  width: 35px;
  margin: 0px 10px;
  text-align: center;
  line-height: 35px;
  color: #0063be !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-chemistry .lessons-nav-button-circle-active:active,
.island-theme-chemistry .lessons-nav-button-circle-active:hover,
.island-theme-chemistry .lessons-nav-button-circle-active:visited {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-chemistry .step-rect-sel {
  background-color: #ffa500 !important;
}

.island-theme-chemistry .step-rect {
  background-color: #033563 !important;
}

.island-theme-chemistry .island-modal {
  height: auto;
  display: block;
  background-color: #044f95;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-chemistry .island-report {
  height: 90vh;
  width: 100vw;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.island-theme-chemistry .modal-header {
  color: #0063be !important;
  border-radius: 10px 10px 0px 0px;
}

.island-theme-chemistry .modal-body {
  border-radius: 0px;
}

.island-theme-chemistry .modal-custom-bg {
  background-color: #0063be !important;
  color: #fff !important;
}

.island-theme-chemistry .modal-custom-bg h5 {
  color: #fff !important;
}

.island-theme-chemistry .modal-custom-footer-bg {
  background-color: #0063be !important;
}

.island-theme-chemistry .btn-pill {
  height: 40px;
  font-size: 15px;
  line-height: 28px;
  border-radius: 100px;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-weight: 500;
  background-color: #00488b;
  border: none;
  padding: 0px 8px;
  color: #fff;
  z-index: 3000;
}

.island-theme-chemistry .btn-pill .btn-basic:hover {
  border: none;
  outline: none !important;
}

.island-theme-chemistry .btn-pill .btn-basic:focus {
  border: none;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.island-theme-chemistry .button-small {
  background-color: #033563;
}

.island-theme-chemistry .button-small:hover {
  background-color: #044f95;
}

.island-theme-chemistry .button-small:active {
  background-color: #044f95;
}

.island-theme-chemistry .button-small:visited {
  background-color: #044f95;
}

.island-theme-chemistry .button-small:focus {
  background-color: #044f95;
}

.island-theme-chemistry .button-small:active:focus {
  background-color: #044f95;
}

.island-theme-chemistry .button-small:focus-within {
  background-color: #044f95;
}

.island-theme-chemistry .modal-button {
  min-width: auto;
  min-height: 32px;
  background-color: #00488b;
  border-radius: 10px !important;
  border: none;
  font-size: 15px;
  color: #ffffff;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  direction: ltr;
}

.island-theme-chemistry .modal-button:hover {
  background-color: #044f95;
}

.island-theme-chemistry .modal-button:active {
  background-color: #044f95;
}

.island-theme-chemistry .modal-button:visited {
  background-color: #044f95;
}

.island-theme-chemistry .modal-button:focus {
  background-color: #044f95;
}

.island-theme-chemistry .modal-button:active:focus {
  background-color: #044f95;
}

.island-theme-chemistry .modal-button:focus-within {
  background-color: #044f95;
}

.island-theme-chemistry .theme-text,
.island-theme-chemistry .lessontitle,
.island-theme-chemistry .theme-text {
  color: #044f95 !important;
}

.island-theme-chemistry .progress-bar-color {
  background-color: #044f95 !important;
  border-radius: 10px;
}

.island-theme-chemistry .island-tabs-sel {
  border-bottom: 4px solid #0063be;
  color: #0063be;
}

.island-theme-chemistry .test {
  background: linear-gradient(to bottom, #65c1f3, #0373b4);
}

.island-theme-chemistry .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-4 {
  background: linear-gradient(to bottom, #65c1f3, #0373b4) !important;
}

.island-theme-chemistry .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-1 {
  background: linear-gradient(to bottom, #5ee7df, #fbc2eb);
}

.island-theme-chemistry .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-2 {
  background: linear-gradient(to bottom, #e579b0, #9075e7);
}

.island-theme-chemistry .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-3 {
  background: linear-gradient(to bottom, #0feadd, #3198d1);
}

.island-theme-chemistry .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-5 {
  background: linear-gradient(to bottom, #1b1f5f, #000);
}

.island-theme-chemistry .input[type="radio"]:checked+label {
  background: linear-gradient(to bottom, #65c1f3, #0373b4) !important;
}

.island-theme-chemistry .nav_bg {
  background-color: Transparent;
  width: 100%;
}

.island-theme-chemistry .modal-title {
  color: #0063be;
}

.island-theme-chemistry .bg-circle-big-silver {
  background-color: #044f95;
  color: #fff;
}

.island-theme-chemistry .quiz-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  max-height: 40vh;
  background-color: #022d4c;
}

.island-theme-chemistry .ant-drawer-header {
  background-color: #044f95;
}

.island-theme-chemistry .ant-drawer-header .ant-drawer-title {
  color: #fff;
}

.island-theme-chemistry .shaka-seek-bar {
  border: 0.5px solid #044f95 !important;
  background-color: #044f95 !important;
}

.island-theme-chemistry .shaka-range-element {
  border: 0.5px solid #044f95 !important;
  background-color: #044f95 !important;
}

.island-theme-chemistry .dv-star-rating {
  display: flex;
}

.island-theme-chemistry .dv-star-rating input[type="radio"]:checked+label {
  border: 3px solid transparent;
  background-color: #044f95;
  opacity: 1;
}

.island-theme-chemistry .dv-star-rating input[type="radio"]:checked+label::after {
  display: none;
}

.island-theme-chemistry .dv-star-rating-empty-star {
  background-color: #caccce;
}

.island-theme-chemistry .dv-star-rating-full-star {
  background-color: #044f95;
}

.island-theme-chemistry .button-red,
.island-theme-chemistry .button-red:active,
.island-theme-chemistry .button-red:focus,
.island-theme-chemistry .button-red:visited {
  background-color: red !important;
}

.island-theme-chemistry .button-green,
.island-theme-chemistry .button-green:active,
.island-theme-chemistry .button-green:focus,
.island-theme-chemistry .button-green:visited,
.island-theme-chemistry .button-green:focus-within,
.island-theme-chemistry .button-green:focus-visible {
  background-color: green !important;
}

.island-theme-chemistry .info-bg-island {
  background-color: #044f95;
}

.island-theme-physics body {
  background-image: linear-gradient(to bottom, #d4cc14, #e98816);
}

.island-theme-physics .theme-color {
  height: 100vh;
  display: block;
  background-image: linear-gradient(to bottom, #d4cc14, #e98816);
  padding: 0px;
  margin: 0px;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-maths .theme-color {
  height: 100vh;
  display: block;
  padding: 0;
  margin: 0;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-maths .theme-color,
.island-theme-maths body {
  background-image: linear-gradient(180deg, #e96969, #fe9f65);
}

.island-theme-biology .theme-color {
  height: 100vh;
  display: block;
  padding: 0;
  margin: 0;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-biology .theme-color,
.island-theme-biology body {
  background-image: linear-gradient(180deg, #47bd76, #05c5b9);
}

.island-theme-chemistry .theme-color {
  height: 100vh;
  display: block;
  padding: 0;
  margin: 0;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-chemistry .theme-color,
.island-theme-chemistry body {
  background-image: linear-gradient(180deg, #65c1f3, #0373b4);
}

.island-theme-physics .external-color {
  background-image: linear-gradient(to bottom, #d4cc14, #e98816);
}

.island-theme-physics .island-course-header {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.island-theme-physics .island-course-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}

.island-theme-physics #content-with-header {
  position: fixed;
  top: 56px;
  left: 0px;
  height: calc(100vh - 56px);
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 50px;
}

.island-theme-physics #island-content {
  height: 100vh;
  display: block;
  padding: 0px;
  margin: 0px;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-physics .fix-bottom-nav {
  background: transparent;
}

.island-theme-physics .island-pri-bg {
  border-radius: 5px;
  color: #fff;
  background-color: #ba7419;
  height: 40px;
  min-width: 40px;
  border: none;
  max-width: auto;
  padding: 0px 5px;
}

.island-theme-physics .island-pri-bg:hover {
  background-color: #b15715;
  border: none;
}

.island-theme-physics .island-pri-bg:active,
.island-theme-physics .island-pri-bg:focus {
  background-color: #de6d1b;
}

.island-theme-physics .island-pri-bg:visited {
  background-color: #de6d1b;
}

.island-theme-physics .intro-btn button {
  min-height: 52px;
  background-color: #ba7419;
  border-radius: 5px;
  border: none;
  font-size: 15px;
  color: #ffffff;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.island-theme-physics .intro-btn button:hover {
  background-color: #ba7419;
}

.island-theme-physics .intro-btn button:active {
  background-color: #ba7419;
}

.island-theme-physics .intro-btn button:visited {
  background-color: #ba7419;
}

.island-theme-physics .intro-btn button:focus {
  background-color: #ba7419;
}

.island-theme-physics .intro-btn button:active:focus {
  background-color: #ba7419;
}

.island-theme-physics .intro-btn button:focus-within {
  background-color: #ba7419;
}

.island-theme-physics .incomplete-block .intro-btn button {
  opacity: 0.3;
  cursor: not-allowed;
}

.island-theme-physics .incomplete-block .intro-btn button:hover {
  background-color: #ba7419;
}

.island-theme-physics .incomplete-block .intro-btn button:active {
  background-color: #ba7419;
}

.island-theme-physics .incomplete-block .intro-btn button:visited {
  background-color: #ba7419;
}

.island-theme-physics .button {
  background-color: #ba7419;
  border: 0;
  border-radius: 5px;
  bottom: 10px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  padding: 1em 2em;
  position: absolute;
  right: 10px;
}

.island-theme-physics .course-progress ul {
  background-color: #de6d1b;
}

.island-theme-physics .course-progress ul li {
  color: #fff;
}

.island-theme-physics .videosection .nav_bg {
  background: transparent !important;
}

.island-theme-physics .revisevideo .nav_bg {
  background: transparent !important;
}

.island-theme-physics .treasurevideo .nav_bg {
  background: transparent !important;
}

.island-theme-physics .lessons-nav-button {
  background-color: #8d5813 !important;
}

.island-theme-physics .video-lesson-card {
  background-color: #060401;
  border-radius: 0px;
  margin: 0px auto;
  padding: 0px;
}

.island-theme-physics .video-Card {
  background-color: #060401;
}

.island-theme-physics .info-screen-header-border,
.island-theme-physics .info-screen-footer-border {
  border-color: rgba(255, 255, 255, 0.8) !important;
}

.island-theme-physics .info-screen {
  background-color: #de6d1b !important;
  border: 3px solid #fff !important;
}

.island-theme-physics .select-theme {
  height: 16vh;
  display: block;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  position: relative;
  opacity: 1;
  width: 110px;
  margin: 10px;
  border: 3px solid transparent;
}

.island-theme-physics .island-video {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 81vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.island-theme-physics .lessons-nav-button-circle {
  background-color: #de6d1b !important;
}

.island-theme-physics .vid-lessons-title {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  text-align: left;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
}

.island-theme-physics .lessons-nav-button-circle {
  cursor: pointer;
  background-color: #de6d1b !important;
  border-radius: 50px;
  padding: 0px;
  box-shadow: none;
  border: 1px solid transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #fff;
  height: 35px;
  width: 35px;
  margin: 0px 10px;
  text-align: center;
  line-height: 35px;
}

.island-theme-physics .lessons-nav-button-circle:hover {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #de6d1b !important;
}

.island-theme-physics .lessons-nav-button-circle:active {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-physics .lessons-nav-button-circle:visited {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #de6d1b !important;
}

.island-theme-physics .lessons-nav-button-circle-active {
  cursor: pointer;
  border-radius: 50px;
  padding: 0px;
  box-shadow: none;
  border: 1px solid transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  height: 35px;
  width: 35px;
  margin: 0px 10px;
  text-align: center;
  line-height: 35px;
  color: #de6d1b !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-physics .lessons-nav-button-circle-active:active,
.island-theme-physics .lessons-nav-button-circle-active:hover,
.island-theme-physics .lessons-nav-button-circle-active:visited {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-physics .step-rect-sel {
  background-color: #ffa500 !important;
}

.island-theme-physics .step-rect {
  background-color: #8d5813 !important;
}

.island-theme-physics .island-modal {
  height: auto;
  display: block;
  background-color: #ba7419;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-physics .island-report {
  height: 90vh;
  width: 100vw;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.island-theme-physics .modal-header {
  color: #de6d1b !important;
  border-radius: 10px 10px 0px 0px;
}

.island-theme-physics .modal-body {
  border-radius: 0px;
}

.island-theme-physics .modal-custom-bg {
  background-color: #de6d1b !important;
  color: #fff !important;
}

.island-theme-physics .modal-custom-bg h5 {
  color: #fff !important;
}

.island-theme-physics .modal-custom-footer-bg {
  background-color: #de6d1b !important;
}

.island-theme-physics .btn-pill {
  height: 40px;
  font-size: 15px;
  line-height: 28px;
  border-radius: 100px;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-weight: 500;
  background-color: #b15715;
  border: none;
  padding: 0px 8px;
  color: #fff;
  z-index: 3000;
}

.island-theme-physics .btn-pill .btn-basic:hover {
  border: none;
  outline: none !important;
}

.island-theme-physics .btn-pill .btn-basic:focus {
  border: none;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.island-theme-physics .button-small {
  background-color: #8d5813;
}

.island-theme-physics .button-small:hover {
  background-color: #ba7419;
}

.island-theme-physics .button-small:active {
  background-color: #ba7419;
}

.island-theme-physics .button-small:visited {
  background-color: #ba7419;
}

.island-theme-physics .button-small:focus {
  background-color: #ba7419;
}

.island-theme-physics .button-small:active:focus {
  background-color: #ba7419;
}

.island-theme-physics .button-small:focus-within {
  background-color: #ba7419;
}

.island-theme-physics .modal-button {
  min-width: auto;
  min-height: 32px;
  background-color: #b15715;
  border-radius: 10px !important;
  border: none;
  font-size: 15px;
  color: #ffffff;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  direction: ltr;
}

.island-theme-physics .modal-button:hover {
  background-color: #ba7419;
}

.island-theme-physics .modal-button:active {
  background-color: #ba7419;
}

.island-theme-physics .modal-button:visited {
  background-color: #ba7419;
}

.island-theme-physics .modal-button:focus {
  background-color: #ba7419;
}

.island-theme-physics .modal-button:active:focus {
  background-color: #ba7419;
}

.island-theme-physics .modal-button:focus-within {
  background-color: #ba7419;
}

.island-theme-physics .theme-text,
.island-theme-physics .lessontitle,
.island-theme-physics .theme-text {
  color: #8b5712 !important;
}

.island-theme-physics .progress-bar-color {
  background-color: #8b5712 !important;
  border-radius: 10px;
}

.island-theme-physics .island-tabs-sel {
  border-bottom: 4px solid #de6d1b;
  color: #de6d1b;
}

.island-theme-physics .test {
  background: linear-gradient(to bottom, #d4cc14, #e98816);
}

.island-theme-physics .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-4 {
  background: linear-gradient(to bottom, #d4cc14, #e98816) !important;
}

.island-theme-physics .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-1 {
  background: linear-gradient(to bottom, #5ee7df, #fbc2eb);
}

.island-theme-physics .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-2 {
  background: linear-gradient(to bottom, #e579b0, #9075e7);
}

.island-theme-physics .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-3 {
  background: linear-gradient(to bottom, #0feadd, #3198d1);
}

.island-theme-physics .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-5 {
  background: linear-gradient(to bottom, #1b1f5f, #000);
}

.island-theme-physics .input[type="radio"]:checked+label {
  background: linear-gradient(to bottom, #d4cc14, #e98816) !important;
}

.island-theme-physics .nav_bg {
  background-color: Transparent;
  width: 100%;
}

.island-theme-physics .modal-title {
  color: #de6d1b;
}

.island-theme-physics .bg-circle-big-silver {
  background-color: #ba7419;
  color: #fff;
}

.island-theme-physics .quiz-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  max-height: 40vh;
  background-color: #8d5813;
}

.island-theme-physics .ant-drawer-header {
  background-color: #ba7419;
}

.island-theme-physics .ant-drawer-header .ant-drawer-title {
  color: #fff;
}

.island-theme-physics .shaka-seek-bar {
  border: 0.5px solid #ba7419 !important;
  background-color: #ba7419 !important;
}

.island-theme-physics .shaka-range-element {
  border: 0.5px solid #ba7419 !important;
  background-color: #ba7419 !important;
}

.island-theme-physics .dv-star-rating {
  display: flex;
}

.island-theme-physics .dv-star-rating input[type="radio"]:checked+label {
  border: 3px solid transparent;
  background-color: #ba7419;
  opacity: 1;
}

.island-theme-physics .dv-star-rating input[type="radio"]:checked+label::after {
  display: none;
}

.island-theme-physics .dv-star-rating-empty-star {
  background-color: #caccce;
}

.island-theme-physics .dv-star-rating-full-star {
  background-color: #ba7419;
}

.island-theme-physics .button-red,
.island-theme-physics .button-red:active,
.island-theme-physics .button-red:focus,
.island-theme-physics .button-red:visited {
  background-color: red !important;
}

.island-theme-physics .button-green,
.island-theme-physics .button-green:active,
.island-theme-physics .button-green:focus,
.island-theme-physics .button-green:visited,
.island-theme-physics .button-green:focus-within,
.island-theme-physics .button-green:focus-visible {
  background-color: green !important;
}

.island-theme-physics .info-bg-island {
  background-color: #ba7419;
}

.island-theme-biology body {
  background-image: linear-gradient(to bottom, #47bd76, #05c5b9);
}

.island-theme-biology .external-color {
  background-image: linear-gradient(to bottom, #47bd76, #05c5b9);
}

.island-theme-biology .island-course-header {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.island-theme-biology .island-course-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}

.island-theme-biology #content-with-header {
  position: fixed;
  top: 56px;
  left: 0px;
  height: calc(100vh - 56px);
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 50px;
}

.island-theme-biology #island-content {
  height: 100vh;
  display: block;
  padding: 0px;
  margin: 0px;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-biology .fix-bottom-nav {
  background: transparent;
}

.island-theme-biology .island-pri-bg {
  border-radius: 5px;
  color: #fff;
  background-color: #0e8576;
  height: 40px;
  min-width: 40px;
  border: none;
  max-width: auto;
  padding: 0px 5px;
}

.island-theme-biology .island-pri-bg:hover {
  background-color: #09574d;
  border: none;
}

.island-theme-biology .island-pri-bg:active,
.island-theme-biology .island-pri-bg:focus {
  background-color: #0e8576;
}

.island-theme-biology .island-pri-bg:visited {
  background-color: #0e8576;
}

.island-theme-biology .intro-btn button {
  min-height: 52px;
  background-color: #0e8576;
  border-radius: 5px;
  border: none;
  font-size: 15px;
  color: #ffffff;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.island-theme-biology .intro-btn button:hover {
  background-color: #0e8576;
}

.island-theme-biology .intro-btn button:active {
  background-color: #0e8576;
}

.island-theme-biology .intro-btn button:visited {
  background-color: #0e8576;
}

.island-theme-biology .intro-btn button:focus {
  background-color: #0e8576;
}

.island-theme-biology .intro-btn button:active:focus {
  background-color: #0e8576;
}

.island-theme-biology .intro-btn button:focus-within {
  background-color: #0e8576;
}

.island-theme-biology .incomplete-block .intro-btn button {
  opacity: 0.3;
  cursor: not-allowed;
}

.island-theme-biology .incomplete-block .intro-btn button:hover {
  background-color: #0e8576;
}

.island-theme-biology .incomplete-block .intro-btn button:active {
  background-color: #0e8576;
}

.island-theme-biology .incomplete-block .intro-btn button:visited {
  background-color: #0e8576;
}

.island-theme-biology .button {
  background-color: #0e8576;
  border: 0;
  border-radius: 5px;
  bottom: 10px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  padding: 1em 2em;
  position: absolute;
  right: 10px;
}

.island-theme-biology .course-progress ul {
  background-color: #0e8576;
}

.island-theme-biology .course-progress ul li {
  color: #fff;
}

.island-theme-biology .videosection .nav_bg {
  background: transparent !important;
}

.island-theme-biology .revisevideo .nav_bg {
  background: transparent !important;
}

.island-theme-biology .treasurevideo .nav_bg {
  background: transparent !important;
}

.island-theme-biology .lessons-nav-button {
  background-color: #09574d !important;
}

.island-theme-biology .video-lesson-card {
  background-color: black;
  border-radius: 0px;
  margin: 0px auto;
  padding: 0px;
}

.island-theme-biology .video-Card {
  background-color: black;
}

.island-theme-biology .info-screen-header-border,
.island-theme-biology .info-screen-footer-border {
  border-color: rgba(255, 255, 255, 0.8) !important;
}

.island-theme-biology .info-screen {
  background-color: #0e8576 !important;
  border: 3px solid #fff !important;
}

.island-theme-biology .select-theme {
  height: 16vh;
  display: block;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  position: relative;
  opacity: 1;
  width: 110px;
  margin: 10px;
  border: 3px solid transparent;
}

.island-theme-biology .island-video {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 81vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.island-theme-biology .lessons-nav-button-circle {
  background-color: #0e8576 !important;
}

.island-theme-biology .vid-lessons-title {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  text-align: left;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
}

.island-theme-biology .lessons-nav-button-circle {
  cursor: pointer;
  background-color: #0e8576 !important;
  border-radius: 50px;
  padding: 0px;
  box-shadow: none;
  border: 1px solid transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #fff;
  height: 35px;
  width: 35px;
  margin: 0px 10px;
  text-align: center;
  line-height: 35px;
}

.island-theme-biology .lessons-nav-button-circle:hover {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #0e8576 !important;
}

.island-theme-biology .lessons-nav-button-circle:active {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-biology .lessons-nav-button-circle:visited {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #0e8576 !important;
}

.island-theme-biology .lessons-nav-button-circle-active {
  cursor: pointer;
  border-radius: 50px;
  padding: 0px;
  box-shadow: none;
  border: 1px solid transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  height: 35px;
  width: 35px;
  margin: 0px 10px;
  text-align: center;
  line-height: 35px;
  color: #0e8576 !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-biology .lessons-nav-button-circle-active:active,
.island-theme-biology .lessons-nav-button-circle-active:hover,
.island-theme-biology .lessons-nav-button-circle-active:visited {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-biology .step-rect-sel {
  background-color: #fff !important;
}

.island-theme-biology .step-rect {
  background-color: #09574d !important;
}

.island-theme-biology .island-modal {
  height: auto;
  display: block;
  background-color: #0e8576;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-biology .island-report {
  height: 90vh;
  width: 100vw;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.island-theme-biology .modal-header {
  color: #0e8576 !important;
  border-radius: 10px 10px 0px 0px;
}

.island-theme-biology .modal-body {
  border-radius: 0px;
}

.island-theme-biology .modal-custom-bg {
  background-color: #0e8576 !important;
  color: #fff !important;
}

.island-theme-biology .modal-custom-bg h5 {
  color: #fff !important;
}

.island-theme-biology .modal-custom-footer-bg {
  background-color: #0e8576 !important;
}

.island-theme-biology .btn-pill {
  height: 40px;
  font-size: 15px;
  line-height: 28px;
  border-radius: 100px;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-weight: 500;
  background-color: #09574d;
  border: none;
  padding: 0px 8px;
  color: #fff;
  z-index: 3000;
}

.island-theme-biology .btn-pill .btn-basic:hover {
  border: none;
  outline: none !important;
}

.island-theme-biology .btn-pill .btn-basic:focus {
  border: none;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.island-theme-biology .button-small {
  background-color: #09574d;
}

.island-theme-biology .button-small:hover {
  background-color: #0e8576;
}

.island-theme-biology .button-small:active {
  background-color: #0e8576;
}

.island-theme-biology .button-small:visited {
  background-color: #0e8576;
}

.island-theme-biology .button-small:focus {
  background-color: #0e8576;
}

.island-theme-biology .button-small:active:focus {
  background-color: #0e8576;
}

.island-theme-biology .button-small:focus-within {
  background-color: #0e8576;
}

.island-theme-biology .modal-button {
  min-width: auto;
  min-height: 32px;
  background-color: #09574d;
  border-radius: 10px !important;
  border: none;
  font-size: 15px;
  color: #ffffff;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  direction: ltr;
}

.island-theme-biology .modal-button:hover {
  background-color: #0e8576;
}

.island-theme-biology .modal-button:active {
  background-color: #0e8576;
}

.island-theme-biology .modal-button:visited {
  background-color: #0e8576;
}

.island-theme-biology .modal-button:focus {
  background-color: #0e8576;
}

.island-theme-biology .modal-button:active:focus {
  background-color: #0e8576;
}

.island-theme-biology .modal-button:focus-within {
  background-color: #0e8576;
}

.island-theme-biology .theme-text,
.island-theme-biology .lessontitle,
.island-theme-biology .theme-text {
  color: #07574d !important;
}

.island-theme-biology .progress-bar-color {
  background-color: #07574d !important;
  border-radius: 10px;
}

.island-theme-biology .island-tabs-sel {
  border-bottom: 4px solid #0e8576;
  color: #0e8576;
}

.island-theme-biology .test {
  background: linear-gradient(to bottom, #47bd76, #05c5b9);
}

.island-theme-biology .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-4 {
  background: linear-gradient(to bottom, #47bd76, #05c5b9) !important;
}

.island-theme-biology .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-1 {
  background: linear-gradient(to bottom, #5ee7df, #fbc2eb);
}

.island-theme-biology .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-2 {
  background: linear-gradient(to bottom, #e579b0, #9075e7);
}

.island-theme-biology .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-3 {
  background: linear-gradient(to bottom, #0feadd, #3198d1);
}

.island-theme-biology .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-5 {
  background: linear-gradient(to bottom, #1b1f5f, #000);
}

.island-theme-biology .input[type="radio"]:checked+label {
  background: linear-gradient(to bottom, #47bd76, #05c5b9) !important;
}

.island-theme-biology .nav_bg {
  background-color: Transparent;
  width: 100%;
}

.island-theme-biology .modal-title {
  color: #0e8576;
}

.island-theme-biology .bg-circle-big-silver {
  background-color: #0e8576;
  color: #fff;
}

.island-theme-biology .quiz-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  max-height: 40vh;
  /* background-color: #09574d; */
  background-color: #024c4c;
}

.island-theme-biology .ant-drawer-header {
  background-color: #0e8576;
}

.island-theme-biology .ant-drawer-header .ant-drawer-title {
  color: #fff;
}

.island-theme-biology .shaka-seek-bar {
  border: 0.5px solid #0e8576 !important;
  background-color: #0e8576 !important;
}

.island-theme-biology .shaka-range-element {
  border: 0.5px solid #0e8576 !important;
  background-color: #0e8576 !important;
}

.island-theme-biology .dv-star-rating {
  display: flex;
}

.island-theme-biology .dv-star-rating input[type="radio"]:checked+label {
  border: 3px solid transparent;
  background-color: #0e8576;
  opacity: 1;
}

.island-theme-biology .dv-star-rating input[type="radio"]:checked+label::after {
  display: none;
}

.island-theme-biology .dv-star-rating-empty-star {
  background-color: #caccce;
}

.island-theme-biology .dv-star-rating-full-star {
  background-color: #0e8576;
}

.island-theme-biology .button-red,
.island-theme-biology .button-red:active,
.island-theme-biology .button-red:focus,
.island-theme-biology .button-red:visited {
  background-color: red !important;
}

.island-theme-biology .button-green,
.island-theme-biology .button-green:active,
.island-theme-biology .button-green:focus,
.island-theme-biology .button-green:visited,
.island-theme-biology .button-green:focus-within,
.island-theme-biology .button-green:focus-visible {
  background-color: green !important;
}

.island-theme-biology .info-bg-island {
  background-color: #0e8576;
}

.island-theme-5 body {
  background-image: linear-gradient(to bottom, #1b1f5f, #000);
}

.island-theme-5 .theme-color {
  height: 100vh;
  display: block;
  background-image: linear-gradient(to bottom, #1b1f5f, #000);
  padding: 0px;
  margin: 0px;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-5 .external-color {
  background-image: linear-gradient(to bottom, #1b1f5f, #000);
}

.island-theme-5 .island-course-header {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.island-theme-5 .island-course-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}

.island-theme-5 #content-with-header {
  position: fixed;
  top: 56px;
  left: 0px;
  height: calc(100vh - 56px);
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 50px;
}

.island-theme-5 #island-content {
  height: 100vh;
  display: block;
  padding: 0px;
  margin: 0px;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-5 .fix-bottom-nav {
  background: transparent;
}

.island-theme-5 .island-pri-bg {
  border-radius: 5px;
  color: #fff;
  background-color: #e96969;
  height: 40px;
  min-width: 40px;
  border: none;
  max-width: auto;
  padding: 0px 5px;
}

.island-theme-5 .island-pri-bg:hover {
  background-color: #e23d3d;
  border: none;
}

.island-theme-5 .island-pri-bg:active,
.island-theme-5 .island-pri-bg:focus {
  background-color: #e96969;
}

.island-theme-5 .island-pri-bg:visited {
  background-color: #e96969;
}

.island-theme-5 .intro-btn button {
  min-height: 52px;
  background-color: #e96969;
  border-radius: 5px;
  border: none;
  font-size: 15px;
  color: #ffffff;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.island-theme-5 .intro-btn button:hover {
  background-color: #e96969;
}

.island-theme-5 .intro-btn button:active {
  background-color: #e96969;
}

.island-theme-5 .intro-btn button:visited {
  background-color: #e96969;
}

.island-theme-5 .intro-btn button:focus {
  background-color: #e96969;
}

.island-theme-5 .intro-btn button:active:focus {
  background-color: #e96969;
}

.island-theme-5 .intro-btn button:focus-within {
  background-color: #e96969;
}

.island-theme-5 .incomplete-block .intro-btn button {
  opacity: 0.3;
  cursor: not-allowed;
}

.island-theme-5 .incomplete-block .intro-btn button:hover {
  background-color: #e96969;
}

.island-theme-5 .incomplete-block .intro-btn button:active {
  background-color: #e96969;
}

.island-theme-5 .incomplete-block .intro-btn button:visited {
  background-color: #e96969;
}

.island-theme-5 .button {
  background-color: #e96969;
  border: 0;
  border-radius: 5px;
  bottom: 10px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  padding: 1em 2em;
  position: absolute;
  right: 10px;
}

.island-theme-5 .course-progress ul {
  background-color: #e96969;
}

.island-theme-5 .course-progress ul li {
  color: #fff;
}

.island-theme-5 .videosection .nav_bg {
  background: transparent !important;
}

.island-theme-5 .revisevideo .nav_bg {
  background: transparent !important;
}

.island-theme-5 .treasurevideo .nav_bg {
  background: transparent !important;
}

.island-theme-5 .lessons-nav-button {
  background-color: #e23d3d !important;
}

.island-theme-5 .video-lesson-card {
  background-color: #751111;
  border-radius: 0px;
  margin: 0px auto;
  padding: 0px;
}

.island-theme-5 .video-Card {
  background-color: #751111;
}

.island-theme-5 .info-screen-header-border,
.island-theme-5 .info-screen-footer-border {
  border-color: rgba(255, 255, 255, 0.8) !important;
}

.island-theme-5 .info-screen {
  background-color: #e96969 !important;
  border: 3px solid #fff !important;
}

.island-theme-5 .select-theme {
  height: 16vh;
  display: block;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  position: relative;
  opacity: 1;
  width: 110px;
  margin: 10px;
  border: 3px solid transparent;
}

.island-theme-5 .island-video {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 81vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.island-theme-5 .lessons-nav-button-circle {
  background-color: #e96969 !important;
}

.island-theme-5 .vid-lessons-title {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  text-align: left;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
}

.island-theme-5 .lessons-nav-button-circle {
  cursor: pointer;
  background-color: #e96969 !important;
  border-radius: 50px;
  padding: 0px;
  box-shadow: none;
  border: 1px solid transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #fff;
  height: 35px;
  width: 35px;
  margin: 0px 10px;
  text-align: center;
  line-height: 35px;
}

.island-theme-5 .lessons-nav-button-circle:hover {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #e96969 !important;
}

.island-theme-5 .lessons-nav-button-circle:active {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-5 .lessons-nav-button-circle:visited {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #e96969 !important;
}

.island-theme-5 .lessons-nav-button-circle-active {
  cursor: pointer;
  border-radius: 50px;
  padding: 0px;
  box-shadow: none;
  border: 1px solid transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  height: 35px;
  width: 35px;
  margin: 0px 10px;
  text-align: center;
  line-height: 35px;
  color: #e96969 !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-5 .lessons-nav-button-circle-active:active,
.island-theme-5 .lessons-nav-button-circle-active:hover,
.island-theme-5 .lessons-nav-button-circle-active:visited {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-5 .step-rect-sel {
  background-color: #fff !important;
}

.island-theme-5 .step-rect {
  background-color: #e23d3d !important;
}

.island-theme-5 .island-modal {
  height: auto;
  display: block;
  background-color: #e96969;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-5 .island-report {
  height: 90vh;
  width: 100vw;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.island-theme-5 .modal-header {
  color: #e96969 !important;
  border-radius: 10px 10px 0px 0px;
}

.island-theme-5 .modal-body {
  border-radius: 0px;
}

.island-theme-5 .modal-custom-bg {
  background-color: #e96969 !important;
  color: #fff !important;
}

.island-theme-5 .modal-custom-bg h5 {
  color: #fff !important;
}

.island-theme-5 .modal-custom-footer-bg {
  background-color: #e96969 !important;
}

.island-theme-5 .btn-pill {
  height: 40px;
  font-size: 15px;
  line-height: 28px;
  border-radius: 100px;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-weight: 500;
  background-color: #e23d3d;
  border: none;
  padding: 0px 8px;
  color: #fff;
  z-index: 3000;
}

.island-theme-5 .btn-pill .btn-basic:hover {
  border: none;
  outline: none !important;
}

.island-theme-5 .btn-pill .btn-basic:focus {
  border: none;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.island-theme-5 .button-small {
  background-color: #e23d3d;
}

.island-theme-5 .button-small:hover {
  background-color: #e96969;
}

.island-theme-5 .button-small:active {
  background-color: #e96969;
}

.island-theme-5 .button-small:visited {
  background-color: #e96969;
}

.island-theme-5 .button-small:focus {
  background-color: #e96969;
}

.island-theme-5 .button-small:active:focus {
  background-color: #e96969;
}

.island-theme-5 .button-small:focus-within {
  background-color: #e96969;
}

.island-theme-5 .modal-button {
  min-width: auto;
  min-height: 32px;
  background-color: #e23d3d;
  border-radius: 10px !important;
  border: none;
  font-size: 15px;
  color: #ffffff;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  direction: ltr;
}

.island-theme-5 .modal-button:hover {
  background-color: #e96969;
}

.island-theme-5 .modal-button:active {
  background-color: #e96969;
}

.island-theme-5 .modal-button:visited {
  background-color: #e96969;
}

.island-theme-5 .modal-button:focus {
  background-color: #e96969;
}

.island-theme-5 .modal-button:active:focus {
  background-color: #e96969;
}

.island-theme-5 .modal-button:focus-within {
  background-color: #e96969;
}

.island-theme-5 .theme-text,
.island-theme-5 .lessontitle,
.island-theme-5 .theme-text {
  color: #e96969 !important;
}

.island-theme-5 .progress-bar-color {
  background-color: #e96969 !important;
  border-radius: 10px;
}

.island-theme-5 .island-tabs-sel {
  border-bottom: 4px solid #e96969;
  color: #e96969;
}

.island-theme-5 .test {
  background: linear-gradient(to bottom, #1b1f5f, #000);
}

.island-theme-5 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-4 {
  background: linear-gradient(to bottom, #1b1f5f, #000) !important;
}

.island-theme-5 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-1 {
  background: linear-gradient(to bottom, #5ee7df, #fbc2eb);
}

.island-theme-5 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-2 {
  background: linear-gradient(to bottom, #e579b0, #9075e7);
}

.island-theme-5 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-3 {
  background: linear-gradient(to bottom, #0feadd, #3198d1);
}

.island-theme-5 .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-5 {
  background: linear-gradient(to bottom, #1b1f5f, #000);
}

.island-theme-5 .input[type="radio"]:checked+label {
  background: linear-gradient(to bottom, #1b1f5f, #000) !important;
}

.island-theme-5 .nav_bg {
  background-color: Transparent;
  width: 100%;
}

.island-theme-5 .modal-title {
  color: #e96969;
}

.island-theme-5 .bg-circle-big-silver {
  background-color: #e96969;
  color: #fff;
}

.island-theme-5 .quiz-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  max-height: 40vh;
  /* background-color: #e23d3d; */
  background-color: #da6d6d;
}

.island-theme-5 .ant-drawer-header {
  background-color: #e96969;
}

.island-theme-5 .ant-drawer-header .ant-drawer-title {
  color: #fff;
}

.island-theme-5 .shaka-seek-bar {
  border: 0.5px solid #e96969 !important;
  background-color: #e96969 !important;
}

.island-theme-5 .shaka-range-element {
  border: 0.5px solid #e96969 !important;
  background-color: #e96969 !important;
}

.island-theme-5 .dv-star-rating {
  display: flex;
}

.island-theme-5 .dv-star-rating input[type="radio"]:checked+label {
  border: 3px solid transparent;
  background-color: #e96969;
  opacity: 1;
}

.island-theme-5 .dv-star-rating input[type="radio"]:checked+label::after {
  display: none;
}

.island-theme-5 .dv-star-rating-empty-star {
  background-color: #caccce;
}

.island-theme-5 .dv-star-rating-full-star {
  background-color: #e96969;
}

.island-theme-5 .button-red,
.island-theme-5 .button-red:active,
.island-theme-5 .button-red:focus,
.island-theme-5 .button-red:visited {
  background-color: red !important;
}

.island-theme-5 .button-green,
.island-theme-5 .button-green:active,
.island-theme-5 .button-green:focus,
.island-theme-5 .button-green:visited,
.island-theme-5 .button-green:focus-within,
.island-theme-5 .button-green:focus-visible {
  background-color: green !important;
}

.island-theme-5 .info-bg-island {
  background-color: #e96969;
}

.island-theme-default body {
  background-image: linear-gradient(to bottom, #1b1f5f, #000);
}

.island-theme-default .theme-color {
  height: 100vh;
  display: block;
  background-image: linear-gradient(to bottom, #1b1f5f, #000);
  padding: 0px;
  margin: 0px;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-default .external-color {
  background-image: linear-gradient(to bottom, #1b1f5f, #000);
}

.island-theme-default .island-course-header {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.island-theme-default .island-course-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}

.island-theme-default #content-with-header {
  position: fixed;
  top: 56px;
  left: 0px;
  height: calc(100vh - 56px);
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 50px;
}

.island-theme-default #island-content {
  height: 100vh;
  display: block;
  padding: 0px;
  margin: 0px;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-default .fix-bottom-nav {
  background: transparent;
}

.island-theme-default .island-pri-bg {
  border-radius: 5px;
  color: #fff;
  background-color: #7d486e;
  height: 40px;
  min-width: 40px;
  border: none;
  max-width: auto;
  padding: 0px 5px;
}

.island-theme-default .island-pri-bg:hover {
  background-color: #914d7e;
  border: none;
}

.island-theme-default .island-pri-bg:active,
.island-theme-default .island-pri-bg:focus {
  background-color: #ad6499;
}

.island-theme-default .island-pri-bg:visited {
  background-color: #ad6499;
}

.island-theme-default .intro-btn button {
  min-height: 52px;
  background-color: #7d486e;
  border-radius: 5px;
  border: none;
  font-size: 15px;
  color: #ffffff;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.island-theme-default .intro-btn button:hover {
  background-color: #7d486e;
}

.island-theme-default .intro-btn button:active {
  background-color: #7d486e;
}

.island-theme-default .intro-btn button:visited {
  background-color: #7d486e;
}

.island-theme-default .intro-btn button:focus {
  background-color: #7d486e;
}

.island-theme-default .intro-btn button:active:focus {
  background-color: #7d486e;
}

.island-theme-default .intro-btn button:focus-within {
  background-color: #7d486e;
}

.island-theme-default .incomplete-block .intro-btn button {
  opacity: 0.3;
  cursor: not-allowed;
}

.island-theme-default .incomplete-block .intro-btn button:hover {
  background-color: #7d486e;
}

.island-theme-default .incomplete-block .intro-btn button:active {
  background-color: #7d486e;
}

.island-theme-default .incomplete-block .intro-btn button:visited {
  background-color: #7d486e;
}

.island-theme-default .button {
  background-color: #7d486e;
  border: 0;
  border-radius: 5px;
  bottom: 10px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  padding: 1em 2em;
  position: absolute;
  right: 10px;
}

.island-theme-default .course-progress ul {
  background-color: #ad6499;
}

.island-theme-default .course-progress ul li {
  color: #fff;
}

.island-theme-default .videosection .nav_bg {
  background: transparent !important;
}

.island-theme-default .revisevideo .nav_bg {
  background: transparent !important;
}

.island-theme-default .treasurevideo .nav_bg {
  background: transparent !important;
}

.island-theme-default .lessons-nav-button {
  background-color: #5d3552 !important;
}

.island-theme-default .video-lesson-card {
  background-color: black;
  border-radius: 0px;
  margin: 0px auto;
  padding: 0px;
}

.island-theme-default .video-Card {
  background-color: black;
}

.island-theme-default .info-screen-header-border,
.island-theme-default .info-screen-footer-border {
  border-color: rgba(255, 255, 255, 0.8) !important;
}

.island-theme-default .info-screen {
  background-color: #ad6499 !important;
  border: 3px solid #fff !important;
}

.island-theme-default .select-theme {
  height: 16vh;
  display: block;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  position: relative;
  opacity: 1;
  width: 110px;
  margin: 10px;
  border: 3px solid transparent;
}

.island-theme-default .island-video {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 81vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.island-theme-default .lessons-nav-button-circle {
  background-color: #ad6499 !important;
}

.island-theme-default .vid-lessons-title {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  text-align: left;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
}

.island-theme-default .lessons-nav-button-circle {
  cursor: pointer;
  background-color: #ad6499 !important;
  border-radius: 50px;
  padding: 0px;
  box-shadow: none;
  border: 1px solid transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #fff;
  height: 35px;
  width: 35px;
  margin: 0px 10px;
  text-align: center;
  line-height: 35px;
}

.island-theme-default .lessons-nav-button-circle:hover {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #ad6499 !important;
}

.island-theme-default .lessons-nav-button-circle:active {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-default .lessons-nav-button-circle:visited {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #ad6499 !important;
}

.island-theme-default .lessons-nav-button-circle-active {
  cursor: pointer;
  border-radius: 50px;
  padding: 0px;
  box-shadow: none;
  border: 1px solid transparent;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  height: 35px;
  width: 35px;
  margin: 0px 10px;
  text-align: center;
  line-height: 35px;
  color: #ad6499 !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-default .lessons-nav-button-circle-active:active,
.island-theme-default .lessons-nav-button-circle-active:hover,
.island-theme-default .lessons-nav-button-circle-active:visited {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
  background-color: #fff !important;
}

.island-theme-default .step-rect-sel {
  background-color: #fff !important;
}

.island-theme-default .step-rect {
  background-color: #5d3552 !important;
}

.island-theme-default .island-modal {
  height: auto;
  display: block;
  background-color: #7d486e;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.island-theme-default .island-report {
  height: 90vh;
  width: 100vw;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.island-theme-default .modal-header {
  color: #ad6499 !important;
  border-radius: 10px 10px 0px 0px;
}

.island-theme-default .modal-body {
  border-radius: 0px;
}

.island-theme-default .modal-custom-bg {
  background-color: #ad6499 !important;
  color: #fff !important;
}

.island-theme-default .modal-custom-bg h5 {
  color: #fff !important;
}

.island-theme-default .modal-custom-footer-bg {
  background-color: #ad6499 !important;
}

.island-theme-default .btn-pill {
  height: 40px;
  font-size: 15px;
  line-height: 28px;
  border-radius: 100px;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-weight: 500;
  background-color: #914d7e;
  border: none;
  padding: 0px 8px;
  color: #fff;
  z-index: 3000;
}

.island-theme-default .btn-pill .btn-basic:hover {
  border: none;
  outline: none !important;
}

.island-theme-default .btn-pill .btn-basic:focus {
  border: none;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.island-theme-default .button-small {
  background-color: #5d3552;
}

.island-theme-default .button-small:hover {
  background-color: #7d486e;
}

.island-theme-default .button-small:active {
  background-color: #7d486e;
}

.island-theme-default .button-small:visited {
  background-color: #7d486e;
}

.island-theme-default .button-small:focus {
  background-color: #7d486e;
}

.island-theme-default .button-small:active:focus {
  background-color: #7d486e;
}

.island-theme-default .button-small:focus-within {
  background-color: #7d486e;
}

.island-theme-default .modal-button {
  min-width: auto;
  min-height: 32px;
  background-color: #914d7e;
  border-radius: 10px !important;
  border: none;
  font-size: 15px;
  color: #ffffff;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  direction: ltr;
}

.island-theme-default .modal-button:hover {
  background-color: #7d486e;
}

.island-theme-default .modal-button:active {
  background-color: #7d486e;
}

.island-theme-default .modal-button:visited {
  background-color: #7d486e;
}

.island-theme-default .modal-button:focus {
  background-color: #7d486e;
}

.island-theme-default .modal-button:active:focus {
  background-color: #7d486e;
}

.island-theme-default .modal-button:focus-within {
  background-color: #7d486e;
}

.island-theme-default .theme-text,
.island-theme-default .lessontitle,
.island-theme-default .theme-text {
  color: #7d486e !important;
}

.island-theme-default .progress-bar-color {
  background-color: #7d486e !important;
  border-radius: 10px;
}

.island-theme-default .island-tabs-sel {
  border-bottom: 4px solid #ad6499;
  color: #ad6499;
}

.island-theme-default .test {
  background: linear-gradient(to bottom, #1b1f5f, #000);
}

.island-theme-default .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-4 {
  background: linear-gradient(to bottom, #1b1f5f, #000) !important;
}

.island-theme-default .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-1 {
  background: linear-gradient(to bottom, #5ee7df, #fbc2eb);
}

.island-theme-default .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-2 {
  background: linear-gradient(to bottom, #e579b0, #9075e7);
}

.island-theme-default .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-3 {
  background: linear-gradient(to bottom, #0feadd, #3198d1);
}

.island-theme-default .island-panel-theme-block input[type="radio"]:checked+.island-theme-select-5 {
  background: linear-gradient(to bottom, #1b1f5f, #000);
}

.island-theme-default .input[type="radio"]:checked+label {
  background: linear-gradient(to bottom, #1b1f5f, #000) !important;
}

.island-theme-default .nav_bg {
  background-color: Transparent;
  width: 100%;
}

.island-theme-default .modal-title {
  color: #ad6499;
}

.island-theme-default .bg-circle-big-silver {
  background-color: #7d486e;
  color: #fff;
}

.island-theme-default .quiz-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  max-height: 40vh;
  background-color: #5d3552;
}

.island-theme-default .ant-drawer-header {
  background-color: #7d486e;
}

.island-theme-default .ant-drawer-header .ant-drawer-title {
  color: #fff;
}

.island-theme-default .shaka-seek-bar {
  border: 0.5px solid #7d486e !important;
  background-color: #7d486e !important;
}

.island-theme-default .shaka-range-element {
  border: 0.5px solid #7d486e !important;
  background-color: #7d486e !important;
}

.island-theme-default .dv-star-rating {
  display: flex;
}

.island-theme-default .dv-star-rating input[type="radio"]:checked+label {
  border: 3px solid transparent;
  background-color: #7d486e;
  opacity: 1;
}

.island-theme-default .dv-star-rating input[type="radio"]:checked+label::after {
  display: none;
}

.island-theme-default .dv-star-rating-empty-star {
  background-color: #caccce;
}

.island-theme-default .dv-star-rating-full-star {
  background-color: #7d486e;
}

.island-theme-default .button-red,
.island-theme-default .button-red:active,
.island-theme-default .button-red:focus,
.island-theme-default .button-red:visited {
  background-color: red !important;
}

.island-theme-default .button-green,
.island-theme-default .button-green:active,
.island-theme-default .button-green:focus,
.island-theme-default .button-green:visited,
.island-theme-default .button-green:focus-within,
.island-theme-default .button-green:focus-visible {
  background-color: green !important;
}

.island-theme-default .info-bg-island {
  background-color: #7d486e;
}

/*----for Selecting themes on drawer--*/
.background-wrap {
  margin: auto;
}

.background-wrap-theme {
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
  flex-wrap: wrap;
}

.island-panel-theme-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.select-theme-dr {
  height: 80px;
  display: block;
  color: #fff;
  border-radius: 15px;
  text-align: center;
  position: relative;
  opacity: 1;
  width: 80px;
  margin: 10px;
  border: 3px solid #fff;
}

.island-theme-select-1 {
  background: linear-gradient(to bottom, #5ee7df, #fbc2eb);
}

.island-theme-select-2 {
  background: linear-gradient(to bottom, #e579b0, #9075e7);
}

.island-theme-select-3 {
  background: linear-gradient(to bottom, #0feadd, #3198d1);
}

.island-theme-select-4 {
  background: linear-gradient(to bottom, #1b1f5f, #000);
}

.island-theme-select-5 {
  background: linear-gradient(to bottom, #1b1f5f, #000);
}

.fixed-top {
  z-index: 1000;
}

.island-intermidate {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  flex-direction: column;
}

.intermidate-info-wrapper {
  display: inline;
}

.info-bg-island {
  width: auto;
  padding: 20px;
  height: auto;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  line-height: 30px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.info-bg-island .caption {
  font-family: "stepapp-secondary-bold", sans-serif;
  font-size: 12px;
  font-weight: 600;
  padding-bottom: 5px;
}

.info-bg-island .lesson-name {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 22px;
  text-align: center;
}

.island-panel-theme-block input[type="radio"]:checked+label::after {
  font-family: fontello;
  content: "";
  height: 30px;
  width: 30px;
  background: url("../images/check.svg");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 20px;
  z-index: 999;
  position: absolute;
  top: -15%;
  right: 29%;
}

.step-rect-sel {
  width: 20px;
  height: 5px;
  border-radius: 50px;
  background-color: #ffa500;
  display: block;
  margin: 10px 5px;
}

.step-rect {
  width: 20px;
  height: 5px;
  border-radius: 50px;
  opacity: 0.3;
  display: block;
  margin: 10px 5px;
}

.video-Card {
  padding: 0px;
  margin: 0px auto;
  border-radius: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  overflow: hidden;
}

.vid-card-vid {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  height: 60vh;
}

.vid-card-info {
  overflow-y: auto;
  height: 100%;
  height: 60vh;
}

.vid-card-info .vid-card-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 10px;
  border-bottom: 4px solid #f1f4f6;
  margin-bottom: 20px;
}
.vid-share-btn {
  padding: 8px 15px;
  border-radius: 10px;
  background: #fff;
  font-size: 16px;
  color: #000;
  border: 2px solid #fc8643;
  cursor: pointer;
}
.vid-share-btn i {
  color: #fc8643;
}
.share-feedback button {
  color: #fc8643;
  background: #feecd3;
  border: 2px solid #fc8643;
  border-radius: 6px;
  padding: 7px 10px;
}
.info-screen {
  border: 3px solid #fff !important;
  border-radius: 10px;
}

.challenge-theme-1 body {
  background-image: linear-gradient(to bottom, #fcac5e, #f57b76);
}

.challenge-theme-1.challenge-theme-color {
  height: 100vh;
  display: block;
  background-image: linear-gradient(to bottom, #fcac5e, #f57b76);
  padding: 0px;
  margin: 0px;
  width: 100vw;
}

.challenge-theme-1 .reportlabel {
  color: #000 !important;
  text-align: center;
}
.challenge-theme-1 input[type="checkbox"]:checked + .challenge-theme-bg {
  background-image: linear-gradient(to bottom, #fcac5e, #f57b76);
}

.challenge-theme-1 .quiz-head-hint {
  background: #e96969;
}

.challenge-theme-1 .game-status-btn {
  background: #e96969;
}

.challenge-theme-1 .game-status-btn:hover,
.challenge-theme-1 .game-status-btn:focus,
.challenge-theme-1 .game-status-btn:active {
  background-color: #fff;
}

.challenge-theme-1 .challenge-pri-bg {
  background: #e96969;
  color: #fff !important;
  border-color: #e96969;
}

.challenge-theme-1 .challenge-sec-bg {
  background: #cf5151;
}

.challenge-theme-1 .challenge-modal-theme {
  background: linear-gradient(to bottom, #fcac5e, #f57b76);
}

.challenge-theme-1 .action-quiz:hover {
  background: #e96969;
}

.challenge-theme-1 .action-quiz:active,
.challenge-theme-1 .action-quiz:focus {
  background: #e96969;
}

.challenge-theme-1 .quiz-bg-subject-icon.challenge-pri-bg {
  background: #e96969 !important;
  margin: 0px;
  width: 60px;
  height: 60px;
  line-height: 62px;
}

.challenge-theme-1 .quiz-subject {
  background: #e96969;
}

.challenge-theme-1 .challenge-bottom-btn {
  border-radius: 50px;
  color: #e96969;
  background-color: #fff;
  border: none;
  height: 45px;
  font-size: 18px;
  margin: 0px 5px;
}

.challenge-theme-1 .join-btn {
  background: #cf5151;
  color: #fff;
}

.challenge-theme-1 .join-btn:hover {
  background-color: #922828;
}

.challenge-theme-1 .quiz-questions {
  background-color: #d56565;
  color: #fff;
}

.challenge-theme-1 .theme-block input[type="radio"]:checked+.theme-block-1 {
  background: linear-gradient(to bottom, #fcac5e, #f57b76);
  border: none;
}

.challenge-theme-1 .theme-block input[type="radio"]:checked+.theme-block-2 {
  background: linear-gradient(to bottom, #e579b0, #9075e7);
  border: none;
}

.challenge-theme-1 .theme-block input[type="radio"]:checked+.theme-block-3 {
  background: linear-gradient(to bottom, #47bd76, #05c5b9);
  border: none;
}

.challenge-theme-1 .theme-block input[type="radio"]:checked+.theme-block-4 {
  background: linear-gradient(to bottom, #4950b7, #131313);
  border: none;
}

.challenge-theme-1 .quiz-modal-header {
  background-image: #e96969;
  border-radius: 10px 10px 0px 0px;
  height: 100px;
  color: #fff;
  text-align: center;
}

.challenge-theme-1 .quiz-modal-header::after {
  background-color: #e96969;
  content: "";
  width: 100%;
  height: 52px;
  position: absolute;
  top: 62px;
  right: 0;
  transform: skewY(-3deg);
}

.challenge-theme-1 .modal-title {
  color: #fff;
}

.challenge-theme-2 body {
  background-image: linear-gradient(to bottom, #e579b0, #9075e7);
}

.challenge-theme-2.challenge-theme-color {
  height: 100vh;
  display: block;
  background-image: linear-gradient(to bottom, #e579b0, #9075e7);
  padding: 0px;
  margin: 0px;
  width: 100vw;
}

.challenge-theme-2 .reportlabel {
  color: #000 !important;
  text-align: center;
}
.challenge-theme-2 input[type="checkbox"]:checked + .challenge-theme-bg {
  background-image: linear-gradient(to bottom, #e579b0, #9075e7);
}

.challenge-theme-2 .quiz-head-hint {
  background: #6856b0;
}

.challenge-theme-2 .game-status-btn {
  background: #6856b0;
}

.challenge-theme-2 .game-status-btn:hover,
.challenge-theme-2 .game-status-btn:focus,
.challenge-theme-2 .game-status-btn:active {
  background-color: #fff;
}

.challenge-theme-2 .challenge-pri-bg {
  background: #6856b0;
  color: #fff !important;
  border-color: #6856b0;
}

.challenge-theme-2 .challenge-sec-bg {
  background: #702677;
}

.challenge-theme-2 .challenge-modal-theme {
  background: linear-gradient(to bottom, #e579b0, #9075e7);
}

.challenge-theme-2 .action-quiz:hover {
  background: #6856b0;
}

.challenge-theme-2 .action-quiz:active,
.challenge-theme-2 .action-quiz:focus {
  background: #6856b0;
}

.challenge-theme-2 .quiz-bg-subject-icon.challenge-pri-bg {
  background: #6856b0 !important;
  margin: 0px;
  width: 60px;
  height: 60px;
  line-height: 62px;
}

.challenge-theme-2 .quiz-subject {
  background: #6856b0;
}

.challenge-theme-2 .challenge-bottom-btn {
  border-radius: 50px;
  color: #6856b0;
  background-color: #fff;
  border: none;
  height: 45px;
  font-size: 18px;
  margin: 0px 5px;
}

.challenge-theme-2 .join-btn {
  background: #702677;
  color: #fff;
}

.challenge-theme-2 .join-btn:hover {
  background-color: #270d2a;
}

.challenge-theme-2 .quiz-questions {
  background-color: #822c8a;
  color: #fff;
}

.challenge-theme-2 .theme-block input[type="radio"]:checked+.theme-block-1 {
  background: linear-gradient(to bottom, #fcac5e, #f57b76);
  border: none;
}

.challenge-theme-2 .theme-block input[type="radio"]:checked+.theme-block-2 {
  background: linear-gradient(to bottom, #e579b0, #9075e7);
  border: none;
}

.challenge-theme-2 .theme-block input[type="radio"]:checked+.theme-block-3 {
  background: linear-gradient(to bottom, #47bd76, #05c5b9);
  border: none;
}

.challenge-theme-2 .theme-block input[type="radio"]:checked+.theme-block-4 {
  background: linear-gradient(to bottom, #4950b7, #131313);
  border: none;
}

.challenge-theme-2 .quiz-modal-header {
  background-image: #6856b0;
  border-radius: 10px 10px 0px 0px;
  height: 100px;
  color: #fff;
  text-align: center;
}

.challenge-theme-2 .quiz-modal-header::after {
  background-color: #6856b0;
  content: "";
  width: 100%;
  height: 52px;
  position: absolute;
  top: 62px;
  right: 0;
  transform: skewY(-3deg);
}

.challenge-theme-2 .modal-title {
  color: #fff;
}

.challenge-theme-3 body {
  background-image: linear-gradient(to bottom, #47bd76, #05c5b9);
}

.challenge-theme-3.challenge-theme-color {
  height: 100vh;
  display: block;
  background-image: linear-gradient(to bottom, #47bd76, #05c5b9);
  padding: 0px;
  margin: 0px;
  width: 100vw;
}

.challenge-theme-3 .reportlabel {
  color: #000 !important;
  text-align: center;
}
.challenge-theme-3 input[type="checkbox"]:checked + .challenge-theme-bg {
  background-image: linear-gradient(to bottom, #47bd76, #05c5b9);
}

.challenge-theme-3 .quiz-head-hint {
  background: #0f9483;
}

.challenge-theme-3 .game-status-btn {
  background: #0f9483;
}

.challenge-theme-3 .game-status-btn:hover,
.challenge-theme-3 .game-status-btn:focus,
.challenge-theme-3 .game-status-btn:active {
  background-color: #fff;
}

.challenge-theme-3 .challenge-pri-bg {
  background: #0f9483;
  color: #fff !important;
  border-color: #0f9483;
}

.challenge-theme-3 .challenge-sec-bg {
  background: #0b8e37;
}

.challenge-theme-3 .challenge-modal-theme {
  background: linear-gradient(to bottom, #47bd76, #05c5b9);
}

.challenge-theme-3 .action-quiz:hover {
  background: #0f9483;
}

.challenge-theme-3 .action-quiz:active,
.challenge-theme-3 .action-quiz:focus {
  background: #0f9483;
}

.challenge-theme-3 .quiz-bg-subject-icon.challenge-pri-bg {
  background: #0f9483 !important;
  margin: 0px;
  width: 60px;
  height: 60px;
  line-height: 62px;
}

.challenge-theme-3 .quiz-subject {
  background: #0f9483;
}

.challenge-theme-3 .challenge-bottom-btn {
  border-radius: 50px;
  color: #0f9483;
  background-color: #fff;
  border: none;
  height: 45px;
  font-size: 18px;
  margin: 0px 5px;
}

.challenge-theme-3 .join-btn {
  background: #0b8e37;
  color: #fff;
}

.challenge-theme-3 .join-btn:hover {
  background-color: #042f12;
}

.challenge-theme-3 .quiz-questions {
  background-color: #0da640;
  color: #fff;
}

.challenge-theme-3 .theme-block input[type="radio"]:checked+.theme-block-1 {
  background: linear-gradient(to bottom, #fcac5e, #f57b76);
  border: none;
}

.challenge-theme-3 .theme-block input[type="radio"]:checked+.theme-block-2 {
  background: linear-gradient(to bottom, #e579b0, #9075e7);
  border: none;
}

.challenge-theme-3 .theme-block input[type="radio"]:checked+.theme-block-3 {
  background: linear-gradient(to bottom, #47bd76, #05c5b9);
  border: none;
}

.challenge-theme-3 .theme-block input[type="radio"]:checked+.theme-block-4 {
  background: linear-gradient(to bottom, #4950b7, #131313);
  border: none;
}

.challenge-theme-3 .quiz-modal-header {
  background-image: #0f9483;
  border-radius: 10px 10px 0px 0px;
  height: 100px;
  color: #fff;
  text-align: center;
}

.challenge-theme-3 .quiz-modal-header::after {
  background-color: #0f9483;
  content: "";
  width: 100%;
  height: 52px;
  position: absolute;
  top: 62px;
  right: 0;
  transform: skewY(-3deg);
}

.challenge-theme-3 .modal-title {
  color: #fff;
}

.challenge-theme-4 body {
  background-image: linear-gradient(to bottom, #4950b7, #131313);
}

.challenge-theme-4.challenge-theme-color {
  height: 100vh;
  display: block;
  background-image: linear-gradient(to bottom, #4950b7, #131313);
  padding: 0px;
  margin: 0px;
  width: 100vw;
}

.challenge-theme-4 .reportlabel {
  color: #fff !important;
  text-align: center;
}
.challenge-theme-4 input[type="checkbox"]:checked + .challenge-theme-bg {
  background-image: linear-gradient(to bottom, #4950b7, #131313);
}

.challenge-theme-4 .quiz-head-hint {
  background: #e96969;
}

.challenge-theme-4 .game-status-btn {
  background: #e96969;
}

.challenge-theme-4 .game-status-btn:hover,
.challenge-theme-4 .game-status-btn:focus,
.challenge-theme-4 .game-status-btn:active {
  background-color: #fff;
}

.challenge-theme-4 .challenge-pri-bg {
  background: #e96969;
  color: #fff !important;
  border-color: #e96969;
}

.challenge-theme-4 .challenge-sec-bg {
  background: #cf5151;
}

.challenge-theme-4 .challenge-modal-theme {
  background: linear-gradient(to bottom, #4950b7, #131313);
}

.challenge-theme-4 .action-quiz:hover {
  background: #e96969;
}

.challenge-theme-4 .action-quiz:active,
.challenge-theme-4 .action-quiz:focus {
  background: #e96969;
}

.challenge-theme-4 .quiz-bg-subject-icon.challenge-pri-bg {
  background: #e96969 !important;
  margin: 0px;
  width: 60px;
  height: 60px;
  line-height: 62px;
}

.challenge-theme-4 .quiz-subject {
  background: #e96969;
}

.challenge-theme-4 .challenge-bottom-btn {
  border-radius: 50px;
  color: #e96969;
  background-color: #fff;
  border: none;
  height: 45px;
  font-size: 18px;
  margin: 0px 5px;
}

.challenge-theme-4 .join-btn {
  background: #cf5151;
  color: #fff;
}

.challenge-theme-4 .join-btn:hover {
  background-color: #922828;
}

.challenge-theme-4 .quiz-questions {
  background-color: #d56565;
  color: #fff;
}

.challenge-theme-4 .theme-block input[type="radio"]:checked+.theme-block-1 {
  background: linear-gradient(to bottom, #fcac5e, #f57b76);
  border: none;
}

.challenge-theme-4 .theme-block input[type="radio"]:checked+.theme-block-2 {
  background: linear-gradient(to bottom, #e579b0, #9075e7);
  border: none;
}

.challenge-theme-4 .theme-block input[type="radio"]:checked+.theme-block-3 {
  background: linear-gradient(to bottom, #47bd76, #05c5b9);
  border: none;
}

.challenge-theme-4 .theme-block input[type="radio"]:checked+.theme-block-4 {
  background: linear-gradient(to bottom, #4950b7, #131313);
  border: none;
}

.challenge-theme-4 .quiz-modal-header {
  background-image: #e96969;
  border-radius: 10px 10px 0px 0px;
  height: 100px;
  color: #fff;
  text-align: center;
}

.challenge-theme-4 .quiz-modal-header::after {
  background-color: #e96969;
  content: "";
  width: 100%;
  height: 52px;
  position: absolute;
  top: 62px;
  right: 0;
  transform: skewY(-3deg);
}

.challenge-theme-4 .modal-title {
  color: #fff;
}

.challenge-theme-default body {
  background-image: #fc8643;
}

.challenge-theme-default.challenge-theme-color {
  height: 100vh;
  display: block;
  background-image: #fc8643;
  padding: 0px;
  margin: 0px;
  width: 100vw;
}

.challenge-theme-default .reportlabel {
  color: #000 !important;
  text-align: center;
}
.challenge-theme-default input[type="checkbox"]:checked + .challenge-theme-bg {
  background-image: #fc8643;
}

.challenge-theme-default .quiz-head-hint {
  background: #e96969;
}

.challenge-theme-default .game-status-btn {
  background: #e96969;
}

.challenge-theme-default .game-status-btn:hover,
.challenge-theme-default .game-status-btn:focus,
.challenge-theme-default .game-status-btn:active {
  background-color: #fff;
}

.challenge-theme-default .challenge-pri-bg {
  background: #e96969;
  color: #fff !important;
  border-color: #e96969;
}

.challenge-theme-default .challenge-sec-bg {
  background: #cf5151;
}

.challenge-theme-default .challenge-modal-theme {
  background: #fc8643;
}

.challenge-theme-default .action-quiz:hover {
  background: #e96969;
}

.challenge-theme-default .action-quiz:active,
.challenge-theme-default .action-quiz:focus {
  background: #e96969;
}

.challenge-theme-default .quiz-bg-subject-icon.challenge-pri-bg {
  background: #e96969 !important;
  margin: 0px;
  width: 60px;
  height: 60px;
  line-height: 62px;
}

.challenge-theme-default .quiz-subject {
  background: #e96969;
}

.challenge-theme-default .challenge-bottom-btn {
  border-radius: 50px;
  color: #e96969;
  background-color: #fff;
  border: none;
  height: 45px;
  font-size: 18px;
  margin: 0px 5px;
}

.challenge-theme-default .join-btn {
  background: #cf5151;
  color: #fff;
}

.challenge-theme-default .join-btn:hover {
  background-color: #922828;
}

.challenge-theme-default .quiz-questions {
  background-color: #d56565;
  color: #fff;
}

.challenge-theme-default .theme-block input[type="radio"]:checked+.theme-block-1 {
  background: linear-gradient(to bottom, #fcac5e, #f57b76);
  border: none;
}

.challenge-theme-default .theme-block input[type="radio"]:checked+.theme-block-2 {
  background: linear-gradient(to bottom, #e579b0, #9075e7);
  border: none;
}

.challenge-theme-default .theme-block input[type="radio"]:checked+.theme-block-3 {
  background: linear-gradient(to bottom, #47bd76, #05c5b9);
  border: none;
}

.challenge-theme-default .theme-block input[type="radio"]:checked+.theme-block-4 {
  background: linear-gradient(to bottom, #4950b7, #131313);
  border: none;
}

.challenge-theme-default .quiz-modal-header {
  background-image: #e96969;
  border-radius: 10px 10px 0px 0px;
  height: 100px;
  color: #fff;
  text-align: center;
}

.challenge-theme-default .quiz-modal-header::after {
  background-color: #e96969;
  content: "";
  width: 100%;
  height: 52px;
  position: absolute;
  top: 62px;
  right: 0;
  transform: skewY(-3deg);
}

.challenge-theme-default .modal-title {
  color: #fff;
}

/*----for Selecting themes on drawer--*/
.theme-block-1 {
  background: linear-gradient(to bottom, #fcac5e, #f57b76);
}

.theme-block-2 {
  background: linear-gradient(to bottom, #e579b0, #9075e7);
}

.theme-block-3 {
  background: linear-gradient(to bottom, #47bd76, #05c5b9);
}

.theme-block-4 {
  background: linear-gradient(to bottom, #4950b7, #131313);
}

input[type="checkbox"]:checked + .challenge-theme-bg {
  background-color: #fc8643 !important;
}

input[type="checkbox"]:checked + .challenge-theme-bg-default {
  background-color: #fc8643 !important;
}

.unified-theme-light {
  background-color: #fff;
  /*for profile module*/
  /*--for chat -module---*/
}

.unified-theme-light h1,
.unified-theme-light .bread-crumb,
.unified-theme-light .page-header,
.unified-theme-light .leader-num-text,
.unified-theme-light .no-content-message,
.unified-theme-light .quiz-title-text,
.unified-theme-light .rail-title {
  color: #181818;
}

.unified-theme-light h5,
.unified-theme-light h3,
.unified-theme-light h4,
.unified-theme-light h6,
.unified-theme-light .ReportCard,
.unified-theme-light .leaderboard-small-title {
  color: #181818 !important;
}

.unified-theme-light .layout-full {
  background-color: #f3f6f8;
}

.unified-theme-light .bookmark-tab-sticky {
  background-color: #f3f6f8;
  z-index: 1000;
}

.unified-theme-light .head-bread-crumb {
  background-color: #f3f6f8;
}

.unified-theme-light .rail-title {
  color: #181818 !important;
}

.unified-theme-light .content_not_found {
  color: #181818 !important;
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 18px;
}

.unified-theme-light .side-menu {
  background-color: #15388c !important;
  color: #f3f6f8 !important;
}

.unified-theme-light .side-menu-nav .nav-item .nav-link {
  color: #f3f6f8;
}

.unified-theme-light .page-header {
  background-color: #f3f6f8;
  border-bottom: 0.5px solid #caccce;
}

.unified-theme-light .back-nav {
  background-color: #15388c;
  color: #f3f6f8;
}

.unified-theme-light .panel-heading {
  border-bottom: 0.5px solid #caccce;
}

.unified-theme-light .nav-tabs>li>a,
.unified-theme-light .nav-pills>li>a {
  color: #181818 !important;
}

.unified-theme-light .logo-dark {
  background-image: url("../images/logo/logo-Icon-light.svg");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  margin: auto;
}

.unified-theme-light .loader-logo {
  background-image: url("../images/logo/logo-dark.svg");
  width: 100px;
  height: 54px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  margin: auto;
}

.unified-theme-light .nav-circle {
  color: #f3f6f8;
}

.unified-theme-light .btn-outline-primary {
  color: #181818 !important;
}

.unified-theme-light .ant-steps-item-title {
  color: #181818 !important;
  font-weight: 600 !important;
}

.unified-theme-light .head-bread-crumb {
  border-bottom: 0.5px solid #caccce !important;
}

.unified-theme-light .step-tabs {
  border-bottom: 1px solid #caccce !important;
}

.unified-theme-light .chat-leftsidebar,
.unified-theme-light .panel,
.unified-theme-light .top-bar,
.unified-theme-light .info,
.unified-theme-light .bottom-bar {
  background-color: #f3f6f8 !important;
  background-image: #f3f6f8 !important;
}

.unified-theme-light .left-pane-title {
  color: #181818;
}

.unified-theme-light .left-pane-header {
  color: #181818;
  background-color: #f3f6f8;
}

.unified-theme-light .filter-bg {
  color: #181818;
}

.unified-theme-light .ask-doubt-card {
  background-color: #f3f6f8;
}

.unified-theme-light .askdoubt-date,
.unified-theme-light .list-question-text {
  color: #181818;
}

.unified-theme-light .ask-doubt-card-sel {
  background-color: #e6ebf5;
}

.unified-theme-light .solution-header {
  color: #181818;
  background-color: #f3f6f8;
}

.unified-theme-light .solution-footer {
  background-color: #f3f6f8;
}

.unified-theme-light .btn-wrap-text {
  color: #181818;
}

.unified-theme-light .solution-content {
  background-color: #e6ebf5;
}

.unified-theme-light .sol-content-question,
.unified-theme-light .btn-tab {
  color: #181818 !important;
}

.unified-theme-light .bottom-bar-conversation {
  color: #181818;
  background-color: #f3f6f8;
}

.unified-theme-light .user-chat {
  background-color: #fff;
}

.unified-theme-light .solution-content-chat {
  background-color: #f3f6f8;
}

.unified-theme-light .messages-wrapper {
  background-color: #e6ebf5 !important;
}

.unified-theme-light .details-room {
  color: #181818;
  background-color: #fff;
}

.unified-theme-light .notice-text,
.unified-theme-light .text,
.unified-theme-light .main-theme-text {
  color: #181818 !important;
}

.unified-theme-light .main-theme-text,
.unified-theme-light .content-upload-doubt,
.unified-theme-light .upload-doubt,
.unified-theme-light .left-pane-footer,
.unified-theme-light .border-radius-round,
.unified-theme-light .date,
.unified-theme-light .notfound,
.unified-theme-light .notfound-extended,
.unified-theme-light .tutorial,
.unified-theme-light .back-filter-right {
  color: #181818 !important;
}

.unified-theme-light .upload-img {
  fill: #181818;
}

.unified-theme-light .members,
.unified-theme-light .main {
  background-color: #fff;
}

.unified-theme-light .solution-header,
.unified-theme-light .left-pane-title,
.unified-theme-light .solution-footer,
.unified-theme-light .ask-doubt-card-sel,
.unified-theme-light .ask-doubt-card,
.unified-theme-light .btn-tab,
.unified-theme-light .btn-wrap-text,
.unified-theme-light .bottom-bar-conversation,
.unified-theme-light .details-room,
.unified-theme-light .left-pane-footer {
  border-color: #caccce !important;
}

.unified-theme-light .room:hover {
  background-color: #e6ebf5;
}

.unified-theme-light .room .profile .img {
  background-color: #e6ebf5;
}

.unified-theme-light .fixed-top-profile-tabs {
  background-color: #f3f6f8;
}

.unified-theme-dark {
  background-color: #15388c;
  background-image: #15388c;
  /*for profile module*/
  /*--for chat -module---*/
}

.unified-theme-dark h1,
.unified-theme-dark .bread-crumb,
.unified-theme-dark .page-header,
.unified-theme-dark .leader-num-text,
.unified-theme-dark .no-content-message,
.unified-theme-dark .quiz-title-text,
.unified-theme-dark .rail-title {
  color: #f3f6f8;
}

.unified-theme-dark h5,
.unified-theme-dark h3,
.unified-theme-dark h4,
.unified-theme-dark h6,
.unified-theme-dark .ReportCard,
.unified-theme-dark .leaderboard-small-title {
  color: #181818 !important;
}

.unified-theme-dark .layout-full {
  background-color: #15388c;
  background-image: #15388c;
}

.unified-theme-dark .bookmark-tab-sticky {
  background-color: #15388c;
  z-index: 1000;
}

.unified-theme-dark .head-bread-crumb {
  background-color: #15388c;
}

.unified-theme-dark .rail-title {
  color: #f3f6f8 !important;
}

.unified-theme-dark .content_not_found {
  color: #f3f6f8 !important;
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 18px;
}

.unified-theme-dark .side-menu {
  background-color: #f3f6f8 !important;
  color: #181818 !important;
}

.unified-theme-dark .side-menu-nav .nav-item .nav-link {
  color: #181818 !important;
}

.unified-theme-dark .page-header {
  background-color: #15388c;
  border-bottom: 0.5px solid #5a62d2;
}

.unified-theme-dark .back-nav {
  background-color: #f3f6f8;
  color: #181818;
}

.unified-theme-dark .panel-heading {
  border-bottom: 0.5px solid #5a62d2;
}

.unified-theme-dark .nav-tabs>li>a,
.unified-theme-dark .nav-pills>li>a {
  color: #f3f6f8 !important;
}

.unified-theme-dark .logo-dark {
  background-image: url("../images/logo/logo-Icon-dark.svg");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  margin: auto;
}

.unified-theme-dark .loader-logo {
  background-image: url("../images/logo/logo-light.svg");
  width: 100px;
  height: 54px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  margin: auto;
}

.unified-theme-dark .nav-circle {
  color: #181818;
}

.unified-theme-dark .btn-outline-primary {
  color: #f3f6f8 !important;
}

.unified-theme-dark .ant-steps-item-title {
  color: #f3f6f8 !important;
  font-weight: 600 !important;
}

.unified-theme-dark .head-bread-crumb {
  border-bottom: 0.5px solid #5a62d2 !important;
}

.unified-theme-dark .step-tabs {
  border-bottom: 1px solid #5a62d2 !important;
}

.unified-theme-dark .chat-leftsidebar,
.unified-theme-dark .panel,
.unified-theme-dark .top-bar,
.unified-theme-dark .info,
.unified-theme-dark .bottom-bar {
  background-color: #303841 !important;
  background-image: #303841 !important;
}

.unified-theme-dark .left-pane-title {
  color: #f3f6f8;
}

.unified-theme-dark .left-pane-header {
  color: #f3f6f8;
  background-color: #303841;
}

.unified-theme-dark .filter-bg {
  color: #f3f6f8;
}

.unified-theme-dark .ask-doubt-card {
  background-color: #303841;
}

.unified-theme-dark .askdoubt-date,
.unified-theme-dark .list-question-text {
  color: #f3f6f8;
}

.unified-theme-dark .ask-doubt-card-sel {
  background-color: #465361;
}

.unified-theme-dark .solution-header {
  color: #f3f6f8;
  background-color: #303841;
}

.unified-theme-dark .solution-footer {
  background-color: #303841;
}

.unified-theme-dark .btn-wrap-text {
  color: #f3f6f8;
}

.unified-theme-dark .solution-content {
  background-color: #465361;
}

.unified-theme-dark .sol-content-question,
.unified-theme-dark .btn-tab {
  color: #f3f6f8 !important;
}

.unified-theme-dark .bottom-bar-conversation {
  color: #f3f6f8;
  background-color: #303841;
}

.unified-theme-dark .user-chat {
  background-color: #262e35;
}

.unified-theme-dark .solution-content-chat {
  background-color: #303841;
}

.unified-theme-dark .messages-wrapper {
  background-color: #465361 !important;
}

.unified-theme-dark .details-room {
  color: #f3f6f8;
  background-color: #262e35;
}

.unified-theme-dark .notice-text,
.unified-theme-dark .text,
.unified-theme-dark .main-theme-text {
  color: #f3f6f8 !important;
}

.unified-theme-dark .main-theme-text,
.unified-theme-dark .content-upload-doubt,
.unified-theme-dark .upload-doubt,
.unified-theme-dark .left-pane-footer,
.unified-theme-dark .border-radius-round,
.unified-theme-dark .date,
.unified-theme-dark .notfound,
.unified-theme-dark .notfound-extended,
.unified-theme-dark .tutorial,
.unified-theme-dark .back-filter-right {
  color: #f3f6f8 !important;
}

.unified-theme-dark .upload-img {
  fill: #f3f6f8;
}

.unified-theme-dark .members,
.unified-theme-dark .main {
  background-color: #262e35;
}

.unified-theme-dark .solution-header,
.unified-theme-dark .left-pane-title,
.unified-theme-dark .solution-footer,
.unified-theme-dark .ask-doubt-card-sel,
.unified-theme-dark .ask-doubt-card,
.unified-theme-dark .btn-tab,
.unified-theme-dark .btn-wrap-text,
.unified-theme-dark .bottom-bar-conversation,
.unified-theme-dark .details-room,
.unified-theme-dark .left-pane-footer {
  border-color: #585d62 !important;
}

.unified-theme-dark .room:hover {
  background-color: #465361;
}

.unified-theme-dark .room .profile .img {
  background-color: #465361;
}

.unified-theme-dark .fixed-top-profile-tabs {
  background-color: #15388c;
}

.layout-wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.layout-full {
  margin: auto !important;
  flex: 1;
  overflow: hidden;
}

.layout-full-screen {
  height: 100vh;
  width: 100vw;
}

.nav-circle {
  background-color: transparent;
  color: #fff;
  height: 40px;
  width: 40px;
  margin: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

#CommonPageHolder {
  height: calc(100vh - 60px);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  padding: 0px 0px 20px 0px;
  margin: auto;
}

#CommonPageHolder-non-scroll {
  height: 100vh;
  width: calc(100vw - 75px);
  overflow-y: hidden;
  overflow-x: hidden;
  display: block;
  padding: 0px 0px 50px 0px;
  margin: auto;
}

#CommonPageloader-full {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 20px 0px;
  margin: auto;
}

.page-header {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 18px;
  line-height: 22px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: 0px 0px 10px 0px;
  padding: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background-color: #f3f6f8;
  z-index: 1000;
}

.horizontal-rule {
  border-bottom: 1px solid #caccce;
  margin: 15px !important;
}

/*----for practice module----used #quiz-bg  on quizmodule.scss page---*/
.install-overlay {
  width: 100px;
  height: 35px;
  position: absolute;
  top: 0px;
  right: 10px;
  background-color: #fc8643;
  z-index: 2000;
  border-radius: 0px 0px 15px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.section-flex-logo {
  width: 100px;
  margin: auto;
}

.section-flex {
  display: flex;
  /* justify-content: flex-start; */
}

.btn-close-install {
  height: 20px;
  width: 20px;
  line-height: 20px;
  font-size: 11px;
  border-radius: 100px;
  cursor: pointer;
  font-family: "stepapp-secondary-medium", sans-serif;
  background-color: #000;
  border: 1px solid #000;
  outline: none;
  margin-left: -18px;
  /* IE 9 */
  /* Safari 3-8 */
  transform: rotate(45deg);
}

.btn-close-install .btn-basic:hover {
  border: 1px solid transparent;
  outline: none !important;
}

.btn-close-install .btn-basic:focus {
  border: 1px solid transparent;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.btn-rounded-install {
  height: 100%;
  width: 80px;
  font-size: 12px;
  line-height: 22px;
  border-radius: 100px;
  cursor: pointer;
  font-family: "stepapp-secondary-medium", sans-serif;
  background-color: #fc8643;
  border: none;
  outline: none;
  margin-left: 0px;
}

.btn-rounded-install .btn-basic:hover {
  border: none;
  outline: none !important;
}

.btn-rounded-install .btn-basic:focus {
  border: none;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.uk-button-default {
  background-color: #fff !important;
  color: #000 !important;
}

.spinner-center {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-f-c {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.d-f-c-c {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.d-f-l {
  display: flex !important;
  justify-content: start;
  align-items: center;
}

.d-f-c-b {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.d-f-b {
  display: flex !important;
  justify-content: space-between;
  width: 100%;
}

.timegrid-axis-background {
  background-color: #fc8643;
  color: #fff;
}

.fc .fc-timegrid-axis-frame {
  background-color: #fff;
  color: #000;
}

.fc .fc-daygrid-day-number,
.fc-daygrid-dot-event .fc-event-title {
  color: #000;
}

.fc-event-title {
  color: #000;
  height: 20px;
  width: auto;
  overflow: hidden;
}

.fc-event .fc-event-main {
  width: auto;
  overflow: hidden;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.fc-daygrid-event {
  white-space: normal !important;
}

/*---for calendar---*/
.step-tabs-sel {
  border-bottom: 3px solid #fc8643;
  font-family: "stepapp-secondary-bold", sans-serif;
  font-size: 18px;
  line-height: 25px;
  font-weight: 600 !important;
  color: #fc8643;
  cursor: pointer;
  height: 35px;
}

.step-tabs {
  border-bottom: 1px solid #caccce;
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 18px;
  line-height: 25px;
  font-weight: 300 !important;
  color: #caccce;
  cursor: pointer;
  height: 35px;
}

.no-content-message {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 16px;
}

@media (max-width: 900px) {
  .layout-full {
    flex: 1;
    margin: auto !important;
    overflow: hidden;
  }

  #CommonPageHolder {
    height: calc(100vh - 50px);
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 0px 50px 0px;
    margin: auto;
  }

  #CommonPageHolder-non-scroll {
    height: calc(100vh - 54px);
    width: 100vw;
    overflow-y: hidden;
    overflow-x: hidden;
    display: block;
    padding: 0px 0px 50px 0px;
    margin: auto;
  }

  #CommonPageHolder-quiz-bg {
    height: calc(100vh - 54px) !important;
    width: 100% !important;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
    padding: 0px 0px 50px 0px !important;
    margin: 0px;
  }

  #CommonPageHolder-full {
    height: 100vh !important;
    width: 100vw !important;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
    padding: 0px 0px 50px 0px !important;
    margin: 0px;
  }
}

.fixed-top-profile {
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
  z-index: 100;
}

.fixed-top-profile-tabs {
  position: relative;
  padding: 10px 0px 0px 0px !important;
}

@media (max-width: 960px) {
  .fixed-top-profile-tabs {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 100;
    padding: 10px 0px 0px 0px !important;
  }
}

.custom-animation-slide {
  -webkit-animation-name: uk-fade-left-slow;
  animation-name: uk-fade-left-slow;
}

@media (max-width: 960px) {
  .custom-animation-slide {
    -webkit-animation-name: uk-fade-left;
    animation-name: uk-fade-left;
  }
}

.add-height {
  height: 100%;
}

/* added for subject chapter page */
.top-fixed-bar {
  position: -webkit-sticky;
  position: sticky;
  display: block;
  top: 0;
  width: 100%;
  z-index: 1000;
  height: 50px;
  line-height: 50px;
}

.head-bread-crumb {
  position: -webkit-sticky;
  position: sticky;
  display: block;
  top: 0;
  width: 100%;
  z-index: 1000;
  height: 50px;
  line-height: 50px;
  border-bottom: 0.5px solid #caccce !important;
  margin-bottom: 20px;
}

.head-bread-crumb-dashboard {
  position: -webkit-sticky;
  position: sticky;
  display: block;
  top: 0;
  width: 100%;
  z-index: 1000;
  height: 50px;
  line-height: 50px;
  border-bottom: 0.5px solid #caccce !important;
  margin-bottom: 20px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.back-nav-btn {
  width: auto;
  height: 40px;
  line-height: 40px;
  background-color: transparent;
  z-index: 2000;
  border-radius: 0px 0px 15px 15px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  float: left;
}

.badge-img {
  width: 30px;
  height: 30px;
}

.content-top-bar {
  width: 100%;
}

/* added for subject chapter page */
.chapter-nav-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#contentpages {
  display: block;
  padding: 0px 0px 50px 0px;
  margin: 0px;
}

/* added for learn quiz */
/* .quiz-cont-flex {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 68px;
} */

.menu-scroll-bignav {
  max-height: calc(100vh - 85px);
  overflow-y: auto;
  overflow-x: hidden;
  top: 85px;
  margin-left: auto;
  margin-right: auto;
}

.profile-scroll {
  top: 85px !important;
}

.profile-scroll-vid {
  top: 85px !important;
}

.main-page-bignav {
  top: 83px !important;
}

.main-page-smallnav {
  position: fixed;
  top: 157px;
}

.menu-scroll-island {
  max-height: calc(100vh - 60px);
  overflow-y: auto;
  position: fixed;
  top: 80px;
  margin-left: auto;
  margin-right: auto;
}

.information-text {
  line-height: 27px;
}

.menu-scroll-quiz {
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  position: fixed;
  top: 80px;
  margin-left: auto;
  margin-right: auto;
}

/*-----------------for quiz pages and quiz-nav-----*/
#contentpages-video {
  padding-bottom: 50px;
  position: fixed;
  overflow-y: auto;
}

.menu-scroll-vidnav {
  top: 60px;
  margin-left: auto;
  margin-right: auto;
}

.quiz-scroll-vidnav {
  overflow-y: auto;
  top: 120px;
}

.menu-scroll-vidnav-text {
  max-height: 100%;
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;
}

.menu-scroll-islandquiz {
  position: fixed;
  top: 90px;
  margin-left: auto;
  margin-right: auto;
}

/*----------quiz pages and quiz nav end--------*/
/*-----------------for quiz pages and quiz-nav-----*/
#contentpages-lessons {
  min-height: calc(100vh - 60px);
  display: block;
  padding-bottom: 50px;
}

.menu-scroll-smallnav {
  max-height: calc(100vh - 60px);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

/*----------quiz pages and quiz nav end--------*/
.bg-nav {
  border-bottom: 1px solid #4e54c2;
}

.nav1 {
  background-color: #3b3398;
  color: #fff;
  height: 120px;
  line-height: 36px;
  width: 100%;
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 16px;
  padding: 0px;
  border: 1px solid green;
}

.nav2 {
  color: #fff;
  line-height: auto;
  width: 100%;
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 16px;
  padding: 10px;
}

.nav_bg {
  width: 100%;
}

.nav.i {
  font-size: 12px;
}

.icon-big {
  font-size: 20px;
}

.nav-link {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 14px;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
  color: #fc8643;
}

.text-secn-nav {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}

.rail-title {
  font-family: "stepapp-secondary-bold", sans-serif;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
}

/*-------- subjects----------*/
.subjectCard {
  border-radius: 10px;
  padding: 0px;
  margin: 15px;
  cursor: pointer;
}

.subjectCard {
  border: 0px solid #fff;
}

.subjectCard .progress {
  width: 100%;
  margin: 5px auto;
  background: #fff !important;
  height: 10px !important;
  padding: 3px;
  border-radius: 10px;
}

.subjectCard .pt-1 {
  width: 100%;
  color: #fff;
  font-weight: 400;
}

.subjectCard .chapters-complete {
  float: right;
  margin-top: 4px;
}

.NewSubjectCard {
  border: 0px solid #fff;
}

.NewSubjectCard .pt-1 {
  width: 100%;
  color: #fff;
  font-weight: 400;
}

.NewSubjectCard .chapters-complete {
  float: right;
  margin-top: 4px;
}

.subject-main {
  padding: 5px;
  vertical-align: middle;
  color: #000;
}

.subject-main .subject-title {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.curved-bg {
  border-radius: 0px 15px 15px 0px;
}

.subject-details {
  border-radius: 10px;
  color: #fff;
  height: 100px;
  width: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subject-details-text {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 16px;
  width: 300px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px;
}

.subject-detail-next {
  font-size: 26px;
  line-height: 74px;
  cursor: pointer;
}

.subject_item_flex {
  width: 250px;
  height: 100%;
  padding: 0px;
  margin: 20px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.pos-img {
  height: 100%;
}

.pos-img-profile {
  height: 100px;
  width: auto;
}

.subject-img-circle {
  width: 100%;
  height: auto;
  margin: auto;
  text-align: center;
}

.subjecttitle {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  width: 100%;
  padding-top: 5px;
  font-weight: 400;
}

.subject_bg_default {
  background-color: #996fd6;
}

.color-orange {
  color: #fc8643;
}

/*-------- subjects----------*/
/*----lessons----*/
.lesson_item_flex {
  border-radius: 10px;
  transform: scale(1);
  transition: all 2s ease;
}

.lesson_item_flex.progress {
  background-color: #cfcfcf;
  padding: 3px;
}

.lesson_item_flex:hover,
.lesson_item_flex:focus {
  transform: scale(1.05);
  transition: all 2s ease;
}

.lessontitle {
  font-size: 16px;
  overflow: hidden;
  display: inline-block;
  line-height: 24px;
  font-weight: 600;
  border-top: 0.5px solid #6c757d;
}

.lesson_item_flex .leader-name {
  position: absolute;
  top: 0;
}

.lessonCard {
  border-radius: 10px;
  padding: 0px;
  margin: 20px 15px;
  cursor: pointer;
  width: 300px;
  background-color: #f5f5f5;
  color: #181818;
}

.chapters-complete {
  font-size: 11px;
}

.lesson-details {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 16px;
  line-height: auto;
  color: #000;
  width: 100%;
  min-height: 88px;
  background: #fff;
}

.lesson-details-text {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 16px;
  width: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lessonCard-bottomnav {
  background-color: transparent;
  border-radius: 10px;
  padding: 0px;
  margin: 10px;
  min-height: 50px;
}

.chapter-height {
  width: 450px;
  height: 150px;
  background-image: url("../images/logo/thumbnail.jpg");
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: 100%;
  background-position-x: center;
  background-position-y: center;
}

.card-image {
  width: auto;
  height: 180px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 15px 15px 0px 0px;
}

.card-image-subject {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}

.card-image-post {
  width: 100%;
  height: 165px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
  background-image: url("../images/logo/logo-dark.svg");
  background-repeat: no-repeat;
  background-color: #fff;
  background-size: 50%;
  background-position-x: center;
  background-position-y: center;
}

.text-small {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 11px !important;
}

.badge-wealth {
  font-family: "stepapp-secondary-bold", sans-serif;
  font-size: 24px;
  line-height: 43px;
  padding-left: 5px;
  color: #000 !important;
  font-weight: 600;
}

/*--------video Lessons----*/
.revise-spinner-card {
  background-color: transparent;
  border-radius: 0px 0px 15px 15px;
  margin: 0px auto;
  padding: 0px;
  display: flex;
  justify-content: center;
}

.video-block {
  width: 100%;
  height: 100%;
  border-radius: 0px;
  margin: auto;
}

.video-block iframe {
  width: 100%;
  height: 100%;
  border-radius: 0px;
  background-color: #fff;
}

.lessons-title {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  text-align: left;
  color: #fff;
  margin-bottom: 15px;
}

.lessons-title-vid {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  text-align: left;
  color: #fff;
  margin-bottom: 15px;
}

.lessons-title-yellow {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  text-align: left;
  color: #e4ac13;
}

.video-lesson-details {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: auto;
  color: #000;
  width: 100%;
  margin-top: 0px;
  padding: 20px;
}

.lessons-text-title {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  text-align: left;
  color: #fff;
  margin-bottom: 10px;
}

.lessons-text {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  text-align: left;
  color: #fff;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1.6;
}

i {
  font-style: italic;
}

.lesson-image {
  width: 80%;
}

.quiz-image {
  background-color: #fc8643;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  border-radius: 10px;
}

/*--------quiz----------*/
.quiz-question {
  font-family: "stepapp-secondary-medium", sans-serif;
  font-size: 22px;
  line-height: auto;
  /* color: #15388c; */
  color: #fff;
  text-align: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.quizCard {
  background-color: #fff;
  border-radius: 10px;
  margin: 0px;
  width: 50vw !important;
  padding: 15px;
  height: auto;
  border: 3px solid #ccc;
}

.report-content {
  max-height: 56vh;
  min-height: 30vh;
  overflow-y: auto;
  color: #181818;
}

.cell-height-left {
  min-height: 35px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cell-height-right {
  min-height: 35px;
  font-size: 12px;
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
}

.LeaderboardCard {
  border-radius: 10px;
  margin: 0px;
  min-height: 200px;
  width: 100%;
  padding: 0px 20px 20px 20px;
}

.content-leaderboard {
  padding: 0px;
  margin: 5px auto 10px auto;
}

.border-sepration {
  border-top: 0.5px solid #caccce;
}

.timer {
  border-radius: 50px;
  width: 170px;
  height: 50px;
  background-color: #fff;
  color: #fff;
  margin: auto;
  font-size: 40px;
  line-height: 50px;
  color: #fc8643;
}

.timer-text {
  font-size: 22px;
  font-family: "stepapp-secondary-medium", sans-serif;
  float: left;
  text-align: left;
  color: #15388c;
  line-height: 50px;
  padding-left: 10px;
}

.timer-text-otp {
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  color: #fff;
  line-height: 50px;
}

.step1 {
  width: 25%;
  height: 120px;
  padding: 0px;
  margin: 0px;
  display: block;
}

.step2 {
  width: 25%;
  height: 145px;
  padding: 0px;
  margin: 0px;
}

.step3 {
  width: 25%;
  height: 170px;
  padding: 0px;
  margin: 0px;
}

.step4 {
  width: 25%;
  height: 200px;
  padding: 0px;
  margin: 0px;
}

.answer-height {
  height: 70px;
}

.wrong-answer {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  line-height: 18px;
  color: red;
}

.correct-answer {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  line-height: 18px;
  color: green;
  text-align: center;
}

/*-----------island-----*/

.progress-info ul {
  max-width: 100%;
}

.progress-info ul li {
  list-style-type: none;
  padding-top: 100px;
}

.course-progress {
  float: right;
  width: 100%;
}

.status-block {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro-btn {
  padding: 0px;
  margin: 0px;
}

.intro-btn button {
  min-width: 220px;
  max-width: 300px;
  min-height: 52px;
  background: #15388c;
  border-radius: 10px;
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  font-size: 15px;
  color: #ffffff;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  direction: ltr;
}

.intro-btn button:hover {
  border: 0.5px solid rgba(255, 255, 255, 0.3) !important;
  background-color: #fc8643;
}

.intro-btn button:active {
  border: 0.5px solid rgba(255, 255, 255, 0.3) !important;
  background-color: #fc8643;
}

.intro-btn button:visited {
  border: 0.5px solid rgba(255, 255, 255, 0.3) !important;
  background-color: #fc8643;
}

.intro-btn button:focus {
  border: 0.5px solid rgba(255, 255, 255, 0.3) !important;
  background-color: #fc8643;
}

.intro-btn button:active:focus {
  border: 0.5px solid rgba(255, 255, 255, 0.3) !important;
  background-color: #fc8643;
}

.intro-btn button:focus-within {
  border: 0.5px solid rgba(255, 255, 255, 0.3) !important;
  background-color: #fc8643;
}

.intro-btn button em {
  font-style: normal;
  flex: 1;
  padding: 5px;
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 15px;
  max-height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  margin: 10px 5px 25px 5px;
  width: 100%;
  color: #fff !important;
}

.status-block::after {
  content: "";
  width: 150px;
  height: 150px;
  position: absolute;
  left: 53%;
  bottom: 116%;
  transform: translate(36%, 46%);
}

.status-block-2::after {
  content: "";
  width: 150px;
  height: 150px;
  position: absolute;
  left: 25%;
  bottom: 116%;
  transform: translate(36%, 46%);
}

.status-block-2 {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.island-list {
  width: 500px;
  margin: auto;
  text-align: center;
}

.complete-block {
  min-height: 300px;
  width: 100%;
}

.incomplete-block {
  min-height: 300px;
  width: 100%;
  mix-blend-mode: luminosity !important;
  background-blend-mode: luminosity;
}

.seen-status-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.progress-info ul li:nth-child(odd) {
  direction: rtl;
}

.progress-info ul li:nth-child(odd) .status-block {
  transform: scaleX(-1);
}

.progress-info ul li:nth-child(odd) .intro-btn button {
  margin: auto;
}

.course-progress ul {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ededed;
  padding: 10px;
  border-radius: 50px;
  min-width: auto;
  /* max-width: 450px; */
  max-width: 300px;
  margin: auto;
}

.course-progress ul li {
  list-style-type: none;
  min-width: 33.33%;
  height: 40px;
  border-radius: 0%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #000;
  font-size: 15px;
  line-height: 16px;
  margin: 0px 0px;
  padding: 5px;
  /* border-right: 0.5px solid rgba(255, 255, 255, 0.3); */
}

.course-progress ul li:nth-child(3) {
  border-right: none;
}

.opacity-less {
  opacity: 0.4;
}

.incomplete-block .status-block::after {
  background: url("../images/step-right.svg") no-repeat center;
  z-index: 20 !important;
}

.complete-block .status-block::after {
  background: url("../images/step-right-sel.svg") no-repeat center;
  z-index: 20 !important;
}

.incomplete-block .status-block-2::after {
  background: url("../images/step-right.svg") no-repeat center;
  z-index: 20 !important;
}

.complete-block .status-block-2::after {
  background: url("../images/step-right-sel.svg") no-repeat center;
  z-index: 20 !important;
}

.progress-info ul li:first-child .status-block::after {
  display: none;
}

.incomplete-block .intro-btn button {
  opacity: 0.3;
  cursor: not-allowed;
}

.incomplete-block .intro-btn button:hover {
  border: 1px solid #fff !important;
  background-color: #15388c;
}

.incomplete-block .intro-btn button:active {
  border: 1px solid #fff !important;
  background-color: #15388c;
}

.incomplete-block .intro-btn button:visited {
  border: 1px solid #fff !important;
  background-color: #15388c;
}

.chapter-progress-wrapper~.progress {
  padding: 3px;
  border-radius: 50px;
}

.btn-icon {
  min-width: 41px;
  height: 41px;
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #15388c;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  bottom: -25px;
}

.btn-icon-score {
  min-width: 41px;
  height: 41px;
  background: #fc8643;
  border-radius: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*---- reports---*/
.reports-title {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 18px;
  font-weight: 600 !important;
  line-height: 40px;
}

.score-text {
  font-size: 14px;
  line-height: 27px;
}

.bg-circle-sm-icon {
  border-radius: 100%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  background-color: #fff;
  color: #000;
  margin: 5px;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.bg-circle-sm-icon:hover,
.bg-circle-sm-icon:active,
.bg-circle-sm-icon:visited,
.bg-circle-sm-icon:focus {
  background-color: #fff !important;
  color: #000 !important;
}

.bg-circle-big-silver {
  border-radius: 100%;
  height: 74px;
  width: 74px;
  background-color: #c2c2c2;
  color: #000;
  padding: 2px;
  margin: 5px;
  line-height: 69px;
  font-size: 20px;
  font-family: "stepapp-secondary-bold", sans-serif;
}

.report-number {
  text-align: center;
  color: #000;
  margin: 0 0 100px;
  font-size: 34px;
  font-weight: 100;
  text-transform: uppercase;
  padding: 20px 0;
}

/*---profile-----*/
.Report_item_flex {
  width: 100%;
  margin: auto 10px;
  transition: all 1s ease;
  transform: scale(1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.Report_item_flex .subjecttitle {
  color: #615f5f;
}

.ReportCard {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  margin: 15px auto;
}

.Report-main {
  padding: 5px;
  vertical-align: middle;
}

.Report-details {
  border-radius: 10px;
  color: #fff;
  height: 100px;
  width: 100%;
  padding: 5px 10px;
}

.Report-details-text {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 16px;
  width: 300px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px;
}

.subjecttitle-profile {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  color: #000;
  text-align: left;
  width: 100%;
}

.profile-percentage {
  font-family: "Poppins", sans-serif;
  font-size: 35px;
  color: #000;
  text-align: left;
  width: 100%;
}

.progress-text {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
}

.bookmarks-details {
  border-radius: 10px;
  color: #fff;
  height: auto;
  width: 100%;
  padding: 10px;
}

.Bookmarks-details-text {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 16px;
  width: 300px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bookmarks-detail-next {
  font-size: 26px;
  line-height: auto;
  vertical-align: center;
}

.progress-text {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
}

.progress-text-number {
  color: #fff;
  font-family: "stepapp-secondary-bold", sans-serif;
  font-size: 20px;
  display: block;
  line-height: 44px;
}

.progress-text-number2 {
  color: #fff;
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 16px;
  display: block;
  line-height: 44px;
}

/*---help---*/
.help-bg {
  width: 100%;
  height: auto;
  background-color: #15388c;
  border-radius: 10px;
  color: #fff;
}

.dotted-seperator {
  border-right: 1px dotted #caccce;
}

/*-----------Leaderboard--------------*/
.btn-leaderboard {
  color: #fff;
  background-color: #15388c;
  border-color: #6c757d;
  border-radius: 10px;
  border: none;
}

.btn-leaderboard:hover,
.btn-leaderboard :focus {
  background-color: #fc8643;
  color: #fff;
}

.btn-leaderboard:visited,
.btn-leaderboard:active {
  background-color: #15388c;
  color: #fff;
}

.leaderboard-name {
  font-family: "Poppins", sans-serif;
  color: #fc8643;
  font-size: 25px;
}

.leaderboard-class {
  font-family: "Poppins", sans-serif;
  color: #15388c;
  font-size: 20px;
}

.leaderboard-normaltext {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 18px;
  color: #000;
}

.leaderboard-small-title {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 12px;
  color: #000;
}

.leaderboard-score-main {
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  color: #fc8643;
}

.leaderboard-rank-main {
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  color: #000;
  background-color: #caccce;
  border-radius: 10px;
  margin: 5px;
}

.leaderboard-item-flex-title {
  width: 400px;
  height: 10px;
  border-radius: 10px;
  background-color: transparent;
  vertical-align: middle;
  margin: 10px;
  display: flex;
  align-items: center;
}

.leaderboard-item-flex {
  width: 400px;
  height: 50px;
  border-radius: 10px;
  background-color: #fff;
  color: #000;
  vertical-align: middle;
  padding: 5px;
  margin: 10px;
  display: flex;
  align-items: center;
}

.leaderboard-item-flex+.selected {
  background-color: #15388c;
  color: #fff !important;
}

.leaderboard-item-flex+.selected .leader-num {
  color: #333741;
}

.leader-num {
  background-color: #caccce;
  border-radius: 10px;
  text-align: center;
  margin-right: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 5px;
  min-width: 40px;
  width: auto;
  height: 40px;
}

.leader-num-text {
  background-color: transparent;
  border-radius: 10px;
  text-align: center;
  margin-right: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: auto;
  height: 50px;
}

.lblable {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  width: 100%;
  text-align: left;
  font-weight: 600;
}

.lbrank {
  font-size: 22px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
}

.lbscore {
  color: #fc8643;
  width: 100%;
  font-weight: 600;
}

.leader-name {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 14px;
  line-height: 14px;
  text-align: left;
}

.leader-score {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #fc8643;
  padding-right: 10px !important;
}

/*---------feeds-page------*/
.Feedstitle {
  font-family: "Poppins", sans-serif;
  color: #fc8643;
  font-size: 22px;
  text-align: left;
  width: 100%;
  font-weight: 100;
}

.popularchapter_item_flex {
  background-color: #fff;
  color: #000;
  border-radius: 10px;
  padding: 10px;
  margin: 20px;
  cursor: pointer;
  flex: 1 1 auto;
  position: relative;
  height: 273px !important;
  overflow-y: hidden;
  transition: all 1s ease;
  transform: scale(1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.popularchapter_item_flex:hover {
  transform: scale(1.1);
  transition: all 1s ease;
}

.popularchapter_item_flex:hover,
.popularchapter_item_flex:focus {
  transform: scale(1.1);
  transition: all 1s ease;
}

.feed-chapter-cell {
  background-image: url("../images/logo/logo-dark.svg");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  border-radius: 10px 10px;
  margin: 0px;
  background-position: center;
  background-size: 50%;
  background-position-x: center;
  background-position-y: center;
}

.post_item_flex {
  background-color: #fff;
  color: #000;
  border-radius: 10px;
  padding: 0px 0px 20px 0px;
  margin: 20px;
  cursor: pointer;
  flex: 1 1 auto;
  position: relative;
  height: auto;
  transition: all 1s ease;
  transform: scale(1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.post_item_flex:hover {
  transform: scale(1.1);
  transition: all 1s ease;
}

.Knowledge_item_flex {
  background-color: #fff;
  border-radius: 10px;
  padding: 0px;
  margin: 20px;
  cursor: pointer;
  flex: 1 1 auto;
  position: relative;
  width: 200px;
  min-height: 150px;
}

.testimonials_item_flex {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  margin: 20px;
  cursor: pointer;
  flex: 1 1 auto;
  position: relative;
  height: 300px;
}

.description-text {
  word-break: break-all;
  height: 95px;
  overflow-y: auto;
}

.testimonial_bg {
  background-image: url("../images/testimonials-bg.png");
  width: 100%;
  height: 136px;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
  display: block;
}

.feed-chapeter-cell {
  border-radius: 10px;
  background-color: #fff;
  padding: 0px;
  width: 100%;
  height: 165px;
  border-radius: 0px 0px 15px 15px;
  margin: 0px;
}

.feed-card-image {
  width: 100%;
  height: 169px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
  padding-bottom: 3px;
  border-bottom: 0.5px dotted #caccce;
  margin: 0px;
}

.live-tv-img {
  border-radius: 10px;
  height: 100%;
}

.date-time {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 20px;
  text-align: left;
}

.live-class-button {
  background-color: #fc8643;
  border-radius: 50px;
  padding: 10px;
  width: 100px;
  box-shadow: none;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #fff;
  height: 50px;
}

.liveclass_item_flex {
  background-color: #f4f4f4;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  position: relative;
}

.live-cell {
  border-radius: 10px;
  background-color: #fff;
}

.subjecttitle-chaptercard {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  text-align: left;
  padding-top: 5px;
}

.subject-chapter-card {
  font-family: "stepapp-secondary-regular", sans-serif;
  color: #000;
  font-size: 18px;
}

.subjecnormal-chaptercard {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 14px;
  text-align: left;
  width: 300px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
}

.quote {
  font-family: "stepapp-secondary-medium", sans-serif;
  color: #000;
  font-size: 18px;
  padding-top: 0px;
  min-height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quote-title {
  font-family: "stepapp-secondary-regular", sans-serif;
}

.quote-title-back {
  font-family: "Poppins", sans-serif;
  color: #fc8643;
  font-size: 18px;
  padding: 10px 0px;
}

/*------------payment section-------------*/
.card-header {
  font-family: "stepapp-secondary-bold", sans-serif !important;
  font-size: 14px;
  font-weight: 600;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  align-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
}

.select-label-payment {
  display: block;
  background-color: #dadada;
  color: #15388c;
  border: none;
  border-radius: 10px;
  text-align: left;
  position: relative;
  opacity: 1;
  font-family: "stepapp-secondary-regular", sans-serif;
  border: 3px solid transparent;
  padding: 10px;
}

.payment-title {
  font-family: "stepapp-secondary-medium", sans-serif;
  font-size: 16px;
}

.payment-text {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 12px;
}

.payment-item-flex {
  width: 90%;
  padding: 5px 10px;
  font-size: 20px;
}

.payment-item-flex-small {
  width: 30%;
  padding: 5px 10px;
  font-size: 20px;
}

.paymentCard {
  background-color: #f4f4f4;
  border-radius: 10px;
  padding: 20px 0px 0px 0px;
  margin: 0px;
  min-height: 200px;
  width: 100%;
}

.sap_tabs {
  clear: both;
  padding: 0em 0 0em;
}

.pay-tabs {
  margin: 1em 0px 0px 0px;
  padding: 10px;
}

.title-sec {
  font-family: "stepapp-secondary-medium", sans-serif;
  font-size: 18px;
  color: #000;
  text-align: center;
}

.pay-tabs h2 {
  font-size: 26px;
  text-align: center;
  margin-bottom: 25px;
  color: #fc8643;
  font-weight: 500;
  font-family: "stepapp-secondary-regular", sans-serif;
}

.resp-tabs-list {
  list-style: none;
  margin: 0 0 0em 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.resp-tab-item {
  cursor: pointer;
  list-style: none;
  outline: none;
  transition: all 0.3s ease-out;
  text-transform: capitalize;
  display: inline-block;
  margin: 0 1%;
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-family: "stepapp-secondary-regular", sans-serif;
}

.resp-tab-active {
  color: #fc8643;
  font-size: 12px;
  font-family: "stepapp-secondary-bold", sans-serif;
}

.resp-tabs-container {
  padding: 0px;
  clear: left;
  padding: 1em;
  background: #fff;
  border: 1px solid #e7ebee;
  border-radius: 0px 0px 15px 15px;
}

h2.resp-accordion {
  cursor: pointer;
  padding: 5px;
  display: none;
}

.resp-tab-content {
  display: none;
}

.resp-content-active,
.resp-accordion-active {
  display: block;
}

.pic {
  width: 100%;
  line-height: 15px;
  display: block;
  border: 1px solid #e7ebee;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border-radius: 5px;
  font-size: 9px;
}

.pic-icon {
  font-size: 30px;
}

li.resp-tab-item span .pic1,
li.resp-tab-item span .pic2,
li.resp-tab-item span .pic3,
li.resp-tab-item span .pic4 {
  width: 100%;
  height: 32px;
  display: block;
  border: 1px solid #e7ebee;
  padding: 15px 0px;
  margin-bottom: 15px;
  cursor: pointer;
  text-align: center;
}

li.resp-tab-item.resp-tab-active span label {
  border: 2px solid #f16b24;
  background-color: #fc8643;
  color: #fff;
  text-align: center;
}

.payment-info h3 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 15px;
  color: #000;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.tab-for input[type="text"] {
  width: 96%;
  padding: 8px;
  font-size: 13px;
  font-weight: 600;
  border: 1px solid #e7ebee;
  outline: none;
  color: #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
}

.tab-for h5,
.tab-form-left h5,
.tab-form-right h5 {
  color: #435964;
  font-size: 13px;
  text-transform: uppercase;
  margin: 0 0 8px 0;
  font-weight: 600;
}

.tab-for input[type="text"]:hover {
  border: 1px solid #b3e03f;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
}

input.pay-logo {
  width: 100% !important;
  padding: 8px 45px 8px 8px !important;
  background: url(../images/payment-options/visa.png) no-repeat 95% 50%;
}

.tab-form-left {
  float: left;
  width: 48%;
}

.user-form ul li {
  list-style-type: none;
  display: inline-block;
  width: 23%;
  margin-right: 9%;
}

.text_box {
  display: inline;
  padding: 3px 5px;
  outline: none;
  margin-left: 1%;
  font-size: 20px;
  color: #444;
  color: #606060;
  font-size: 15px;
  padding: 8px 0 8px 10px;
  border: none;
  outline: none;
  background: #fff;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
}

input.text_box {
  background: #fcfcfc;
  border: 1px solid #ebeced;
}

.tab-form-right {
  float: right;
  width: 48%;
}

.tab-form-right input[type="text"] {
  width: 92%;
  padding: 8px;
  font-size: 13px;
  font-weight: 600;
  border: 1px solid #e7ebee;
  outline: none;
  color: #ccc;
  border-radius: 5px;
  margin-bottom: 30px;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
}

.tab-form-right input[type="text"]:hover {
  border: 1px solid #b3e03f;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
}

.payment-info form input[type="submit"] {
  background: #b3e03f;
  color: #ffffff;
  text-align: center;
  padding: 10px 0;
  border: none;
  font-size: 16px;
  outline: none;
  width: 22%;
  cursor: pointer;
  font-weight: 600;
  margin-bottom: 30px;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
}

.payment-info form input[type="submit"]:hover {
  background: #3ad5a0;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
}

.single-bottom ul li {
  list-style: none;
  padding: 0px;
}

.radio {
  position: relative;
  display: inline-block;
  margin-left: 15px;
}

.radio:first-child {
  margin-left: 0;
  margin: 0;
}

.radio {
  padding-left: 22px;
  line-height: 26px;
  color: #404040;
  cursor: pointer;
}

.radio input[type="radio"] {
  position: absolute;
  left: -9999px;
}

.radio-btns label {
  font-size: 14px;
  color: #000;
  padding: 0px 0 0 10px;
}

.radio i {
  position: absolute;
  top: 5px;
  left: 0;
  display: block;
  width: 20px;
  height: 20px;
  outline: none;
  border: 2px solid #f16b24;
  background: #fff;
  cursor: pointer;
}

.radio i {
  border-radius: 50%;
}

.radio input+i:after {
  position: absolute;
  opacity: 0;
  transition: opacity 0.1s;
  -o-transition: opacity 0.1s;
  -ms-transition: opacity 0.1s;
  -moz-transition: opacity 0.1s;
  -webkit-transition: opacity 0.1s;
}

.radio input+i:after {
  content: "";
  top: 2px;
  left: 2px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
}

.radio input:checked+i:after {
  opacity: 1;
}

label.checkbox {
  width: 28%;
  float: left;
}

/*** normal state ***/
.radio i {
  transition: border-color 0.3s;
  -o-transition: border-color 0.3s;
  -ms-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  -webkit-transition: border-color 0.3s;
}

/*** checked state ***/
.radio input+i:after {
  content: "";
  background: url("../images/tick-mark1.png") no-repeat center;
  top: 2px;
  left: 2px;
  width: 9px;
  height: 9px;
  text-align: center;
}

.radio input:checked+i {
  border: 3px solid #f16b24;
  background: #fff;
}

.swit {
  float: left;
  width: 50%;
}

.check_box {
  margin-bottom: 10px;
}

.payment-info a {
  padding: 8px 10px;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  background: #fc8643;
}

.payment-info a:hover {
  background: #3ad5a0;
}

.radio-btns {
  margin-bottom: 30px;
}

.login-tab {
  margin-top: 15px;
}

.user-login h2 {
  margin: 0 0 18px 0;
  color: #424448;
  font-size: 24px;
  text-align: center;
  font-weight: bold;
}

.graph-header h3 {
  margin: 0 0 18px 0;
  color: #424448;
  font-size: 24px;
  text-align: center;
  font-weight: bold;
}

.graph-grid {
  background: #fff;
  padding: 42px 20px;
  margin: 15px 0;
}

.con-btm-l {
  float: left;
  width: 46%;
}

.con-btm-r {
  float: left;
  width: 51.5%;
  margin-left: 2.5%;
}

.con-btm-right {
  padding-right: 0;
}

.user-login form input[type="text"],
.user-login form input[type="password"] {
  margin: 0 0 24px 0;
  background: #fcfcfc;
  width: 94%;
  padding: 12px 15px;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #ebeced;
  outline: none;
  color: #d7d7d7;
}

.user-login form {
  margin-top: 22px;
}

.user-left i {
  font-style: normal;
  vertical-align: text-bottom;
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
}

.user-left {
  width: 61%;
  float: left;
}

.user-right {
  width: 21%;
  float: right;
}

.user-right input[type="submit"] {
  background: #fc8643;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  padding: 10px 25px;
  border: none;
  width: 100% !important;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
}

.user-right input[type="submit"]:hover {
  background: #28b08a;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
}

.payment-info h4 {
  font-size: 14px;
  text-align: center;
  color: #000;
}

.payment-button {
  background-color: #fc8643;
  border-radius: 50px;
  padding: 10px;
  width: 100%;
  box-shadow: none;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #fff;
  height: 45px;
  line-height: 28px;
}

.treasure-question {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-weight: bold;
  font-size: 14px;
}

.treasure-your-answer {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 16px;
  color: #000;
  padding: 10px 0px !important;
}

.treasure-answer {
  font-family: "stepapp-secondary-regular", sans-serif;
  background-color: #caccce;
  font-size: 16px;
  color: #000;
  border-radius: 5px;
  padding: 10px !important;
}

.chapter-title-modal {
  font-family: "stepapp-secondary-medium", sans-serif;
  font-size: 20px;
  font-weight: 600;
}

/*------------flip card for tips--------------*/
.flip-card {
  background-color: transparent;
  perspective: 1000px;
  border-radius: 10px;
  cursor: help;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 200px;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
  border-radius: 10px;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 10px;
}

.flip-card-front {
  background-color: #fff;
  color: black;
  border-radius: 10px;
}

.flip-card-back {
  background-color: #caccce;
  color: white;
  transform: rotateY(180deg);
  border-radius: 10px;
}

/*------------flip card for tips--------------*/
.island-tabs-sel {
  border-bottom: 2px solid #fc8643;
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 18px;
  font-weight: 600 !important;
  color: #fc8643;
  cursor: pointer;
}

.island-tabs {
  border-bottom: 2px solid #000;
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 18px;
  font-weight: 300 !important;
  color: #000;
  cursor: pointer;
}

/*----------external links page-----*/
.external-link-page {
  height: 100vh;
  overflow-y: auto;
  margin: 0px;
  padding: 0px;
}

/*----------News-cards----------*/
.news-card {
  width: 300px;
  height: 300px;
  border-radius: 10px;
  margin: 20px;
  background-color: black;
  background-size: cover;
  cursor: pointer;
  box-shadow: 0 0 5px #000;
}

.overlay {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: rgba(77, 77, 77, 0.9);
  color: #fff;
  opacity: 0;
  transition: all 0.5s;
}

.items {
  padding-left: 20px;
  letter-spacing: 3px;
}

.head {
  font-size: 30px;
  line-height: 40px;
  transform: translateY(40px);
  transition: all 0.7s;
}

.head hr {
  display: block;
  width: 0;
  border: none;
  border-bottom: solid 2px #15388c;
  transition: all 0.5s;
}

.news-card:hover .overlay {
  opacity: 1;
}

.news-card:hover .overlay hr {
  width: 75px;
  transition-delay: 0.4s;
}

/*---- to include for island animation */
.floating-island {
  -webkit-animation-name: floating;
  animation-name: floating;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  height: auto;
  width: 100%;
}

@-webkit-keyframes floating {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(0.75rem);
  }
}

@keyframes floating {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(0.75rem);
  }
}

.non-floating-island {
  height: 100%;
  width: auto;
  mix-blend-mode: luminosity;
  background-blend-mode: luminosity;
  opacity: 0.6;
}

/* card stack for island */
/*  for card stack*/
[card-stack] {
  will-change: transform;
  position: relative;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}

[name="card-set"] {
  display: none;
}

[name="card-set"]:checked+[card] {
  display: flex;
  opacity: 1;
  filter: blur(0);
  transform: translateY(0) scale(1);
  transition: transform 1s, opacity 1s, filter 0.25s;
  transition-delay: 0.3s;
  z-index: 0;
}

[name="card-set"]:checked+[card]~[card] {
  z-index: -1;
  display: flex;
  opacity: 0.9;
  filter: blur(1px);
  transform: translateY(1.2rem) scale(0.95);
  transition: transform 1.5s, opacity 1.5s, filter 0.5s;
  transition-delay: 0.4s;
}

[name="card-set"]:checked+[card]~[card]~[card] {
  z-index: -2;
  display: flex;
  opacity: 0.7;
  filter: blur(2px);
  transform: translateY(2.4rem) scale(0.9);
  transition: transform 2s, opacity 2s, filter 1.5s;
  transition-delay: 0.5s;
}

[name="card-set"]:checked+[card]~[card]~[card]~[card] {
  z-index: -3;
  display: flex;
  opacity: 0.5;
  filter: blur(3px);
  transform: translateY(3.6rem) scale(0.85);
  transition: transform 2.5s, opacity 2.5s, filter 2s;
  transition-delay: 0.7s;
}

[name="card-set"]:checked+[card]~[card]~[card]~[card]~[card] {
  z-index: -4;
  display: flex;
  opacity: 0;
  filter: blur(4px);
  transform: translateY(5rem) scale(0.8);
  transition: transform 3s, opacity 3s, filter 2.5s;
  transition-delay: 0.8s;
}

[card] {
  display: flex;
  will-change: filter opacity transform;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: white;
  opacity: 0;
  transform: translateY(0) translateX(calc(-100% - 1rem)) scale(1);
  transition: transform 0.5s, opacity 0.2s 0.3s, filter 0.1s;
  transition-timing-function: ease-in;
  box-shadow: 0 0.2rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: 50vh;
}

[card]::before {
  content: "";
  display: block;
  padding-bottom: 130%;
}

[card] .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 2rem;
  text-align: center;
}

[card] label {
  color: white;
  display: inline-block;
  padding: 1rem 2rem;
  margin: 1rem;
  background-color: #47cf73;
  border-radius: 200px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[card] label:hover {
  background-color: #6fda92;
}

[card] label:active {
  background-color: #248c46;
}

.embed-responsive {
  width: 100vw;
  height: 100vh;
}

.page-back {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 2000;
}

/**********************Live Class Card***************************/
.liveclass-card-wrapper {
  padding: 0px;
  cursor: pointer;
  height: 236px;
  transition: all 2s ease;
  transform: scale(1);
  background-color: #fff;
  border-radius: 10px;
  margin: 20px;
  margin-bottom: 30px;
}

.live-subject-circle {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  background: #47bd76;
  text-align: center;
  padding: 4px;
  margin: auto;
  position: relative;
  bottom: 30px;
  border: 4px solid #fff;
}

.live-subject-circle icon {
  font-size: 30px;
  margin: 8px;
  color: #fff;
}

.live-subject-circle img {
  width: 37px;
  height: 37px;
  margin: auto;
  display: block;
}

.live-btn {
  background-color: #ff1b1b;
  font-size: 11px;
  font-family: "quiz-sec-font-reg";
  width: 62px;
  border-radius: 50px;
  color: #fff;
  position: relative;
  z-index: 500;
  height: 26px;
  padding: 7px 25px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}

.liveclass-subject-detail p {
  font-size: 14px;
  font-family: "stepapp-secondary-regular", sans-serif;
  font-weight: 600;
  margin-bottom: 5px;
}

.liveclass-teacher-info p {
  font-size: 14px;
  font-family: "stepapp-secondary-regular", sans-serif;
  margin-bottom: 5px;
  color: #fff;
}

.teacher-image {
  position: absolute;
  bottom: 0px;
  right: 20px;
}

.teacher-info-wrapper {
  background-color: #47bd76;
  margin-top: -8px;
}

.liveclass-subject-detail {
  padding: 10px;
}

.liveclass-teacher-info {
  padding: 10px;
}

.liveclass-subject-info p {
  font-family: "Poppins", sans-serif;
  padding: 10px;
}

.liveclass-row {
  height: 10px;
}

.liveclass-btn {
  width: 100%;
  max-width: 170px;
  height: 40px;
  border-radius: 50px;
  background-color: #47bd76;
  color: #fff;
  border: none;
  font-weight: 600;
  font-family: "stepapp-secondary-bold", sans-serif;
}

.liveclass-join-btn {
  position: relative;
  bottom: 13px;
  text-align: center;
}

.chapter-header-new {
  top: 50px !important;
  min-height: 100vh !important;
}

.token-login-bignav {
  top: 0px !important;
  min-height: calc(100vh - 0px) !important;
}

.token-login-bignav .text-secn-nav {
  padding-top: 20px;
}

.token-login-quiz {
  top: 0px !important;
  min-height: calc(100vh - 0px) !important;
}

.content-vid-flex {
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
}

.lessons-title-video {
  line-height: 40px;
  text-decoration: underline;
  font-family: "Poppins", sans-serif;
}

.bookmark-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.island-tabs-sel {
  border-bottom: 4px solid #fc8643;
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 16px;
  font-weight: 200 !important;
  color: #fc8643;
  height: 40px;
}

.island-tabs {
  border-bottom: none;
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 16px;
  font-weight: 200 !important;
  color: #000;
}

.scholarship-card {
  background-color: #6c757d;
  border-radius: 10px;
  cursor: pointer !important;
  margin: 10px;
  width: 100px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 1s ease;
  transform: scale(1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.scholarship-card .scholarship-label {
  width: 100px;
  height: 70px;
  color: #fff;
  border: none;
  border-radius: 10px;
  text-align: center;
  position: relative;
  opacity: 1;
  font-size: 14px;
  font-family: "stepapp-secondary-regular", sans-serif;
  border: 0px solid transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-transform: capitalize;
}

.scholarship-card .scholarship-label input[type="radio"]:checked+label {
  border: 3px solid #fc8643;
  color: #fff;
  opacity: 1;
  font-family: "stepapp-secondary-bold", sans-serif;
  background: #fc8643;
  width: 100px;
  height: 70px;
  margin-bottom: 0px !important;
}

.scholarship-card .scholarship-label input[type="radio"]:checked+label::after {
  font-family: fontello;
  content: "";
  height: 25px;
  width: 25px;
  background: url("../images/check.svg");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 20px;
  z-index: 999;
  position: absolute;
  top: -4px !important;
  right: -6px !important;
}

.scholarship-card:hover {
  transform: scale(1.1);
  transition: all 1s ease;
}

.date-white {
  font-size: 22px;
  color: #fff;
}

#content {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  align-items: center;
  justify-self: center;
  text-align: center;
  display: flex;
}

#bottom-card {
  width: 100%;
}

#onboarding-content-card {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container {
  padding: 0px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.loginCard {
  background-color: transparent;
  border-radius: 30px;
  padding: 20px 0px 30px 0px;
  margin: 0px;
  width: 550px;
}

.cardtitle {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #fc8643;
  text-align: center;
  width: 100%;
  height: 75px;
  line-height: 102px;
  font-weight: 400 !important;
}

.card-button {
  background-color: #fc8643;
  border-radius: 50px;
  width: 100%;
  box-shadow: none;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #fff;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-with-button {
  height: 40px;
  text-align: center;
  border: 1px solid transparent !important;
  color: #000 !important;
  width: 75%;
  float: left;
  border-radius: 50px 0px 0px 50px;
  background-color: #fff;
}

.input-with-button:hover {
  background-color: #fff;
  color: #000;
  text-decoration: none;
  border: none;
}

.input-with-button:active,
.input-with-button:focus {
  background-color: #caccce;
  color: #000 !important;
}

.input-with-button:visited {
  background-color: #fff;
  color: #000 !important;
}

.card-button-join {
  background-color: #fc8643 !important;
  border-radius: 0px 50px 50px 0px;
  width: 25%;
  box-shadow: none;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #fff;
  height: 40px;
  float: left;
  text-align: center;
  margin: auto;
}

.time {
  font-family: "stepapp-secondary-bold", sans-serif;
  font-size: 30px;
  font-weight: 600px;
}

.cus_link_lineheight {
  line-height: 50px;
}

.select-label-item-flex {
  width: 100px;
  height: 100px;
  padding: 5px 10px;
  font-size: 20px;
}

.language-item-flex {
  width: 200px;
  padding: 5px 10px;
  font-size: 20px;
}

.boards-item-flex {
  width: 130px;
  padding: 5px 10px;
  font-size: 14px;
  margin: 10px;
}

.grade-item-flex {
  width: 100px;
  padding: 5px 10px;
  font-size: 20px;
}

.icon-next2 {
  font-size: 26px;
  vertical-align: middle;
  margin: auto;
}

.grade-item-flex {
  width: 100px;
  height: 100px;
  padding: 5px 10px;
  font-size: 20px;
}
.img-border-radius {
  border-radius: 10px;
}

.game-rail {
  position: relative;
  width: 273px;
  height: 150px;
  background: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 40px;
  cursor: pointer;
}

.game-rail:before,
.game-rail:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: #caccce;
  transition: 0.5s;
  z-index: -1;
}

.game-rail:hover:before {
  transform: rotate(10deg);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
}

.game-rail:hover:after {
  transform: rotate(5deg);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
}

.game-rail .imgBx {
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  background: #fff;
  transition: 0.5s;
  z-index: 1;
}

.game-rail:hover .imgBx {
  bottom: 10px;
}

.game-rail .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

/*---added for ask doubt---*/
.svg-shadow {
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.2));
  /* Similar syntax to box-shadow */
}

.askdoubt-subject-card {
  border-radius: 10px;
  padding: 10px;
  cursor: pointer !important;
  margin: 15px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 1s ease;
  transform: scale(1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.askdoubt-subject-card .askdoubt-subject-icon {
  font-size: 30px;
}
.askdoubt-subject-card .askdoubt-subject-label {
  width: 100px;
  background: transparent !important;
  color: #fff;
  border: none;
  border-radius: 0px;
  text-align: center;
  position: relative;
  opacity: 1;
  font-size: 14px;
  font-family: "stepapp-secondary-regular", sans-serif;
  border: 0px solid transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-transform: capitalize;
}
.askdoubt-subject-card
  .askdoubt-subject-label
  input[type="radio"]:checked
  + label {
  border: 3px solid #15388c;
  color: #fff;
  opacity: 1;
  font-family: "stepapp-secondary-bold", sans-serif;
  background: #fc8643;
}
.askdoubt-subject-card
  .askdoubt-subject-label
  input[type="radio"]:checked
  + label::after {
  font-family: fontello;
  content: "";
  height: 30px;
  width: 30px;
  background: url("../images/check.svg");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 30px;
  z-index: 999;
  position: absolute;
  top: -50px !important;
  right: -9px !important;
}
.askdoubt-subject-card:hover {
  transform: scale(1.1);
  transition: all 1s ease;
}

.btn-border-radius {
  height: 40px;
  font-size: 16px;
  line-height: 28px;
  border-radius: 10px;
  cursor: pointer;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-weight: 600;
}
.btn-border-radius .btn-basic:hover {
  border: none;
  outline: none !important;
}
.btn-border-radius .btn-basic:focus {
  border: none;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.solution-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
}

.solution-header {
  height: 70px;
  background-color: #ffff;
  font-family: "stepapp-secondary-regular", sans-serif;
  background-color: #fff;
  color: #000;
  padding: 5px 15px;
  text-align: left;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  border-bottom: 1px solid #caccce;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.solution-content {
  height: calc(100vh - 140px);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f3f6f8;
  padding: 25px;
}
@media (max-width: 991.98px) {
  .solution-content {
    height: calc(100vh - 200px);
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.solution-content-chat {
  height: calc(100vh - 70px);
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: #f3f6f8;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991.98px) {
  .solution-content-chat {
    padding: 3px;
  }
}

.tutor-chat {
  height: calc(100vh - 70px);
  width: 100%;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  .tutor-chat {
    width: 100vw;
  }
}

.detail-room-show {
  width: 100vw !important;
}

.details-room {
  height: 100%;
  width: 350px;
  background: #fcffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  border-left: 1px solid #caccce;
}

@media (max-width: 991.98px) {
  .details-room {
    position: fixed;
    left: 0;
    top: 70px;
    width: 100vw !important;
    background: #fcffff;
    height: 100%;
    border-left: 1px solid #caccce;
    visibility: hidden;
    transform: translateX(100%);
    z-index: 99;
  }

  .details-room-show {
    visibility: visible;
    transform: translateX(0);
  }
}
.mod-height {
  height: calc(100% - 56px) !important;
  overflow-y: auto !important;
}
@media (max-width: 991.98px) {
  .mod-height {
    height: calc(100% - 106px) !important;
  }
}

.solution-footer {
  height: 71px;
  padding: 0px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #caccce;
}
@media (max-width: 991.98px) {
  .solution-footer {
    padding: 3px;
  }
}

.content-header {
  border-bottom: 1px solid #caccce;
  height: 40px;
  padding: 10px 20px;
}

.sol-num {
  border-radius: 50%;
  min-width: 25px;
  height: 25px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6c757d;
  margin-right: 5px;
  font-size: 12px;
  font-weight: 800;
  border: 0.5px solid #caccce;
  font-family: "stepapp-secondary-bold", sans-serif;
}

.sol-blank {
  border-radius: 50%;
  min-width: 25px;
  height: 25px;
  line-height: 25px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6c757d;
  margin-right: 5px;
  font-size: 12px;
  border: 0.5px solid transparent;
}

.sol-content-title {
  font-family: "stepapp-secondary-bold", sans-serif;
  font-size: 18px;
  display: flex;
}

.step-by-step {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 14px;
  text-transform: capitalize;
  color: #fc8643;
  font-weight: 600;
  line-height: 30px;
  border-bottom: 3px solid #fc8643;
}

.sol-content-question {
  font-family: "stepapp-secondary-bold", sans-serif;
  font-size: 16px;
  padding: 0px 15px;
  text-transform: capitalize;
  font-weight: 700;
}

.sol-content-card {
  border-radius: 10px;
  background-color: #fff;
  font-size: 14px;
  padding: 10px;
  min-height: 50px;
  margin: 0px 15px;
  flex: 1;
  text-transform: capitalize;
}

.content-solution {
  padding: 20px;
}

.answer-step {
  border: 0.2px solid #caccce;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.answer-text {
  margin: 10px;
}

.answer-image {
  border-radius: 5px;
  border: 1px solid #caccce;
  margin: 5px 0px;
  padding: 5px;
}

.list-question {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  flex-direction: column;
}

.list-question-text {
  margin: 5px;
}

.list-question-image {
  margin: 5px;
  border: 0.5px solid #caccce;
  border-radius: 10px;
}

.question-image {
  border: 0.5px solid #caccce;
  border-radius: 10px;
  width: auto !important;
  height: 30px !important;
}

/* Timeline holder */
ul.timeline {
  list-style-type: none;
  position: relative;
  padding-left: 25px !important;
}

/* Timeline vertical line */
ul.timeline:before {
  content: " ";
  background: #fc8643;
  display: inline-block;
  position: absolute;
  left: 16px;
  width: 4px;
  height: 100%;
  z-index: 400;
  border-radius: 1rem;
}

li.timeline-item {
  margin: 20px 0;
}

/* Timeline item arrow */
.timeline-arrow {
  border-top: 1rem solid transparent;
  border-right: 1rem solid #fff;
  border-bottom: 1rem solid transparent;
  display: block;
  position: absolute;
  left: 2rem;
}

/* Timeline item circle marker */
li.timeline-item::before {
  content: " ";
  background: #fc8643;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #fff;
  left: 11px;
  width: 14px;
  height: 14px;
  z-index: 400;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.display-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 10px;
}

.upload-img {
  display: block;
  width: auto !important;
  height: 100px !important;
  margin: auto;
  overflow: hidden;
  margin: auto;
}

.upload-img svg {
  fill: red;
}

.content-select-subjects {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.content-upload-doubt {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
}

/*----for ask doubt cards-----*/
.ask-doubt-card {
  font-family: "stepapp-secondary-regular", sans-serif;
  background-color: #fff;
  color: #000;
  padding: 15px;
  text-align: left;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  border-bottom: 1px solid #caccce;
}

.ask-doubt-card:last-child {
  border-bottom: none;
}

.ask-doubt-card-sel {
  font-family: "stepapp-secondary-regular", sans-serif;
  background-color: #e6ebf5;
  color: #000;
  padding: 15px;
  text-align: left;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  border-bottom: 1px solid #caccce;
}

.askdoubt-list-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: start;
}

.rtpane-h-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.rtpane-h-item-flex {
  display: flex;
  justify-content: start;
  align-items: center;
}

.rtpane-h-item-flex-text {
  display: flex;
  justify-content: start;
  align-items: center;
  text-transform: capitalize;
}

@media (max-width: 991.98px) {
  .rtpane-h-item-flex-text {
    word-break: break-word;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
  }
}

.question-flex {
  padding-left: 3px;
  margin: 0px;
  text-transform: capitalize;
}

.askdoubt-date {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 11px;
}

.sidebar-flex {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.left-pane-header {
  height: 40px;
  line-height: 40px;
  border-bottom: 1px dotted grey;
  display: flex;
  justify-content: center;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background-color: #fff;
  z-index: 10;
}

.left-pane-content {
  height: calc(100vh - 140px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

@media (max-width: 991.98px) {
  .left-pane-content {
    height: calc(100% - 163px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.left-pane-content-without-footer {
  height: calc(100vh - 70px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

@media (max-width: 991.98px) {
  .left-pane-content-without-footer {
    height: calc(100vh - 148px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.left-pane-title {
  font-family: "stepapp-secondary-bold", sans-serif;
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 20px;
  border-bottom: 1px solid #caccce;
  height: 70px;
  line-height: 70px;
  width: 100%;
  padding: 0px 20px;
}

@media (max-width: 991.98px) {
  .left-pane-title {
    height: 40px;
    line-height: 40px;
  }
}

.left-pane-footer {
  width: 100%;
  padding: 0px 20px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 0.5px solid #caccce;
}

.left-pane-desc {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 12px;
  padding-bottom: 10px;
}

.askdoubt-subject-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

/*-----------added for ask doubt-----*/
.subject-icon-askdoubt {
  height: 32px;
  width: 32px;
  line-height: 32px;
  border: none;
  border-radius: 5px;
  text-align: center;
  position: relative;
  opacity: 1;
  font-size: 20px;
  font-family: "quiz-sec-font-reg";
  margin: 0px 5px;
  background-color: #996fd6;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textbx-disabled {
  opacity: 0.1;
}

/*-----------added to add teachers to doubt room------------*/
.teacher-list-header {
  border-bottom: 1px solid #caccce;
  min-height: 40px;
  height: auto;
  padding: 10px 20px;
}

.teacher-list-content {
  padding: 20px;
  height: calc(100vh - 250px);
  overflow-y: auto;
}

@media (max-width: 991.98px) {
  .teacher-list-content {
    height: calc(100vh - 375px);
  }
}

.teacher-selection-flex {
  display: flex;
  width: 100%;
  justify-content: center;
  /* align-items: flex-start; */
  flex-wrap: wrap;
  flex-direction: row;
}

.teacher-selection-flexbox {
  display: flex;
  margin: 5px auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #dee2e6;
  width: 49%;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;
}

@media (max-width: 991.98px) {
  .teacher-selection-flexbox {
    width: 250px;
  }
}

.teacher-selection-flexbox-sel {
  display: flex;
  margin: 5px auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: bisque;
  width: 49%;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;
}

@media (max-width: 991.98px) {
  .teacher-selection-flexbox-sel {
    width: 250px;
  }
}

.teacher-flex-img {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fff;
  text-transform: uppercase;
}

.teacher-flex-name {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-weight: 600;
  text-align: left;
  flex: 1;
  padding-left: 10px;
}

.teacher-flex-add {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fff;
}

.user-chat {
  flex: 1;
}

.info-icon-chat {
  background-color: #fc8643;
  border-radius: 5px 0px 0px 5px;
  position: absolute;
  top: 80px;
  right: 0px;
  width: 30px;
  height: 30px;
  display: none;
  justify-content: center;
  align-items: center;
  color: #fff;
}

@media (max-width: 991.98px) {
  .info-icon-chat {
    display: flex;
  }
}

.filter-bg {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 20px;
  color: #000;
}

.custom-drop {
  width: 215px !important;
  right: 0px !important;
}

.doubt-subject-list-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
}

.doubt-subject-flex {
  width: auto;
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
}

/************************Gayatri CSS*************************/
/***********************Practice Details***************/
.quiz-reports-flex-circle {
  width: 100px;
  border-radius: 10px;
  height: 100px;
  margin: 10px;
  padding: 10px;
  color: #fff;
  border-radius: 50%;
  background-color: #fff;
}

.quiz-questions {
  background: #fff;
}

.quiz-timer {
  background: #f2f2f2;
}

.quiz-subject {
  background: #17bd59;
}

.quiz_info_card {
  background-color: #f4f4f4;
  border-radius: 10px;
  padding: 0px;
  cursor: pointer;
  min-height: 160px;
  color: #000;
}

.quiz-reports-flex-circle-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

#quiz-player-head .icon-plus:before {
  transform: rotateZ(45deg);
}

.quiz-reports-flex-circle-wrap icon {
  font-size: 80px;
}

/* .quiz-reports-flex-circle-wrap::after{
position: absolute;
content: '';
width: 1px;
height: 118%;
background: #000;
right: -45px;
} */
.quiz-header {
  font-size: 22px;
  font-weight: 600;
  font-family: "quiz-sec-font";
}

.assessment-instruction-wrap {
  text-align: left;
}

.assessment-instruction-wrap li p {
  font-family: "quiz-sec-font-reg";
  color: #000;
}

.assessment-instruction-wrap ul {
  list-style: none;
  text-align: left;
  padding-left: 0px !important;
}

.settings-row {
  padding-top: 20px;
}

/*--------for correct and wrong answers---------------*/
/*--------for Single Select ---------------*/
.quiz-img {
  height: 35vh;
  width: auto;
}

.select-theme-square {
  width: 130px;
  height: 150px;
  border-radius: 10px;
  margin: 10px;
}

.select-theme-square .select-label-r {
  height: 30vh;
  line-height: 30vh;
  display: block;
  color: #fff;
  border: none;
  border-radius: 10px;
  text-align: center;
  position: relative;
  opacity: 1;
  font-size: 26px;
  font-family: "stepapp-secondary-regular", sans-serif;
  border: 3px solid transparent;
}

.select-theme-square input[type="radio"]:checked+label {
  border: 3px solid #1b1f5f;
  color: #000;
  opacity: 1;
  font-family: "quiz-sec-font";
  background: transparent;
  border: none;
}

.quiz-reports-flex-circle .small-sub-title {
  text-align: center !important;
  font-size: 14px;
}

.quiz-reports-flex-circle .text-subject-big {
  font-size: 35px;
  line-height: auto;
  text-align: center !important;
  font-weight: 600;
}

.check-trail {
  display: flex;
  align-items: center;
  width: 68px;
  height: 32px;
  background-color: #FF4217;
  border-radius: 2.5em;
  transition: all 0.5s ease;
  cursor: pointer;
  margin-bottom: 0px !important;  
  box-shadow: inset 0px 6px 8px 3px rgba(0, 0, 0, 0.1);
}

.check-trail input[type="checkbox"]:checked {
  background-color: #76EE59;
  width: 68px;
  height: 32px;
}

.check-trail input[type="checkbox"]:checked+.check-handler {
  margin-left: 55%;
  background: #fff;
}
.check-trail input[type="checkbox"]:checked + .check-handler::after {
  content: "✔";
  color: #46b353;
}

.check-success {
  background-color: #17bd59 !important;
}

.check-handler {
  display: flex;
  margin-left: 5%;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  background: #f4f4f4;
  border-radius: 50%;
  transition: all 0.5s ease;
  box-shadow: inset 0px 6px 8px 3px rgba(0, 0, 0, 0.1);
  
}

.check-handler:after {
  /* content: "×";
  color: #514748; */
  font-size: 21px;
  font-weight: bold;
}

input[type="checkbox"]:checked + .check-trail {
  background: linear-gradient(
    89.97deg,
    rgba(248, 167, 11, 0.98) 9.58%,
    #ff4e4e 89.6%
  );
}

input[type="checkbox"]:checked+.check-trail .check-handler {
  margin-left: 55%;
  background: #fff;
}

input[type="checkbox"]:checked + .check-trail .check-handler::after {
  content: "✔";
  color: #46b353;
}

.setting-checkbox {
  margin: auto;
  width: 100%;
}

.bdr-bottom {
  border-bottom: 0.5px solid #caccce;
}

.join-btn {
  width: auto;
  padding: 0px 30px;
  height: 56px;
  border-radius: 50px;
  color: #fff;
  background: #a04610;
  border: 1px solid #9a2929;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-size: 20px;
}

.join-btn:hover {
  border: none;
  background-color: #72320b;
}

.join-btn:active {
  border: none;
}

.join-btn:visited {
  border: none;
}

/*----------for sidepanel drawer----*/
.ant-drawer-content {
  position: relative;
  z-index: 1;
  overflow: auto;
  background-color: #f57b76;
  background-clip: padding-box;
  border: 0;
  font-family: "quiz-sec-font";
}

.ant-drawer-header {
  position: relative;
  padding: 16px 24px;
  color: #fff;
  background-color: #fcac5e;
  border-bottom: 1px solid #d08b49;
  border-radius: 2px 2px 0 0;
  font-family: "quiz-sec-font";
}

.ant-drawer-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.ant-drawer-body {
  padding: 14px;
}

.ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #e9e9e9;
  height: auto;
  max-height: 100vh;
  border-radius: 15px 0px 0px 15px;
}

/******************Island Quiz CSS********************/
.bottom-images {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 0;
}

.bottom-images img {
  width: 75%;
}

/* .question-card {
  border-radius: 20px;
  min-height: 240px;
  /* height: 400px; */
/* height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-items: center;
  /* height: auto; */
/* background-color: #fff; */
/* background-color: #632626;
  position: relative;
  width: 90vw;
  background-size: cover;
  background-position: 50%;
  flex-direction: column; */
/*} */

.question-card {
  border-radius: 20px;
  /* min-height: 240px;  */
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  /* height: auto; */
  border-radius: 20px;
  background-color: #7c3131;
  position: relative;
  /* background-color: #fff; */
  width: 90vw;
  border-radius: 20px;
  background-size: cover;
  background-position: 50%;
  flex-direction: column;
}
.island-theme-1 .question-card-bg {
  background-color: #7d486e;
}

.island-theme-2 .question-card-bg {
  background-color: #42024c;
}

.island-theme-3 .question-card-bg {
  background-color: #022d4c;
}

.island-theme-4 .question-card-bg {
  background-color: #024c4c;
}

.island-theme-5 .question-card-bg {
  background-color: #da6d6d;
}

.island-theme-default .question-card-bg {
  background-color: #4c0212;
}

.island-theme-maths .question-card-bg {
  background-color: #4c0212;
}

.island-theme-chemistry .question-card-bg {
  background-color: #022d4c;
}

.island-theme-physics .question-card-bg {
  background-color: #8d5813;
}

.island-theme-biology .question-card-bg {
  background-color: #024c4c;
}



.question-card-custom-shadow {
  box-shadow: none !important;
}

.question-select-label-true {
  height: 50px;
  line-height: 45px;
  display: block;
  background: #17bd59;
  color: #fff;
  border: none;
  border-radius: 50px;
  text-align: center;
  position: relative;
  opacity: 1;
  font-size: 22px;
  font-family: "stepapp-secondary-regular", sans-serif;
  border: 3px solid transparent;
}

.language-item-flex input[type="radio"]:checked+label {
  border: 2px solid #fff;
}

.question-select-label-false {
  height: 50px;
  line-height: 45px;
  display: block;
  background: #f16b24 !important;
  color: #fff;
  border: none;
  border-radius: 50px;
  text-align: center;
  position: relative;
  opacity: 1;
  font-size: 22px;
  font-family: "stepapp-secondary-regular", sans-serif;
  border: 3px solid transparent;
}

.language-item-flex .select-label-false:hover {
  background-color: #fa8181 !important;
}

.question-timer {
  border-radius: 50px;
  width: 121px;
  height: 35px;
  background-color: #fff;
  color: #fff;
  margin: auto;
  font-size: 40px;
  line-height: 35px;
  color: #f16b24;
}

.question-timer-text {
  font-size: 18px;
  font-family: "stepapp-secondary-medium", sans-serif;
  float: left;
  text-align: left;
  line-height: 50px;
  padding-left: 15px;
}

.question-card label:hover {
  background-color: #6fda92;
}

.question-card label:active {
  background-color: #248c46;
}

.question-step1 {
  width: 25%;
  padding: 0px;
  margin: 0px 5px;
  display: block;
}

.question-step2 {
  width: 25%;
  padding: 0px;
  margin: 0px 5px;
}

.character-img img {
  position: absolute;
  bottom: 10px;
  left: 10%;
}

.ant-drawer-body .text-drawer {
  color: #000;
}

.text-drawer-font {
  color: #000;
  font-size: 10px;
}

/***************************Theme**********************/
/* .theme-block{
  width: 120px;
    height: 150px;
    background: #000;
    border-radius: 15px;
    margin: 5px;
} */
/* .theme-block-2 input[type="radio"]:checked + label::after #quiz-player{
  background: linear-gradient(90deg, #00FFF0 0%, #0083FE 100%);
} */
.theme-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.theme-block {
  margin: 5px;
}

.select-theme {
  height: 52px;
  display: block;
  color: #fff;
  border-radius: 10px;
  text-align: center;
  position: relative;
  opacity: 1;
  width: 105px;
  margin: 30px auto;
}

.theme-block input[type="radio"]:checked+label::after {
  font-family: fontello;
  content: "";
  height: 30px;
  width: 30px;
  background: url("../images/check.svg");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 27px;
  z-index: 999;
  position: absolute;
  top: -15%;
  right: 37%;
}

/**************Challenge Theme*************************/
.subject-detail-wrapper {
  width: 100%;
  background: #f4f4f4;
  border-radius: 10px;
  min-height: 240px;
  /* background-image: url(../images/body-movement.svg); */
  background-repeat: no-repeat;
  background-size: cover;
}

.subject-challenge-wrapper {
  width: 100%;
  background: #f4f4f4;
  border-radius: 10px;
  height: 100%;
}

.subject-challenge-wrapper .practice-center {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  height: 200px;
}

.subject-detail-wrapper .subject-detail-header {
  color: #fff;
  padding: 10px 20px 4px 19px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.subject-detail-wrapper .subject-detail-header h6 {
  color: #fff;
  font-family: "quiz-title-font";
  text-align: center;
}

.challenge-detail-section span input {
  width: 100%;
  margin: auto;
  background: #ffffff !important;
  height: 50px;
  border-radius: 50px;
  width: 100%;
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: revert;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: 600;
  color: #000;
}

.practice-detail-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.bdr-bottom {
  border-bottom: 0.5px solid #caccce;
}

.setting-label {
  padding-left: 15px !important;
  width: 100%;
}

.MJXp-display {
  display: none !important;
}

.mjx-chtml {
  white-space: break-spaces !important;
  word-break: break-word !important;
  font-family: "quiz-sec-font-reg" !important;
}

.MJXc-TeX-math-I {
  font-family: "quiz-sec-font-reg" !important;
  font-style: normal;
  font-size: 19px;
}

.mjx-chtml .mjx-noError {
  border: none !important;
}

.mjx-chtml .mjx-noError {
  text-align: center !important;
}

/**************Rail Quiz Intermediate****************/
.quiz-subject-wrapper {
  height: 200px;
  margin: auto;
  text-align: center;
}

.quiz-subject-wrapper img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 180px;
  width: auto;
  padding-top: 5px;
}

/*****************Competitive Exams**************/
.competitive-rail-wrapper {
  min-height: 560px !important;
  background-color: #fff !important;
}

.competitive-exam-circle-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.competitive-exam-circle-wrap p {
  margin-bottom: 0px;
}

.competitive-exam-detail-wrapper .mcq-title {
  position: relative;
}

.competitive-exam-detail-wrapper .mcq-title:after {
  content: "";
  position: absolute;
  width: 94%;
  height: 1px;
  background: rgba(0, 0, 0, 0.5);
  top: 28px;
  left: 0;
}

.competitive-exam-mcq-detail {
  position: relative;
}

.competitive-exam-mcq-detail:after {
  content: "";
  position: absolute;
  width: 94%;
  height: 1px;
  background: rgba(0, 0, 0, 0.5);
  bottom: -6px;
  left: 0;
}

.competitive-exam-mcq-detail:last-child::after {
  display: none;
}

.competitive-exam-detail-wrapper li {
  list-style: none;
}

.competitive-exam-detail-wrapper li:before {
  content: "";
  background-color: red;
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

.flip-card {
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  min-height: 309px !important;
  margin-bottom: 10px;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: transparent;
}

.flip-card-back {
  background-color: #fff;
}

.tab-pane {
  color: #000;
}

.subject-detail .nav-tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: auto;
  margin-top: 27px;
}

.subject-detail ul {
  list-style: none;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  margin: auto;
  margin-top: 25px;
}

.subject-detail ul li:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  background: #c4c4c4;
  left: 16%;
  margin: 7px;
}

/**************Competitive Exams**************/
.back-arrow-btn {
  border-radius: 5px;
  color: #000;
  background-color: transparent;
  height: 40px;
  width: 40px;
  border-color: transparent;
}

.competitive-quiz-title {
  line-height: 40px;
  color: #000;
  font-size: 19px;
  font-weight: 600;
}

.competitive-quiz-timer {
  line-height: 40px;
  color: #000;
  font-size: 26px;
  font-weight: 600;
}

.competitive-quiz-player-head {
  background: #f2f2f2;
  margin-bottom: 20px;
  height: 14% !important;
}

.competitive-head {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.competitive-head-inner {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.competitive-subject-quiz .nav-tabs {
  border-bottom: none;
}

.competitive-submit-btn {
  color: #fff;
  border: none;
  background: #ff6700;
  height: 40px;
  font-size: 14px;
  line-height: 25px;
  border-radius: 10px;
  height: 40px;
  padding: 0px 20px;
}

.competitive-status-btn {
  color: #fff;
  border: none;
  background-color: #000;
  height: 40px;
  font-size: 14px;
  line-height: 25px;
  border-radius: 5px;
  height: 40px;
  padding: 0px 20px;
}

/***************Will be used for competitive exams side drawer***************/
#CommonPageHolder-quiz-bg {
  height: 100vh;
  width: 100% !important;
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
  padding: 0px 0px 30px 0px !important;
  margin: 0px;
}

.quiz_bg {
  background-color: #000;
  width: 100%;
  padding: 15px;
}

.quiz2 {
  background-color: #000;
  color: #fff;
  line-height: 36px;
  width: 100%;
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 16px;
  padding: 0px;
}

.quiz-nav {
  border-bottom: 1px solid #4e54c2;
}

.quiztitle {
  font-family: "quiz-title-font";
  font-size: 18px;
  color: #000;
  text-align: left;
  width: 100%;
}

.quiz_item_flex {
  height: 210px;
  padding: 0px 0px 0px 0px;
  margin: 20px;
  border-radius: 10px;
  transition: all 1s ease;
  transform: scale(1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.quiz_item_flex:hover {
  transform: scale(1.1);
  transition: all 1s ease;
}

.practicequiz-card {
  background-color: #fff;
  cursor: pointer;
  padding: 0px;
}

.quiz_item_leaderboard {
  width: 350px;
  height: 210px;
  padding: 0px 0px 0px 0px;
  margin: 20px;
  border-radius: 10px;
}

.arrow-pos {
  position: relative;
  bottom: -29px;
}

.quiz-card-header {
  font-family: "quiz-title-font";
  font-size: 14px;
  color: #000;
  text-align: center;
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #caccce;
}

.leaderboard-title {
  font-family: "quiz-sec-font";
  font-size: 12px;
  color: #000;
  text-align: center;
  width: 100%;
  line-height: 27px;
}

.leaderboard-num {
  font-family: "quiz-title-font";
  font-size: 30px;
  color: #fc8643;
  margin-top: 5px;
}

.leaderboard-score {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.qscore-flex {
  width: 175px;
}

.quiz-sec-font {
  font-family: "quiz-sec-font";
  font-size: 18px;
  color: #000;
  text-align: center;
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #6c757d;
  margin-bottom: 20px;
}

.activity-title {
  font-family: "quiz-sec-font";
  font-size: 16px;
  text-align: left;
  width: 300px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 20px;
  padding-left: 5px;
}

.quiz_activity_item_flex {
  background-color: #f4f4f4;
  border-radius: 10px;
  padding: 0px;
  margin: 10px;
  cursor: pointer;
  flex: 1 1 auto;
  position: relative;
  height: 234px;
  transition: all 2s ease;
  transform: scale(1);
}

.bg-count-maths {
  background-color: #e96969;
  font-size: 11px;
  font-family: "quiz-sec-font-reg";
  padding: 3px 15px;
  width: 200px;
  border-radius: 50px;
  color: #fff;
  position: relative;
  bottom: -10px;
  z-index: 500;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.bg-grade {
  background-color: #e96969;
  font-size: 11px;
  font-family: "quiz-sec-font-reg";
  padding: 3px 15px;
  width: 160px;
  border-radius: 50px;
  color: #fff;
  position: absolute;
  z-index: 500;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  top: 54px;
  margin: auto;
  text-align: center;
  left: 0;
  right: 0;
}

.bg-grade-2 {
  background-color: #e96969;
  font-size: 11px;
  font-family: "quiz-sec-font-reg";
  padding: 3px 15px;
  width: 160px;
  border-radius: 50px;
  color: #fff;
  position: absolute;
  z-index: 500;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  top: -5px;
  margin: auto;
  text-align: center;
  left: 0;
  right: 0;
}

.practice-card-info {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  min-height: 100px;
}

/*---practice quiz card-----*/
.infotitle {
  font-family: "quiz-sec-font";
  font-size: 16px;
  line-height: 18px;
  text-align: left;
  padding-bottom: 5px;
}

.rail-infotitle {
  font-family: "quiz-sec-font";
  font-size: 16px;
  line-height: 18px;
  text-align: left;
  width: 220px;
  padding-bottom: 5px;
}

.quiz-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.infotitle-message {
  font-family: "quiz-sec-font-reg";
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  padding-bottom: 5px;
}

.infotitle-reg {
  font-family: "quiz-sec-font-reg";
  font-size: 12px;
  text-align: left;
  width: 300px;
}

.scorecard-Maths {
  border-left: 4px solid #e96969;
  padding-bottom: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.scorecard-Chemistry {
  border-left: 4px solid #e96969;
  padding-bottom: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.scorecard-Biology {
  border-left: 4px solid #17bd59;
  padding-bottom: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.scorecard-Physics {
  border-left: 4px solid #e4ac13;
  padding-bottom: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.scorecard-Chemistry {
  border-left: 4px solid #3396be;
  padding-bottom: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.border-bottom {
  border-bottom: 1px solid #6c757d;
}

.score-title-sub {
  font-family: "quiz-sec-font";
  font-size: 20px;
  text-transform: uppercase;
}

.score-num {
  font-family: "quiz-title-font";
  font-size: 20px;
  margin-top: 5px;
}

/*-----quiz player mode-------*/
#quiz-player {
  height: 100vh;
  display: block;
  padding: 0px;
  margin: 0px;
  width: 100vw;
}

#quiz-player-head {
  height: 70px;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quiz-head-bg {
  border-radius: 5px;
  color: #fff;
  background-color: #000;
  margin: 0px 8px;
  height: 40px;
  line-height: 40px;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quiz-head-bg .icon {
  line-height: 40px;
}

.quiz-head-hint {
  border-radius: 5px;
  color: #fff;
  background-color: #a04610;
  margin: 0px 5px;
  height: 40px;
  line-height: 40px;
  padding: 0px 5px;
}

.quiz-head-bg-progress {
  border-radius: 5px 0px 0px;
  color: #fff;
  height: 40px;
  line-height: 40px;
  padding: 0px 5px;
}

.quiz-head-bg-progress .progress {
  border-radius: 50px;
  display: flex;
  height: 40px;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #000;
  padding: 10px;
}

.quiz-head-bg-progress .progress-bar-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #17bd59;
  transition: width 0.6s ease;
  border-radius: 50px;
}

.quiz-head-bg-progress .progress-bar-wrong {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #e96969;
  transition: width 0.6s ease;
  border-radius: 50px;
}

.right-count {
  height: 60px;
  width: 60px;
  background-color: #17bd59;
  color: #fff;
  border-radius: 50%;
  position: relative;
  top: -53px;
  right: -5px;
  float: right;
  text-align: center;
  line-height: 60px;
}

.wrong-count {
  height: 60px;
  width: 60px;
  background-color: #e96969;
  color: #fff;
  border-radius: 50%;
  position: relative;
  top: -53px;
  right: -5px;
  float: right;
  text-align: center;
  line-height: 60px;
}

.action-quiz {
  border-radius: 5px;
  color: #fff;
  background-color: #000;
  height: 40px;
  width: 40px;
  box-shadow: none !important;
  border: none !important;
}

.action-quiz:hover {
  background-color: #119044;
  border: none;
}

.action-quiz:active,
.action-quiz:focus {
  background-color: #fc8643;
}

.action-quiz:visited {
  background-color: #17bd59;
}

.action-quiz-checked {
  border-radius: 5px;
  color: yellow;
  background-color: #000;
  height: 40px;
  width: 40px;
}

.action-quiz-checked:hover {
  color: yellow;
  background-color: #000;
}

.action-quiz-checked:active,
.action-quiz-checked:focus {
  color: yellow;
  background-color: #000;
}

.action-quiz-checked:visited {
  color: yellow;
  background-color: #000;
}

.action-time {
  border-radius: 5px;
  color: #fff;
  border: none;
  background-color: #000;
  height: 40px;
  line-height: 40px;
  width: 145px;
  text-align: center;
}

.action-time:hover {
  background-color: #119044;
}

.action-time:active,
.action-time:focus {
  background-color: #fc8643;
}

.action-time:visited {
  background-color: #17bd59;
}

.action-time-disabled {
  border-radius: 5px;
  color: #fff;
  background-color: #6c757d;
  cursor: not-allowed;
  opacity: 50%;
  height: 40px;
  line-height: 40px;
  width: 145px;
  text-align: center;
}

.action-time-disabled:hover {
  background-color: #545b62;
  border: none;
}

.action-time-disabled:active,
.action-time-disabled:focus {
  background-color: #6c757d;
}

.action-time-disabled:visited {
  background-color: #6c757d;
}

.action-time-select {
  border-radius: 5px;
  color: #fff;
  border: none;
  background-color: #000;
  height: 40px;
  line-height: 40px;
  width: auto;
  padding: 0px 20px;
  text-align: center;
}

.action-time-select:hover {
  background-color: #119044;
}

.action-time-select:active,
.action-time-select:focus {
  background-color: #fc8643;
}

.action-time-select:visited {
  background-color: #17bd59;
}

.action-time-select-disabled {
  border-radius: 5px;
  color: #fff;
  background-color: #6c757d;
  cursor: not-allowed;
  opacity: 50%;
  height: 40px;
  line-height: 40px;
  width: auto;
  padding: 0px 20px;
  text-align: center;
}

.action-time-select-disabled:hover {
  background-color: #545b62;
  border: none;
}

.action-time-select-disabled:active,
.action-time-select-disabled:focus {
  background-color: #6c757d;
}

.action-time-select-disabled:visited {
  background-color: #6c757d;
}

.action-status {
  border-radius: 5px;
  color: #fff;
  background-color: #000;
  height: 40px;
  width: 200px;
}

.action-status:hover {
  background-color: #119044;
  border: none;
}

.action-status:active,
.action-status:focus {
  background-color: #fc8643;
}

.action-status:visited {
  background-color: #17bd59;
}

.action-submit {
  border-radius: 5px;
  color: #fff;
  background-color: #000;
  height: 40px;
  width: 145px;
}

.action-submit:hover {
  background-color: #119044;
  border: none;
}

.action-submit:active,
.action-submit:focus {
  background-color: #fc8643;
}

.action-submit:visited {
  background-color: #17bd59;
}

.action-quiz-exit {
  border-radius: 5px;
  color: #fff;
  background-color: #000;
  height: 40px;
  width: 80px;
  font-size: 15px;
  border: none !important;
}

.action-quiz-exit:hover {
  background-color: #119044;
  border: none;
}

.action-quiz-exit:active,
.action-quiz-exit:focus {
  background-color: #fc8643;
}

.action-quiz-exit:visited {
  background-color: #17bd59;
}

.quiz-question-bg {
  background-color: rgba(255, 255, 255, 0.5);
  font-family: "quiz-sec-font";
  font-size: 40px;
  min-height: 35vh;
  border-radius: 5px;
}

.quiz-quest {
  font-family: "quiz-sec-font";
  font-size: 30px;
  color: #000;
  padding: 5px 5px 10px 5px;
}

.quiz-quest mjx-math {
  /*display: inline !important;*/
  font-size: 1.5rem !important;
  /*white-space: break-spaces !important;
  align-self: center !important;
  gap: 6px !important;*/
}


.quiz-item-flex mjx-math {
  /*display: inline !important;*/
  font-size: 1rem !important;
  /*white-space: break-spaces !important;
  align-self: center !important;
  gap: 6px !important;*/
}

.quiz-quest-hint {
  font-family: "quiz-sec-font-reg";
  font-size: 13px;
  width: 300px;
  height: 30px;
  padding: 0px 5px;
  line-height: 30px;
  background-color: #f57b76;
  z-index: 500;
  border-radius: 0px 5px 5px 0px;
  color: #fff;
  text-align: left;
  position: relative;
  top: 5px;
  left: 0px;
}

.quiz-item-flex {
  width: 25%;
  height: 30vh;
  padding: 5px 10px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: stretch;
  justify-self: center;
  border: none;
}

.quiz-item-flex .select-label {
  min-height: 60px;
  height: 30vh;
  width: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-align: center;
  opacity: 1;
  font-size: 26px;
  line-height: 26px;
  font-family: "quiz-sec-font";
  border: 3px solid transparent;
}

.quiz-item-flex .select-label .select-label-img {
  display: flex;
  align-items: stretch;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 10px;
  max-width: 300px;
  height: 30vh;
}

.quiz-item-flex .select-label .select-label-img img {
  padding: 5px;
  border-radius: 10px;
}

.quiz-item-flex input[type="radio"]:checked+label::after {
  display: none;
}

.quiz-item-flex .confetti {
  width: 30px;
  height: 30px;
  position: absolute;
  -webkit-animation: confetti 5s ease-in-out -2s 10px;
  animation: confetti 5s ease-in-out -2s 10px;
  transform-origin: left top;
  top: 0%;
}

.quiz-item-flex .confetti-w {
  width: 30px;
  height: 30px;
  position: absolute;
  -webkit-animation: confetti-w 5s ease-in-out -2s -10px;
  animation: confetti-w 5s ease-in-out -2s -10px;
  transform-origin: left top;
  top: 0%;
}

.quiz-item-flex-answer {
  width: 50%;
}

.quiz-ans-correct {
  background-color: #17bd59;
  min-height: 100px !important;
}

.quiz-ans-wrong {
  background-color: #e96969;
  min-height: 100px !important;
}

.quiz-ans-gradient {
  background-image: linear-gradient(to bottom, #fcac5e, #f57b76);
}

.quiz-ans-1 {
  background-color: #57add0;
}

.quiz-ans-normal {
  background-color: #57add0;
  color: #000;
}

.quiz-ans-sel {
  background-color: #17bd59;
  color: #fff;
  font-weight: 700;
}

.quiz-ans-2 {
  background-color: #3168b9;
}

.quiz-ans-3 {
  background-color: #a667f3;
}

.quiz-ans-4 {
  background-color: #8d1a7b;
}

.quiz-ans-default {
  background-color: #e9696900;
}

#quiz-player-footer {
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 0px;
  width: 98vw;
}

.display-footer {
  display: flex;
  justify-items: start;
}

.footer-quiz-flex {
  margin: 5px;
}

.footer-flex {
  background-color: transparent;
}

.zoom-button {
  background-color: #000;
  height: 40px;
  width: 40px;
}

.zoom-button:hover {
  background-color: #119044;
  border: none;
}

.zoom-button:active,
.zoom-button:focus {
  background-color: #119044;
  border: none;
}

.zoom-button:visited {
  background-color: #17bd59;
}

.practice-center {
  display: flex;
  justify-content: center;
  align-content: center;
}

.quiz-subject-item-flex {
  width: 130px;
  height: 100px;
  padding: 5px 10px;
  font-size: 20px;
}

.quiz-subject-flex {
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
}

.quiz-bg-subject-icon-rails {
  height: 35px;
  width: 35px;
  line-height: 35px;
  display: block;
  color: #fff;
  background-color: #e96969;
  border: none;
  border-radius: 50%;
  text-align: center;
  position: relative;
  opacity: 1;
  font-size: 25px;
  font-family: "quiz-sec-font-reg";
  margin: auto;
}

.quiz-bg-subject-icon {
  height: 75px;
  width: 75px;
  line-height: 75px;
  display: block;
  color: #fff;
  background-color: #fc8643;
  border: none;
  border-radius: 50%;
  text-align: center;
  position: relative;
  opacity: 1;
  font-size: 40px;
  font-family: "quiz-sec-font-reg";
  margin: auto;
}

/*-----reports page----*/
.quiz-reports-card-header {
  font-family: "quiz-title-font";
  font-size: 16px;
  color: #000;
  text-align: center;
  width: 100%;
  line-height: 40px;
  border-bottom: 1px solid #caccce;
}

.small-sub-title {
  font-family: "quiz-sec-font";
  font-size: 12px;
  line-height: 14px;
  padding: 5px 0px 0px 0px;
}

.text-subject {
  font-family: "quiz-title-font";
  font-size: 18px;
  line-height: 20px;
  padding: 0px;
}

.text-subject-big {
  font-family: "quiz-sec-font";
  font-size: 28px;
  line-height: 30px;
  padding: 0px;
}

.quiz-reports-flex {
  display: flex;
  justify-content: center;
  padding: 20px 5px;
}

.quiz-reports-flex-cards {
  width: 140px;
  border-radius: 10px;
  height: 100px;
  margin: 10px;
  padding: 10px;
  color: #fff;
}

.quiz_info_item {
  background-color: #fff;
  color: #000;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 10px;
  cursor: default;
  min-height: 160px;
  transition: all 1s ease;
  transform: scale(1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

/*.quiz_info_item:hover {
  transform: scale(1.1);
  transition: all 1s ease;
}*/

.quiz-title-text {
  font-size: "quiz-sec-font";
  font-size: 18px;
}

.quiz-subject-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 0px;
  cursor: pointer !important;
  min-height: 160px;
  margin: 15px;
  width: 160px;
  transition: all 1s ease;
  transform: scale(1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.quiz-subject-card .quiz-subject-label {
  font-size: 15px;
  line-height: 20px;
  font-family: "quiz-title-font";
  color: #000;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  padding-top: 10px;
  cursor: pointer !important;
}

.quiz-subject-card:hover {
  transform: scale(1.1);
  transition: all 1s ease;
}

/*--back button-floating-----*/
.back-nav {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #000;
  z-index: 2000;
  border-radius: 0px 0px 15px 15px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  float: left;
}

.back-nav-left {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: #fc8643;
  z-index: 2000;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  float: left;
  margin: 0px 0px 0px 20px;
}

.back-head-left {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: #fc8643;
  z-index: 2000;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  float: left;
  margin: 0px 10px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-empty-right {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: transparent;
  z-index: 2000;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  float: left;
  margin: 0px 0px 0px 20px;
}

.back-filter-right {
  width: 180px;
  height: 30px;
  line-height: 30px;
  background-color: transparent;
  z-index: 2000;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  float: left;
  margin: 0px 0px 0px 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-content-left {
  flex: 1;
  text-align: left;
  font-weight: 600;
  padding-left: 15px;
}

.bread-crumb {
  height: 40px;
  line-height: 46px;
  color: #000;
  padding-left: 10px !important;
  font-size: 14px;
  font-family: "stepapp-secondary-regular", sans-serif;
}

/*---quiz modal popup----*/
.quiz-modal-title {
  font-family: "quiz-sec-font";
  font-size: 16px;
  padding-top: 10px;
  color: #fff;
}

.quiz-timer-ass {
  width: 120px;
  height: 35px;
  line-height: 33px;
  background-color: #a04610;
  z-index: 500;
  border-radius: 0px 5px 0px 20px;
  color: #fff;
  text-align: left;
  position: absolute;
  right: 15px;
}

.quiz-img {
  height: 100%;
  width: auto;
}

.timer-bg {
  background-color: #000;
  border-radius: 50%;
  padding-bottom: 5px;
}

#quiz-m-body {
  width: 100%;
  height: 100px;
  position: relative;
  background: #fff;
}

.row-quiz-sub-model {
  height: 60px;
}

.quiz-subject-card-modal {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 0px;
  cursor: pointer;
  height: 130px;
  margin: 20px;
  width: 130px;
  position: relative;
  top: -82px;
}

.quiz-bg-subject-icon-modal {
  height: 100px;
  width: 100px;
  line-height: 100px;
  display: block;
  color: #fff;
  background-color: #e96969;
  border: none;
  border-radius: 50%;
  text-align: center;
  position: relative;
  opacity: 1;
  font-size: 50px;
  font-family: "quiz-sec-font-reg";
  margin: auto;
}

.c-p {
  cursor: pointer;
}

.close-modal {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
  background-color: #000;
  width: 25px;
  height: 25px;
  line-height: 17px;
  border-radius: 50%;
  border: none;
}

/*----------forquizbutons------------*/
.wrapper-cta-quiz {
  margin: auto;
  padding: 30px;
  text-align: center;
}

.cta-img {
  position: relative;
  left: -20px;
  bottom: 5px;
  transform: skewX(0deg);
}

.cta {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 35px;
  padding: 10px;
  text-decoration: none;
  font-family: "quiz-title-font";
  font-size: 20px;
  color: white;
  transition: 1s;
  box-shadow: 2px 2px 0 #caccce;
  border-radius: 5px;
}

.button-svg {
  width: 20px;
  height: 20px;
  display: inline-block;
}

.cta:focus {
  outline: none;
  color: white;
}

.cta:hover {
  transition: 0.5s;
  box-shadow: 10px 10px 0 #fbc638;
  color: white;
  text-decoration: none;
}

.cta span:nth-child(2) {
  transition: 0.5s;
  margin-right: 0px;
}

.cta:hover span:nth-child(2) {
  transition: 0.5s;
  margin-right: 45px;
}

.cta.span:nth-child(2) {
  width: 20px;
  margin-left: 30px;
  position: relative;
  top: -7%;
}

/**************SVG for modal button animation****************/
path.one {
  transition: 0.4s;
  transform: translateX(-60%);
}

path.two {
  transition: 0.5s;
  transform: translateX(-30%);
}

.cta:hover path.three {
  -webkit-animation: color_anim 1s infinite 0.2s;
  animation: color_anim 1s infinite 0.2s;
}

.cta:hover path.one {
  transform: translateX(0%);
  -webkit-animation: color_anim 1s infinite 0.6s;
  animation: color_anim 1s infinite 0.6s;
}

.cta:hover path.two {
  transform: translateX(0%);
  -webkit-animation: color_anim 1s infinite 0.4s;
  animation: color_anim 1s infinite 0.4s;
}

/* SVG animations */
@-webkit-keyframes color_anim {
  0% {
    fill: white;
  }

  50% {
    fill: #fbc638;
  }

  100% {
    fill: white;
  }
}

@keyframes color_anim {
  0% {
    fill: white;
  }

  50% {
    fill: #fbc638;
  }

  100% {
    fill: white;
  }
}

/**************SVG for modal button animation end***************/
.iconpad {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: auto;
}

.powerup-nav-item {
  padding: 5px;
}

.powerup-btn {
  color: #fff;
  border: none;
  background-color: #000;
  height: 40px;
  font-size: 14px;
  line-height: 25px;
  border-radius: 100px;
  height: 40px;
  padding: 0px 20px;
  margin: 10px;
}

.powerup-btn:hover,
.powerup-btn:focus,
.powerup-btn:active {
  background-color: #fff;
  border: none;
  color: #000;
}

.powerup-btn-time {
  color: #fff;
  border: none;
  background-color: #000;
  height: 40px;
  width: 40px;
  font-size: 14px;
  line-height: 25px;
  border-radius: 50%;
  height: 40px;
  margin: 10px;
}

.powerup-btn-time:hover,
.powerup-btn-time:focus,
.powerup-btn-time:active {
  background-color: #fff;
  border: none;
  color: #000;
}

.powerup-btn-time.disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

.powerup-btn-time.disabled:hover,
.powerup-btn-time.disabled:focus,
.powerup-btn-time.disabled:active {
  background-color: #000;
  color: #fff;
}

.powerup-btn.disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

.powerup-btn.disabled:hover,
.powerup-btn.disabled:focus,
.powerup-btn.disabled:active {
  background-color: #000;
  color: #fff;
}

/*--------for Single Select ---------------*/
.quiz-img {
  height: 35vh;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quiz-item-flex-single-select {
  width: 25%;
  height: 30vh;
  padding: 5px 10px;
  font-size: 20px;
  cursor: pointer;
  font-family: "quiz-sec-font-reg";
}
.quiz-item-flex-single-select .select-label-r {
  height: 30vh;
  line-height: auto;
  padding: 5px;
  display: flex;
  align-items: center;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-align: center;
  position: relative;
  opacity: 1;
  font-size: 26px;
  font-family: "quiz-sec-font";
  border: 3px solid transparent;
}
.quiz-item-flex-single-select input[type="radio"]:checked + label {
  border: 3px solid #15388c;
  color: #000;
  opacity: 1;
  font-family: "quiz-sec-font-reg";
  background: #fff;
  border-radius: 5px;
}
.quiz-item-flex-single-select input[type="radio"]:checked + label::after {
  height: 50px;
  width: 50px;
  background: url("../images/check-quiz.svg");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 50px;
  z-index: 999;
  position: absolute;
  top: -6px;
  right: -3px;
}

.quiz-item-flex-mcq {
  width: 25%;
  height: 30vh;
  padding: 5px 10px;
  font-size: 20px;
  cursor: pointer;
  font-family: "quiz-sec-font-reg";
}

.quiz-item-flex-mcq .select-label-r {
  height: 30vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  position: relative;
  opacity: 1;
  font-size: 24px;
  line-height: 24px;
  font-family: "quiz-sec-font";
  border: 3px solid transparent;
}

.quiz-item-flex-mcq input[type="radio"]:checked+label {
  border: 3px solid #15388c;
  color: #000;
  opacity: 1;
  font-family: "quiz-sec-font";
  background: #fff;
}

.quiz-item-flex-mcq input[type="radio"]:checked+label::after {
  height: 50px;
  width: 50px;
  background: url("../images/check-quiz.svg");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 50px;
  z-index: 999;
  position: absolute;
  top: -6px;
  right: -3px;
}

.multipleSelect {
  border: 3px solid #1b1f5f !important;
  color: black !important;
  opacity: 1 !important;
  font-family: "quiz-sec-font";
  background: #ffffff !important;
}

.multipleSelect::after {
  content: "";
  height: 50px;
  width: 50px;
  background: url("../images/check-quiz.svg");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 50px;
  z-index: 999;
  position: absolute;
  top: -3px;
  right: -3px;
}

/*----added for question numbers--*/
.questionnumber_item_flex {
  border-radius: 10px;
  padding: 0px;
  margin: 20px;
  cursor: pointer;
  flex: 1 1 auto;
  position: relative;
  line-height: 50px;
  height: 50px;
  transition: all 2s ease;
  transform: scale(1);
  display: flex;
  align-items: center;
}

.test-list {
  width: 100%;
}

.q-n {
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  background-color: #a04610;
  color: #fff;
  margin: 0 10px;
  text-align: center;
  cursor: pointer;
}

.q-n-sel {
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  margin: 5px;
  text-align: center;
  cursor: pointer;
  border-bottom: 3px solid #fff;
}

.q-n-bg {
  width: 100%;
  height: 50px;
  margin: auto;
  background-color: transparent;
}

/*********************Open Ended Questions**********************/
.question-btn {
  height: 29px;
  width: 29px;
  background-color: #4c4c4c;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 29px;
  font-size: 12px;
  cursor: pointer;
}

.question-btn:hover {
  background-color: #a04610;
}

.question-btn:active,
.question-btn:visited {
  background-color: #000;
  color: #f4d302;
}

.question-btn-sq {
  height: 29px;
  width: 29px;
  background-color: #4c4c4c;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  line-height: 29px;
  font-size: 12px;
  cursor: pointer;
}

.question-btn-sq:hover {
  background-color: #a04610;
}

.question-btn-sq:active,
.question-btn-sq:visited {
  background-color: #000;
  color: #f4d302;
}

.question-btn.active {
  background-color: #fff;
  color: #000;
}

.question-btn-answered {
  height: 29px;
  width: 29px;
  background-color: #17bd59;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 29px;
  cursor: pointer;
}

.question-btn-answered:hover {
  background-color: #a04610;
}

.question-btn-answered:active,
.question-btn-answered:visited {
  background-color: #000;
  color: #f4d302;
}

.question-bg {
  border-radius: 5px;
  color: #fff;
  background-color: #000;
  margin: 0px 5px;
  line-height: 40px;
  padding: 0px 5px;
  text-align: center;
}

.question-no {
  text-align: center;
}

.action-quiz .dropdown-menu {
  position: absolute;
  will-change: transform;
  top: 7px !important;
  left: -9px !important;
  transform: translate3d(-5px, 31px, 0px);
  min-width: 120px;
  max-width: 123px;
  background: #000;
}

.action-quiz .dropdown-menu .dropdown-item {
  padding: 11px 6px;
  color: #fff;
  font-size: 13px;
}

.action-quiz .dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent !important;
}

.bookmark-count {
  float: left;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  background: #faaf43;
  text-align: center;
  margin-right: 2px;
  line-height: 30px;
  margin-top: -3px;
}

.action-quiz .dropdown-toggle:empty::after {
  display: none;
}

/*************************Editor Module**********************/
.rdw-editor-toolbar {
  padding: 6px 5px 0;
  border-radius: 5px !important;
  border: 1px solid #a04610 !important;
  display: flex;
  /* justify-content: flex-start; */
  background: #a04610 !important;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.public-DraftStyleDefault-block {
  margin: 10px !important;
}

.rdw-colorpicker-modal-options {
  overflow: auto;
}

.rdw-option-wrapper {
  border: 1px solid transparent !important;
  padding: 5px !important;
  min-width: 25px;
  height: 20px;
  border-radius: 5px;
  margin: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent !important;
  text-transform: capitalize;
  box-shadow: none !important;
}

.rdw-option-wrapper:hover {
  border: 1px solid #000;
  box-shadow: none !important;
}

.rdw-dropdown-wrapper {
  height: 20px;
  background: white;
  cursor: pointer;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  margin: 0 3px;
  text-transform: capitalize;
  background: white;
}

.rdw-option-wrapper img {
  filter: invert(1);
}

.rdw-image-modal {
  position: absolute;
  top: 35px;
  left: auto !important;
  right: 0px !important;
  display: flex;
  flex-direction: column;
  width: 235px;
  height: 100px;
  overflow-y: auto;
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: none;
}

.rdw-embedded-modal {
  position: absolute;
  top: 35px;
  left: auto !important;
  right: 0px !important;
  display: flex;
  flex-direction: column;
  width: 235px;
  height: 100px;
  overflow-y: auto;
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  justify-content: space-between;
  box-shadow: none !important;
}

.rdw-emoji-modal {
  overflow: auto;
  position: absolute;
  top: 35px;
  left: auto !important;
  right: 0px !important;
  display: flex;
  flex-wrap: wrap;
  width: 235px;
  height: 180px;
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: none;
}

.editor-question-bg {
  background-color: rgba(255, 255, 255, 0.5);
  font-family: "quiz-sec-font";
  line-height: auto;
  height: 30vh;
  overflow-y: auto;
  border-radius: 5px;
}

.quiz-edit {
  flex-direction: row;
  /* align-self: flex-start; */
}

.quiz-question-wrap {
  min-height: 35vh;
}

/* Added for navigation  for practice test */
.game-nav-flex-header {
  display: flex;
  align-items: center;
  width: 100%;
  margin: auto;
}

.game-nav-flex-item {
  margin: 0px 5px;
}

.game-submit-btn {
  color: #fff;
  border: none;
  background-color: #000;
  height: 40px;
  font-size: 14px;
  line-height: 25px;
  border-radius: 100px;
  height: 40px;
  padding: 0px 20px;
}

.game-submit-btn:hover,
.game-submit-btn:focus,
.game-submit-btn:active {
  background-color: #fff;
  border: none;
  color: #000;
}

.game-status-btn {
  color: #fff;
  border: none;
  background-color: #a04610;
  height: 40px;
  font-size: 14px;
  line-height: 25px;
  border-radius: 5px;
  height: 40px;
  padding: 0px 20px;
}

.game-status-btn:hover,
.game-status-btn:focus,
.game-status-btn:active {
  background-color: #fff;
  border: none;
  color: #000;
}

/* added for Drawer */
.list-flex-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.list-flex-item {
  width: 30px;
  height: 30px;
  margin: 5px;
}

.border-top-drw {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.text-drawer {
  text-align: left;
  font-family: "quiz-sec-font";
  color: #fff;
}

.background-success {
  background-image: url("../images/bg-complete.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  height: 100%;
}

.success-text {
  font-family: "quiz-sec-font";
  font-size: 20px;
  color: #fff;
  text-align: center;
}

.background-countdown {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
}

.succes-item-flex {
  width: 300px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

/* for bookmark drawer Assessment */
.flex-box-status {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.flex-item-status {
  width: auto;
  height: 50px;
  padding: 5px;
  margin: 5px;
  border: 1px solid #caccce;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-answered {
  background-color: #17bd59 !important;
}

.bg-unanswered {
  background-color: #e96969;
}

.bg-review {
  background-color: #e4ac13 !important;
}

.bg-answerreview {
  background-color: #fc8643 !important;
}

.bg-notvisited {
  background-color: #6c757d !important;
}

/* for swipe transitions*/
#root {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
}

.pos-timer {
  position: relative;
  top: -40%;
  z-index: 2000;
}

.quiz-timer {
  border-radius: 50px;
  width: 90px;
  height: 34px;
  background-color: #e96969;
  color: #fff;
  margin: auto;
  font-size: 40px;
  line-height: 50px;
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
  border: 3px solid #fff;
}

.quiz-timer-icon {
  font-size: 25px;
}

.quiz-timer-text {
  font-size: 18px;
  font-family: "stepapp-secondary-medium", sans-serif;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  margin-top: 5px;
}

.question-card-wrapper {
  border: 1px solid green;
}

.card-swipe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100vw;
}

.swipe {
  position: absolute;
}

.cardContainer {
  margin-top: 30px;
  width: 90vw;
  height: 60vh;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.cardContent {
  width: 100%;
  height: 100%;
}

.infoText {
  width: 100%;
  height: 28px;
  justify-content: center;
  display: flex;
  color: #fff;
  -webkit-animation-name: popup;
  animation-name: popup;
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
}

.buttons {
  display: flex;
  z-index: 3000;
  margin-top: 20px;
}

.buttons button {
  flex-shrink: 0;
  padding: 10px;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  transition: 200ms;
  margin: 0 10px;
  font-weight: bolder;
  width: 130px;
  box-shadow: none;
}

.button-red,
.button-red:active,
.button-red:focus,
.button-red:visited {
  background-color: red !important;
}

.button-green,
.button-green:active,
.button-green:focus,
.button-green:visited,
.button-green:focus-within,
.button-green:focus-visible {
  background-color: green !important;
}

.buttons button:hover {
  transform: scale(1.05);
}

@-webkit-keyframes popup {
  0% {
    transform: scale(1, 1);
  }

  10% {
    transform: scale(1.1, 1.1);
  }

  30% {
    transform: scale(0.9, 0.9);
  }

  50% {
    transform: scale(1, 1);
  }

  57% {
    transform: scale(1, 1);
  }

  64% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes popup {
  0% {
    transform: scale(1, 1);
  }

  10% {
    transform: scale(1.1, 1.1);
  }

  30% {
    transform: scale(0.9, 0.9);
  }

  50% {
    transform: scale(1, 1);
  }

  57% {
    transform: scale(1, 1);
  }

  64% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

.bookmark-q button {
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 16px;
  margin: 0 10px 0px 0px;
  width: auto;
  padding: 5px 10px;
  height: 40px;
}

#content-quiz {
  width: 100vw;
  height: calc(100vh - 100px);
  margin: auto;
  text-align: center;
  overflow-y: auto;
  display: flex;
}

.pb-30 {
  padding-bottom: 30px;
}

.practice-quiz-flex {
  display: flex;
  flex-flow: row wrap;
}

.practice-header {
  flex: 1 100%;
  background-color: #fff;
  cursor: pointer;
  padding: 0px;
}

.practice-footer {
  flex: 1 100%;
  background-color: transparent;
}

.practice-theme {
  flex-grow: 6;
  margin: 5px;
}

.practice-settings {
  flex-grow: 1;
  margin: 5px;
}

/*********************Join Button*******************/
.join-btn-wrap {
  display: flex;
  height: 60px;
  margin: auto;
  flex-direction: row;
  justify-content: center;
}

.join-btn-wrap input {
  flex: 1;
  font-size: 1.1em;
  -webkit-border-top-left-radius: 50px;
  -webkit-border-bottom-left-radius: 50px;
  -moz-border-radius-topleft: 50px;
  -moz-border-radius-bottomleft: 50px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  box-shadow: none;
  border: none;
  background: #fff !important;
  text-align: center;
}

.join-btn-wrap input::-moz-placeholder {
  text-align: center;
}

.join-btn-wrap input:-ms-input-placeholder {
  text-align: center;
}

.join-btn-wrap input::placeholder {
  text-align: center;
}

.join-btn-wrap button {
  padding-right: 10px;
  background-color: #fff;
  -webkit-border-top-right-radius: 50;
  -webkit-border-bottom-right-radius: 50px;
  -moz-border-radius-topright: 50px;
  -moz-border-radius-bottomright: 50px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border: none;
  cursor: pointer;
  cursor: hand;
}

.join-btn-wrap button span {
  padding: 14px 20px;
  font-size: 0.9em;
  text-transform: uppercase;
  font-weight: 300;
  color: #fff;
  background: linear-gradient(89.97deg,
      rgba(248, 167, 11, 0.98) 9.58%,
      #ff4e4e 89.6%);
  border-radius: 20px;
}

/************************Find Friend*****************************/
.quiz-title {
  background: #f4f4f4;
  max-width: 700px;
  height: 60px;
  border-radius: 30px;
  text-align: center;
  padding: 20px;
  margin: auto;
}

.ask-friend-wrapper span input {
  width: 100%;
  margin: auto;
  background: #c4c4c4;
  height: 50px;
  border-radius: 50px;
  width: 100%;
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: revert;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: 600;
  color: #000;
}

.waiting-zone-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.waiting-zone-header img {
  margin-left: 20px;
}

.or-wrapper {
  width: 62px;
  height: 62px;
  background: #f16b24;
  border-radius: 50%;
  text-align: center;
  margin: auto;
  vertical-align: middle;
  padding-top: 14px;
}

.or-wrapper p {
  font-size: 23px !important;
  color: #fff;
  font-weight: 600;
}

.share-link {
  width: 100%;
  height: 60px;
  border-radius: 50px;
  background: linear-gradient(89.97deg,
      rgba(248, 167, 11, 0.98) 9.58%,
      #ff4e4e 89.6%);
  font-size: 25px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
}

.count-rectangle {
  background: linear-gradient(89.97deg,
      rgba(248, 167, 11, 0.98) 9.58%,
      #ff4e4e 89.6%);
  border-radius: 15px;
  width: 70px;
  height: 40px;
  text-align: center;
  padding: 4px;
}

.count-rectangle p {
  font-size: 21px;
  color: #fff;
  font-weight: 600;
}

.circle-container {
  position: relative;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  padding: 0;
  list-style: none;
  margin: auto;
  margin-top: 60px;
  border: 3px dashed #9a2929;
}

.circle-container p {
  position: absolute;
  left: -38px;
  top: 21px;
  font-size: 14px;
  font-weight: 600;
}

.circle-container>* {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -3em;
  width: 6em;
  height: 6em;
}

.circle-container>*:nth-of-type(1) p {
  position: absolute;
  left: 85px;
  top: 30px;
}

.circle-container .user1 {
  transform: rotate(0deg) translate(8em) rotate(0deg);
}

.circle-container .user2 {
  transform: rotate(180deg) translate(8em) rotate(-180deg);
}

.circle-container .user3 {
  transform: rotate(270deg) translate(8em) rotate(-270deg);
}

.circle-container .user4 {
  transform: rotate(90deg) translate(9em) rotate(-90deg);
}

.circle-container img {
  display: block;
  max-width: 64%;
  border-radius: 50%;
  transition: 0.15s;
  -webkit-animation: spin 10s linear infinite;
  animation: spin 10s linear infinite;
  margin: auto;
}

@-webkit-keyframes spin {
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes spin {
  100% {
    transform: rotateZ(360deg);
  }
}
.find-friend {
  background-repeat: no-repeat;
  background-image: linear-gradient(to bottom, #fcac5e, #f57b76);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center bottom;
  background-attachment: fixed;
}

.board-wrap {
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  margin: auto;
  height: 81px;
  border-radius: 10px;
  transition: 1s ease;
}

.board-wrap:hover {
  transform: scale(1.2);
  transition: 1s ease;
  background: #fffc00;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.board-detail {
  width: 100%;
}

.board-wrap.active-user {
  transform: scale(1.2);
  transition: 1s ease;
  background: #fffc00;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.board-detail .detail-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}

.detail-flex-item {
  padding: 0px 10px;
}

.board-mark {
  float: right;
  text-align: right;
}

.board-mark h3 {
  font-size: 28px;
  font-weight: 600;
}

.board-detail ul h6 {
  font-size: 18px;
  font-weight: 600;
}

.board-detail ul p {
  font-size: 14px;
  font-weight: 600;
}

.board-detail ul img {
  width: 60px;
  height: 60px;
}

.challenge-modal-header {
  background: linear-gradient(180deg,
      rgba(248, 167, 11, 0.98) 0%,
      #e99999 56.89%,
      #da5b5b 109.49%);
}

.play-cta-wrapper .wrapper-cta-quiz {
  margin: auto;
  padding: 10px;
  text-align: center;
}

.play-cta {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 35px;
  padding: 10px;
  text-decoration: none;
  font-family: "quiz-title-font";
  font-size: 20px;
  color: white;
  background: linear-gradient(89.97deg,
      rgba(248, 167, 11, 0.98) 9.58%,
      #ff4e4e 89.6%);
  transition: 1s;
  box-shadow: 2px 2px 0 #caccce;
  border-radius: 5px;
  width: 140px;
}

.play-cta span {
  font-size: 14px;
}

.play-cta .cta-img {
  position: relative;
  left: -30px;
  bottom: 2px;
  width: 40px;
  height: 40px;
  margin-right: -20px;
}

.play-cta-wrapper {
  display: flex;
  flex-direction: revert;
  justify-content: space-around;
  align-items: center;
}

.find-friend2 {
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center bottom;
  background-attachment: fixed;
}

.find-friend2 .ask-friend-wrapper2 {
  /* max-width: 500px; */
  width: 100%;
  background: #f4f4f4;
  border-radius: 15px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.find-friend2 .ask-friend-wrapper2 p {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

.ask-friend-wrapper2 span input {
  margin: auto;
  background: #c4c4c4;
  height: 50px;
  border-radius: 50px;
  width: 90%;
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: revert;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: 600;
  color: #000;
}

.find-friend2 .circle-container {
  margin-top: 30px;
  margin-bottom: 19px;
}

.find-friend2 .waiting-zone {
  width: 100%;
  background: rgba(244, 244, 244, 0.5);
  border-radius: 15px;
  padding: 15px 20px;
  min-height: 290px;
  text-align: center;
}

/*************************Detail********************/
.subject-detail-wrapper {
  width: 100%;
  background: #f4f4f4;
  border-radius: 15px;
  min-height: 240px;
}

.subject-challenge-wrapper {
  width: 100%;
  background: #f4f4f4;
  border-radius: 15px;
  height: 100%;
  padding: 0px 20px;
}

.subject-challenge-wrapper .practice-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.subject-detail-wrapper .subject-detail-header {
  color: #fff;
  padding: 10px 20px 4px 19px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.subject-detail-wrapper .subject-detail-header h6 {
  color: #fff;
}

.challenge-detail-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.challenge-detail-section span input {
  width: 100%;
  margin: auto;
  background: #ffffff !important;
  height: 50px;
  border-radius: 50px;
  width: 100%;
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: revert;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: 600;
  color: #000;
}

.setting-checkbox {
  margin: auto;
  width: 100%;
}

.bdr-bottom {
  border-bottom: 0.5px solid #caccce;
}

/********************Waiting Animation***************/
.waiting-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80vh !important;
}

.waiting-screen p {
  font-size: 25px;
  color: #fff;
  font-weight: 600;
}

.waiting-loader {
  width: 48px;
  height: 24px;
  color: #fff;
  background: currentColor;
  border-radius: 50% 50% 0 0;
  position: relative;
  display: block;
  margin: 60px auto 0;
  box-sizing: border-box;
  -webkit-animation: animloader 4s linear infinite;
  animation: animloader 4s linear infinite;
}

.waiting-loader::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: currentColor;
  top: -34px;
  box-sizing: border-box;
  -webkit-animation: animloader1 4s linear infinite;
  animation: animloader1 4s linear infinite;
}

@-webkit-keyframes animloader {
  0% {
    box-shadow: 0 0 0 -2px, 0 0 0 -2px, 0 0 0 -5px, 0 0 0 -5px;
  }

  20% {
    box-shadow: 40px -1px 0 -2px, 0 0 0 -2px, 40px -1px 0 -5px, 0 0 0 -5px;
  }

  40% {
    box-shadow: 40px -1px 0 -2px, -40px -1px 0 -2px, 40px -1px 0 -5px,
      -40px -1px 0 -5px;
  }

  60% {
    box-shadow: 40px -1px 0 -2px, -40px -1px 0 -2px, 23px -29px 0 -5px,
      -40px -1px 0 -5px;
  }

  80%,
  95% {
    box-shadow: 40px -1px 0 -2px, -40px -1px 0 -2px, 23px -29px 0 -5px,
      -23px -29px 0 -5px;
  }

  100% {
    box-shadow: 40px -1px 0 -2px rgba(255, 255, 255, 0),
      -40px -1px 0 -2px rgba(255, 255, 255, 0),
      23px -29px 0 -5px rgba(255, 255, 255, 0),
      -23px -29px 0 -5px rgba(255, 255, 255, 0);
  }
}

@keyframes animloader {
  0% {
    box-shadow: 0 0 0 -2px, 0 0 0 -2px, 0 0 0 -5px, 0 0 0 -5px;
  }

  20% {
    box-shadow: 40px -1px 0 -2px, 0 0 0 -2px, 40px -1px 0 -5px, 0 0 0 -5px;
  }

  40% {
    box-shadow: 40px -1px 0 -2px, -40px -1px 0 -2px, 40px -1px 0 -5px,
      -40px -1px 0 -5px;
  }

  60% {
    box-shadow: 40px -1px 0 -2px, -40px -1px 0 -2px, 23px -29px 0 -5px,
      -40px -1px 0 -5px;
  }

  80%,
  95% {
    box-shadow: 40px -1px 0 -2px, -40px -1px 0 -2px, 23px -29px 0 -5px,
      -23px -29px 0 -5px;
  }

  100% {
    box-shadow: 40px -1px 0 -2px rgba(255, 255, 255, 0),
      -40px -1px 0 -2px rgba(255, 255, 255, 0),
      23px -29px 0 -5px rgba(255, 255, 255, 0),
      -23px -29px 0 -5px rgba(255, 255, 255, 0);
  }
}

@-webkit-keyframes animloader1 {
  0% {
    box-shadow: 0 0 0 -2px, 0 0 0 -2px, 0 0 0 -5px, 0 0 0 -5px;
  }

  20% {
    box-shadow: 40px 2px 0 -2px, 0 0 0 -2px, 40px 2px 0 -5px, 0 0 0 -5px;
  }

  40% {
    box-shadow: 40px 2px 0 -2px, -40px 2px 0 -2px, 40px 2px 0 -5px,
      -40px 2px 0 -5px;
  }

  60% {
    box-shadow: 40px 2px 0 -2px, -40px 2px 0 -2px, 23px -23px 0 -5px,
      -40px 2px 0 -5px;
  }

  80%,
  95% {
    box-shadow: 40px 2px 0 -2px, -40px 2px 0 -2px, 23px -23px 0 -5px,
      -23px -23px 0 -5px;
  }

  100% {
    box-shadow: 40px 2px 0 -2px rgba(255, 255, 255, 0),
      -40px 2px 0 -2px rgba(255, 255, 255, 0),
      23px -23px 0 -5px rgba(255, 255, 255, 0),
      -23px -23px 0 -5px rgba(255, 255, 255, 0);
  }
}

@keyframes animloader1 {
  0% {
    box-shadow: 0 0 0 -2px, 0 0 0 -2px, 0 0 0 -5px, 0 0 0 -5px;
  }

  20% {
    box-shadow: 40px 2px 0 -2px, 0 0 0 -2px, 40px 2px 0 -5px, 0 0 0 -5px;
  }

  40% {
    box-shadow: 40px 2px 0 -2px, -40px 2px 0 -2px, 40px 2px 0 -5px,
      -40px 2px 0 -5px;
  }

  60% {
    box-shadow: 40px 2px 0 -2px, -40px 2px 0 -2px, 23px -23px 0 -5px,
      -40px 2px 0 -5px;
  }

  80%,
  95% {
    box-shadow: 40px 2px 0 -2px, -40px 2px 0 -2px, 23px -23px 0 -5px,
      -23px -23px 0 -5px;
  }

  100% {
    box-shadow: 40px 2px 0 -2px rgba(255, 255, 255, 0),
      -40px 2px 0 -2px rgba(255, 255, 255, 0),
      23px -23px 0 -5px rgba(255, 255, 255, 0),
      -23px -23px 0 -5px rgba(255, 255, 255, 0);
  }
}

/*****************Find Friends New CSS*****************/
.joined-users-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.joined-user {
  margin: 5px;
}

.joined-user img {
  -webkit-animation: spin 10s linear infinite;
  animation: spin 10s linear infinite;
}

/****************Challenge Modal Theme***************/
.challenge-modal-theme-1 {
  height: auto;
  display: block;
  background-image: linear-gradient(to bottom, #fcac5e, #f57b76) !important;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.challenge-modal-theme-2 {
  height: auto;
  display: block;
  background-image: linear-gradient(180deg, #00fff0 0%, #0083fe 100%);
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.challenge-modal-theme-3 {
  height: auto;
  display: block;
  background-image: linear-gradient(180deg, #b993d6 0%, #8ca6db 100%);
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.challenge-modal-theme-4 {
  height: auto;
  display: block;
  background-image: linear-gradient(180deg, #00c9ff 0%, #92fe9d 100%);
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.find-friend-loader {
  position: absolute;
  left: 47%;
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #ff3d00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}

@-webkit-keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.custom-margin-challenge {
  margin-top: 20px;
}

@media (min-width: 960px) {
  .custom-margin-challenge {
    margin-top: 20px;
  }
}

/*   for new profile  subject cards */
#profile-content {
  height: calc(100vh - 50px);
  overflow-y: auto;
}

.profilesubjectCard {
  background-color: #fff;
  border-radius: 10px;
  padding: 5px;
  margin: 15px auto;
  cursor: pointer;
}

.profile-subject-details-flex {
  border-radius: 10px;
  color: #fff;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 5px;
}

.profile-item-flex {
  width: 100px;
  text-align: center;
}

.progress-topic-text {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 14px;
  line-height: 10px;
  text-align: center;
}

.progress-topic-text .h2 {
  color: #fff;
  font-weight: 400 !important;
}

.progress-topic-text-h2 {
  color: #fff;
  font-weight: 400 !important;
}

.profile-percentage {
  font-family: "Poppins", sans-serif;
  font-size: 35px;
  color: #000;
  text-align: left;
  width: 100%;
}

.panel-heading {
  padding-bottom: 15px;
  border-bottom: 1px solid #caccce;
  margin-bottom: 20px;
}

.profile-subtitle {
  font-family: "stepapp-secondary-medium", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #000;
}

.avatar-item-flex {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 20px;
  margin: 0 9px 10px;
}

.avatar-flex {
  display: flex;
  flex-wrap: wrap;
}

.select-label-avatar {
  display: block;
  color: #fff;
  border: none;
  border-radius: 50%;
  text-align: center;
  position: relative;
  opacity: 1;
  border: 3px solid #f4f4f4;
}

input[type="radio"]:checked+label::after {
  content: "";
  height: 30px;
  width: 30px;
  background: url("../images/check.svg");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 20px;
  z-index: 999;
  position: absolute;
  top: -7px;
  right: -10px;
  border: 3px solid transparent;
}

.rounded-rect {
  border-radius: 10px !important;
}

.ant-table-thead>tr>th {
  font-family: "stepapp-secondary-bold", sans-serif;
  color: #000 !important;
  background-color: paleturquoise !important;
  font-size: 12px !important;
  line-height: 12px !important;
  font-weight: 700;
}

.ant-table-thead {
  border-radius: 10px 0px;
}

.text-value {
  font-size: 40px;
  line-height: 40px;
}

.info-text {
  font-size: 14px;
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
}

.card {
  border-radius: 10px !important;
}

.header-card {
  border-radius: 0px 0px 10px 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid #f5f7fb;
  margin-bottom: 20px;
}

.card-shadow {
  /* box-shadow: 0 5px 5px rgba(0, 0, 0, 0.03), 0 1px 2px rgba(0, 0, 0, 0.03);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); */
  background-color: black;
  opacity: 0.3;
  color: white;
}

.dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.custom-tab {
  border: 0.5px solid #ccc !important;
  border-radius: 5px !important;
  min-width: 5px;
  text-align: center;
  background-color: #fff !important;
  margin: 8px;
  color: #222222;
  transition: background-color 2s ease-out;
}

.custom-tab:hover {
  border: 0.5px solid #ccc !important;
  background-color: #7a7f9a !important;
  color: #000;
  transition: background-color 2s ease-out;
}

ul.diamond_img {
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.diamond_img li {
  display: inline-block;
  margin: 0 3px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

ul.diamond_img li img {
  width: 50px;
}

ul.diamond_img li:first-child span {
  top: 35%;
}

ul.diamond_img span {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

li.certificate_icon img {
  width: 45px !important;
  margin-left: 5px;
}

.min-h {
  min-height: 337px;
}

.info-icon {
  font-size: 22px;
  position: absolute;
  top: 6px;
  right: 8px;
}

.filterdropdown .btn.btn-secondary,
.filterdropdown .btn.btn-secondary:hover,
.filterdropdown .btn.btn-secondary:focus {
  background: transparent;
  border-color: transparent;
  outline: none;
}

.header-fixed .app-header {
  position: fixed;
  z-index: 100 !important;
  width: 100%;
}

.tab-content {
  border-color: #fff #e6ebf5 #e6ebf5 #e6ebf5;
}

.circle-icon-size {
  font-size: 44px;
  position: absolute;
  left: -10px;
  top: -6px;
}

.info-cards-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.info-card {
  background-color: transparent;
  margin: 0px 8px;
  width: 350px;
}

.info-card-bg-main {
  background-color: #55b2ff !important;
  color: #fff !important;
}

.info-card-bg-all {
  background-color: #996fd6 !important;
  color: #fff !important;
}

.student-profile-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: stretch;
  padding: 0px 10px;
}

.student-profile-item-flex {
  margin: 10px;
  padding: 20px;
  border: 1px dotted #caccce;
}

.student-profile-label {
  font-size: 12px;
  color: #6c757d;
  width: 100%;
  border-bottom: 0.5px solid #caccce;
  line-height: 20px;
}

.student-profile-value {
  font-size: 18px;
  line-height: 30px;
}

.chapter-detail-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: start;
  background-color: #fff;
  margin: 10px auto;
  border-bottom: 0.5px solid #caccce;
  padding-bottom: 10px;
}

.chapter-detail-flex:last-child {
  border-bottom: none;
}

.chapter-detail-flex-item {
  margin: 5px;
  min-width: 200px;
  flex: 3;
  min-height: 30px;
}

.chapter-detail-flex-item-5 {
  margin: 5px;
  width: 100%;
  min-height: 30px;
}

.custom-card-stu-count {
  width: 150px;
  padding: 10px 5px;
  background-color: #fc8643;
  border-radius: 10px;
  border-radius: 10px 0px 0px 10px;
  position: absolute;
  z-index: 900;
  right: 12px;
  color: #fff;
  border-right: 3px solid #15388c;
}

.student-count-custom {
  font-size: 30px;
  line-height: auto;
}

.detail-value {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 16px !important;
  font-weight: 600;
}

.detail-number {
  font-family: "stepapp-secondary-bold", sans-serif;
  font-size: 26px !important;
  font-weight: 600;
}

.detail-label {
  font-size: 12px;
}

.detail-date {
  font-size: 11px;
  color: #15388c;
  font-weight: normal;
}

.info-icon-pos {
  position: absolute;
  font-size: 18px;
  right: -1px;
  top: -11px;
  color: #fff;
  background-color: #fc8643;
  border-radius: 50%;
  border: 2px solid #fff;
  height: 28px;
}

.subject-analysis-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  padding: 0px;
}

.subject-analysis-flex-item {
  margin: 10px;
  border: 1px dotted #caccce;
  width: 200px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.subject-analysis-label {
  font-size: 14px;
  color: #fff;
  width: 100%;
  line-height: 30px;
}

.sub-badge {
  background-color: #fff;
  border-radius: 100px;
  padding: 0px 10px;
}

.subject-analysis-value {
  font-size: 26px;
}

/*----cards for -subjectdetail-students summary ----*/
.stu-sum-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.stu-sum-flex-card {
  background-color: transparent;
  margin: 0px 8px;
  width: 350px;
}

.stu-sum-flex-bg-all {
  background-color: #996fd6 !important;
  color: #fff !important;
  padding: 20px;
  margin: 5px 20px;
}

.stu-sum {
  font-size: 40px;
  text-align: center;
}

.thumb {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.dash-card-success {
  background-color: #17bd59;
  padding: 10px;
  font-size: 14px;
  color: #fff;
  flex: 1;
  border-radius: 10px;
  margin: 10px;
}

.dash-card-fail {
  background-color: #e96969;
  padding: 10px;
  font-size: 14px;
  color: #fff;
  flex: 1;
  border-radius: 10px;
  margin: 10px;
}

.chart-wrapper {
  width: 100%;
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

#test-banner {
  width: 100%;
  height: 300px;
  background-image: linear-gradient(114deg, #5686fb, #fc8643);
}
@media (max-width: 960px) {
  #test-banner {
    height: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.test-banner {
  width: 100%;
  height: 300px;
  /* background-image: url("../images/test-banner/banner-bg.png"); */
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: contain;
}
@media (max-width: 960px) {
  .test-banner {
    background-image: none;
  }
}

.test-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 300px;
  width: 100%;
  flex-direction: row;
}
@media (max-width: 960px) {
  .test-flex {
    background-image: none;
    flex-direction: column-reverse;
  }
}

.test-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.test-logo {
  width: 100%;
  height: 105px;
  display: flex;
  justify-content: flex-end;
  /* align-items: flex-start; */
}

.test-schedule {
  font-family: "stepapp-secondary-regular", sans-serif;
  color: #fff;
  font-size: 30px;
  line-height: 25px;
}
@media (max-width: 960px) {
  .test-schedule {
    font-size: 20px;
  }
}

.test-day {
  font-family: "stepapp-secondary-bold", sans-serif;
  color: #fff;
  font-size: 70px;
}

.test-date {
  font-family: "stepapp-secondary-medium", sans-serif;
  color: #fff;
  font-size: 25px;
  line-height: 25px;
}
@media (max-width: 960px) {
  .test-date {
    font-size: 20px;
  }
}

.test-buttons {
  margin-top: 20px;
}

.button-reg-basic-sec {
  background-color: #fff !important;
  font-family: "stepapp-secondary-bold", sans-serif;
  font-size: 14px;
  line-height: 14px;
  border-radius: 5px;
  border: 1px solid #caccce;
  padding: 10px;
  min-width: 80px;
  box-shadow: none;
  color: #000;
  height: 40px;
}
.button-reg-basic-sec:hover {
  background-color: #fff;
  border: 1px solid #6c757d;
}
.button-reg-basic-sec:active,
.button-reg-basic-sec:focus {
  background-color: #b0b3b6;
  border: 1px solid #6c757d;
}
.button-reg-basic-sec:visited {
  background-color: #fff;
  border: 1px solid #6c757d;
}

.button-reg-basic-pri {
  background-color: #fc8643 !important;
  font-family: "stepapp-secondary-bold", sans-serif;
  font-size: 14px;
  line-height: 14px;
  border-radius: 5px;
  border: 1px solid #caccce;
  padding: 10px;
  min-width: 80px;
  box-shadow: none;
  color: #fff;
  height: 40px;
}
.button-reg-basic-pri:hover {
  background-color: #fb6611;
  border: 1px solid transparent;
}
.button-reg-basic-pri:focus {
  background-color: #fb6611;
  border: 1px solid #a33d03;
}
.button-reg-basic-pri:active,
.button-reg-basic-pri:visited {
  background-color: #fc8643;
  border: 1px solid transparent;
}

.banner-sub-logo {
  height: 100px;
  width: auto;
}

.reg-subtitle {
  font-family: "stepapp-secondary-medium", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.reg-btn {
  width: auto;
  padding: 15px;
  min-height: 56px;
  height: auto;
  line-height: 27px;
  border-radius: 10px;
  color: #fff;
  background: #fc8643;
  border: none;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-size: 20px;
  margin: 5px;
}
.reg-btn:hover {
  border: none;
  background-color: #72320b;
}
.reg-btn:active {
  border: none;
}
.reg-btn:visited {
  border: none;
}

.reg-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.btn-basic {
  height: 40px;
  font-size: 16px;
  line-height: 28px;
  border-radius: 10px;
  font-family: "stepapp-secondary-medium", sans-serif;
}

.btn-basic .btn-basic:hover {
  border: none;
  outline: none !important;
}

.btn-basic .btn-basic:focus {
  border: none;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.btn-rounded {
  height: 40px;
  font-size: 16px;
  line-height: 28px;
  border-radius: 100px;
  cursor: pointer;
  font-family: "stepapp-secondary-medium", sans-serif;
}

.btn-rounded .btn-basic:hover {
  border: none;
  outline: none !important;
}

.btn-rounded .btn-basic:focus {
  border: none;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.btn-border-radius {
  height: 40px;
  font-size: 16px;
  line-height: 28px;
  border-radius: 10px;
  cursor: pointer;
  font-family: "stepapp-secondary-medium", sans-serif;
}
.btn-border-radius .btn-basic:hover {
  border: none;
  outline: none !important;
}
.btn-border-radius .btn-basic:focus {
  border: none;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.button-small-basic-pri {
  background-color: #fc8643;
  font-family: "stepapp-secondary-bold", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  border-radius: 5px;
  border: 1px solid transparent;
  padding: 10px;
  min-width: 60px;
  box-shadow: none;
  color: #fff;
  height: 32px;
}

.button-small-basic-pri:hover {
  background-color: #fb6611;
  border: 1px solid transparent;
}

.button-small-basic-pri:focus {
  background-color: #fb6611;
  border: 1px solid #a33d03;
}

.button-small-basic-pri:active,
.button-small-basic-pri:visited {
  background-color: #fc8643;
  border: 1px solid transparent;
}

.button-small-basic-sec {
  background-color: #fff !important;
  font-family: "stepapp-secondary-bold", sans-serif;
  font-size: 12px;
  line-height: 12px;
  border-radius: 5px;
  border: 1px solid #caccce;
  padding: 10px;
  min-width: 60px;
  box-shadow: none;
  color: #000;
  height: 32px;
}

.button-small-basic-sec:hover {
  background-color: #fff;
  border: 1px solid #6c757d;
}

.button-small-basic-sec:active,
.button-small-basic-sec:focus {
  background-color: #b0b3b6;
  border: 1px solid #6c757d;
}

.button-small-basic-sec:visited {
  background-color: #fff;
  border: 1px solid #6c757d;
}

.button-med-basic-sec {
  background-color: #fff !important;
  font-family: "stepapp-secondary-bold", sans-serif;
  font-size: 12px;
  line-height: 12px;
  border-radius: 5px;
  border: 1px solid #caccce;
  padding: 10px;
  min-width: 55px;
  max-width: auto;
  padding: 0px 10px;
  box-shadow: none;
  color: #000;
  height: 45px;
}

.button-med-basic-sec:hover {
  background-color: #fff;
  border: 1px solid #6c757d;
}

.button-med-basic-sec:active,
.button-med-basic-sec:focus {
  background-color: #b0b3b6;
  border: 1px solid #6c757d;
}

.button-med-basic-sec:visited {
  background-color: #fff;
  border: 1px solid #6c757d;
}

.btn-secondary {
  border: none;
  height: 40px;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-size: 14px;
  line-height: 25px;
  border-radius: 100px;
}

.btn-primary {
  font-family: "stepapp-secondary-medium", sans-serif;
  color: #fff;
  background-color: #fc8643 !important;
  border: none;
  height: 40px;
  font-size: 14px;
  font-weight: 600 !important;
  line-height: 25px;
  border-radius: 100px;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #fc8643 !important;
  border-color: none;
}

.btn-outline-none {
  height: 40px;
  font-size: 16px;
  line-height: 28px;
  border-radius: 10px;
  font-family: "stepapp-secondary-medium", sans-serif;
  border: none !important;
}

.btn-outline-none .btn-basic:hover {
  border: none;
  outline: none !important;
}

.btn-outline-none .btn-basic:focus {
  border: none;
  outline: 0px auto -webkit-focus-ring-color !important;
}

/*----------lesson-subject buttons-----*/
.lesson-button-bio {
  background-color: #17bd59;
  border-radius: 50px;
  border: 1px solid transparent;
  padding: 10px;
  width: 100%;
  box-shadow: none;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  height: 45px;
}

.lesson-button-bio:hover {
  background-color: #119044;
  border: none;
}

.lesson-button-bio:active,
.lesson-button-bio:focus {
  background-color: #fc8643;
}

.lesson-button-bio:visited {
  background-color: #17bd59;
}

.lesson-button-maths {
  background-color: #e96969;
  border-radius: 50px;
  border: 1px solid transparent;
  padding: 10px;
  width: 100%;
  box-shadow: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  height: 45px;
}

.lesson-button-maths:hover {
  background-color: #e23d3d;
  border: none;
}

.lesson-button-maths:active,
.lesson-button-maths:focus {
  background-color: #fc8643;
}

.lesson-button-maths:visited {
  background-color: #e23d3d;
}

.lesson-button-unlock {
  background-color: #caccce;
  color: #6c757d;
  border-radius: 50px;
  border: 1px solid transparent;
  padding: 10px;
  width: 100%;
  box-shadow: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 16px;
  height: 45px;
}

.lesson-button-unlock:hover {
  background-color: #e23d3d;
  border: none;
}

.lesson-button-unlock:active,
.lesson-button-unlock:focus {
  background-color: #fc8643;
}

.lesson-button-unlock:visited {
  background-color: #e23d3d;
}

.lesson-button-chemistry {
  background-color: #3396be;
  border-radius: 50px;
  border: 1px solid transparent;
  padding: 10px;
  width: 100%;
  box-shadow: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  height: 45px;
}

.lesson-button-chemistry:hover {
  background-color: #287696;
  border: none;
}

.lesson-button-chemistry:active,
.lesson-button-chemistry:focus {
  background-color: #fc8643;
}

.lesson-button-chemistry:visited {
  background-color: #287696;
}

.lesson-button-physics {
  background-color: #e4ac13;
  border-radius: 50px;
  border: 1px solid transparent;
  width: 100%;
  box-shadow: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  height: 45px;
}

.lesson-button-physics:hover {
  background-color: #b5880f;
  border: none;
}

.lesson-button-physics:active,
.lesson-button-physics:focus {
  background-color: #fc8643;
}

.lesson-button-physics:visited {
  background-color: #e4ac13;
}

.lesson-button-default {
  background-color: #fc8643;
  border-radius: 50px;
  border: 1px solid transparent;
  padding: 10px;
  width: 100%;
  box-shadow: none;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  font-weight: 600;
  height: 40px;
}

.lesson-button-default:hover {
  background-color: #fb6611;
  border: 1px solid transparent;
}

.lesson-button-default:active,
.lesson-button-default:focus {
  background-color: #fc8643;
}

.lesson-button-default:visited {
  background-color: #fc8643;
}

/*------------- subject button end-------*/
/*-----video sec nav buttons-----*/
.lessons-nav-button {
  background-color: #fc8643;
  border-radius: 50px;
  box-shadow: none;
  border: none;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-size: 14px;
  color: #fff;
  height: 35px;
  width: 80px;
}

.lessons-nav-button-disabled {
  background-color: transparent;
  opacity: 0.4;
  border-radius: 50px;
  padding: 5px 10px;
  width: auto;
  box-shadow: none;
  border: none;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  height: 35px;
  cursor: none;
  border: 1px solid #fff;
  opacity: 0.2;
  min-width: 50px;
}

.lessons-nav-button-disabled:hover {
  border: 1px solid #fff !important;
  opacity: 0.2;
}

.lessons-nav-button-disabled:active {
  border: 1px solid #fff !important;
  opacity: 0.2;
}

.lessons-nav-button-disabled:visited {
  border: 1px solid #fff;
  opacity: 0.4;
}

.lessons-nav-button-circle {
  cursor: pointer;
  background-color: #fc8643;
  border-radius: 50px;
  padding: 0px;
  box-shadow: none;
  border: 1px solid transparent;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-size: 18px;
  color: #fff;
  height: 35px;
  width: 35px;
  margin: 0px 10px;
  text-align: center;
  line-height: 35px;
}

.lessons-nav-button-circle:hover {
  border: 1px solid #fff !important;
  opacity: 0.4;
}

.lessons-nav-button-circle:active {
  border: 1px solid #fff !important;
  opacity: 0.4;
}

.lessons-nav-button-circle:visited {
  border: 1px solid #fff;
  opacity: 0.4;
}

/*-----video sec nav buttons end -----*/
/*----modal-popup button---*/
.modal-button {
  background-color: #fc8643;
  border-radius: 10px;
  padding: 5px 20px;
  width: auto;
  box-shadow: none;
  border: none;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-size: 14px;
  line-height: 25px;
  color: #fff;
  height: 35px;
}

.modal-button:hover {
  background-color: #fc8643;
  color: #fff;
}

.modal-button:active,
.modal-button:focus {
  background-color: #fc8643;
  color: #fff;
}

.modal-button:visited {
  background-color: #fc8643;
  color: #fff;
}

.modal-button-sec {
  background-color: #15388c;
  border-radius: 10px;
  padding: 5px 20px;
  width: auto;
  box-shadow: none;
  border: none;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-size: 14px;
  line-height: 25px;
  color: #fff;
  height: 35px;
}

.modal-button-sec:hover {
  background-color: #fb6611;
}

.modal-button-sec:active,
.modal-button-sec:focus {
  background-color: #fc8643;
}

.modal-button-sec:visited {
  background-color: #fc8643;
}

.modal-button-secondary {
  background-color: #15388c;
  border-radius: 10px;
  padding: 5px 20px;
  width: auto;
  box-shadow: none;
  border: none;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-size: 14px;
  line-height: 25px;
  color: #fff;
  height: 35px;
}

.modal-button-secondary:hover {
  background-color: #fb6611;
}

.modal-button-secondary:active,
.modal-button-secondary:focus {
  background-color: #fc8643;
}

.modal-button-secondary:visited {
  background-color: #fc8643;
}

.modal-button-primary {
  background-color: #fc8643;
  border-radius: 10px;
  padding: 5px 20px;
  width: auto;
  box-shadow: none;
  border: none;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-size: 14px;
  line-height: 25px;
  color: #fff;
  height: 35px;
}

.modal-button-primary:hover {
  background-color: #fb6611;
}

.modal-button-primary:active,
.modal-button-primary:focus {
  background-color: #fc8643;
}

.modal-button-primary:visited {
  background-color: #fc8643;
}

.modal-button-grey {
  background-color: #6c757d;
  border-radius: 10px;
  padding: 5px 20px;
  width: auto;
  box-shadow: none;
  border: none;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-size: 14px;
  line-height: 25px;
  color: #caccce;
  height: 35px;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

button.close:hover {
  border: none;
  overflow: 0.9;
}

button.close:active {
  border: none;
}

button.close:visited {
  border: none;
}

.button-small {
  padding: 0;
  background-color: #15388c;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 100px;
  width: 100px;
  padding: 10px;
  height: 35px;
  color: #fff;
  line-height: 14px;
  font-size: 14px;
  font-family: "stepapp-secondary-medium", sans-serif;
}

.button-small:hover {
  border: none;
  opacity: 0.4;
}

.button-small:active {
  border: none;
  opacity: 0.4;
}

.button-small:visited {
  border: none;
  opacity: 0.4;
}

.button-small-success {
  padding: 0;
  background-color: #17bd59;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 100px;
  width: 100px;
  padding: 10px;
  height: 35px;
  color: #fff;
  line-height: 14px;
  font-size: 14px;
  font-family: "stepapp-secondary-medium", sans-serif;
}

.button-small-success:hover {
  border: none;
  opacity: 0.4;
}

.button-small-success:active {
  border: none;
  opacity: 0.4;
}

.button-small-success:visited {
  border: none;
  opacity: 0.4;
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fc8643;
  font-family: "stepapp-secondary-bold", sans-serif;
  font-size: 12px;
  line-height: 12px;
  border-radius: 5px;
  border: 1px solid transparent;
  padding: 10px;
  min-width: 60px;
  box-shadow: none;
  color: #fff;
  height: 32px;
}

.spinner-small-orange {
  width: 100%;
  height: 20px;
  color: #fff;
  background-color: #fc8643;
}

.button-small-orange {
  background-color: #fc8643;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 100px;
  width: 90px;
  padding: 10px;
  height: 30px;
  color: #fff;
  line-height: 11px;
  font-size: 12px;
  font-family: "stepapp-secondary-medium", sans-serif;
}

.button-small-orange:hover {
  border: none;
  background-color: #fb6611;
}

.button-small-orange:active {
  border: none;
  opacity: 0.4;
}

.button-small-orange:visited {
  border: none;
  opacity: 0.4;
}

.button-small-grey {
  padding: 0;
  background-color: #caccce;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 100px;
  width: 90px;
  padding: 10px;
  height: 35px;
  color: #fff;
  line-height: 14px;
  font-size: 14px;
  font-family: "stepapp-secondary-medium", sans-serif;
}

.button-small-grey:hover {
  border: none;
  opacity: 0.4;
}

.button-small-grey:active {
  border: none;
  opacity: 0.4;
}

.button-small-grey:visited {
  border: none;
  opacity: 0.4;
}

.button-small-green {
  padding: 0;
  background-color: #fc8643;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 100px;
  width: 90px;
  padding: 10px;
  height: 35px;
  color: #fff;
  line-height: 14px;
  font-size: 14px;
  font-family: "stepapp-secondary-medium", sans-serif;
}

.button-small-green:hover {
  border: none;
  opacity: 0.4;
}

.button-small-green:active {
  border: none;
  opacity: 0.4;
}

.button-small-green:visited {
  border: none;
  opacity: 0.4;
}

.button-small-grey {
  padding: 0;
  background-color: #caccce;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 100px;
  width: 90px;
  padding: 10px;
  height: 35px;
  color: #fff;
  line-height: 14px;
  font-size: 14px;
  font-family: "stepapp-secondary-medium", sans-serif;
}

.button-small-grey:hover {
  border: none;
  opacity: 0.4;
}

.button-small-grey:active {
  border: none;
  opacity: 0.4;
}

.button-small-grey:visited {
  border: none;
  opacity: 0.4;
}

.button-small-circle {
  padding: 0;
  background-color: #caccce;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 10px;
  height: 35px;
  color: #fff;
  line-height: 13px;
  font-size: 12px;
}

.button-small-circle:hover {
  border: none;
  opacity: 0.4;
}

.button-small-circle:active {
  border: none;
  opacity: 0.4;
}

.button-small-circle:visited {
  border: none;
  opacity: 0.4;
}

.button-item-flex {
  min-width: 100px;
  padding: 5px;
  font-size: 20px;
  margin: 5px;
}

.button-big {
  padding: 0;
  background-color: #fc8643;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 100px;
  width: auto;
  padding: 0px 20px;
  height: 55px;
  color: #fff;
  line-height: 20px;
  font-size: 20px;
  font-family: "stepapp-secondary-medium", sans-serif;
}

/*-------------forquiz------*/
.button-small-quiz {
  padding: 0;
  background-color: #a04610;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 5px;
  width: 100px;
  padding: 10px;
  height: 35px;
  color: #fff;
  line-height: 14px;
  font-size: 14px;
  font-family: "quiz-sec-font";
}

.button-small-quiz:hover {
  border: none;
  opacity: 0.4;
}

.button-small-quiz:active {
  border: none;
  opacity: 0.4;
}

.button-small-quiz:visited {
  border: none;
  opacity: 0.4;
}

.rouded-white-quiz {
  background-color: #fff;
  border-radius: 50px;
  padding: 5px 10px;
  width: auto;
  box-shadow: none;
  border: none;
  font-family: "quiz-sec-font";
  color: #a04610;
  font-size: 14px;
  line-height: 18px;
  height: 35px;
  cursor: pointer;
  border: 1px solid #fff;
  min-width: 50px;
}

.rouded-white-quiz:hover {
  border: 1px solid #fff !important;
  background-color: #fff;
}

.rouded-white-quiz:active {
  border: 1px solid #fff !important;
  background-color: #fff;
}

.rouded-white-quiz:visited {
  border: 1px solid #fff;
  background-color: #fff;
}

.lessons-video-suggest-btn {
  color: #fff;
  height: 45px !important;
  width: 155px !important;
  line-height: 41px !important;
}

/*--- added for calendar buttons for event---*/
.btn-cal {
  height: 40px;
  font-size: 16px;
  line-height: 28px;
  border-radius: 10px;
  font-family: "stepapp-secondary-medium", sans-serif;
  padding: 0px 10px;
  border: none;
  box-shadow: none;
  outline: none;
}

.btn-join {
  background: #17bd59;
  border: none;
  color: #fff;
}

.btn-join:hover {
  background: #139948;
  transition: all 0.3s ease;
}

.btn-join:active {
  background: #094b23;
}

.btn-blue {
  background: #3396be;
  border: none;
  color: #fff;
}

.btn-blue:hover {
  background: #2a7d9e;
  transition: all 0.3s ease;
}

.btn-blue:active {
  background: #184759;
}

.btn-disable {
  background: #adb5bd;
  border: none;
  color: #fff;
  color: #7a7f9a;
  cursor: not-allowed;
}

.btn-disable:hover {
  background: #96a1ab;
  transition: all 0.3s ease;
}

.btn-disable:active {
  background: #697582;
}

.btn-danger {
  background: #e74c3c;
  border: none;
  color: #fff;
}

.btn-danger:hover {
  background: #df2e1b;
  transition: all 0.3s ease;
}

.btn-danger:active {
  background: #921e12;
}

.button-doubt-resolved {
  background-color: #fc8643;
  font-family: "stepapp-secondary-bold", sans-serif;
  font-size: 12px;
  line-height: 12px;
  border-radius: 5px;
  border: 1px solid #fc8643;
  padding: 0px 10px;
  min-width: 60px;
  box-shadow: none;
  color: #fff;
  height: 20px;
}

.button-doubt-resolved:hover {
  background-color: #fb6611;
  border: 1px solid #fc8643;
}

.button-doubt-resolved:active,
.button-doubt-resolved:focus {
  background-color: #fb6611;
  border: 1px solid #fc8643;
}

.button-doubt-resolved:visited {
  background-color: #fc8643;
  border: 1px solid #fc8643;
}

.button-doubt-progress {
  background-color: #17bd59;
  font-family: "stepapp-secondary-bold", sans-serif;
  font-size: 12px;
  line-height: 12px;
  border-radius: 5px;
  border: 1px solid #17bd59;
  padding: 0px 10px;
  min-width: 60px;
  box-shadow: none;
  color: #fff;
  height: 20px;
}

.button-doubt-progress:hover {
  background-color: #119044;
  border: 1px solid #6c757d;
}

.button-doubt-progress:active,
.button-doubt-progress:focus {
  background-color: #119044;
  border: 1px solid #6c757d;
}

.button-doubt-progress:visited {
  background-color: #fff;
  border: 1px solid #17bd59;
}

.btn-tab {
  height: 29px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 12px !important;
}

.update-button-pos {
  position: absolute;
  left: -90px;
  top: 0px;
  width: 92px;
  z-index: 1000;
}

.resync-button-pos {
  position: absolute;
  left: -300px;
  top: 0px;
  width: 92px;
  z-index: 1000;
}

.btn-wrap-text {
  white-space: normal !important;
  word-wrap: break-word !important;
  border-radius: 10px;
  border: 1px solid #adb5bd;
  height: 35px;
  width: auto;
  display: inline-block;
  font-weight: 400;
  color: #495057;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  padding: 0.47rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1 !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (max-width: 991.98px) {
  .btn-wrap-text {
    white-space: normal !important;
    word-wrap: break-word !important;
    border-radius: 10px;
    border: 1px solid #adb5bd;
    height: 60px;
    width: 94px;
  }
}

.custom-select {
  position: relative;
  font-family: "stepapp-secondary-regular", sans-serif;
  border-radius: 10px;
  height: 50px;
  padding-left: 20px;
}

.custom-select select {
  display: none;
  /*hide original SELECT element:*/
}

.select-selected {
  background-color: #fc8643;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: #fc8643;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.custom-select:focus {
  border-color: #fc8643;
  outline: 0;
  box-shadow: none;
}

.modal-content {
  border-radius: 15px;
  outline: 0;
}

.modal-title {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  transition: all 0.5s ease 0s;
  text-decoration: none;
}

.modal-dialog-centered::before {
  display: initial !important;
  height: calc(100vh - 1rem);
  content: "";
}

.info-screen {
  background-color: #15388c !important;
  color: #fff;
}

.info-screen-border-seperation {
  border-top: 1px solid #3b3398;
}

/*----added by Abhijeet---*/
.info-popup-backdrop {
  z-index: 1051;
}

.info-popup-modal {
  z-index: 1071;
}

/*----added by Abhijeet---*/
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #fff;
  border-radius: 10px;
  outline: 0;
}

.modal-content {
  background-color: #f4f4f4;
}

.modal.fade .modal-dialog {
  transform: translate(0, 20%);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

@-webkit-keyframes slideIn {
  0% {
    transform: translateX(500px) scale(0.2);
  }

  100% {
    transform: translateX(0px) scale(1);
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(500px) scale(0.2);
  }

  100% {
    transform: translateX(0px) scale(1);
  }
}

@-webkit-keyframes slideUp {
  0% {
    transform: translateY(300px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(300px);
  }

  100% {
    transform: translateY(0px);
  }
}

@-webkit-keyframes expand {
  0% {
    transform: translateX(100px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes expand {
  0% {
    transform: translateX(100px);
  }

  100% {
    transform: translateX(0px);
  }
}

@-webkit-keyframes fadeUp {
  0% {
    transform: translate3d(0, 100px, 0);
  }

  100% {
    transform: translate3d(0, 0px, 0);
  }
}

@keyframes fadeUp {
  0% {
    transform: translate3d(0, 100px, 0);
  }

  100% {
    transform: translate3d(0, 0px, 0);
  }
}

.transition-fadeup {
  -webkit-animation: fadeUp 0.8s ease 0.5s;
  animation: fadeUp 0.8s ease 0.5s;
}

@-webkit-keyframes scaleUp {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.scaleUp {
  -webkit-animation: scaleUp 0.5s ease forwards 0.5s;
  animation: scaleUp 0.5s ease forwards 0.5s;
}

.uk-animation-slide-right-small {
  -webkit-animation-name: uk-fade-right-small;
  animation-name: uk-fade-right-small;
}

.coin {
  cursor: pointer;
  content: "";
  width: 52px;
  height: 52px;
  display: inline-block;
  position: absolute;
  bottom: -30px;
  border-radius: 50px;
  z-index: 500;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.1);
}

.silver {
  background: linear-gradient(45deg, #a0a0a0 0%, #e8e8e8 56%);
  border: none;
}

.silver:before {
  background: linear-gradient(45deg, #b5b5b5 0%, #fcfcfc 56%, #e8e8e8 96%);
  border: 1px solid #b5b5b5;
}

.silver:after {
  background: linear-gradient(45deg, #b5b5b5 0%, #fcfcfc 56%, #e8e8e8 96%);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(160, 160, 160, 0.3);
  border-right: 1px solid rgba(160, 160, 160, 0.5);
  box-shadow: inset 0px 0px 2px 2px rgba(150, 150, 150, 0.05);
}

.silver:hover:after {
  background: linear-gradient(45deg, #b5b5b5 0%, #fcfcfc 38%, #e8e8e8 96%);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(160, 160, 160, 0.3);
  border-right: 1px solid rgba(160, 160, 160, 0.5);
  box-shadow: inset 0px 0px 2px 2px rgba(150, 150, 150, 0.05);
}

.coin.silver p {
  color: #15388c;
  font-weight: 800;
}

.coin p {
  font-family: "stepapp-secondary-bold", sans-serif;
  position: absolute;
  font-size: 12px;
  text-align: center;
  z-index: 700;
  top: 17px;
  left: auto;
  color: #15388c;
  width: 102%;
}

.coin.golden p {
  color: #15388c;
}

.golden {
  background: linear-gradient(45deg, #f0e68c 0%, #ffd700 56%);
  border: none;
}

/*---------for quiz fab icon---------*/
.material {
  width: auto;
}

.clearfix:after,
.clearfix:before {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
  display: block;
}

.material .profile {
  position: relative;
}

.material .profile .links,
.material .profile .toggle,
.material .profile .links a {
  bottom: 0px;
  left: 0px;
  z-index: 8;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  position: absolute;
}

.material .profile .plus {
  display: none;
}

.material .profile .toggle {
  z-index: 10;
  background: #000;
  transition: all 0.2s ease-in-out 0s;
}

.material .profile .toggle:before,
.material .profile .toggle:after {
  top: 50%;
  left: 50%;
  content: "";
  background: #eee;
  position: inherit;
}

.material .profile .toggle:before {
  width: 12px;
  height: 1px;
  margin-top: -0.5px;
  margin-left: -6px;
}

.material .profile .toggle:after {
  width: 1px;
  height: 12px;
  margin-top: -6px;
  margin-left: -0.5px;
}

.material .profile .links a {
  bottom: 10px;
  z-index: 8;
  right: 0px;
  left: 10px;
  width: 35px;
  height: 35px;
  color: #343434;
  background: #000;
  text-align: center;
  line-height: 35px;
  transform: none;
  transition: all 0.3s ease-in-out 0s;
}

.material .profile .links a:before {
  top: 50%;
  left: 50px;
  color: #ccc;
  height: 20px;
  opacity: 0;
  font-size: 12px;
  min-width: 65px;
  margin-top: -10px;
  line-height: 20px;
  position: inherit;
  border-radius: 2px;
  content: attr(data-title);
  background: rgba(0, 0, 0, 0.4);
  transition: all 0.4s ease-in-out 0s;
}

.material .profile .plus:checked+.toggle {
  transform: rotate(45deg);
}

.material .profile .plus:checked+.toggle+.links a:nth-child(2) {
  transform: translate(0, -86px);
  transition-duration: 0.3s;
}

.material .profile .plus:checked+.toggle+.links a:nth-child(1) {
  transform: translate(0, -55px);
  transition-duration: 0.2s;
}

.material .profile .plus:checked+.toggle+.links a:before {
  opacity: 1;
  transform: translate(-5px, 0);
}

.material .profile .plus:checked+.toggle+.links a:nth-child(1):before {
  transition-duration: 0.95s;
}

.material .profile .plus:checked+.toggle+.links a:nth-child(2):before {
  transition-duration: 0.85s;
}

.fab-disabled {
  opacity: 0.65;
  cursor: not-allowed;
  background-color: #000;
  border-radius: 50%;
}

.fab-disabled:hover,
.fab-disabled:focus,
.fab-disabled:active {
  background-color: #000;
  color: #fff;
  opacity: 0.65;
  cursor: not-allowed;
}

/*---------------confetti animation-----*/
.confetti-container {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  width: 100%;
  height: 30vh;
  padding: 5px 10px;
  font-size: 20px;
  text-align: center;
  margin: auto;
}

.confetti {
  top: 0px;
  -webkit-animation: confetti 5s ease-in-out -2s infinite;
  animation: confetti 5s ease-in-out -2s infinite;
  transform-origin: left top;
}

.confetti-w {
  top: 0px;
  -webkit-animation: confetti-w 3s ease-in-out -3s infinite;
  animation: confetti-w 3s ease-in-out -3s infinite;
  transform-origin: left top;
}

.confetti:nth-child(1) {
  background-image: url("../images/svg-elements/con-01.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  border: none;
  left: 0%;
  -webkit-animation-delay: 0;
  animation-delay: 0;
}

.confetti:nth-child(2) {
  background-color: #f2d74e;
  left: 10%;
  -webkit-animation-delay: -6s;
  animation-delay: -6s;
  border-radius: 50%;
}

.confetti:nth-child(3) {
  background-color: #ff9a91;
  left: 15%;
  -webkit-animation-delay: -3s;
  animation-delay: -3s;
  width: 20px;
  height: 20px;
}

.confetti:nth-child(4) {
  background-image: url("../images/svg-elements/con-02.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  left: 20%;
  -webkit-animation-delay: -5s;
  animation-delay: -5s;
}

.confetti:nth-child(5) {
  background-color: #95c3de;
  left: 30%;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
  width: 20px;
  height: 20px;
  border: 50%;
}

.confetti:nth-child(6) {
  background-color: transparent;
  left: 40%;
  -webkit-animation-delay: -2.5s;
  animation-delay: -2.5s;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #e96969;
}

.confetti:nth-child(7) {
  background-color: #f2d74e;
  left: 50%;
  -webkit-animation-delay: -4s;
  animation-delay: -4s;
  width: 10px;
  height: 10px;
  border: 50%;
}

.confetti:nth-child(8) {
  background-color: #8f00d1;
  left: 60%;
  -webkit-animation-delay: -6s;
  animation-delay: -6s;
  width: 20px;
  height: 20px;
  border: 50%;
}

.confetti:nth-child(9) {
  background-color: transparent;
  left: 70%;
  -webkit-animation-delay: -1.5s;
  animation-delay: -1.5s;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #ff9a91;
}

.confetti:nth-child(10) {
  background-color: #e55abb;
  left: 80%;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
  width: 20px;
  height: 20px;
  border: 50%;
}

.confetti:nth-child(11) {
  background-image: url("../images/svg-elements/con-03.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  left: 90%;
  -webkit-animation-delay: -3.5s;
  animation-delay: -3.5s;
  width: 20px;
}

.confetti:nth-child(12) {
  background-color: transparent;
  left: 100%;
  -webkit-animation-delay: -2.5s;
  animation-delay: -2.5s;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #8f00d1;
  border-radius: 50px;
}

@-webkit-keyframes confetti {
  0% {
    transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
  }

  25% {
    transform: rotateZ(5deg) rotateY(360deg) translate(-5vw, 20vh);
  }

  50% {
    transform: rotateZ(15deg) rotateY(720deg) translate(5vw, 60vh);
  }

  75% {
    transform: rotateZ(5deg) rotateY(1080deg) translate(-10vw, 80vh);
  }

  100% {
    transform: rotateZ(15deg) rotateY(1440deg) translate(10vw, 110vh);
  }
}

@keyframes confetti {
  0% {
    transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
  }

  25% {
    transform: rotateZ(5deg) rotateY(360deg) translate(-5vw, 20vh);
  }

  50% {
    transform: rotateZ(15deg) rotateY(720deg) translate(5vw, 60vh);
  }

  75% {
    transform: rotateZ(5deg) rotateY(1080deg) translate(-10vw, 80vh);
  }

  100% {
    transform: rotateZ(15deg) rotateY(1440deg) translate(10vw, 110vh);
  }
}

@-webkit-keyframes confetti-w {
  0% {
    transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
  }

  25% {
    transform: rotateZ(5deg) rotateY(360deg) translate(-5vw, 20vh);
  }

  50% {
    transform: rotateZ(15deg) rotateY(720deg) translate(5vw, 60vh);
  }

  75% {
    transform: rotateZ(5deg) rotateY(1080deg) translate(-10vw, 80vh);
  }

  100% {
    transform: rotateZ(15deg) rotateY(1440deg) translate(10vw, 110vh);
  }
}

@keyframes confetti-w {
  0% {
    transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
  }

  25% {
    transform: rotateZ(5deg) rotateY(360deg) translate(-5vw, 20vh);
  }

  50% {
    transform: rotateZ(15deg) rotateY(720deg) translate(5vw, 60vh);
  }

  75% {
    transform: rotateZ(5deg) rotateY(1080deg) translate(-10vw, 80vh);
  }

  100% {
    transform: rotateZ(15deg) rotateY(1440deg) translate(10vw, 110vh);
  }
}

.confetti-w:nth-child(1) {
  background-image: url("../images/svg-elements/con-w.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  border: none;
  left: 3%;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  top: 0%;
}

.confetti-w:nth-child(2) {
  background-image: url("../images/svg-elements/con-w.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  border: none;
  top: 0%;
  left: 25%;
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.confetti-w:nth-child(3) {
  background-image: url("../images/svg-elements/con-w.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  top: 0%;
  left: 50%;
  -webkit-animation-delay: -1.5s;
  animation-delay: -1.5s;
}

.confetti-w:nth-child(4) {
  background-image: url("../images/svg-elements/con-w.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  top: 0%;
  left: 75%;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

.confetti-w:nth-child(5) {
  background-image: url("../images/svg-elements/con-w.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  top: 0%;
  left: 100%;
  -webkit-animation-delay: -2.5s;
  animation-delay: -2.5s;
}

/*------------countdown----------------*/
.demo {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 300px;
  height: 140px;
  margin-top: -70px;
  padding: 10px;
  border-radius: 20px;
  transform: translateX(-50%);
}

.demo__colored-blocks {
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 0;
  width: 300px;
  height: 100%;
  margin-left: -150px;
  padding: 10px;
  border-radius: 20px;
  perspective: 1000px;
  -webkit-animation: demoAnim 4s ease-in-out infinite, contAnim 4s infinite;
  animation: demoAnim 4s ease-in-out infinite, contAnim 4s infinite;
}

.demo__colored-blocks-rotater {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  -webkit-animation: rotation 1.3s linear infinite;
  animation: rotation 1.3s linear infinite;
}

.demo__colored-blocks-inner {
  overflow: hidden;
  position: relative;
  height: 100%;
  background: #32386d;
  border-radius: inherit;
}

.demo__colored-block {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 300%;
  height: 300%;
  transform-origin: 0 0;
}

.demo__colored-block:nth-child(1) {
  transform: rotate(0deg) skewX(-30deg);
  background-color: #e96969;
}

.demo__colored-block:nth-child(2) {
  transform: rotate(120deg) skewX(-30deg);
  background-color: #f4d302;
}

.demo__colored-block:nth-child(3) {
  transform: rotate(240deg) skewX(-30deg);
  background-color: #3396be;
}

.demo__inner {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.demo__numbers {
  overflow: visible;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px !important;
  height: 50px !important;
  margin-left: -25px;
  margin-top: -25px;
}

.demo__numbers-path {
  fill: none;
  stroke-width: 10px;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 0, 518.055065155;
  stroke-dashoffset: 0;
  -webkit-animation: numAnim 4s ease-in-out infinite;
  animation: numAnim 4s ease-in-out infinite;
  opacity: 0;
}

.demo__text {
  position: absolute;
  left: 50%;
  top: 0;
  width: 300px;
  height: 100%;
  margin-left: -150px;
  text-align: center;
  line-height: 140px;
  font-size: 100px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0px;
  transform: translateX(10px);
  -webkit-animation: hideText 4s infinite;
  animation: hideText 4s infinite;
}

@-webkit-keyframes contAnim {

  15%,
  100% {
    margin-left: -150px;
    width: 300px;
  }

  25%,
  90% {
    margin-left: -70px;
    width: 140px;
  }
}

@keyframes contAnim {

  15%,
  100% {
    margin-left: -150px;
    width: 300px;
  }

  25%,
  90% {
    margin-left: -70px;
    width: 140px;
  }
}

@-webkit-keyframes numAnim {
  15% {
    stroke-dasharray: 0, 518.055065155;
    stroke-dashoffset: 0;
    opacity: 0;
  }

  25%,
  41% {
    opacity: 1;
    stroke-dasharray: 144.4256591797, 518.055065155;
    stroke-dashoffset: -40;
  }

  53%,
  66% {
    stroke-dasharray: 136.0216217041, 518.055065155;
    stroke-dashoffset: -227.238697052;
  }

  76% {
    stroke-dasharray: 113.4751205444, 518.055065155;
    stroke-dashoffset: -445.8995704651;
  }

  88%,
  100% {
    stroke-dasharray: 72.1554946899, 518.055065155;
    stroke-dashoffset: -445.8995704651;
  }

  92% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes numAnim {
  15% {
    stroke-dasharray: 0, 518.055065155;
    stroke-dashoffset: 0;
    opacity: 0;
  }

  25%,
  41% {
    opacity: 1;
    stroke-dasharray: 144.4256591797, 518.055065155;
    stroke-dashoffset: -40;
  }

  53%,
  66% {
    stroke-dasharray: 136.0216217041, 518.055065155;
    stroke-dashoffset: -227.238697052;
  }

  76% {
    stroke-dasharray: 113.4751205444, 518.055065155;
    stroke-dashoffset: -445.8995704651;
  }

  88%,
  100% {
    stroke-dasharray: 72.1554946899, 518.055065155;
    stroke-dashoffset: -445.8995704651;
  }

  92% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes rotation {
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes demoAnim {
  15% {
    border-radius: 20px;
    transform: rotate(0);
  }

  30%,
  43% {
    border-radius: 50%;
    transform: rotate(360deg);
  }

  52%,
  65% {
    border-radius: 0;
    transform: rotate(720deg);
  }

  78%,
  90% {
    border-radius: 50%;
    transform: rotate(1080deg);
  }

  100% {
    border-radius: 20px;
    transform: rotate(1440deg);
  }
}

@keyframes demoAnim {
  15% {
    border-radius: 20px;
    transform: rotate(0);
  }

  30%,
  43% {
    border-radius: 50%;
    transform: rotate(360deg);
  }

  52%,
  65% {
    border-radius: 0;
    transform: rotate(720deg);
  }

  78%,
  90% {
    border-radius: 50%;
    transform: rotate(1080deg);
  }

  100% {
    border-radius: 20px;
    transform: rotate(1440deg);
  }
}

@-webkit-keyframes hideText {

  15%,
  100% {
    opacity: 1;
  }

  20%,
  96% {
    opacity: 0;
  }
}

@keyframes hideText {

  15%,
  100% {
    opacity: 1;
  }

  20%,
  96% {
    opacity: 0;
  }
}

.demo__text {
  font-family: "quiz-sec-font";
  font-size: 50px;
}

/*--------------Memes Card-------------------*/
.container-memecard {
  position: relative;
}

.container-memecard .page-title,
.container-memecard .page-sub-title {
  color: white;
  text-align: center;
}

.card-container {
  perspective: 1000;
  margin: 10% auto 50px;
  width: 400px;
}

.front,
.back {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: 30px;
  box-sizing: border-box;
  transition-delay: 0.15s;
  transition: 0;
}

.front {
  transform: rotateY(0deg);
}

.back {
  transform: rotateY(180deg);
  opacity: 0;
  padding-top: 60px;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  background: #333741;
  color: white;
}

.card-meme {
  position: relative;
  width: 400px;
  height: 430px;
  background: white;
  border-radius: 5px;
  overflow: hidden;
  transition: 0.5s;
  transform-style: preserve-3d;
}

.sub-title-meme {
  font-family: "quiz-title-font";
  color: #000;
  -webkit-animation: slide-in 0.75s;
  animation: slide-in 0.75s;
  -webkit-animation: slide-in 0.75s cubic-bezier(0.625, 0.075, 0.11, 1.165);
  animation: slide-in 0.75s cubic-bezier(0.625, 0.075, 0.11, 1.165);
  -webkit-animation-timing-function: cubic-bezier(0.625, 0.075, 0.11, 1.165);
  animation-timing-function: cubic-bezier(0.625, 0.075, 0.11, 1.165);
  font-size: 18px;
  font-weight: 200;
  margin: 0 0 5px 0;
}

.title-meme {
  font-family: "quiz-sec-font-reg";
  color: #000;
  -webkit-animation: slide-in-slow 0.75s;
  animation: slide-in-slow 0.75s;
  -webkit-animation: slide-in-slow 0.75s cubic-bezier(0.625, 0.075, 0.11, 1.165);
  animation: slide-in-slow 0.75s cubic-bezier(0.625, 0.075, 0.11, 1.165);
  -webkit-animation-timing-function: cubic-bezier(0.625, 0.075, 0.11, 1.165);
  animation-timing-function: cubic-bezier(0.625, 0.075, 0.11, 1.165);
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.synopsis {
  color: #de6262;
  font-size: 27px;
  font-weight: 600;
  margin: 0 0 15px;
}

.image-meme {
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-animation: slide-in-right 1.25s;
  animation: slide-in-right 1.25s;
  -webkit-animation: slide-in-right 1.25s cubic-bezier(0.625, 0.075, 0.11, 1.165);
  animation: slide-in-right 1.25s cubic-bezier(0.625, 0.075, 0.11, 1.165);
  -webkit-animation-timing-function: cubic-bezier(0.625, 0.075, 0.11, 1.165);
  animation-timing-function: cubic-bezier(0.625, 0.075, 0.11, 1.165);
  font-size: 35px;
  font-weight: 600;
  margin: 0;
  width: 400px;
  height: auto;
}

@-webkit-keyframes slide-in {
  0% {
    transform: translate3d(-300px, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slide-in {
  0% {
    transform: translate3d(-300px, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slide-in-slow {
  0% {
    transform: translate3d(-300px, 0, 0);
  }

  20% {
    transform: translate3d(-300px, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slide-in-slow {
  0% {
    transform: translate3d(-300px, 0, 0);
  }

  20% {
    transform: translate3d(-300px, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slide-in-right {
  0% {
    transform: translate3d(400px, 0, 0);
  }

  30% {
    transform: translate3d(400px, 0, 0);
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    transform: translate3d(400px, 0, 0);
  }

  30% {
    transform: translate3d(400px, 0, 0);
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

/* for clouds animation */
#background-wrap {
  bottom: -10px;
  left: 0;
  padding-top: 50px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 0;
}

/* KEYFRAMES */
@-webkit-keyframes animateCloud {
  0% {
    margin-left: -1000px;
  }

  100% {
    margin-left: 100%;
  }
}

@keyframes animateCloud {
  0% {
    margin-left: -1000px;
  }

  100% {
    margin-left: 100%;
  }
}

/* ANIMATIONS */
.x1 {
  -webkit-animation: animateCloud 35s linear infinite;
  animation: animateCloud 35s linear infinite;
  transform: scale(0.65);
}

.x2 {
  -webkit-animation: animateCloud 20s linear infinite;
  animation: animateCloud 20s linear infinite;
  transform: scale(0.3);
}

.x3 {
  -webkit-animation: animateCloud 30s linear infinite;
  animation: animateCloud 30s linear infinite;
  transform: scale(0.5);
}

.x4 {
  -webkit-animation: animateCloud 18s linear infinite;
  animation: animateCloud 18s linear infinite;
  transform: scale(0.4);
}

.x5 {
  -webkit-animation: animateCloud 25s linear infinite;
  animation: animateCloud 25s linear infinite;
  transform: scale(0.55);
}

/* OBJECTS */
.cloud {
  background: url("../images/island/cloud1.svg");
  height: 95px;
  position: relative;
  width: 350px;
}

.pos-bottom-clouds {
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  bottom: 0px;
}

.bottom-clouds {
  background: url("../images/island/bottomclouds.svg");
  height: 120px;
  width: 100%;
  background-repeat: no-repeat;
  text-align: center;
  background-position: center;
  display: flex;
  justify-self: center;
}

/*---- to include for island animation */
.floating-island {
  -webkit-animation-name: floating;
  animation-name: floating;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@keyframes floating {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(0.75rem);
  }
}

.shake {
  -webkit-animation: shake 1.5s linear 0s infinite;
  animation: shake 1.5s linear 0s infinite;
}

@-webkit-keyframes shake {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  25% {
    transform: rotate(10deg) translate3d(0, 0, 0);
  }

  50% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  75% {
    transform: rotate(-10deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}

@keyframes shake {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  25% {
    transform: rotate(10deg) translate3d(0, 0, 0);
  }

  50% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  75% {
    transform: rotate(-10deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}

.scale-icon {
  -webkit-animation: scale-icon 3s alternate infinite ease-in;
  animation: scale-icon 3s alternate infinite ease-in;
}

@-webkit-keyframes scale-icon {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes scale-icon {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.loader {
  --duration: 3s;
  width: 44px;
  height: 44px;
  position: relative;
}

.loader:before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  display: block;
  background: #fc8643;
  top: 37px;
  left: 19px;
  transform: translate(-18px, -18px);
  -webkit-animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader svg {
  display: block;
  width: 100%;
  height: 100%;
}

.loader svg rect,
.loader svg polygon,
.loader svg circle {
  fill: none;
  stroke: #fff;
  stroke-width: 10px;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.loader svg polygon {
  stroke-dasharray: 145 76 145 76;
  stroke-dashoffset: 0;
  -webkit-animation: pathTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  animation: pathTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader svg rect {
  stroke-dasharray: 192 64 192 64;
  stroke-dashoffset: 0;
  -webkit-animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader svg circle {
  stroke-dasharray: 150 50 150 50;
  stroke-dashoffset: 75;
  -webkit-animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader.triangle {
  width: 48px;
}

.loader.triangle:before {
  left: 21px;
  transform: translate(-10px, -18px);
  -webkit-animation: dotTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  animation: dotTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

@-webkit-keyframes pathTriangle {
  33% {
    stroke-dashoffset: 74;
  }

  66% {
    stroke-dashoffset: 147;
  }

  100% {
    stroke-dashoffset: 221;
  }
}

@keyframes pathTriangle {
  33% {
    stroke-dashoffset: 74;
  }

  66% {
    stroke-dashoffset: 147;
  }

  100% {
    stroke-dashoffset: 221;
  }
}

@-webkit-keyframes dotTriangle {
  33% {
    transform: translate(0, 0);
  }

  66% {
    transform: translate(10px, -18px);
  }

  100% {
    transform: translate(-10px, -18px);
  }
}

@keyframes dotTriangle {
  33% {
    transform: translate(0, 0);
  }

  66% {
    transform: translate(10px, -18px);
  }

  100% {
    transform: translate(-10px, -18px);
  }
}

@-webkit-keyframes pathRect {
  25% {
    stroke-dashoffset: 64;
  }

  50% {
    stroke-dashoffset: 128;
  }

  75% {
    stroke-dashoffset: 192;
  }

  100% {
    stroke-dashoffset: 256;
  }
}

@keyframes pathRect {
  25% {
    stroke-dashoffset: 64;
  }

  50% {
    stroke-dashoffset: 128;
  }

  75% {
    stroke-dashoffset: 192;
  }

  100% {
    stroke-dashoffset: 256;
  }
}

@-webkit-keyframes dotRect {
  25% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(18px, -18px);
  }

  75% {
    transform: translate(0, -36px);
  }

  100% {
    transform: translate(-18px, -18px);
  }
}

@keyframes dotRect {
  25% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(18px, -18px);
  }

  75% {
    transform: translate(0, -36px);
  }

  100% {
    transform: translate(-18px, -18px);
  }
}

@-webkit-keyframes pathCircle {
  25% {
    stroke-dashoffset: 125;
  }

  50% {
    stroke-dashoffset: 175;
  }

  75% {
    stroke-dashoffset: 225;
  }

  100% {
    stroke-dashoffset: 275;
  }
}

@keyframes pathCircle {
  25% {
    stroke-dashoffset: 125;
  }

  50% {
    stroke-dashoffset: 175;
  }

  75% {
    stroke-dashoffset: 225;
  }

  100% {
    stroke-dashoffset: 275;
  }
}

.loader {
  display: inline-block;
  margin: 0 16px;
}

.loader .svg {
  width: 50px;
  height: 50px;
  margin: 20px;
  display: inline-block;
}

.loader h1 {
  text-align: center;
  color: #fff;
  margin: 0 0 100px;
  font-size: 34px;
  font-weight: 100;
  text-transform: uppercase;
  background-color: #fc762a;
  padding: 20px 0;
}

.loader h1 b {
  font-weight: 700;
}

* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

/*---2----*/
.ant-table {
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
}

.ant-message-notice-content {
  border-radius: 10px !important;
}

.svg-social-media {
  width: 100px;
  height: 100px;
  margin: 20px;
  display: inline-block;
}

.section-overlay {
  width: 100px;
  height: 55px;
  position: absolute;
  top: 0px;
  left: 130px;
  background-color: #000;
  z-index: 2000;
  border-radius: 0px 0px 15px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*--------------StarRating-------------*/
.dv-star-rating-empty-star {
  width: 40px;
  margin: 5px;
  text-align: center;
  height: 40px;
  line-height: 31px;
  display: block;
  background: #3396be;
  color: #fff;
  border: none;
  border-radius: 15px;
  text-align: center;
  position: relative;
  opacity: 1;
  font-size: 26px;
  font-family: "stepapp-secondary-regular", sans-serif;
  border: 3px solid transparent;
}

.dv-star-rating-full-star {
  width: 40px;
  margin: 5px;
  text-align: center;
  height: 40px;
  line-height: 31px;
  display: block;
  background: #3396be;
  color: #fff;
  border: none;
  border-radius: 15px;
  text-align: center;
  position: relative;
  opacity: 1;
  font-size: 26px;
  font-family: "stepapp-secondary-regular", sans-serif;
  border: 3px solid transparent;
}

.rating-item-flex {
  width: 50px;
  margin: 5px;
  text-align: center;
  height: 50px;
  line-height: 43px;
  display: block;
  background: #3396be;
  color: #fff;
  border: none;
  border-radius: 15px;
  text-align: center;
  position: relative;
  opacity: 1;
  font-size: 26px;
  font-family: "stepapp-secondary-regular", sans-serif;
  border: 3px solid transparent;
}

/*----------for sidepanel drawer----*/
.ant-drawer-content {
  position: relative;
  z-index: 1;
  overflow: auto;
  background-color: #f57b76;
  background-clip: padding-box;
  border: 0;
  font-family: "quiz-sec-font";
}

.ant-drawer-header {
  position: relative;
  padding: 16px 24px;
  color: #fff;
  background-color: #fcac5e;
  border-bottom: 1px solid #d08b49;
  border-radius: 2px 2px 0 0;
  font-family: "quiz-sec-font";
}

.ant-drawer-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-family: "quiz-sec-font";
}

.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none;
}

.ant-drawer-header {
  background-color: #e9e9e9;
}

/* subject themes for card  */
.subject-maths .card-bg {
  background-color: #e96969;
  color: #fff !important;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.subject-maths .chart-item-flex-sel {
  background-color: #e96969;
  padding: 20px;
  color: #fff !important;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: -20px;
}

.subject-maths .subject-circle-icon-bg,
.subject-maths .subject-circle-icon-bg-small {
  background-color: #e96969;
}
.subject-maths .quiz-subject-card,
.subject-maths .askdoubt-subject-card {
  background-color: #e96969 !important;
  color: #fff !important;
}
.subject-maths .quiz-subject-label {
  color: #fff !important;
}

.subject-maths .quiz-bg-subject-icon {
  color: #fff;
  background-color: transparent !important;
  font-size: 60px;
}

.subject-maths .subject-title {
  color: #e96969;
}

.subject-maths .subject-badge {
  color: #e96969;
  font-weight: 600;
}

.subject-maths .subject-icon-rails {
  background-color: #e96969;
  color: #fff;
}

.subject-maths .text-color {
  color: #e96969;
  text-transform: capitalize;
}

.subject-maths .bg-count-subject {
  background-color: #e96969;
}

.subject-maths .assignment-complete {
  color: #e96969;
}

.subject-maths .progress {
  background-color: #caccce;
  padding: 0px;
  border-radius: 100px;
}

.subject-maths .progress-bar-subject {
  background-color: #e96969;
  border-radius: 100px;
  color: #fff !important;
}

.subject-maths .card-button-default {
  background-color: #e96969;
  color: #fff !important;
}

.subject-maths .circle-icon-accordian {
  font-size: 30px;
  background-color: #e96969;
  color: #fff;
  border-radius: 0px 0px 0px 28px;
  padding: 5px;
  font-size: 30px;
}

.subject-maths .subject-icon-askdoubt {
  background-color: #e96969;
  color: #fff;
}

.subject-maths .doubt-subject-flex {
  background-color: #e96969;
  color: #fff;
}

.subject-chemistry .card-bg {
  background-color: #3396be;
  color: #fff !important;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.subject-chemistry .chart-item-flex-sel {
  background-color: #3396be;
  padding: 20px;
  color: #fff !important;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: -20px;
}

.subject-chemistry .subject-circle-icon-bg,
.subject-chemistry .subject-circle-icon-bg-small {
  background-color: #3396be;
}
.subject-chemistry .quiz-subject-card,
.subject-chemistry .askdoubt-subject-card {
  background-color: #3396be !important;
  color: #fff !important;
}
.subject-chemistry .quiz-subject-label {
  color: #fff !important;
}

.subject-chemistry .quiz-bg-subject-icon {
  color: #fff;
  background-color: transparent !important;
  font-size: 60px;
}

.subject-chemistry .subject-title {
  color: #3396be;
}

.subject-chemistry .subject-badge {
  color: #3396be;
  font-weight: 600;
}

.subject-chemistry .subject-icon-rails {
  background-color: #3396be;
  color: #fff;
}

.subject-chemistry .text-color {
  color: #3396be;
  text-transform: capitalize;
}

.subject-chemistry .bg-count-subject {
  background-color: #3396be;
}

.subject-chemistry .assignment-complete {
  color: #3396be;
}

.subject-chemistry .progress {
  background-color: #caccce;
  padding: 0px;
  border-radius: 100px;
}

.subject-chemistry .progress-bar-subject {
  background-color: #3396be;
  border-radius: 100px;
  color: #fff !important;
}

.subject-chemistry .card-button-default {
  background-color: #3396be;
  color: #fff !important;
}

.subject-chemistry .circle-icon-accordian {
  font-size: 30px;
  background-color: #3396be;
  color: #fff;
  border-radius: 0px 0px 0px 28px;
  padding: 5px;
  font-size: 30px;
}

.subject-chemistry .subject-icon-askdoubt {
  background-color: #3396be;
  color: #fff;
}

.subject-chemistry .doubt-subject-flex {
  background-color: #3396be;
  color: #fff;
}

.subject-physics .card-bg {
  background-color: #e4ac13;
  color: #fff !important;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.subject-physics .chart-item-flex-sel {
  background-color: #e4ac13;
  padding: 20px;
  color: #fff !important;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: -20px;
}

.subject-physics .subject-circle-icon-bg,
.subject-physics .subject-circle-icon-bg-small {
  background-color: #e4ac13;
}
.subject-physics .quiz-subject-card,
.subject-physics .askdoubt-subject-card {
  background-color: #e4ac13 !important;
  color: #fff !important;
}
.subject-physics .quiz-subject-label {
  color: #fff !important;
}

.subject-physics .quiz-bg-subject-icon {
  color: #fff;
  background-color: transparent !important;
  font-size: 60px;
}

.subject-physics .subject-title {
  color: #e4ac13;
}

.subject-physics .subject-badge {
  color: #e4ac13;
  font-weight: 600;
}

.subject-physics .subject-icon-rails {
  background-color: #e4ac13;
  color: #fff;
}

.subject-physics .text-color {
  color: #e4ac13;
  text-transform: capitalize;
}

.subject-physics .bg-count-subject {
  background-color: #e4ac13;
}

.subject-physics .assignment-complete {
  color: #e4ac13;
}

.subject-physics .progress {
  background-color: #caccce;
  padding: 0px;
  border-radius: 100px;
}

.subject-physics .progress-bar-subject {
  background-color: #e4ac13;
  border-radius: 100px;
  color: #fff !important;
}

.subject-physics .card-button-default {
  background-color: #e4ac13;
  color: #fff !important;
}

.subject-physics .circle-icon-accordian {
  font-size: 30px;
  background-color: #e4ac13;
  color: #fff;
  border-radius: 0px 0px 0px 28px;
  padding: 5px;
  font-size: 30px;
}

.subject-physics .subject-icon-askdoubt {
  background-color: #e4ac13;
  color: #fff;
}

.subject-physics .doubt-subject-flex {
  background-color: #e4ac13;
  color: #fff;
}

.subject-biology .card-bg {
  background-color: #17bd59;
  color: #fff !important;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.subject-biology .chart-item-flex-sel {
  background-color: #17bd59;
  padding: 20px;
  color: #fff !important;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: -20px;
}

.subject-biology .subject-circle-icon-bg,
.subject-biology .subject-circle-icon-bg-small {
  background-color: #17bd59;
}
.subject-biology .quiz-subject-card,
.subject-biology .askdoubt-subject-card {
  background-color: #17bd59 !important;
  color: #fff !important;
}
.subject-biology .quiz-subject-label {
  color: #fff !important;
}

.subject-biology .quiz-bg-subject-icon {
  color: #fff;
  background-color: transparent !important;
  font-size: 60px;
}

.subject-biology .subject-title {
  color: #17bd59;
}

.subject-biology .subject-badge {
  color: #17bd59;
  font-weight: 600;
}

.subject-biology .subject-icon-rails {
  background-color: #17bd59;
  color: #fff;
}

.subject-biology .text-color {
  color: #17bd59;
  text-transform: capitalize;
}

.subject-biology .bg-count-subject {
  background-color: #17bd59;
}

.subject-biology .assignment-complete {
  color: #17bd59;
}

.subject-biology .progress {
  background-color: #caccce;
  padding: 0px;
  border-radius: 100px;
}

.subject-biology .progress-bar-subject {
  background-color: #17bd59;
  border-radius: 100px;
  color: #fff !important;
}

.subject-biology .card-button-default {
  background-color: #17bd59;
  color: #fff !important;
}

.subject-biology .circle-icon-accordian {
  font-size: 30px;
  background-color: #17bd59;
  color: #fff;
  border-radius: 0px 0px 0px 28px;
  padding: 5px;
  font-size: 30px;
}

.subject-biology .subject-icon-askdoubt {
  background-color: #17bd59;
  color: #fff;
}

.subject-biology .doubt-subject-flex {
  background-color: #17bd59;
  color: #fff;
}

.subject-english .card-bg {
  background-color: #6f98a9;
  color: #fff !important;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.subject-english .chart-item-flex-sel {
  background-color: #6f98a9;
  padding: 20px;
  color: #fff !important;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: -20px;
}

.subject-english .subject-circle-icon-bg,
.subject-english .subject-circle-icon-bg-small {
  background-color: #6f98a9;
}
.subject-english .quiz-subject-card,
.subject-english .askdoubt-subject-card {
  background-color: #6f98a9 !important;
  color: #fff !important;
}
.subject-english .quiz-subject-label {
  color: #fff !important;
}

.subject-english .quiz-bg-subject-icon {
  color: #fff;
  background-color: transparent !important;
  font-size: 60px;
}

.subject-english .subject-title {
  color: #6f98a9;
}

.subject-english .subject-badge {
  color: #6f98a9;
  font-weight: 600;
}

.subject-english .subject-icon-rails {
  background-color: #6f98a9;
  color: #fff;
}

.subject-english .text-color {
  color: #6f98a9;
  text-transform: capitalize;
}

.subject-english .bg-count-subject {
  background-color: #6f98a9;
}

.subject-english .assignment-complete {
  color: #6f98a9;
}

.subject-english .progress {
  background-color: #caccce;
  padding: 0px;
  border-radius: 100px;
}

.subject-english .progress-bar-subject {
  background-color: #6f98a9;
  border-radius: 100px;
  color: #fff !important;
}

.subject-english .card-button-default {
  background-color: #6f98a9;
  color: #fff !important;
}

.subject-english .circle-icon-accordian {
  font-size: 30px;
  background-color: #6f98a9;
  color: #fff;
  border-radius: 0px 0px 0px 28px;
  padding: 5px;
  font-size: 30px;
}

.subject-english .subject-icon-askdoubt {
  background-color: #6f98a9;
  color: #fff;
}

.subject-english .doubt-subject-flex {
  background-color: #6f98a9;
  color: #fff;
}

.subject-science .card-bg {
  background-color: #17bd59;
  color: #fff !important;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.subject-science .chart-item-flex-sel {
  background-color: #17bd59;
  padding: 20px;
  color: #fff !important;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: -20px;
}

.subject-science .subject-circle-icon-bg,
.subject-science .subject-circle-icon-bg-small {
  background-color: #17bd59;
}
.subject-science .quiz-subject-card,
.subject-science .askdoubt-subject-card {
  background-color: #17bd59 !important;
  color: #fff !important;
}
.subject-science .quiz-subject-label {
  color: #fff !important;
}

.subject-science .quiz-bg-subject-icon {
  color: #fff;
  background-color: transparent !important;
  font-size: 60px;
}

.subject-science .subject-title {
  color: #17bd59;
}

.subject-science .subject-badge {
  color: #17bd59;
  font-weight: 600;
}

.subject-science .subject-icon-rails {
  background-color: #17bd59;
  color: #fff;
}

.subject-science .text-color {
  color: #17bd59;
  text-transform: capitalize;
}

.subject-science .bg-count-subject {
  background-color: #17bd59;
}

.subject-science .assignment-complete {
  color: #17bd59;
}

.subject-science .progress {
  background-color: #caccce;
  padding: 0px;
  border-radius: 100px;
}

.subject-science .progress-bar-subject {
  background-color: #17bd59;
  border-radius: 100px;
  color: #fff !important;
}

.subject-science .card-button-default {
  background-color: #17bd59;
  color: #fff !important;
}

.subject-science .circle-icon-accordian {
  font-size: 30px;
  background-color: #17bd59;
  color: #fff;
  border-radius: 0px 0px 0px 28px;
  padding: 5px;
  font-size: 30px;
}

.subject-science .subject-icon-askdoubt {
  background-color: #17bd59;
  color: #fff;
}

.subject-science .doubt-subject-flex {
  background-color: #17bd59;
  color: #fff;
}

.subject-cyber .card-bg {
  background-color: #fe9f65;
  color: #fff !important;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.subject-cyber .chart-item-flex-sel {
  background-color: #fe9f65;
  padding: 20px;
  color: #fff !important;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: -20px;
}

.subject-cyber .subject-circle-icon-bg,
.subject-cyber .subject-circle-icon-bg-small {
  background-color: #fe9f65;
}
.subject-cyber .quiz-subject-card,
.subject-cyber .askdoubt-subject-card {
  background-color: #fe9f65 !important;
  color: #fff !important;
}
.subject-cyber .quiz-subject-label {
  color: #fff !important;
}

.subject-cyber .quiz-bg-subject-icon {
  color: #fff;
  background-color: transparent !important;
  font-size: 60px;
}

.subject-cyber .subject-title {
  color: #fe9f65;
}

.subject-cyber .subject-badge {
  color: #fe9f65;
  font-weight: 600;
}

.subject-cyber .subject-icon-rails {
  background-color: #fe9f65;
  color: #fff;
}

.subject-cyber .text-color {
  color: #fe9f65;
  text-transform: capitalize;
}

.subject-cyber .bg-count-subject {
  background-color: #fe9f65;
}

.subject-cyber .assignment-complete {
  color: #fe9f65;
}

.subject-cyber .progress {
  background-color: #caccce;
  padding: 0px;
  border-radius: 100px;
}

.subject-cyber .progress-bar-subject {
  background-color: #fe9f65;
  border-radius: 100px;
  color: #fff !important;
}

.subject-cyber .card-button-default {
  background-color: #fe9f65;
  color: #fff !important;
}

.subject-cyber .circle-icon-accordian {
  font-size: 30px;
  background-color: #fe9f65;
  color: #fff;
  border-radius: 0px 0px 0px 28px;
  padding: 5px;
  font-size: 30px;
}

.subject-cyber .subject-icon-askdoubt {
  background-color: #fe9f65;
  color: #fff;
}

.subject-cyber .doubt-subject-flex {
  background-color: #fe9f65;
  color: #fff;
}

.subject-cricket .card-bg {
  background-color: #e98816;
  color: #fff !important;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.subject-cricket .chart-item-flex-sel {
  background-color: #e98816;
  padding: 20px;
  color: #fff !important;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: -20px;
}

.subject-cricket .subject-circle-icon-bg,
.subject-cricket .subject-circle-icon-bg-small {
  background-color: #e98816;
}
.subject-cricket .quiz-subject-card,
.subject-cricket .askdoubt-subject-card {
  background-color: #e98816 !important;
  color: #fff !important;
}
.subject-cricket .quiz-subject-label {
  color: #fff !important;
}

.subject-cricket .quiz-bg-subject-icon {
  color: #fff;
  background-color: transparent !important;
  font-size: 60px;
}

.subject-cricket .subject-title {
  color: #e98816;
}

.subject-cricket .subject-badge {
  color: #e98816;
  font-weight: 600;
}

.subject-cricket .subject-icon-rails {
  background-color: #e98816;
  color: #fff;
}

.subject-cricket .text-color {
  color: #e98816;
  text-transform: capitalize;
}

.subject-cricket .bg-count-subject {
  background-color: #e98816;
}

.subject-cricket .assignment-complete {
  color: #e98816;
}

.subject-cricket .progress {
  background-color: #caccce;
  padding: 0px;
  border-radius: 100px;
}

.subject-cricket .progress-bar-subject {
  background-color: #e98816;
  border-radius: 100px;
  color: #fff !important;
}

.subject-cricket .card-button-default {
  background-color: #e98816;
  color: #fff !important;
}

.subject-cricket .circle-icon-accordian {
  font-size: 30px;
  background-color: #e98816;
  color: #fff;
  border-radius: 0px 0px 0px 28px;
  padding: 5px;
  font-size: 30px;
}

.subject-cricket .subject-icon-askdoubt {
  background-color: #e98816;
  color: #fff;
}

.subject-cricket .doubt-subject-flex {
  background-color: #e98816;
  color: #fff;
}

.subject-history .card-bg {
  background-color: #977373;
  color: #fff !important;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.subject-history .chart-item-flex-sel {
  background-color: #977373;
  padding: 20px;
  color: #fff !important;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: -20px;
}

.subject-history .subject-circle-icon-bg,
.subject-history .subject-circle-icon-bg-small {
  background-color: #977373;
}
.subject-history .quiz-subject-card,
.subject-history .askdoubt-subject-card {
  background-color: #977373 !important;
  color: #fff !important;
}
.subject-history .quiz-subject-label {
  color: #fff !important;
}

.subject-history .quiz-bg-subject-icon {
  color: #fff;
  background-color: transparent !important;
  font-size: 60px;
}

.subject-history .subject-title {
  color: #977373;
}

.subject-history .subject-badge {
  color: #977373;
  font-weight: 600;
}

.subject-history .subject-icon-rails {
  background-color: #977373;
  color: #fff;
}

.subject-history .text-color {
  color: #977373;
  text-transform: capitalize;
}

.subject-history .bg-count-subject {
  background-color: #977373;
}

.subject-history .assignment-complete {
  color: #977373;
}

.subject-history .progress {
  background-color: #caccce;
  padding: 0px;
  border-radius: 100px;
}

.subject-history .progress-bar-subject {
  background-color: #977373;
  border-radius: 100px;
  color: #fff !important;
}

.subject-history .card-button-default {
  background-color: #977373;
  color: #fff !important;
}

.subject-history .circle-icon-accordian {
  font-size: 30px;
  background-color: #977373;
  color: #fff;
  border-radius: 0px 0px 0px 28px;
  padding: 5px;
  font-size: 30px;
}

.subject-history .subject-icon-askdoubt {
  background-color: #977373;
  color: #fff;
}

.subject-history .doubt-subject-flex {
  background-color: #977373;
  color: #fff;
}

.subject-economics .card-bg {
  background-color: #a0be6b;
  color: #fff !important;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.subject-economics .chart-item-flex-sel {
  background-color: #a0be6b;
  padding: 20px;
  color: #fff !important;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: -20px;
}

.subject-economics .subject-circle-icon-bg,
.subject-economics .subject-circle-icon-bg-small {
  background-color: #a0be6b;
}
.subject-economics .quiz-subject-card,
.subject-economics .askdoubt-subject-card {
  background-color: #a0be6b !important;
  color: #fff !important;
}
.subject-economics .quiz-subject-label {
  color: #fff !important;
}

.subject-economics .quiz-bg-subject-icon {
  color: #fff;
  background-color: transparent !important;
  font-size: 60px;
}

.subject-economics .subject-title {
  color: #a0be6b;
}

.subject-economics .subject-badge {
  color: #a0be6b;
  font-weight: 600;
}

.subject-economics .subject-icon-rails {
  background-color: #a0be6b;
  color: #fff;
}

.subject-economics .text-color {
  color: #a0be6b;
  text-transform: capitalize;
}

.subject-economics .bg-count-subject {
  background-color: #a0be6b;
}

.subject-economics .assignment-complete {
  color: #a0be6b;
}

.subject-economics .progress {
  background-color: #caccce;
  padding: 0px;
  border-radius: 100px;
}

.subject-economics .progress-bar-subject {
  background-color: #a0be6b;
  border-radius: 100px;
  color: #fff !important;
}

.subject-economics .card-button-default {
  background-color: #a0be6b;
  color: #fff !important;
}

.subject-economics .circle-icon-accordian {
  font-size: 30px;
  background-color: #a0be6b;
  color: #fff;
  border-radius: 0px 0px 0px 28px;
  padding: 5px;
  font-size: 30px;
}

.subject-economics .subject-icon-askdoubt {
  background-color: #a0be6b;
  color: #fff;
}

.subject-economics .doubt-subject-flex {
  background-color: #a0be6b;
  color: #fff;
}

.subject-geography .card-bg {
  background-color: #2eb2aa;
  color: #fff !important;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.subject-geography .chart-item-flex-sel {
  background-color: #2eb2aa;
  padding: 20px;
  color: #fff !important;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: -20px;
}

.subject-geography .subject-circle-icon-bg,
.subject-geography .subject-circle-icon-bg-small {
  background-color: #2eb2aa;
}
.subject-geography .quiz-subject-card,
.subject-geography .askdoubt-subject-card {
  background-color: #2eb2aa !important;
  color: #fff !important;
}
.subject-geography .quiz-subject-label {
  color: #fff !important;
}

.subject-geography .quiz-bg-subject-icon {
  color: #fff;
  background-color: transparent !important;
  font-size: 60px;
}

.subject-geography .subject-title {
  color: #2eb2aa;
}

.subject-geography .subject-badge {
  color: #2eb2aa;
  font-weight: 600;
}

.subject-geography .subject-icon-rails {
  background-color: #2eb2aa;
  color: #fff;
}

.subject-geography .text-color {
  color: #2eb2aa;
  text-transform: capitalize;
}

.subject-geography .bg-count-subject {
  background-color: #2eb2aa;
}

.subject-geography .assignment-complete {
  color: #2eb2aa;
}

.subject-geography .progress {
  background-color: #caccce;
  padding: 0px;
  border-radius: 100px;
}

.subject-geography .progress-bar-subject {
  background-color: #2eb2aa;
  border-radius: 100px;
  color: #fff !important;
}

.subject-geography .card-button-default {
  background-color: #2eb2aa;
  color: #fff !important;
}

.subject-geography .circle-icon-accordian {
  font-size: 30px;
  background-color: #2eb2aa;
  color: #fff;
  border-radius: 0px 0px 0px 28px;
  padding: 5px;
  font-size: 30px;
}

.subject-geography .subject-icon-askdoubt {
  background-color: #2eb2aa;
  color: #fff;
}

.subject-geography .doubt-subject-flex {
  background-color: #2eb2aa;
  color: #fff;
}

.subject-political .card-bg {
  background-color: #ca6dbc;
  color: #fff !important;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.subject-political .chart-item-flex-sel {
  background-color: #ca6dbc;
  padding: 20px;
  color: #fff !important;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: -20px;
}

.subject-political .subject-circle-icon-bg,
.subject-political .subject-circle-icon-bg-small {
  background-color: #ca6dbc;
}
.subject-political .quiz-subject-card,
.subject-political .askdoubt-subject-card {
  background-color: #ca6dbc !important;
  color: #fff !important;
}
.subject-political .quiz-subject-label {
  color: #fff !important;
}

.subject-political .quiz-bg-subject-icon {
  color: #fff;
  background-color: transparent !important;
  font-size: 60px;
}

.subject-political .subject-title {
  color: #ca6dbc;
}

.subject-political .subject-badge {
  color: #ca6dbc;
  font-weight: 600;
}

.subject-political .subject-icon-rails {
  background-color: #ca6dbc;
  color: #fff;
}

.subject-political .text-color {
  color: #ca6dbc;
  text-transform: capitalize;
}

.subject-political .bg-count-subject {
  background-color: #ca6dbc;
}

.subject-political .assignment-complete {
  color: #ca6dbc;
}

.subject-political .progress {
  background-color: #caccce;
  padding: 0px;
  border-radius: 100px;
}

.subject-political .progress-bar-subject {
  background-color: #ca6dbc;
  border-radius: 100px;
  color: #fff !important;
}

.subject-political .card-button-default {
  background-color: #ca6dbc;
  color: #fff !important;
}

.subject-political .circle-icon-accordian {
  font-size: 30px;
  background-color: #ca6dbc;
  color: #fff;
  border-radius: 0px 0px 0px 28px;
  padding: 5px;
  font-size: 30px;
}

.subject-political .subject-icon-askdoubt {
  background-color: #ca6dbc;
  color: #fff;
}

.subject-political .doubt-subject-flex {
  background-color: #ca6dbc;
  color: #fff;
}

.subject-default .card-bg {
  background-color: #996fd6;
  color: #fff !important;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.subject-default .chart-item-flex-sel {
  background-color: #996fd6;
  padding: 20px;
  color: #fff !important;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: -20px;
}

.subject-default .subject-circle-icon-bg,
.subject-default .subject-circle-icon-bg-small {
  background-color: #996fd6;
}
.subject-default .quiz-subject-card,
.subject-default .askdoubt-subject-card {
  background-color: #996fd6 !important;
  color: #fff !important;
}
.subject-default .quiz-subject-label {
  color: #fff !important;
}

.subject-default .quiz-bg-subject-icon {
  color: #fff;
  background-color: transparent !important;
  font-size: 60px;
}

.subject-default .subject-title {
  color: #996fd6;
}

.subject-default .subject-badge {
  color: #996fd6;
  font-weight: 600;
}

.subject-default .subject-icon-rails {
  background-color: #996fd6;
  color: #fff;
}

.subject-default .text-color {
  color: #996fd6;
  text-transform: capitalize;
}

.subject-default .bg-count-subject {
  background-color: #996fd6;
}

.subject-default .assignment-complete {
  color: #996fd6;
}

.subject-default .progress {
  background-color: #caccce;
  padding: 0px;
  border-radius: 100px;
}

.subject-default .progress-bar-subject {
  background-color: #996fd6;
  border-radius: 100px;
  color: #fff !important;
}

.subject-default .card-button-default {
  background-color: #996fd6;
  color: #fff !important;
}

.subject-default .circle-icon-accordian {
  font-size: 30px;
  background-color: #996fd6;
  color: #fff;
  border-radius: 0px 0px 0px 28px;
  padding: 5px;
  font-size: 30px;
}

.subject-default .subject-icon-askdoubt {
  background-color: #996fd6;
  color: #fff;
}

.subject-default .doubt-subject-flex {
  background-color: #996fd6;
  color: #fff;
}

.subject-cards .pt-1 {
  width: 100%;
  color: #fff;
  font-weight: 400;
}

.subject-cards .chapters-complete {
  float: right;
  margin-top: 4px;
}

.card-title-regular {
  color: #000;
}

.bookmark-subject-card {
  min-width: 120px;
  width: auto;
  background-color: #fff;
  height: 120px;
  border-radius: 10px;
  text-align: center;
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  transition: all 1s ease;
  transform: scale(1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.bookmark-subject-card:hover {
  transform: scale(1.1);
  transition: all 1s ease;
}

.subject-circle-icon-bg {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  padding: 10px;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  margin: 5px;
}

.subject-circle-icon-bg-small {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 10px;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  margin: 5px;
}

.subject-title {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.subject-normal-text {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 12px;
}

.bookmark-flex-card {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.bookmark-video-card {
  width: 400px;
  background-color: #fff;
  color: #000;
  height: auto;
  border-radius: 10px;
  padding: 10px;
  text-align: left;
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 14px;
  margin: 15px;
  cursor: pointer;
  transition: all 1s ease;
  transform: scale(1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.bookmark-video-card:hover {
  transform: scale(1.1);
  transition: all 1s ease;
}

.subject-icon-rails {
  height: 35px;
  width: 35px;
  line-height: 35px;
  border: none;
  border-radius: 50%;
  text-align: center;
  position: relative;
  opacity: 1;
  font-size: 25px;
  font-family: "quiz-sec-font-reg";
  margin: 10px 10px 0px 10px;
  background-color: #996fd6;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-count-subject {
  background-color: #996fd6;
  font-size: 11px;
  font-family: "quiz-sec-font-reg";
  padding: 3px 15px;
  width: 200px;
  border-radius: 0px 0px 25px 25px;
  color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.bookmark-delete-button-circle {
  cursor: pointer;
  background-color: #e96969 !important;
  border-radius: 50px;
  padding: 0px;
  box-shadow: none;
  border: 1px solid transparent;
  font-size: 15px;
  color: #fff;
  height: 25px;
  width: 25px;
  margin: 0px 10px;
  text-align: center;
  line-height: 20px;
}

.bookmark-delete-button-circle:hover {
  color: #f4d302 !important;
  border: 1px solid transparent !important;
  opacity: 1;
}

.bookmark-delete-button-circle:active {
  border: 1px solid #fc8643 !important;
  opacity: 1;
  background-color: #fff !important;
}

.bookmark-delete-button-circle:visited {
  border: 1px solid #fc8643 !important;
  opacity: 1;
  background-color: #fff !important;
}

.question-card-bookmark-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px;
}

.question-card-bookmark {
  font-family: "stepapp-secondary-regular", sans-serif;
  background-color: #fff;
  color: #000;
  border-radius: 10px;
  padding: 10px;
  text-align: left;
  width: 90%;
  font-size: 16px;
  cursor: pointer;
  margin: 10px auto;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.bookmark-tab-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background-color: #f3f6f8;
  padding-top: 10px;
}

.bookmark-row {
  display: flex;
  align-items: center;
  justify-content: start;
}

.q-c-bookmark-item-flex {
  margin: 10px;
}

.question-text-bold {
  font-family: "stepapp-secondary-bold", sans-serif;
  font-weight: 600;
}

.question-card-delete {
  background-color: transparent;
  border-radius: 10px;
  text-align: center;
}

.question-card-flex-mcq {
  width: 25%;
  height: auto;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
  font-family: "stepapp-secondary-bold", sans-serif;
}

.question-card-flex-mcq .select-label-r {
  min-height: 60px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  position: relative;
  opacity: 1;
  font-size: 12px !important;
  line-height: 12px !important;
  font-family: "stepapp-secondary-regular", sans-serif;
  border: 3px solid transparent;
}

.question-card-flex-mcq input[type="radio"]:checked+label {
  border: 3px solid #15388c;
  color: #000;
  opacity: 1;
  font-family: "stepapp-secondary-regular", sans-serif;
  background-color: transparent;
}

.question-card-flex-mcq input[type="radio"]:checked+label::after {
  height: 30px;
  width: 30px;
  background: url("../images/check-quiz.svg");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 30px;
  z-index: 999;
  position: absolute;
  top: -3px;
  right: -3px;
}

.img-bg {
  background: #6c757d;
}

.subject-main {
  padding: 5px;
  vertical-align: middle;
  color: #fff;
}

.subject-title {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.quiz_activity_subject_flex {
  min-height: 270px;
  background-color: #fff;
  border-radius: 10px;
  padding: 3px;
  margin: 20px;
  cursor: pointer;
  transition: all 1s ease;
  transform: scale(1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.quiz_activity_subject_flex:hover {
  transform: scale(1.1);
  transition: all 1s ease;
}

.profile_activity_subject_flex {
  min-height: 230px;
  background-color: #fff;
  color: #000;
  border-radius: 10px;
  padding: 3px;
  margin: 20px auto;
  cursor: pointer;
  transition: all 1s ease;
  transform: scale(1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.profile_activity_subject_flex:hover {
  transform: scale(1.1);
  transition: all 1s ease;
}

.quiz_activity_header {
  padding-bottom: 30px;
  border-bottom: 1px solid #caccce;
  min-height: 55px;
}

.card-image-assignment {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 0px 0px 10px 10px;
  z-index: 1;
}

.card-image-assignment-2 {
  width: 100%;
  height: 169px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 0px 0px 10px 10px;
  z-index: 1;
}

.card-image-assignment-pro-report {
  width: 120px;
  height: 130px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid #caccce;
}

.false-margin-up {
  margin: 0px 5px auto 5px;
}

.false-margin-up-pro {
  margin: -12px 5px auto 5px;
}

.assignment-complete {
  background-color: #000;
  border-radius: 0px 10px 0px 100px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #fff;
}

.accuracy-card {
  float: left;
  position: absolute;
  left: 8px;
  bottom: 8px;
  background-color: green;
  width: 95%;
  height: 50px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #caccce;
  padding: 0px 10px;
  z-index: 20;
}

.accuracy-card-2 {
  background-color: green;
  width: 95%;
  height: 50px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #caccce;
  padding: 0px 10px;
  margin: auto;
  z-index: 20;
}

.flex-score {
  width: 100%;
  border-right: 0.5px solid #6c757d;
}

.flex-score .title-text {
  color: #6c757d;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 3px !important;
  height: 30px;
}

.flex-score .score {
  font-size: 25px;
  color: #000;
  font-weight: 600;
  padding-bottom: 10px;
}

.flex-score:nth-child(3) {
  border-right: none;
}

.text-lable-small {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 12px;
  line-height: 27px;
  color: #000;
}

.text-percentage-big {
  font-family: "stepapp-secondary-bold", sans-serif;
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
  color: #000;
}

.chapter-header {
  border-bottom: 1px solid #caccce;
  height: 71px;
}

/*-----------for Interactivelivetv---------------*/
.livtv_item_flex {
  width: 350px;
  background-color: #fff;
  padding: 5px 0px 0px 0px;
  margin: 20px;
  transition: all 1s ease;
  transform: scale(1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.livtv_item_flex:hover,
.livtv_item_flex:focus {
  transform: scale(1.1);
  transition: all 1s ease;
}

.livtv-main {
  padding: 5px;
  vertical-align: middle;
  color: #000;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}

.livtv-sub {
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 11px;
  line-height: 11px;
  color: #000;
}

.livtv-date {
  font-family: "stepapp-secondary-medium", sans-serif;
  font-size: 12px;
  color: #000;
}

.livtv-small-btn {
  height: 20px;
  font-family: "stepapp-secondary-regular", sans-serif;
  font-size: 12px;
  border-radius: 10px;
  border: none;
  width: 50px;
  background-color: #17bd59;
  color: #fff;
}

.livtv-detail-text {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #000;
}

.liv-button-Physics {
  background-color: #e4ac13;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid transparent;
  width: 100%;
  box-shadow: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  height: 40px;
}

.liv-button-Physics:hover {
  background-color: #b5880f;
  border: none;
}

.liv-button-Physics:active,
.liv-button-Physics:focus {
  background-color: #fc8643;
}

.liv-button-Physics:visited {
  background-color: #e4ac13;
}

.liv-button-Bio {
  background-color: #17bd59;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid transparent;
  width: 100%;
  box-shadow: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  height: 40px;
}

.liv-button-Bio:hover {
  background-color: #119044;
  border: none;
}

.liv-button-Bio:active,
.liv-button-Bio:focus {
  background-color: #fc8643;
}

.liv-button-Bio:visited {
  background-color: #17bd59;
}

.liv-button-Maths {
  background-color: #e96969;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid transparent;
  width: 100%;
  box-shadow: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  height: 40px;
}

.liv-button-Maths:hover {
  background-color: #e23d3d;
  border: none;
}

.liv-button-Maths:active,
.liv-button-Maths:focus {
  background-color: #fc8643;
}

.liv-button-Maths:visited {
  background-color: #e96969;
}

.liv-button-Chemistry {
  background-color: #3396be;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid transparent;
  width: 100%;
  box-shadow: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  height: 40px;
}

.liv-button-Chemistry:hover {
  background-color: #287696;
  border: none;
}

.liv-button-Chemistry:active,
.liv-button-Chemistry:focus {
  background-color: #fc8643;
}

.liv-button-Chemistry:visited {
  background-color: #3396be;
}

.quiz-subject-card {
  border-radius: 10px;
  padding: 0px;
  cursor: pointer !important;
  min-height: 160px;
  margin: 15px;
  width: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 1s ease;
  transform: scale(1);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.quiz-subject-card .quiz-subject-icon {
  font-size: 60px;
}

.quiz-subject-card .quiz-subject-label {
  font-size: 18px;
  line-height: 20px;
  font-family: "quiz-title-font";
  color: #fff;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  padding-top: 10px;
  cursor: pointer !important;
}

.quiz-subject-card:hover {
  transform: scale(1.1);
  transition: all 1s ease;
}

.pos-float-top-left {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 200;
}

.pos-float-top-right {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 200;
}

.pos-float-top-right1 {
  position: absolute;
  top: -10px;
  right: -5px;
  z-index: 200;
}

.pos-right-top {
  position: absolute;
  top: -25px;
  right: -20px;
}

.card-button-default {
  background-color: #fc8643;
  border-radius: 50px;
  border: 1px solid transparent !important;
  padding: 10px;
  width: auto;
  box-shadow: none;
  font-family: "stepapp-secondary-medium", sans-serif;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  font-weight: 600;
  height: 40px;
}

.card-button-default:hover {
  background-color: #fb6611;
  border: 1px solid transparent !important;
}

.card-button-default:active,
.card-button-default:focus {
  background-color: #fc8643;
}

.card-button-default:active::after,
.card-button-default:focus::after {
  height: 30px;
  width: 30px;
  background: url("../images/check.svg");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 30px;
  z-index: 999;
  position: absolute;
  top: -3px;
  right: 10px;
}

.card-button-default:visited {
  background-color: #fc8643;
}

.dash-card-default {
  background-color: #996fd6;
  padding: 10px;
  font-size: 14px;
  color: #fff;
  flex: 1;
  border-radius: 10px;
  margin: 10px;
}

.chart-item-flex {
  margin: 10px;
  background-color: transparent;
  color: #000 !important;
  border: 1px solid #caccce;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 20px;
}

.c-default {
  cursor: default !important;
}

.ant-steps-item-title {
  color: #000 !important;
  font-weight: 600 !important;
}

#bg-div {
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
}

#border-btm {
  box-shadow: 0px 35px 2px -35px lightgray;
}

.active1 {
  color: #00c853 !important;
  font-weight: bold;
}

.bar4 {
  width: 35px;
  height: 5px;
  background-color: #ffffff;
  margin: 6px 0;
}

.list-group .tabs {
  color: #000000;
}

#menu-toggle {
  height: 50px;
}

#new-label {
  padding: 2px;
  font-size: 10px;
  font-weight: bold;
  background-color: red;
  color: #ffffff;
  border-radius: 5px;
  margin-left: 5px;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 180px;
  text-decoration: none;
  margin: 10px;
}

.sidebar-icon {
  width: 40px;
  height: auto;
}

#page-content-wrapper {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

.td-n:hover {
  text-decoration: none;
}

.list-group-item.active {
  z-index: 2;
  background-color: #caccce !important;
  border-color: #fff !important;
  text-decoration: none;
  border-radius: 10px;
}

.card0 {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.card {
  border-radius: 10px;
}

.list-div {
  text-decoration: none !important;
}

.list-div:hover {
  text-decoration: none !important;
}

.top-highlight {
  color: #00c853;
  font-weight: bold;
  font-size: 20px;
}

.form-card input,
.form-card textarea {
  padding: 10px 15px 5px 15px;
  border: none;
  border: 1px solid lightgrey;
  border-radius: 6px;
  background-color: #fff !important;
  margin-top: 2px;
  margin-bottom: 5px;
  width: 100%;
  box-sizing: border-box;
  font-family: "stepapp-secondary-regular", sans-serif;
  color: #2c3e50;
  font-size: 14px;
  letter-spacing: 1px;
}

.form-card input:focus,
.form-card textarea:focus {
  box-shadow: 0px 0px 0px 1.5px skyblue !important;
  font-weight: bold;
  border: 1px solid skyblue;
  outline-width: 0;
}

#below-btn a {
  font-weight: bold;
  color: #000000;
}

.input-group {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
}

.input-group input {
  position: relative;
  margin-left: 1px;
  margin-right: 1px;
  border-radius: 6px;
  padding-top: 30px;
  color: #000;
  font-weight: 600;
  border: 1px solid lightgrey;
}

.input-group label {
  position: absolute;
  height: 24px;
  background: none;
  border-radius: 6px;
  line-height: 48px;
  font-size: 15px;
  color: gray;
  width: 100%;
  font-weight: 100;
  padding-left: 25px;
}

input:focus+label {
  color: #1e88e5;
}

#qr {
  margin-bottom: 150px;
  margin-top: 50px;
}

.list-group-item {
  border: none;
  text-decoration: none;
  font-size: 12px;
}

.flex-package-card-item {
  font-size: 12px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin: 10px;
  text-align: left;
}

.flex-package-card-item .select-label-package {
  width: auto;
  height: 100%;
  border: 1px solid #caccce;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: #000;
  border: 1px solid #caccce;
  font-size: 12px;
  font-family: "stepapp-secondary-regular", sans-serif;
  text-align: left;
  border-radius: 10px;
  padding: 0px;
  margin: 0px;
}

.flex-package-card-item input[type="radio"]:checked+label {
  border: 3px solid #3396be;
  color: #000;
  opacity: 1;
  font-family: "quiz-sec-font";
  background-color: #fff;
  padding: 0px;
  margin: 0px;
}

.flex-package-card-item input[type="radio"]:checked+label::after {
  height: 30px;
  width: 30px;
  background: url("../images/check.svg");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 25px;
  z-index: 999;
  position: absolute;
  top: 3px;
  right: 8px;
}

.active-pack {
  color: #000;
  opacity: 1;
  font-family: "quiz-sec-font";
  background-color: #fff;
}

.active-pack::after {
  height: 30px;
  width: 30px;
  background: url("../images/check.svg");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 25px;
  z-index: 999;
  position: absolute;
  top: 3px;
  right: 8px;
}

.flex-payment-cards {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.flex-payment-card-item {
  width: 100px;
  border: 1px solid #caccce;
  height: 50px;
  font-size: 12px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin: 5px;
}

.flex-payment-card-item .select-label-payment {
  display: block;
  position: relative;
  opacity: 1;
  font-family: "stepapp-secondary-regular", sans-serif;
  border: 3px solid transparent;
  padding: 10px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #000;
  border: 1px solid #caccce;
  font-size: 12px;
  font-family: "stepapp-secondary-regular", sans-serif;
  text-align: center;
}

.flex-payment-card-item input[type="radio"]:checked+label {
  border: 2px solid #15388c;
  color: #000;
  opacity: 1;
  font-family: "quiz-sec-font";
  background: #fff;
}

.flex-payment-card-item input[type="radio"]:checked+label::after {
  height: 20px;
  width: 20px;
  background: url("../images/check.svg");
  background-repeat: no-repeat;
  position: absolute;
  background-size: 17px;
  z-index: 999;
  position: absolute;
  top: -8px;
  right: -3px;
}

/*for select package */
.icons-flex {
  display: flex;
  /* align-items: flex-start;
  justify-content: flex-start; */
}

.learn-icon {
  background-color: #2fb9b0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 10px 10px 0px;
}

.practice-icon {
  background-color: #6c8bf1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 10px 10px 0px;
}

.ask-icon {
  background-color: #3a9af2;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 10px 10px 0px;
}

.blue-text-caps {
  text-transform: uppercase;
  color: #15388c;
  font-size: 18px;
  font-weight: 500;
}

.gray-text-caps {
  text-transform: uppercase;
  color: #6c757d;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
}

.blue-text {
  color: #15388c;
}

.package-features-flex {
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
  flex-wrap: wrap;
}

.flex-package-card {
  display: flex;
  justify-content: center;
}

.package-features-flex-item {
  width: 168px;
  padding: 6px 0px;
}

.flex-package-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*----for learn practice cards----*/
.custom-select-soft {
  position: relative;
  font-family: "stepapp-secondary-regular", sans-serif;
  border-radius: 10px;
  height: 50px;
  padding-left: 20px;
}

.error-message {
  color: red;
  font-size: 12px;
  padding-left: 30px;
}

/* for second step card display amonut */
.card-display {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  min-height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  background-color: #fff;
  padding: 30px;
  border: 1px solid #caccce;
}

.card-display-item-flex {
  width: auto;
}

.payment-flex-center {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*-----for custom-color of stepper----*/
.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: grey !important;
}

.ant-steps-item-process .ant-steps-item-icon {
  background: #15388c;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #15388c;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  color: #15388c !important;
}

.ant-steps-icon {
  color: #15388c !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fc8643 !important;
  border-color: #15388c !important;
}

.anticon svg {
  display: inline-block;
  width: 15px !important;
  height: 15px !important;
  margin: 0px;
}

.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nav-tabs.nav-link {
  display: block;
  border-bottom: 1px solid #fff !important;
  padding: 0px 10px 15px;
}

.nav-tabs.nav-link.active {
  background-color: transparent;
  border-bottom: 4px solid #fc8643 !important;
  color: #fc8643 !important;
  border-left: none;
  border-right: none;
  border-top: none;
}

.nav-tabs.nav-link.active:hover {
  background-color: transparent;
  border-bottom: 4px solid #fc8643 !important;
  color: #fc8643 !important;
  border-left: none;
  border-right: none;
  border-top: none;
}

.modalImg {
  text-align: center;
}

.logoutFooter {
  align-items: center !important;
  justify-content: center !important;
  border: none !important;
}

.modalLogout h3 {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.modalLogout h3 span {
  display: block;
  margin-top: 6px;
  font-size: 16px;
  font-weight: 400;
}

.logoutFooter .btn {
  width: 130px;
  height: 40px;
  border-radius: 6px;
}

.logoutFooter .btn.btn-outline {
  border: 2px solid #fc8643;
  color: #fc8643;
  margin-left: 6px;
}

.clsPop {
  position: absolute;
  top: -25px;
  right: -25px;
  border-radius: 50%;
  opacity: 1 !important;
}

.copyUrl {
  display: flex;
  align-items: center;
  justify-content: center;
}

.copyUrl button {
  border: none;
  background: #fc8643;
  color: #fff;
  padding: 0.3rem 1rem;
  border-radius: 0 10px 10px 0;
  height: 50px;
}

.copyUrl input {
  color: #000;
  background: #F1F4F6;
  height: 50px;
  border: 1px solid #767676;
  padding: 0px 40px 0 15px;
  text-align: left;
  border-radius: 10px 0 0 10px;
  border-right: 0;
}

/* video-nav-tabs */
.vid-card-vid {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.video-nav-tabs {
  border-bottom: 1px solid transparent !important;
}

.video-nav-tabs.nav-tabs .nav-link.active {
  background-color: transparent !important;
  border-color: transparent;
}

.video-nav-tabs.nav-tabs>li>a,
.nav-pills>li>a {
  color: #fff !important;
  font-size: 16px;
}

.video-icons {
  position: relative;
}

.video-icons ul li {
  padding: 5px 15px 0;
  color: #fff;
}

.video-icons ul {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 0 0 20px;
}

.lessons-title-video {
  margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
  .lessons-title-video {
    margin-bottom: 0;
  }
}

/* video-nav-tabs end */

mjx-math {
  /*display: flex !important;
  flex-wrap: wrap !important;*/
  font-size: 1rem !important;
  /*white-space: break-spaces !important;
  align-items: baseline !important;
  gap: 6px !important;*/
}

/*.option_Latex mjx-container mjx-math{
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}*/

/*.option_Latex mjx-container mjx-math mjx-texatom{
  display: flex !important;
  flex-wrap: wrap;
  align-items: baseline;
}*/

.optionContainer{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

@media screen and (max-width: 768px) {
  .optionContainer{
    grid-template-columns: repeat(1, 1fr);
  }
}

.quiz_submit_button{
  position: relative;
  z-index: 1000;
}