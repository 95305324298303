.side-menu {
    @media screen and (min-width: 768px){
        .side-menu {
            background-color: #fff !important;
        }
    }
    .showOnMobile {
        display: none;
    }
    @media screen and (max-width: 768px){
        .showOnDesktop {
            display: none;
        }
        .showOnMobile {
            display: flex;
            bottom: 0;
            position: absolute;
            width: 100%;
        }
    }
}

@media screen and (min-width: 1440px) { 
    .page-layout-wrapper {
        .layout-wrapper {
            .side-menu#leftSidebar {
                width: 20vw;
            }
        }
    }
    .side-menu .mid-sidenav-content .side-menu-nav li.student-list-item button div div {
      font-size: 22px;
    }
}

.nav-item a button img {
    filter: brightness(0.5);
}
.nav-item a.active button img {
    filter: brightness(1.5);
}

.nav-item .active button {
    background-color: #15388C !important;
    color: #fff !important;
}  