@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

*,
*:before,
*:after {
	padding: 0;
	margin: 0;
	outline: none;
	box-sizing: border-box;
}

a {
	transition: 0.7s ease-in-out;
}

html,
body,
#root,
.theme {
	font-family: 'Poppins', sans-serif !important;
	position: relative;
	overflow: inherit;
	width: 100%;
	height: 100%;
}

*html,
body,
button {
	font-family: 'Poppins', sans-serif !important;
}

body {
	overflow-x: hidden;
	overflow-y: hidden;
	font-family: 'Poppins', sans-serif !important;
}

/* * {
  scrollbar-width: thin;
  scrollbar-color: #e9e9e9;
} */

::-webkit-scrollbar {
	width: 8px !important;
}

/* Track */
::-webkit-scrollbar-track {
	background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #d6dee1 !important;
	border-radius: 20px;
	border: 6px solid transparent;
	background-clip: content-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #a8bbbf !important;
}


/**** Custom Style-RV ******/
.page-layout-wrapper .unified-theme-light .layout-full {
	background: #fff;
}

/* .page-layout-wrapper .layout-full {
  overflow: inherit;
} */

#comment-area {
    background: blue;
}

/** subject-list-frame **/

.subject-content-frame {
	padding: 48px 48px;
}

.subject-content-frame .title-lbl {
	font-style: normal;
	font-weight: bold;
	font-size: 28px;
	color: #2B2B2B;
}

.subject-content-frame .resume-left-section .title-lbl {
	padding-top: 20px;
	display: block;
	margin-bottom: 0 !important;
}

.subject-list-frame {
	display: flex;
	justify-content: flex-start;
	align-content: center;
	gap: 40px;
	border-bottom: 4px solid #F1F4F6;
    padding-bottom: 40px;
    margin-bottom: 25px;
}

.subject-list-frame a {
	width:100%;
	max-width: 250px;
	max-height: 250px;
	position: relative;
	display: inline-flex;
}

.subject-list-frame a .CircularProgressbar {
	width: 100% !important;
}

.subject-list-frame .CircularProgressbar .CircularProgressbar-trail {
	stroke: #fff !important;
}

.subject-content-frame .resume-left-section .chapterResumeCard2 {
	margin-top: 20px !important;
}

.island-theme-maths #content-with-header {
	padding-top: 10px;
	padding-bottom: 20px;
	top: 0;
}

#main .main-inner-content-frame #content-with-header {
	position: absolute;
	top: 35px;
	left: 0px;
	height: calc(100vh - 56px);
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	padding-top: 10px;
	padding-bottom: 20px
}

.topic-detail-container .title-lbl {
	font-weight: 500;
	font-size: 32px;
	line-height: 48px;
	text-decoration-line: underline;
	color: #686868;
}

.topic-detail-container .progress-info .island-list {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.topic-detail-container .progress-info .island-list li {
	min-height: inherit;
	padding-top: 20px;
	padding-bottom: 40px;
	width: 30%;
}

.topic-detail-container .progress-info .island-list .progress-list-box {
	direction: inherit;
}

.topic-detail-container .progress-info .island-list .progress-list-box .progress-content-box {
	position: relative;
	min-height: 100px;
	padding-bottom: 60px;
	overflow: hidden;
	background: #f5f5f5;
	border-radius: 10px;
}

.topic-detail-container .progress-info .island-list .progress-list-box .progress-content-box .bottom-section {
	background: #8E8E8E;
}

.island-list .progress-list-box .progress-content-box .bottom-section button {
	background: none;
	border: 0 none;
	border-bottom: 2px solid #fff;
}

.topic-detail-container .progress-info .island-list .progress-list-box.completed .progress-content-box {
	background: #F9F9F9;
	border: 1px solid #FFC09C;
}

.topic-detail-container .island-list .progress-list-box .progress-content-box .top-section {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 20px 20px;
}

.topic-detail-container .island-list .progress-list-box .progress-content-box .top-section .icon-block {
	padding: 2px;
	margin-right: 10px;
	background: #8E8E8E;
	border-radius: 50% 50%;
}

.topic-detail-container .island-list .progress-list-box .progress-content-box .top-section .icon-block img {
	border-radius: 50%;
	border: 1px solid #fff;
}

.topic-detail-container .island-list .progress-list-box .progress-content-box .top-section .status-icon .fa {
	font-size: 24px;
	color: #1EB81B;
}

.topic-detail-container .island-list .progress-list-box.completed .progress-content-box .top-section .icon-block {
	background: #FC8643;
}

.progress-content-box .top-section .right-action-block {
	display: flex;
	flex-direction: column;
}

.progress-content-box .top-section .right-action-block .action-frame .txt {
	margin-right: 10px;
	font-size: 14px;
	line-height: 1.5;
	font-weight: 500;
	color: #262626;
}

.topic-detail-container .island-list .progress-list-box .progress-content-box .bottom-section {
	padding: 10px 0;
	text-align: center;
	background: #8E8E8E;
	position: absolute;
	bottom: 0;
	width: 100%;
}

.topic-detail-container .island-list .progress-list-box .progress-content-box .bottom-section .lbl {
	font-weight: 500;
	font-size: 14px;
	line-height: 1.5;
	margin-bottom: 0;
	color: #fff;
}

.topic-detail-container .island-list .progress-list-box.completed .progress-content-box .bottom-section {
	background: #FC8643;
}


#main #contentpages-video {
	top: 55px;
	left: 0;
}

#contentpages-video .island-video {
	display: block;
	height: calc(100vh - 0px);
	overflow-y: hidden;
}

.island-video .video-Card.video-gallery-card {
	flex-wrap: wrap;
	background: none;
	height: inherit;
	padding-top: 25px;
}
@media screen and (max-width:768px) {
	.island-video .video-Card.video-gallery-card {
		padding-top: 10px;
	}
}

.island-video .video-Card.video-gallery-card .lessons-title-video {
	display: none;
}

.island-video .video-Card.video-gallery-card .vid-card-vid {
	height: auto !important;
	width: 100%;
	position: relative;
	flex-wrap: wrap;
	height: 50vh !important;
	align-items: inherit;
	margin-top: 20px;
	border-radius:6px;
	overflow: hidden;
}

.island-video .vid-card-vid .video-url-frame {
	width: 100%;
	height: 100%;
	background: #000;
}

/*video-action-control-frame*/
.left-video-col .video-action-control-frame {
	position: absolute;
	z-index: 9;
	width: 100%;
	top: 50%;
	transform: translateY(-35px);
}

.left-video-col .video-action-control-frame button {
	background: none;
	border: 0 none;
	position: absolute;
	cursor: pointer;
}

.left-video-col .video-action-control-frame button.btn-previous {
	left: 10px;
}

.left-video-col .video-action-control-frame button.btn-next {
	right: 10px;
}

.left-video-col .video-action-control-frame button .fa {
	color: #585858;
	font-size: 40px;
}

.left-video-col .video-action-control-frame button:hover .fa {
	color: #000;
}

.white-rnd-frame {
	/* min-height: 200px; */
	background: #fff;
	border-radius: 8px 8px 8px 8px;
}

/* vid-card-info */
.left-video-col .vid-card-info {
	height: 32vh;
    overflow-y: hidden;
    padding: 25px 10px 0;
}

.video-Card .left-video-col .controls-frame {
	display: flex;
	justify-content: center;
	align-content: center;
	padding-top: 10px;
}

/* .video-Card .left-video-col .video-action-control-frame   */
.video-Card .left-video-col .controls-frame .slider-indicator {
	width: 50px;
	height: 5px;
	margin: 0 5px;
	cursor: pointer;
	border: 0 none;
	background-color: #cfcfcf;
	border-radius: 10px;
}

.video-Card .left-video-col .controls-frame .slider-indicator:hover,
.video-Card .left-video-col .controls-frame .slider-indicator.active {
	background-color: #FC8643;
}

.left-video-col .vid-card-info .vid-card-info-frame {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
} 

@media screen and (max-width: 767px) {
	#contentpages-video .island-video {
		height: 100%;
	}

	#main #contentpages-video {
		position: relative;
	}

	.island-video .video-Card.video-gallery-card .vid-card-vid {
		height: 240px !important;
	}

	.island-video .vid-card-vid .video-url-frame {
		height: max-content;
	}

	/* .dv-star-rating-full-star,
	.dv-star-rating-empty-star {
		width: 21px;
		height: 21px;
	} */

	.feedback-content-frame .fieldset-block .field-row .lbl {
		font-size: 12px !important;
		margin-right: 0 !important;
	}

	.left-video-col .vid-card-info {
		height: 30vh !important;
		margin-bottom: 8px;
		padding-top: 45px;
	}

	.left-video-col .vid-card-info .vid-card-info-frame {
		flex-direction: column;
	}

	.vid-card-info .ev-tab-content-container {
		width: 100% !important;
	}

	.vid-card-info .nav-tabs {
		margin: 10px 0;
	}
	.subject-content-frame {
		padding:20px 15px !important;
	}
}

/** right-vid-dtl-col **/
.right-vid-dtl-col .top-action-section {
	height: calc(100vh - 30vh);
	overflow: hidden;
}

.right-vid-dtl-col .top-action-section .white-rnd-frame {
	border-radius:8px 8px;
	background: #2b2b2b;
}

.right-vid-dtl-col .top-action-section .nav-tabs {
	display: flex;
	padding: 10px 5px !important;
	justify-content: space-between;
	flex-wrap: nowrap;
	border: 0 none;
	gap: 10px;
}

.right-vid-dtl-col .top-action-section .nav-tabs .nav-item {
	width: 60px;
}

.right-vid-dtl-col .top-action-section .nav-tabs .nav-item a {
	height: 58px;
	width: 100%;
	position: relative;
	padding: 0 !important;
	margin: 0 4px;
	color: #fff;
	background: #CECECE;
	border: 2px solid #CECECE;
	border-radius: 5px;
}

.top-action-section .nav-tabs .nav-item a img {
	width: 60%;
	display: inline-flex;
	margin: auto;
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}

.right-vid-dtl-col .top-action-section .nav-tabs .nav-item a.active,
.right-vid-dtl-col .top-action-section .nav-tabs .nav-item a:hover {
	border: 2px solid #FC8643 !important;
	background: #fff;
}

.right-vid-dtl-col .top-action-section .nav-tabs .nav-item a:hover,
.right-vid-dtl-col .top-action-section .nav-tabs .nav-item a.active:hover,
.right-vid-dtl-col .top-action-section .nav-tabs .nav-item a:hover img,
.right-vid-dtl-col .top-action-section .nav-tabs .nav-item a.active img {
	background: none !important;
}

/* .right-vid-dtl-col .top-action-section .nav-tabs li button:hover img, 
.right-vid-dtl-col .top-action-section .nav-tabs li button.active img{
  fill: #FC8643;
} */
.right-vid-dtl-col .top-action-section .nav-tabs .nav-item a:hover img,
.right-vid-dtl-col .top-action-section .nav-tabs .nav-item a.active img {
	/* fill: #FC8643; */
	filter: invert(81%) sepia(53%) saturate(5523%) hue-rotate(370deg) brightness(180%) contrast(98%);
	opacity: 0.8;
}

/* right-vid-dtl-col top-action-section tab-content */
.right-vid-dtl-col .top-action-section .tab-content {
	min-height: 51vh;
	padding: 5px 15px;
	margin: 20px 10px 0;
	border: 0;
}

.top-action-section .tab-content .tab-pane {
	height: calc(100vh - 45vh);
	overflow: auto;
}

.top-action-section .tab-content .tab-pane .container {
	padding: 0;
}

.right-vid-dtl-col .top-action-section .tab-content a {
	font-weight: 600;
	font-size: 30px;
	line-height: 1.5;
	/* identical to box height */
	text-decoration-line: underline !important;
	color: #15388C;
}

.btm-feedback-section .white-rnd-frame {
	height: 15vh;
}

.btm-feedback-section .fieldset-content {
	overflow-y: auto;
	height: calc(27vh - 55px);
}

#noanim-tab-example-tabpane-notes::-webkit-scrollbar {
	width: 3px !important;
}

#noanim-tab-example-tabpane-notes::-webkit-scrollbar-thumb {
	background: #f7f7f7 !important;
}

.vid-card-info .tab-content::-webkit-scrollbar {
	width: 3px !important;
}

.vid-card-info .tab-content::-webkit-scrollbar-thumb {
	background: #f7f7f7 !important;
}

.btm-feedback-section .fieldset-content::-webkit-scrollbar {
	width: 3px !important;
}

.btm-feedback-section .fieldset-content:-webkit-scrollbar-thumb {
	background: #f7f7f7 !important;
}

/** feedback-content-frame **/
.feedback-content-frame {
	padding: 12px 15px 15px;
}

.feedback-content-frame .fieldset-block {
	border: 1px solid #e7e6e5;
	border-radius: 10px 10px;
	padding: 10px 10px;
	position: relative;
}

.feedback-content-frame .fieldset-block .legend {
	font-weight: 700;
	font-size: 16px;
	line-height: 1;
	color: #727272 !important;
	position: absolute;
	top: -11px;
	left: 15px;
	background: #fff;
	padding: 1px 6px;
	margin-bottom: 0;
}

.feedback-content-frame .fieldset-block .field-row {
	align-items: center;
	padding-top: 5px;
	justify-content: space-between;
	position: relative;
}

.field-row .feedback-btn {
	position: absolute;
	bottom: -14px;
	right: 5px;
	background: transparent;
	border: none;
	width: 40px;
	padding: 0;
	margin: 0;
}

.field-row .feedback-btn:hover,
.btn-secondary:hover {
	background-color: transparent;
	border-color: transparent;
}

.feedback-content-frame .fieldset-block .field-row label,
.feedback-content-frame .fieldset-block .field-row p {
	margin-bottom: 0;
}

.feedback-content-frame .fieldset-block .field-row .lbl {
	font-weight: 400;
	font-size: 17px;
	line-height: 1.5;
	color: #585858;
	margin-right: 15px;
	margin-left: 0;
}

.feedback-content-frame .fieldset-block .field-row .rating-block .fa {
	font-size: 24px;
	color: #bbb;
	cursor: pointer;
	margin-left: 3px;
}

.feedback-content-frame .fieldset-block .field-row .rating-block .fa:hover,
.feedback-content-frame .fieldset-block .field-row .rating-block .fa.active {
	color: #FFCF25;
}

.feedback-content-frame .field-row .feedback-txtarea {
	border: 1px solid #E7E6E5;
	margin-top: 5px;
	padding: 10px 15px;
	font-weight: 400;
	font-size: 14px;
	line-height: 25px;
	color: #A6A6A6;
	border-radius: 0;
	width: 100%;
	resize: none;
}

.island-theme-physics .contentpages-video~.fix-bottom-nav {
	display: none;
}

iframe.fast-comments d.footer {
	DISPLAY: none;
}
.chapterTitle {
    font-size: 18px;
	font-weight: 600;
}

/** Media Elements **/
@media screen and (min-width: 768px) {
	
	.header-frame .header-content-section .search-input {
		width: 23vw;
	} 
	/** LeftSidebar **/
	#logoInMobile {
		display: none;
	}

	.page-layout-wrapper .layout-wrapper .side-menu#leftSidebar {
		background-color: #fff !important;
		width: 21vw;
		max-width: inherit !important;
		position: relative !important;
		height: calc(100vh - 60px) !important;
	}

	.layout-wrapper .side-menu .navbar-brand-box {
		display: none;
	}

	.layout-wrapper .side-menu .mid-sidenav-content {
		margin: 0 !important;
		justify-content: inherit !important;
	}

	.layout-wrapper .side-menu .mid-sidenav-content .side-menu-nav {
		width: 100% !important;
		margin: 20px auto 0;
	}

	.layout-wrapper .side-menu .mid-sidenav-content .side-menu-nav li.student-list-item {
		width: 100% !important;
	}

	.side-menu .mid-sidenav-content .side-menu-nav li.student-list-item button {
		padding: 10px 20px !important;
		color: #2b2b2b !important;
	}

	.side-menu .mid-sidenav-content .side-menu-nav li.student-list-item .active button {
		background: #FC8643 !important;
		color: #fff !important;
	}

	.side-menu .mid-sidenav-content .side-menu-nav li.student-list-item .active button img {
		filter: contrast(0) brightness(3);
		-webkit-filter: contrast(0) brightness(3);
	} 

	.side-menu .mid-sidenav-content .side-menu-nav li.student-list-item button div div {
		font-family: 'Poppins', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 1.5;
	}

	.side-menu .mid-sidenav-content .side-menu-nav li.student-list-item .footer-logo {
		position: absolute !important;
		margin-bottom: 0 !important;
		bottom: 30px;
		left: 50%;
		transform: translateX(-50%) !important;
	}

	.page-layout-wrapper .layout-wrapper.mid-content-frame {
		overflow: inherit;
	}

	.subject-list-frame {
		flex-wrap: wrap;
		gap: 15px;
	}
}

@media screen and (min-width: 767px) and (max-width: 900px) {
	.ev-circular-container .ev-icon-block {
		width: 52% !important;
	}

	.ev-circular-container .ev-text-block {
		font-size: 16px !important;
	}
}

/* ##Device = Laptops, Desktops ##Screen = B/w 1025px to 1280px */
@media (min-width: 1100px) and (max-width: 1439px) {
	.header-frame .header-content-section .search-input {
		width: 18.5vw;
	}

	.header-content-section .right-actions-col .setting-option-block ul li,
	.header-content-section .right-actions-col .setting-option-block ul .btn-logout {
		font-size: 16px;
	}

	.page-layout-wrapper .layout-wrapper .side-menu#leftSidebar {
		width: 21vw;
	}

	.side-menu .mid-sidenav-content .side-menu-nav li.student-list-item button div div {
		font-size: 16px;
	}
}

/******* Max Width **************/
@media screen and (max-width: 767px) {
	.student-list-item .ev-nav-list-item {
		padding: 15px;
		position: absolute;
		bottom: 65px;
		background: #c9c9c9;
		border-radius: 10px;
	}

	.student-list-item .ev-nav-list-item::before {
		content: '';
		position: absolute;
		bottom: -16px;
		right: 50%;
		width: 0;
		height: 0;
		border-top: 16px solid transparent;
		border-bottom: 10px solid transparent;
		border-right: 13px solid #c9c9c9;
		transform: rotate(135deg);
	}

	.side-menu-nav li.student-list-item .active button {
		background: #FC8643 !important;
		color: #fff !important;
	}

	.side-menu-nav li.student-list-item .active button img {
		filter: grayscale(1) contrast(10);
		-webkit-filter: grayscale(1) contrast(10);
	}

	.side-menu .mid-sidenav-content .side-menu-nav li.student-list-item button div div {
		padding-left: 10px !important;
		padding-right: 10px;
		font-size: 16px;
	}

	.student-list-item .footer-logo {
		width: 100% !important;
	}

	.subject-content-frame .resume-left-section {
		padding-bottom: 40px !important;
	}
 
	.subject-content-frame .title-lbl {
		font-size: 18px;
	}

	.subject-list-frame {
		gap: 20px;
		flex-wrap: wrap;
	}
	.subject-list-frame a {
		width: 40%;
	}
	.btm-feedback-section .white-rnd-frame {
		margin-bottom: 40px; 
	}
	.right-vid-dtl-col .top-action-section {
		margin-bottom: 20px;
		height: auto;
	}
	.right-vid-dtl-col .top-action-section .tab-content {
		min-height: 100%;
	}
	.top-action-section .tab-content .tab-pane {
		height: auto; 
		margin: 10px 0;
	}
	.fix-bottom-nav {
		bottom: 10px; 
	}
	.lessons-title-lable {
		font-size: 14px;
		margin-bottom: 10px;
	}
	.chapterTitle {
		font-size: 16px; 
	}

}

@media screen and (max-width: 640px) {
	.subject-list-frame a {
		width: 45%;
	}
}


@media screen and (max-width: 540px) {
	.subject-content-frame .resume-left-section .chapterResumeCard2 {
		width: 100%;
	}
	.subject-list-frame {
		justify-content: center;
	}
}

@media screen and (max-width: 480px) {
  .subject-list-frame a {
      max-width: 100%;
	  width: 100%;
  }
}


/** Max Height **/

@media screen and (max-height: 780px) {
	.report-content {
		max-height: 51vh;
	}

	.island-video .video-Card.video-gallery-card {
		padding-top: 15px;
	}
}

 