.left-side {
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 9999;
  width: 30%;
  height: 100%;
  background-color: #fff;
  box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.04);
    @media screen and (max-width: 767px) {
      width: 100%;
      top: 0;
      left: -100%;
    }
  h1 {
    font-size:24px;
  }
  .left-title {
    padding: 10px 20px 0;
    max-height: 25%,

  } 
  .test-search {
    padding: 11px;
    border: 2px solid #FC8643;
    width: 330px;
    text-transform: capitalize;
    margin: 0 0 15px;
    border-radius: 3px 0 0 3px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}
.left-side-mob {
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 9999;
  width: 30%;
  height: 100%;
  background-color: #fff;
  box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.04);
    @media screen and (max-width: 767px) {
      width: 100%;
      top: 0;
    }
  h1 {
    font-size:24px;
  }
  .left-title {
    padding: 10px 20px 0;
    max-height: 25%,
  } 
  .test-search {
    padding: 11px;
    border: 2px solid #FC8643;
    width: 330px;
    text-transform: capitalize;
    margin: 0 0 15px;
    border-radius: 3px 0 0 3px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}
.search-box {
  display: flex;
  align-items:flex-start;
  span {
    background: #FC8643;
    width: 48px;
    height: 48.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    position: relative;
    top: 0px;
    cursor: pointer;
    border-radius:0 3px 3px 0;
  }
}

.right-side {
  position: absolute;
  right: 0;
  z-index: 999;
  width: 70%;
  height: 100%;
  overflow: scroll;
  margin-bottom: 30px;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  h1 {
    font-size:24px;
    span {
      color: #767676;
      font-size: 16px;
      display: block;
      margin: 5px 0 0;
    } 
  }
}

.question-footer {
  position: fixed;
  bottom: 0px;
  width: 70%;
  z-index: 1;
  padding: 10px 20px;
  overflow: hidden;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  box-shadow: 10px 0px 10px rgba(0, 0, 0, 0.29);
  @media screen and (max-width: 767px) {
    width: 100%;
  }
    h3 {
      font-size: 18px;
      font-weight: 700;
      margin: 0;
      line-height: 25px;
      color: #2b2b2b !important;
    }
    p {
      margin: 0;
      span:nth-child(1) {
        color: #3F9C28;
      }
      span:nth-child(2) {
        color: #F3751A;
      }
      span:nth-child(3) {
        color: #ED3C3C;
      }
      span {
        strong {
          font-weight: 500;
          color: #000;
        }
      }
    }
  .import-btn {
      border: none;
      background-color: #fc8643;
      color: #fff;
      padding: 0.3rem 1rem;
      border-radius: 19px;
  }
}
.quize_edit {
    border: none;
    background-color: #fc8643;
    color: #fff;
    padding: 0.3rem 1rem;
    border-radius: 19px;
    margin: 10px;
}
.question-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 20px 10px;
  h1 {
    font-size: 26px;
    text-transform: capitalize;
  }
  button {
    border: none;
    background-color: #fc8643;
    color: #fff;
    padding: 0.3rem 1rem;
    border-radius: 8px;
  }
}
.ques-filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 20px;
  background: #FFE7D8;
  @media screen and (max-width: 767px) {
     flex-wrap: wrap;

  }


  .hide {
    left: -100;
  }
  .show {
    left: 0;
    transition: 1s;
  }
  p {
    margin: 0;
    @media screen and (max-width: 767px) {
      margin: 10px 0;
   }
  }
    select {
      margin: 0 10px;
      border-radius: 20px;
      border: 1px solid #f16b24;
      background-color: #fff;
      color: #2b2b2b;
      padding: 0.3rem 1rem;
      outline: none;
      @media screen and (max-width: 540px) {
        width: 100%;
        margin-bottom: 10px;
     }
      option {
        position: absolute;
        background-color: #fff;
        border-style: none;
        border-color: none;
        box-shadow: 0 1px rgba(0, 0, 0, 0.25);
        z-index: 1;
        cursor: pointer;
        option {
          background-color: #fff;
          cursor: pointer;
          &:hover {
            background-color: #fc8643;
          }
        }
      }
    }
    .reset-btn {
      color: #FC8643;
      background: transparent;
      border:none;
      font-size:14px;
      margin-left: 10px;
      cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
    } 
}
.singleButtonContainer {
  border-radius: 20px;
  border: 1px solid #f16b24;
  background-color: #ffffff;
  color: #2b2b2b;
  padding: 0.3rem 1rem;
  margin: 5px 7px;
  outline: none;
  option {
    background-color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #fc8643;
    }
  }
}
.search-result {
  .search-result-box {
    cursor: pointer;
    padding: 10px 20px;
    border-top: 4px solid #f1f4f6;
    margin: 12px 0;
    p {
      font-weight: 600;
      line-height:25px;
      text-transform: capitalize;
      span {
        display: block;
        color:#767676;
      }
    }
    .badge-primary {
      background:#32C7B5;
      padding: 6px;
      text-transform: capitalize;
    } 
  }:hover {
    background: #ffe7d8;
  }
}

.back-quiz-mob-search{
  display: flex;
  align-items: center;
  justify-content:space-between;
  padding:1px;

  @media screen and (min-width: 767px) {
    display: none;
  }
}

.quiz-mob-search {
  background: #fc8643;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
    img {
      width: 20px;
    }
    @media screen and (min-width: 767px) {
      display: none;
   }
}



 
 
